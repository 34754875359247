export class Category {
  ID: string;
  Controller: string;
  Bezeichnung: string;
  Kurzbezeichnung: string;
  Highlights: string;
  MediaSet?: string;
  ProduktgruppenID: string;
  ProduktgruppenSortierung?: number;
  HatLeistungen?: boolean;
  Leistungen?: Object;
}
