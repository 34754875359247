import { Consumer } from './consumer';
import { BusinessPlusParameter } from './business-plus-parameter';

export class Provider {
  ID: string;
  Name: string;
  Bild: string;
  Vermittlernummer: boolean;
  Aktiv: number;
  Consumer: Array<Consumer>;
  AnbieterRolle?: 'Versicherer' | 'Makler';
  BusinessPlusParameterListe: Array<BusinessPlusParameter>;
}
