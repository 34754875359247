import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _moment from 'moment';

import { MediaSet } from '../../../classes/media-set';
import { NavigationService } from '../../../services/navigation/navigation.service';

@Component({
  selector: 'app-media-set-create-dialog',
  templateUrl: './media-set-create-dialog.component.html',
  styleUrls: ['./media-set-create-dialog.component.css']
})
export class MediaSetCreateDialogComponent implements OnInit {
  public mediaSet: MediaSet;

  constructor(
    public dialogRef: MatDialogRef<MediaSetCreateDialogComponent>,
    public navigationService: NavigationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.navigationService.startLoading('media_set_create_init');

    this.mediaSet = {
      ID: '',
      SetID: '',
      AnbieterID: '',
      AnbieterName: '',
      Beschreibung: '',
      Kategorien: [],
      Produkte: [],
      Downloads: [],
      Formulare: [],
      Videos: [],
      Testberichte: [],
    };

    this.navigationService.stopLoading('media_set_create_init');
  }

}
