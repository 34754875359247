import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, of, forkJoin } from 'rxjs';
import * as _moment from 'moment';
import { Router } from '@angular/router';

import { Md5 } from 'ts-md5/dist/md5';

import { ItemComponent } from '../../classes/item-component';
import { Consumer } from '../../classes/consumer';
import { Editor } from '../../classes/editor';

import { ConsumerService } from '../../services/consumer/consumer.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MessageService } from '../../services/message/message.service';
import { EditorService } from 'src/app/services/editor/editor.service';


export class ConsumerEditor extends Editor<ConsumerItemComponent, any> {

  constructor(
    public component: ConsumerItemComponent,
    private router: Router,
    private messageService: MessageService,
    public dialog: MatDialog,
    private consumerService: ConsumerService,
    private isNewConsumer: boolean,
    private editorService: EditorService
  ) {
      super(component);
  }

  submit(model: any, type: string): Observable<void> {
    return new Observable(subscriber => {
      this.submitModel(model).subscribe((consumer: Consumer) => {
        this.editorService.setModel(null);
        subscriber.next();
        subscriber.complete();

        if (this.isNewConsumer) {
          this.router.navigate(['/consumer-edit', consumer.ID]);
        } else if (this.router.url !== '/providers-management/consumer') {
          this.router.navigate(['/providers-management', 'consumer']);
        } else {
          this.component.editConsumer(false);
        }
      }, error => {
        subscriber.error(error);

        this.messageService.showError('Beim Aktualisieren des Consumers ist es zu einem Fehler gekommen: '
         + error.error.message, error);
      });
    });
  }

  submitModel(model): Observable<Consumer> {
    const file: File = model.Datei ? model.Datei : null;

    if (this.isNewConsumer) {
      return new Observable(subscriber => {
        this.consumerService.create(model).subscribe((consumer: Consumer) => {
          if (file) {
            this.consumerService.uploadConsumerIcon(file, model, subscriber);
          } else {
            subscriber.next(consumer);
            subscriber.complete();
          }
        });
      });
    }

    return new Observable(subscriber => {
      this.consumerService.update(model).subscribe((consumer: Consumer) => {
        if (file) {
          this.consumerService.uploadConsumerIcon(file, model, subscriber);
        } else {
          subscriber.next(consumer);
          subscriber.complete();
        }
      });
    });
  }

  preModelModifier(model: any): void {

  }

  postModelModifier(model: any, type: string): void {

  }
}


@Component({
  selector: 'app-consumer-item',
  templateUrl: './consumer-item.component.html',
  styleUrls: ['./consumer-item.component.css']
})
export class ConsumerItemComponent extends ItemComponent implements OnInit {

  @Input() public consumer: Consumer;
  @Input() public panelOpenState = false;
  @Input() public info = false;
  @Input() public edit = false;
  @Input() public cancelRedirectUrl = '';

  public editor: ConsumerEditor;
  public editorInitialized = false;

  public isNewConsumer = false;

  constructor(
    public dialog: MatDialog,
    private consumerService: ConsumerService,
    private router: Router,
    private navigationService: NavigationService,
    private messageService: MessageService,
    private editorService: EditorService
  ) {
    super();
  }

  createEditorSteps(): void {
    // create step 1
    this.editor.steps.push({
      label: 'Grundeinstellungen',
      fields: [{
        template: '<br>'
      }, {
        type: 'input',
        key: 'Name',
        templateOptions: {
          label: 'Name',
          appearance: 'outline',
          description: 'Der Name ist die dargestellte Bezeichnung des Consumers.',
          maxLength: 256,
          required: true,
          change: (field, $event) => {
            (<any>field.formControl.parent.controls).ID.setValue(Md5.hashStr($event.target.value));
          }
        }
      }, {
        template: '<br>'
      }, {
        type: 'input',
        key: 'ID',
        templateOptions: {
          label: 'ID',
          appearance: 'outline',
          description: 'Die eindeutige ID ist der Schlüssel des Consumers.',
          maxLength: 256,
          required: true,
          disabled: true
        }
      }, {
        template: '<br>'
      }, {
        type: 'input',
        key: 'Suffix',
        templateOptions: {
          label: 'Suffix',
          appearance: 'outline',
          description: 'Der Suffix ist die Endung des Consumers.',
          maxLength: 256,
          required: true,
          disabled: !this.isNewConsumer
        }
      }, {
        template: '<br><p><strong>Consumer Icon</strong><br>Das Consumer Icon wird oben links auf der Startseite im Tarifrechner angezeigt und muss als JPEG hinterlegt werden.</p>'
      }, { // Dateiupload
        key: 'Datei',
        type: 'image-crop',
        className: 'flex-1',
        templateOptions: {
          label: 'Dateiauswahl',
          floatLabel: 'always',
          appearance: 'outline',
          accept: '.jpg,.jpeg,.png,.gif',
          image: this.isNewConsumer ? null : 'public/assets/consumer/' + this.consumer.Suffix + '/START',
          disabled: false,
          cropperMinWidth: 100,
          cropperMinHeight: 100,
          previewHeight: 80,
          onImageChange: (model: any, image: any, originalImage: any, isAppliedExternal: boolean) => {
            this.editor.editorComponent.validateControlByKey('ConsumerImageValidator');
          }
        }
      }, {
        key: 'ConsumerImageValidator',
        type: 'hidden',
        validators: {
          required: {
            expression: (field) => {
              return  typeof (<any>this.editor.model).Datei !== 'undefined' && (<any>this.editor.model).Datei !== null;
            },
            message: () => 'Es muss ein Bild hochgeladen werden.'
          }
        }
      }]
    });
  }

  initEditor() {
    if (!this.editorInitialized) {
      this.navigationService.startLoading('consumer_editor_init');

      this.editor = new ConsumerEditor(
        this,
        this.router,
        this.messageService,
        this.dialog,
        this.consumerService,
        this.isNewConsumer,
        this.editorService
      );

      // tslint:disable-next-line:max-line-length
      this.editor.setInfo1(() => '');
      this.editor.setInfo2(() => '');
      this.editor.title = 'EDITOR';

      forkJoin([
        of({})
      ]).subscribe((result) => {
        this.createEditorSteps();
        this.editorInitialized = true;
        setTimeout(() => {
          this.editorService.setModel(this.consumer);
          }, 0);
        this.navigationService.stopLoading('consumer_editor_init');
      });
    }
  }

  ngOnInit() {
    this.isNewConsumer = this.consumer.ID === '';
    if (this.edit) {
      this.panelOpenState = true;
      this.initEditor();
    }
  }

  editConsumer(edit: boolean) {
    this.info = false;
    this.edit = edit;

    if (edit) {
      this.initEditor();
    } else if (this.cancelRedirectUrl) {
      this.router.navigate([this.cancelRedirectUrl]);
    } else if (this.editorInitialized) {
      this.editorInitialized = false;
    }
  }

  ngOnDestroy(): void {
    if (this.editorInitialized) {
      this.editorService.setModel(null);
    }
  }
}
