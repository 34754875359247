
export const STAGES = [
  { value: 'Entwicklung', name: 'Snoopr Entwicklung', shortname: 'E' },
  { value: 'Test', name: 'Snoopr Test', shortname: 'T' },
  { value: 'Systemtest', name: 'Snoopr Systemtest', shortname: 'ST' },
  { value: 'Vorproduktion', name: 'Snoopr Vorproduktion', shortname: 'VP' },
  { value: 'Produktion', name: 'Snoopr Produktion / Live', shortname: 'P' },
  { value: 'LD-Entwicklung', name: 'b-OX LD Entwicklung', shortname: 'LDE' },
  { value: 'LD-Test', name: 'b-OX LD Test', shortname: 'LDT' },
  { value: 'LD-Systemtest', name: 'b-OX LD Systemtest', shortname: 'LDST' },
  { value: 'LD-Vorproduktion', name: 'b-OX LD Vorproduktion', shortname: 'LDVP' },
  { value: 'LD-Produktion', name: 'b-OX LD Produktion / Live', shortname: 'LDP' }
];
