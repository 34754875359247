import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PdfViewerDialogComponent } from '../../components/dialog/pdfviewer-dialog/pdfviewer-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PdfViewerService {
  constructor(public dialog: MatDialog) {}


  private openDialog(name: string, src: string | ArrayBuffer, keywords?: Array<string>): void {
    const dialogRef = this.dialog.open(PdfViewerDialogComponent, {
        panelClass: 'dialog-fullscreen',
        data: {
            Titel: name,
            Source: src,
            Keywords: []
        }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openDoc(name: string, src: string | File | ArrayBuffer, keywords?: Array<string>): void {
    if (typeof src === 'string' || src instanceof ArrayBuffer) {
        this.openDialog(name, src, keywords);
    } else {
        const reader = new FileReader();
        reader.onload = () => {
            this.openDialog(name, reader.result, keywords);
        };
        reader.readAsArrayBuffer(src);
    }
   }
}
