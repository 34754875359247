import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-tip-section',
  templateUrl: './form-type-tip.component.html',
  styleUrls: ['./form-type-tip.component.css'],
})

export class FormTypeTipComponent extends FieldType implements OnInit {
  public opt: any;

  constructor() {
    super();
    this.opt = {};
  }

  ngOnInit() {
    this.opt = this.to;
  }
}
