import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import { MatDialog } from '@angular/material';
import { GroupedCategorySelectDialogComponent } from '../dialog/grouped-category-select-dialog/grouped-category-select-dialog.component';
import { GroupedCategorySelectDialog } from 'src/app/classes/grouped-category-select-dialog';
import { FormControl} from '@angular/forms';
import { Observable } from 'rxjs';
import { CategoryService } from 'src/app/services/category/category.service';

@Component({
  selector: 'app-formly-category-select-section',
  templateUrl: './form-type-category-select.component.html',
  styleUrls: ['./form-type-category-select.component.css'],
})

export class FormTypeCategorySelectComponent extends FieldType implements OnInit {
  @ViewChild('categoryLabels') private categoryLabels: ElementRef;

  public inputFormControl: FormControl;

  public initialized: boolean;

  constructor(
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.initialized = false;
    if (this.to.categories instanceof Observable) {
      this.to.categories.subscribe((result) => {
        this.to.categories = result;
        this.initialized = true;

        const values = [];
        for (let i = 0; i < this.to.categories.length; i++) {
          if (this.to.categories[i].selected) {
            values.push(this.to.categories[i].value);
          }
        }
        this.formControl.patchValue(this.to.single ? values[0] : values);

        this.setCategoryLabels();
      });
    } else {
      this.initialized = true;
    }
  }
  
  ngAfterViewInit() {
    this.setCategoryLabels();
  }


  openSelectDialog() {
    const groupedCategorySelectDialog = new GroupedCategorySelectDialog();

    const ungroupedCategories = [];
    for (let i = 0; i < this.to.categories.length; i++) {
      ungroupedCategories.push(this.to.categories[i]);
    }

    const groupedCategories = [];
    const categoryGroups = [];
    for (let i = 0; i < CategoryService.categoryGroups.length; i++) {
      const group: {label: string; value: string; count: number} = Object.assign({}, <any>CategoryService.categoryGroups[i]);
      group.count = 0;
      categoryGroups.push(group);

      for (let c = 0; c < ungroupedCategories.length; c++) {
        const category = ungroupedCategories[c];
        if (category.group === group.value || group.value === '_SONSTIGE') {
          groupedCategories.push(ungroupedCategories.splice(c--, 1)[0]);
          group.count++;

          category.group = group.value;
        }
      }
    }

    groupedCategorySelectDialog.single = this.to.single || false;
    groupedCategorySelectDialog.categories = groupedCategories;
    groupedCategorySelectDialog.groups = categoryGroups;
    groupedCategorySelectDialog.isEdit = this.to.isEdit;
    this.dialog.open(GroupedCategorySelectDialogComponent, {
      data: groupedCategorySelectDialog
    }).afterClosed().subscribe((categories: Array<any>) => {
      if (typeof categories !== 'undefined') {
        const values = [];
        for (let i = 0; i < categories.length; i++) {
          values.push(categories[i].value);
        }
        this.formControl.patchValue(this.to.single ? values[0] : values);

        for (let i = 0; i < this.to.categories.length; i++) {
          this.to.categories[i].selected = (this.to.single && this.formControl.value === this.to.categories[i].value) || (!this.to.single && this.formControl.value.indexOf(this.to.categories[i].value) >= 0);
        }

        this.setCategoryLabels();
      }
    });
  }

  setCategoryLabels() {
    let value = '';
    for (let i = 0; i < this.to.categories.length; i++) {
      if (this.to.categories[i].selected) {
        if (value.length > 0) {
          value += ', '
        }
        value += this.to.categories[i].label;
      }
    }
    this.categoryLabels.nativeElement.value = value ? value : 'Keine Auswahl';
  }
}
