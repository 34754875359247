import { Component, OnInit, Input } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { ItemComponent } from '../../classes/item-component';
import { Editor } from '../../classes/editor';
import { Medium } from '../../classes/medium';
import { MediaSet } from '../../classes/media-set';
import { Provider } from '../../classes/provider';
import { GenericDialog } from '../../classes/generic-dialog';
import { Category } from '../../classes/category';
import { Product } from '../../classes/product';

import { NavigationService } from '../../services/navigation/navigation.service';
import { MediaService } from '../../services/media/media.service';
import { ProviderService } from '../../services/provider/provider.service';
import { MessageService } from '../../services/message/message.service';
import { MediaSetService } from '../../services/media-set/media-set.service';
import { StorageService } from '../../services/storage/storage.service';
import { AuthService } from '../../services/auth/auth.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { CategoryService } from '../../services/category/category.service';
import { ProductService } from '../../services/product/product.service';

import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';
import { CategorySelectDialogComponent } from '../dialog/category-select-dialog/category-select-dialog.component';
import { ProductSelectDialogComponent } from '../dialog/product-select-dialog/product-select-dialog.component';
import { ProductSelectDialog } from 'src/app/classes/product-select-dialog';
import { EditorService } from 'src/app/services/editor/editor.service';

export class MediaSetEditor extends Editor<MediaSetItemComponent, any> {

  public title = '...';

  constructor(
    public component: MediaSetItemComponent,
    private router: Router,
    private messageService: MessageService,
    private mediaSetService: MediaSetService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private route: ActivatedRoute,
    private editorService: EditorService
  ) {
      super(component);
  }

  submit(model: any, type: string): Observable<void> {
    return new Observable(subscriber => {
      if (!this.dialogRef || model.ID) {
        this.submitModel(model).subscribe((mediaSet: MediaSet) => {
          this.editorService.setModel(null);
          subscriber.next();
          subscriber.complete();

          if (this.dialogRef) {
            this.dialogRef.close(mediaSet);
          } else if (!model.ID) {
            this.router.navigate(['/media-set-edit', mediaSet.ID]);
          } else if (this.router.url !== '/media-set-list') {
            this.route.queryParams.subscribe(params => {
              const ref = params.ref || null;
              if (ref !== null) {
                this.router.navigateByUrl(params.ref);
              } else {
                this.router.navigate(['/media-set-list']);
              }
            });
          } else {
            this.component.editMediaSet(false);
          }
        }, error => {
          subscriber.error(error);
          this.messageService.showError('Beim Aktualisieren des Mediums ist es zu einem Fehler gekommen: '
          + error.error.message, error);
        });
      } else {
        this.editorComponent.notifyMessages = false;

        subscriber.next();
        subscriber.complete();

        this.dialogRef.close(model);
      }
    });
  }

  submitModel(model): Observable<MediaSet> {
    if (model.ID === '') {
      return this.mediaSetService.create(model);
    } else {
      return this.mediaSetService.update(model);
    }
  }

  delete(model): Observable<void> {
    console.log('delete -> ' + model.ID);
    const genericDialog = new GenericDialog();
    genericDialog.titel = 'Löschvorgang bestätigen';
    genericDialog.text = 'Sie sind im Begriff, das Medien-Paket zu löschen. \
      Dieser Schritt ist unwiderruflich. Möchten Sie den \
      Löschvorgang fortsetzen?';
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      maxWidth: '500px',
      maxHeight: '100vh',
      data: genericDialog
    });
    return new Observable(subscriber => {
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.mediaSetService.delete(model.ID).subscribe(() => {
              subscriber.next();
              subscriber.complete();
            if (!this.dialogRef) {
              this.route.queryParams.subscribe(params => {
                const ref = params.ref || null;
                if (ref !== null) {
                  this.router.navigateByUrl(params.ref);
                } else {
                  this.router.navigate(['/media-set-list']);
                }
              });
            } else {
              this.dialogRef.close('deleted');
            }
          }, error => {
            subscriber.error(error);
            this.messageService.showError('Beim Löschen des Medien-Pakets ist es zu einem Fehler gekommen: '
            + error.error.message, error);
          }); // OK -> route observable from mediaService
        } else {
          // CANCEL -> not error, not next, instead call complete
          subscriber.complete();
        }
      });
    });
  }

  preModelModifier(model: any): void {
    if (model.preModelModified) {
      return;
    }
    model.preModelModified = true;

    model.Anbieter = {
      ID: model.AnbieterID,
      Name: model.AnbieterName,
      AnbieterRolle: model.AnbieterRolle
    };
  }

  postModelModifier(model: any, type: string): void {
    model.AnbieterID = model.Anbieter.ID;
    model.AnbieterName = model.Anbieter.Name;
    model.AnbieterRolle = model.Anbieter.AnbieterRolle;

    for (let i = 0; i < model.Produkte.length; i++) {
      if (typeof model.Produkte[i] !== 'string') {
        model.Produkte[i] = model.Produkte[i].ID;
      }
    }
    for (let i = 0; i < model.Kategorien.length; i++) {
      if (typeof model.Kategorien[i] !== 'string') {
        model.Kategorien[i] = model.Kategorien[i].ID;
      }
    }
  }
}
@Component({
  selector: 'app-media-set-item',
  templateUrl: './media-set-item.component.html',
  styleUrls: ['./media-set-item.component.css']
})
export class MediaSetItemComponent extends ItemComponent implements OnInit {
  @Input() public mediaSet: MediaSet;
  @Input() public panelOpenState = false;
  @Input() public edit = false;
  @Input() public cancelRedirectUrl = '';
  @Input() public dialogRef: MatDialogRef<any>;
  @Input() public dataOnly = false;
  @Input() public providerList: Array<Provider> = null;

  private referenceProducts: Array<Product> = [];

  public editor: MediaSetEditor;
  public editorInitialized = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private navigationService: NavigationService,
    private productService: ProductService,
    private categoryService: CategoryService,
    private providerService: ProviderService,
    private messageService: MessageService,
    private mediaSetService: MediaSetService,
    private storageService: StorageService,
    public authService: AuthService,
    private consumerService: ConsumerService,
    private route: ActivatedRoute,
    private editorService: EditorService
  ) {
    super();
  }

  ngOnInit() {
    if (this.edit) {
      this.panelOpenState = true;
      this.initEditor();
    }
  }
  
  ngOnDestroy(): void {
    if (this.editorInitialized) {
      this.editorService.setModel(null);
    }
  }

  createEditorSteps(): void {
    const anbieterOptions = this.providerList.slice();

    // Anbieter Vorauswahl
    if (!this.mediaSet.AnbieterID && anbieterOptions.length === 1) {
      this.mediaSet.AnbieterID = anbieterOptions[0].ID;
      this.mediaSet.AnbieterName = anbieterOptions[0].Name;
      this.mediaSet.AnbieterRolle = anbieterOptions[0].AnbieterRolle;
    }

    if (!this.dataOnly) {
      for (let i = 0; i < this.mediaSet.Produkte.length; i++) {
        if (typeof this.mediaSet.Produkte[i] === 'string') {
          (<any>this.mediaSet).Produkte[i] = {
            ID: this.mediaSet.Produkte[i]
          };
        }
      }

      for (let i = 0; i < this.mediaSet.Kategorien.length; i++) {
        if (typeof this.mediaSet.Kategorien[i] === 'string') {
          (<any>this.mediaSet).Kategorien[i] = {
            ID: this.mediaSet.Kategorien[i]
          };
        }
      }
    }

    // create step 1
    this.editor.steps.push({
      label: 'Eigenschaften',
      fields: [{ // SetID
        type: 'input',
        key: 'SetID',
        templateOptions: {
		  label: 'Eindeutige Bezeichnung (ID)',
		  floatLabel: 'always',
		  description: 'Über diese Bezeichnung lässt sich das Medienpaket eindeutig identifizieren \
		  und von anderen (ggf. ähnlichen) Sets unterscheiden.',
		  required: true,
		  placeholder: 'z.B. PHV.Medien.Vermieter',
          appearance: 'outline'
        },
        expressionProperties: {
          'templateOptions.disabled': (model) => (model.ID !== '' ? true : false),
        },
        validators: {
          id: {
            expression: (c) => !c.value || /^[A-z,0-9\.]{1,}$/.test(c.value),
            // tslint:disable-next-line:max-line-length
            message: (error, field) => 'Die Bezeichnung kann aus den Zeichen "a-Z", "0-9" oder "." (Punkt) bestehen. Leerzeichen dürfen nicht verwendet werden.',
          }
        }
      }, { // Leerzeile
            template: '<br/>&nbsp;<br/>'
      }, { // Anbieter
        key: 'Anbieter',
        type: 'select',
        templateOptions: {
          label: 'Anbieter',
          floatLabel: 'always',
          appearance: 'outline',
          placeholder: 'Anbieter des Mediums',
          description: 'Anbieter des Mediums. In den meisten Fällen steht hier nur ein \
            Anbieter zur Auswahl.',
          required: true,
          options: anbieterOptions,
          focus: () => { },
          blur: () => { },
          labelProp: (item) => item.Name + ' (' + item.ID + ')',
          valueProp: (item) => item,
          compareWith: (o1, o2) => {
            if (o1 === null || o2 === null) {
              return o1 === o2;
            }
            return o1.ID === o2.ID;
          },
          change: (event) => {
            this.mediaSet.AnbieterID = event.formControl.value.ID;
            this.mediaSet.AnbieterName = event.formControl.value.Name;
            this.mediaSet.AnbieterRolle = event.formControl.value.AnbieterRolle;
          }
        },
        expressionProperties: {
          'templateOptions.disabled': (model) => (model.ID !== '' ? true : false),
        },
        validators: {
          required: {
            expression: (field) => {
              return field.value.ID.length > 0;
            },
            message: () => 'Bitte Anbieter auswählen.'
          }
        }
      }, { // Leerzeile
            template: '<br/>&nbsp;<br/>'
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
			key: 'Beschreibung',
            type: 'textarea',
            className: 'flex-1',
            templateOptions: {
			  label: 'Beschreibung',
			  floatLabel: 'always',
			  appearance: 'outline',
			  placeholder: 'z. B. Zusammenfassung von Medien für die Zielgruppe "Studenten" für den Bereich Haftpflicht.',
			  description: 'Hier können Sie beschreiben, welche Medien Sie zusammengestellt haben. \
			  So können Sie später immer schnell erkennen, um was es geht. Das erleichtert Ihnen die \
			  Zuordnung zu Produkten.',
              rows: 3
            }
          }
        ]
      }]
    });

    if (!this.dataOnly) {
      // create step 2
      const _this = this;
      this.editor.steps.push({
        label: 'Produkte / Kategorien verknüpfen',
        fields: [{
            key: 'PuKValidator',
            type: 'hidden',
            validators: {
              minOne: {
                expression: (field) => {
                  return this.editor.model.Produkte.length + this.editor.model.Kategorien.length > 0;
                },
                message: () => 'Es muss mindestens ein Produkt oder eine Kategorie zugeordnet werden.'
              }
            }
          }, {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'Produkte',
              type: 'repeat',
              className: 'flex-1',
              hideExpression: () => this.hasProductLinking(),
              fieldArray: {
                fieldGroupClassName: 'display-flex',
                templateOptions: {
                  label: 'Produkt hinzufügen',
                  maxLength: 999,
                  isCustom: true,
                  customAdd: () => {
                    const dialogRef = this.dialog.open(ProductSelectDialogComponent, {
                      panelClass: 'product-select-dialog-container',
                      maxHeight: '100vh',
                      data: <ProductSelectDialog>{
                        providerId: this.mediaSet.AnbieterID,
                        excludeProductIds: this.mediaSet.Produkte.map((product: any) => product.ID),
                        selectLimit: 20,
                        includeModels: ['BUSINESS', 'BUSINESS_PRO', 'BUSINESS_PLUS']
                      }
                    });
                    return new Observable(subscriber => {
                      dialogRef.afterClosed().subscribe((result: Array<Product>) => {
                        if (result !== null && result.length > 0) {
                          for (let i = 0; i < result.length; i++) {
                            const selectedProduct = result[i];

                            _this.addProductToReferences(selectedProduct);
                            subscriber.next(selectedProduct);

                            if (selectedProduct.MediaSet) {
                              const genericDialog = new GenericDialog();
                              genericDialog.titel = 'Bisherige Verknüpfung auflösen';
                              genericDialog.text = 'Das ausgewählte Produkt \'' + selectedProduct.Bezeichnung + '\' ist bereits mit dem Medien-Paket \'' +
                                selectedProduct.MediaSet + '\' verknüpft. ';
                              genericDialog.text += 'Ein Produkt darf nur mit einem Medien-Paket verknüpft sein. ';
                              genericDialog.text += 'Falls Sie das Medien-Paket speichern wird die bisherige Verknüpfung gelöscht ';
                              genericDialog.text += 'und das Produkt wird mit dem vorliegenden Medien-Paket verknüpft.';
                              const genericDialogRef = this.dialog.open(GenericDialogComponent, {
                                maxWidth: '500px',
                                maxHeight: '100vh',
                                data: genericDialog
                              });
                            }
                          }
                        }
                        subscriber.complete();
                      });
                    });
                  }
                },
                fieldGroup: [
                  {
                    className: 'flex-1',
                    type: 'product',
                    templateOptions: {
                      referenceProducts: this.referenceProducts
                    }
                  }
                ]
              }
            }
          ]
        }, {
          template: '<br><br><br>',
          hideExpression: () => this.hasCategoryLinking(),
        }, {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'Kategorien',
              type: 'repeat',
              className: 'flex-1',
              hideExpression: () => this.hasCategoryLinking(),
              fieldArray: {
                fieldGroupClassName: 'display-flex',
                templateOptions: {
                  label: 'Kategorie hinzufügen',
                  maxLength: 999,
                  isCustom: true,
                  customAdd: () => {
                    const dialogRef = this.dialog.open(CategorySelectDialogComponent, {
                      panelClass: 'media-set-select-dialog-container',
                      maxHeight: '100vh',
                      data: {
                        Categories: this.categoryService.categoryList.filter((category: Category) => {
                          for (let i = 0; i < this.mediaSet.Kategorien.length; i++) {
                            if ((<any>this.mediaSet.Kategorien[i]).ID === category.ID) {
                              return false;
                            }
                          }
                          return true;
                        }).map(category => category)
                      }
                    });
                    return dialogRef.afterClosed();
                  }
                },
                fieldGroup: [
                  {
                    className: 'flex-1',
                    type: 'category'
                  }
                ]
              }
            }
          ]
        }]
      });

      this.editor.steps.push({
        label: 'Medien verknüpfen',
        fields: [{
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            { // Tipp
              type: 'tip',
              templateOptions: {
                label: 'Warnung',
                open: true,
                description: '<p>Es ist zu beachten, dass nur Enterprise Kunden mehr als 5 Medien anhängen dürfen.\
                              Für den Systemuser wird nicht auf dieses Limit überprüft!</p>'
              },
              // tslint:disable-next-line:max-line-length
              hideExpression: () => !this.authService.isSystem() || (this.mediaSet.Downloads.length <= 5 && this.mediaSet.Testberichte.length <= 5 && this.mediaSet.Formulare.length <= 5)
            }
          ]
        }, {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              type: 'media-linking',
              className: 'width-100',
              templateOptions: {
                providerId: this.mediaSet.AnbieterID,
                Downloads: this.mediaSet.Downloads,
                Testberichte: this.mediaSet.Testberichte,
                Videos: this.mediaSet.Videos,
                Formulare: this.mediaSet.Formulare,
                limits: {
                  Downloads: this.authService.isDefault() ? 5 : 10,
                  Testberichte: this.authService.isDefault() ? 5 : 10,
                  Formulare: this.authService.isDefault() ? 5 : 10
                }
              }
            }
          ]
        }]
      });
    }
  }

  addProductToReferences(product: Product) {
    if (product === null) {
      return;
    }

    let hasReference = false;
    for (let i = 0; i < this.referenceProducts.length; i++) {
      if (this.referenceProducts[i].ID === product.ID) {
        hasReference = true;
        break;
      }
    }
    if (!hasReference) {
      this.referenceProducts.push(product);
    }
  }

  initEditor() {
    if (!this.editorInitialized) {
      this.navigationService.startLoading('media_set_editor_init');

      this.editor = new MediaSetEditor(this,
        this.router,
        this.messageService,
        this.mediaSetService,
        this.dialog,
        this.dialogRef,
        this.route,
        this.editorService
      );

      this.editor.setInfo1(() => '');
      this.editor.setInfo2(() => '');
      this.editor.title = 'EDITOR';

      forkJoin([
        this.mediaSet.ID ? this.mediaSetService.loadMediaSet(this.mediaSet.ID) : of(this.mediaSet)
      ]).subscribe((result1) => {
        this.mediaSet = result1[0];

        forkJoin([
          this.productService.loadProductListByIds(this.mediaSet.Produkte),
          // tslint:disable-next-line: max-line-length
          this.providerList == null ? (this.mediaSet.ID ? of([{ID: this.mediaSet.AnbieterID, Name: this.mediaSet.AnbieterName, AnbieterRolle: this.mediaSet.AnbieterRolle}]) : of(this.providerService.providerFilterList.slice(1))) : of(this.providerList)
        ]).subscribe(result2 => {
          this.referenceProducts = result2[0].productList;
          (<any>this.providerList) = result2[1];
          this.createEditorSteps();
          this.editorInitialized = true;
          setTimeout(() => {
            this.editorService.setModel(this.mediaSet);
          }, 0);
          this.navigationService.stopLoading('media_set_editor_init');
        });
      });
    }
  }

  editMediaSet(edit: boolean) {
    this.edit = edit;

    if (edit) {
      this.initEditor();
    } else if (this.dialogRef) {
      this.dialogRef.close(null);
    } else {
      this.route.queryParams.subscribe(params => {
        const ref = params.ref || null;
        if (ref !== null) {
          this.router.navigateByUrl(params.ref);
        } else if (this.cancelRedirectUrl) {
            this.router.navigate([this.cancelRedirectUrl]);
        } else if (this.editorInitialized) {
          this.editorInitialized = false;
        }
      });
    }
  }

  hasProductLinking() {
    return this.mediaSet.AnbieterID === 'SNOOPR';
  }

  hasCategoryLinking() {
    return this.authService.isDefault() || (
      this.authService.isSystem() &&
      this.consumerService.selectedConsumer.Name === 'Snoopr' &&
      this.mediaSet.AnbieterID !== 'SNOOPR'
    );
  }
}
