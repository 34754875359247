import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Customer } from '../../classes/customer';

import { ItemList } from '../../classes/item-list';

import { MessageService } from '../../services/message/message.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AuthService } from '../../services/auth/auth.service';
import { CustomerService } from '../../services/customer/customer.service';
import { map } from 'rxjs/operators';
import { SearchFieldComponent } from '../search-field/search-field.component';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent extends ItemList<Customer> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  constructor(
    private router: Router,
    public navigationService: NavigationService,
    public messageService: MessageService,
    public authService: AuthService,
    public customerService: CustomerService
  ) {
    super('customer', navigationService, messageService);
  }

  addCustomer(): () => void {
    return (() => {
      this.router.navigate(['/customer-create']);
    });
  }

  loadItems(limit: number, offset: number) {
    return this.customerService.loadCustomerList(
      limit,
      offset,
      this.searchField.searchTerm || null,
      this.filter.selectedCustomerTypeId,
      this.filter.selectedCustomerVerifyStatusId,
      ).pipe(
      map(result => ({items: result.customerList, maxCount: result.metaData.maxCount}))
    );
  }
}
