import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import Amplify from 'aws-amplify';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Fingerprint2 from 'fingerprintjs2';

if (environment.production) {
  /* Google Analytics */
  const setupAnalytics = (fp) => {
    (function(i, s, o, g, r, a, m) {i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function() {
    (i[r].q = i[r].q || []).push(arguments); }, i[r].l = 1 * (+new Date()); a = s.createElement(o),
    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    (<any>window).ga('create', 'UA-7889342-9', {
    'storage': 'none',
      'storeGac': false,
      'clientId': fp
    });
    (<any>window).ga('set', 'anonymizeIp', true);
    (<any>window).ga('send', 'pageview');
  };

  if ((<any>window).requestIdleCallback) {
    (<any>window).requestIdleCallback(function () {
      new Fingerprint2().get(function (result, components) {
        setupAnalytics(result);
      });
    });
  } else {
    setTimeout(function () {
      new Fingerprint2().get(function (result, components) {
        setupAnalytics(result);
      });
    }, 500);
  }

  enableProdMode();
}

Amplify.configure({
    Auth: {
        identityPoolId: environment.auth.identityPoolId,
        region: environment.auth.region,
        userPoolId: environment.auth.userPoolId,
        userPoolWebClientId: environment.auth.userPoolWebClientId
    },
    Storage: {
      bucket: environment.s3bucket,
      region: environment.auth.region
    }
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
