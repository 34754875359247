export class ImageCropDialog {
  ImageBase64: string;
  MaintainAspectRatio: boolean;
  IsRound: boolean;
  MinWidth: number;
  MinHeight: number;
  AspectRatio: number;
  ResizeToWidth: number;
  ResizeToHeight: number;
}
