import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import * as _moment from 'moment';

import { FormEditorDialog } from '../../../classes/form-editor-dialog';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyDialog } from 'src/app/classes/formly-dialog';
import { FormlyDialogComponent } from '../formly-dialog/formly-dialog.component';

const moment = _moment;


@Component({
  selector: 'app-form-editor-dialog',
  templateUrl: './form-editor-dialog.component.html',
  styleUrls: ['./form-editor-dialog.component.css']
})
export class FormEditorDialogComponent implements OnInit {
  @ViewChild('formly', { read: ElementRef }) formlyForm: ElementRef<HTMLElement>;

  public form = new FormGroup({});
  public model: any = {};
  public options: FormlyFormOptions = {};
  public fields: FormlyFieldConfig[] = [];

  public rawModels = [];

  constructor(
      public dialogRef: MatDialogRef<FormEditorDialogComponent>,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: FormEditorDialog
  ) {
  }

  ngOnInit(): void {
    if (this.data.Fragen) {
      for (let i = 0; i < this.data.Fragen.length; i++) {
        this.rawModels.push(this.duplicate(this.data.Fragen[i]));
      }

      for (let i = 0; i < this.rawModels.length; i++) {
        const model = this.rawModels[i];

        if (model.elements === void 0) {
          continue;
        }

        model.type = model.elements[0].type;

        if (typeof model.elements[0].subtype !== 'undefined') {
          model.type = model.elements[0].subtype;
        }

        model.hint = model.elements[0].hint;
        model.label = model.elements[0].label;
        model.optional = model.elements[0].optional;

        if (model.elements[0].options !== void 0) {
          model.options = model.elements[0].options;
        }

        delete model.elements;
      }
      this.buildFormFields();
    }
  }

  onCloseClick(save: boolean): void {
    for (let i = 0; i < this.rawModels.length; i++) {
      const model = this.rawModels[i];
      model.elements = [{}];

      model.elements[0].name = 'input_' + (i + 1);

      model.elements[0].type = model.type;
      delete model.type;

      if (model.elements[0].type === 'date') {
        model.elements[0].type = 'text';
        model.elements[0].subtype = 'date';
      }

      model.elements[0].hint = model.hint;
      delete model.hint;

      model.elements[0].label = model.label;
      delete model.label;

      model.elements[0].optional = model.optional;
      delete model.optional;

      if (model.options !== void 0) {
        model.elements[0].options = model.options;
        delete model.options;
      }

      model.type = 'dialog';
    }
    this.dialogRef.close(save ? this.rawModels : null);
  }

  openQuestionFieldDialog(fieldId?: number) {
    const isEditing = (fieldId !== void 0);
    const model = isEditing ? this.rawModels[fieldId] : {};

    const formlyDialog = new FormlyDialog();
    formlyDialog.titel = 'Frage ' + (model ? 'bearbeiten' : 'hinzufügen');
    formlyDialog.model = isEditing ? this.duplicate(model) : { optional: false};

    if (isEditing && formlyDialog.model.type === 'select') {
      // tslint:disable-next-line:max-line-length
      if (formlyDialog.model.options.length === 2 && formlyDialog.model.options[0].value === 'Ja' && formlyDialog.model.options[1].value === 'Nein') {
        formlyDialog.model.type = 'yesno';
        formlyDialog.model.options = [];
      }

      if (formlyDialog.model.type === 'select') {
        const newOptions = [];
        for (let i = 0; i < formlyDialog.model.options.length; i++) {
          const wert = formlyDialog.model.options[i].value;
          newOptions.push({
            Text: wert
          });
        }
        formlyDialog.model.options = newOptions;
      }
    }

    formlyDialog.fields = [
      {
        type: 'input',
        key: 'submitLabel',
        templateOptions: {
          label: 'Fragestellung',
          type: 'text',
          required: true,
          maxLength: 500,
          placeholder: '',
          appearance: 'outline'
        },
        validators: {
          isQuestion: {
            expression: (field) => {
              return (field.value || '').indexOf('?') > 0;
            },
            message: () => 'Eine Frage muss mit einem Fragezeichen (?) enden.'
          }
        }
      },
      {
        type: 'input',
        key: 'label',
        templateOptions: {
          label: 'Bezeichnung des Feldes',
          type: 'text',
          required: true,
          maxLength: 500,
          placeholder: '',
          appearance: 'outline'
        }
      },
      {
        type: 'input',
        key: 'hint',
        templateOptions: {
          label: 'Hilfetext',
          type: 'text',
          required: false,
          maxLength: 500,
          placeholder: '',
          appearance: 'outline'
        }
      },
      {
        type: 'checkbox',
        key: 'optional',
        templateOptions: {
          label: 'Ist dieses Feld optional?',
        }
      },
      {
        type: 'select',
        key: 'type',
        templateOptions: {
          multiple: false,
          label: 'Antwortart',
          required: true,
          placeholder: '',
          appearance: 'outline',
          options: [
            { value: 'number', label: 'Zahl', disabled: false },
            { value: 'text', label: 'Text', disabled: false },
            { value: 'select', label: 'Auswahlfelder', disabled: false },
            { value: 'date', label: 'Datum', disabled: false },
            { value: 'yesno', label: 'Ja/Nein Auswahlfeld', disabled: false }
          ]
        }
      },
      {
        type: 'repeat-drag-drop',
        key: 'options',
        fieldArray: {
          fieldGroupClassName: 'display-flex',
          templateOptions: {
            label: 'Option hinzufügen'
          },
          fieldGroup: [
            { // Text
              className: 'flex-1',
              type: 'input',
              key: 'Text',
              templateOptions: {
                floatLabel: 'always',
                required: true,
                placeholder: 'Option',
                appearance: 'outline',
              }
            }
          ]
        },
        validators: {
          minOne: {
            expression: (field) => {
              return (field.controls.length > 0);
            },
            message: () => 'Es muss mindestens eine Option eingetragen werden.',
          },
        },
        hideExpression: () => {
          return formlyDialog.model.type !== 'select';
        }
      }
    ];
    formlyDialog.options = {};

    const dialogRef = this.dialog.open(FormlyDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      minWidth: '500px',
      maxHeight: '100vh',
      data: formlyDialog
    });

    dialogRef.afterClosed().subscribe(resultModel => {
      if (resultModel !== null) {
        if (resultModel.type === 'select') {
          const newOptions = [];
          for (let i = 0; i < resultModel.options.length; i++) {
            const wert = resultModel.options[i].Text;
            newOptions.push({
              label: wert,
              value: wert
            });
          }
          resultModel.options = newOptions;
        } else if (resultModel.type === 'yesno') {
          resultModel.type = 'select';
          resultModel.options = [{
              label: 'Ja',
              value: 'Ja'
            }, {
              label: 'Nein',
              value: 'Nein'
            }
          ];
        } else {
          delete resultModel.options;
        }

        if (isEditing) {
          this.updateQuestionField(fieldId, resultModel);
        } else {
          this.addQuestionField(resultModel);
        }
      }
    });
  }

  addQuestionField(model) {
    this.rawModels.push(model);
    this.buildFormFields();
  }

  updateQuestionField(fieldId, model) {
    this.rawModels[fieldId] = model;
    this.buildFormFields();
  }

  buildFormFields() {
    this.fields.splice(0, this.fields.length);
    const fieldGroups = [];
    for (let i = 0; i < this.rawModels.length; i++) {
      const model = this.rawModels[i];

      let specificField = null;
      if (model.type === 'number') {
        specificField = this.generateNumberField(model);
      } else if (model.type === 'text') {
        specificField = this.generateTextField(model);
      } else if (model.type === 'select') {
        specificField = this.generateSelectField(model);
      } else if (model.type === 'date') {
        specificField = this.generateDateField(model);
      }

      fieldGroups.push({
        key: 'question-' + i,
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            type: 'icon',
            className: 'align-items-flex-end padding-bottom-34',
            templateOptions: {
              icon: 'dehaze',
              cursor: 'move',
              size: '24px',
              isDragHandle: true
            }
          },
          {
            className: 'flex-1',
            fieldGroup: [
              {
                template: '<div>' + model.submitLabel + '</div>',
              },
              specificField
            ]
          },
          {
            type: 'button',
            className: 'margin-left-15 align-items-flex-end padding-bottom-22',
            templateOptions: {
              icon: 'edit',
              tooltip: 'Dieses Feld bearbeiten',
              onClick: () => {
                this.editField(i);
              }
            }
          },
          {
            type: 'button',
            className: 'margin-left-15 align-items-flex-end padding-bottom-22',
            templateOptions: {
              icon: 'delete',
              tooltip: 'Dieses Feld löschen',
              onClick: () => {
                this.deleteField(i);
              }
            }
          },
        ],
      });
    }

    this.fields = [...this.fields, {
      fieldGroupClassName: 'display-flex',
      type: 'drag-drop',
      fieldGroup: fieldGroups,
      templateOptions: {
        onDrop: (newIndex, previousIndex) => {
          this.drop(newIndex, previousIndex);
        }
      }
    }];
  }

  generateNumberField(model) {
    return {
      type: 'input',
      key: 'value',
      templateOptions: {
        type: 'number',
        floatLabel: 'always',
        required: !model.optional,
        label: model.label,
        placeholder: 'Antwort',
        appearance: 'outline',
      }
    };
  }

  generateTextField(model) {
    return {
      type: 'input',
      key: 'value',
      templateOptions: {
        type: 'text',
        floatLabel: 'always',
        required: !model.optional,
        label: model.label,
        placeholder: 'Antwort',
        appearance: 'outline',
      }
    };
  }

  generateSelectField(model) {
    return {
      type: 'select',
      key: 'value',
      templateOptions: {
        floatLabel: 'always',
        required: !model.optional,
        label: model.label,
        placeholder: 'Antwort',
        appearance: 'outline',
        options: model.options
      }
    };
  }

  generateDateField(model) {
    return {
      type: 'extended-datepicker',
      key: 'value',
      templateOptions: {
        floatLabel: 'always',
        required: !model.optional,
        label: model.label,
        placeholder: 'TT.MM.JJJJ',
        appearance: 'outline',
        datepickerOptions: {
          startAt: moment().toDate(),
          startView: 'month',
          touchUi: true
        },
      }
    };
  }

  editField(fieldId) {
    this.openQuestionFieldDialog(fieldId);
  }

  deleteField(fieldId) {
    this.rawModels.splice(fieldId, 1);
    this.buildFormFields();
  }

  duplicate(src) {
    const target = {};
    for (const prop in src) {
      if (src.hasOwnProperty(prop)) {
        target[prop] = src[prop];
      }
    }
    return target;
  }

  drop(newIndex, previousIndex) {
    const temp = this.rawModels[newIndex];
    this.rawModels[newIndex] = this.rawModels[previousIndex];
    this.rawModels[previousIndex] = temp;
    this.buildFormFields();
  }
}
