import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

import { Message } from '../../classes/message';
import { GenericDialog } from '../../classes/generic-dialog';
import { GenericDialogComponent } from '../../components/dialog/generic-dialog/generic-dialog.component';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class MessageService {
  messages: Array<Message> = [];

  constructor(private dialog: MatDialog) { }

  public add(title: string, text: string) {
    this.messages.push({
        Type: 0,
        Title: title,
        Text: text
    });
  }

  public clear() {
    this.messages = [];
  }

  public getMessages(): Array<Message> {
      return this.messages;
  }

  public getMessage(index: number): Array<Message> {
      return this.messages;
  }

  public showMessage(index: number): void {
    alert(this.messages[index].Text);
  }

  public showError(text: string, error?: JSON): void {
    const genericDialog = new GenericDialog();
    genericDialog.hideCancel = true;
    genericDialog.titel = 'Hoppla. Da ist etwas schief gelaufen :-(';
    genericDialog.text = text;
    genericDialog.details = JSON.stringify(error, null, 1);
    this.dialog.open(GenericDialogComponent, {
      maxWidth: '500px',
      data: genericDialog
    });
  }

  public handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(operation + '(): ', error);
      return of(result as T);
    };
  }
}
