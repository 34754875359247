import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscriber } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageService } from '../../services/message/message.service';

import { environment } from '../../../environments/environment';
import { Profile } from 'src/app/classes/profile';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  /* URL  Definitionen */
  private static readonly customersUrl = environment.api + '/customer';

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private authService: AuthService,
    private storageService: StorageService
  ) {

  }

  public loadProfile(customerId: string): Observable<Profile> {
    return this.http.get<Profile>(ProfileService.customersUrl + '/' + customerId + '/profile').pipe(
      catchError(this.messageService.handleError('loadProfile', null))
    );
  }

  public updateEmails(customerId: string, emails: Profile['Emails']): Observable<Profile> {
    const customerProfile: Profile = {
      CustomerID: customerId
    };
    customerProfile.Emails = [];
    for (let i = 0; i < emails.length; i++) {
      customerProfile.Emails.push({
        Email: (emails[i].Email || '').trim(),
        Primaer: emails[i].Primaer || false,
        ValidierungsCode: emails[i].ValidierungsCode || undefined,
        ValidierungsCodeSenden: emails[i].ValidierungsCodeSenden || false
      });
    }
    return this.http.put(ProfileService.customersUrl + '/' + customerProfile.CustomerID +
      '/profile', customerProfile) as Observable<Profile>;
  }

  public updateBankAccounts(customerId: string, accounts: Profile['Konten']): Observable<Profile> {
    const customerProfile: Profile = {
      CustomerID: customerId
    };
    customerProfile.Konten = [];
    for (let i = 0; i < accounts.length; i++) {
      customerProfile.Konten.push({
        IBAN: (accounts[i].IBAN || '').trim(),
        BIC: (accounts[i].BIC || '').trim(),
        Kontoinhaber: (accounts[i].Kontoinhaber || '').trim(),
        Institut: (accounts[i].Institut || '').trim(),
        Primaer: accounts[i].Primaer || false,
        ValidierungsCode: accounts[i].ValidierungsCode || undefined,
        ValidierungsCodeSenden: accounts[i].ValidierungsCodeSenden || false
      });
    }
    return this.http.put(ProfileService.customersUrl + '/' + customerProfile.CustomerID +
      '/profile', customerProfile) as Observable<Profile>;
  }


  public updateProfile(customerProfileModel: Profile): Observable<Profile> {
    const conditions = customerProfileModel.Bedingungen || {};

    conditions.Leadkauf = conditions.Leadkauf || false;
    conditions.WerbeflaechenPreise = conditions.WerbeflaechenPreise || false;
    conditions.SEPALastschrift = conditions.SEPALastschrift || false;

    const customerProfile: Profile = {
      CustomerID: customerProfileModel.CustomerID,
      Anrede: customerProfileModel.Anrede || '',
      Vorname: (customerProfileModel.Vorname || '').trim(),
      Name: (customerProfileModel.Name || '').trim(),
      Rechtsname: (customerProfileModel.Rechtsname || '').trim(),
      Strasse: (customerProfileModel.Strasse || '').trim(),
      Hausnummer: (customerProfileModel.Hausnummer || '').trim(),
      Postleitzahl: (customerProfileModel.Postleitzahl || '').trim(),
      Ort: (customerProfileModel.Ort || '').trim(),
      Telefon: (customerProfileModel.Telefon || '').trim(),
      TelefonMobil: (customerProfileModel.TelefonMobil || '').trim(),
      Taetigkeitsart: (customerProfileModel.Taetigkeitsart || '').trim(),
      Geschaeftsfuehrer: (customerProfileModel.Geschaeftsfuehrer || '').trim(),
      TVO: (customerProfileModel.TVO || '').trim(),
      HatBeteiligungen: customerProfileModel.HatBeteiligungen || false,
      Bedingungen: conditions
    };

    if (customerProfileModel.HatBeteiligungen) {
      customerProfile.Beteiligungen = customerProfileModel.Beteiligungen || '';
    }

    customerProfile.Emails = [];
    if (typeof customerProfileModel.Emails !== 'undefined') {
      for (let i = 0; i < customerProfileModel.Emails.length; i++) {
        customerProfile.Emails.push({
          Email: (customerProfileModel.Emails[i].Email || '').trim(),
          Primaer: customerProfileModel.Emails[i].Primaer || false,
          ValidierungsCode: customerProfileModel.Emails[i].ValidierungsCode || undefined,
          ValidierungsCodeSenden: customerProfileModel.Emails[i].ValidierungsCodeSenden || false
        });
      }
    }

    customerProfile.Konten = [];
    if (typeof customerProfileModel.Konten !== 'undefined') {
      for (let i = 0; i < customerProfileModel.Konten.length; i++) {
        customerProfile.Konten.push({
          IBAN: (customerProfileModel.Konten[i].IBAN || '').trim(),
          BIC: (customerProfileModel.Konten[i].BIC || '').trim(),
          Institut: (customerProfileModel.Konten[i].Institut || '').trim(),
          Kontoinhaber: (customerProfileModel.Konten[i].Kontoinhaber || '').trim(),
          Primaer: customerProfileModel.Konten[i].Primaer || false,
          ValidierungsCode: customerProfileModel.Konten[i].ValidierungsCode || undefined,
          ValidierungsCodeSenden: customerProfileModel.Konten[i].ValidierungsCodeSenden
        });
      }
    }

    if (typeof customerProfileModel.IHK !== 'undefined') {
      customerProfile.IHK = {
        Name: (customerProfileModel.IHK.Name || '').trim(),
        Strasse: (customerProfileModel.IHK.Strasse || '').trim(),
        Hausnummer: (customerProfileModel.IHK.Hausnummer || '').trim(),
        Postleitzahl: (customerProfileModel.IHK.Postleitzahl || '').trim(),
        Ort: (customerProfileModel.IHK.Ort || '').trim()
      };
    } else {
      customerProfile.IHK = {
        Name: '',
        Strasse: '',
        Hausnummer: '',
        Postleitzahl: '',
        Ort: ''
      };
    }

    if (typeof customerProfileModel.Bedingungen !== 'undefined') {
      customerProfile.Bedingungen = {
        Leadkauf: customerProfileModel.Bedingungen.Leadkauf || false,
        WerbeflaechenPreise: customerProfileModel.Bedingungen.WerbeflaechenPreise || false,
        SEPALastschrift: customerProfileModel.Bedingungen.SEPALastschrift || false
      };
    } else {
      customerProfile.Bedingungen = {
        Leadkauf: false,
        WerbeflaechenPreise: false,
        SEPALastschrift: false
      };
    }

    customerProfile.Schlichtungsstellen = [];
    if (typeof customerProfileModel.Schlichtungsstellen !== 'undefined') {
      for (let i = 0; i < customerProfileModel.Schlichtungsstellen.length; i++) {
        customerProfile.Schlichtungsstellen.push({
          Name: (customerProfileModel.Schlichtungsstellen[i].Name || '').trim(),
          AdresseOderPostfach: (customerProfileModel.Schlichtungsstellen[i].AdresseOderPostfach || '').trim(),
          Postleitzahl: (customerProfileModel.Schlichtungsstellen[i].Postleitzahl || '').trim(),
          Ort: (customerProfileModel.Schlichtungsstellen[i].Ort || '').trim(),
          Website: (customerProfileModel.Schlichtungsstellen[i].Website || '').trim(),
          Email: (customerProfileModel.Schlichtungsstellen[i].Email || '').trim(),
          Telefon: (customerProfileModel.Schlichtungsstellen[i].Telefon || '').trim()
        });
      }
    }

    customerProfile.Kategorieauswahl = [];
    if (typeof customerProfileModel.Kategorieauswahl !== 'undefined') {
      for (let i = 0; i < customerProfileModel.Kategorieauswahl.length; i++) {
        customerProfile.Kategorieauswahl.push(customerProfileModel.Kategorieauswahl[i]);
      }
    }


    const http = this.http;
    return new Observable(observer => {
      // tslint:disable-next-line:max-line-length
      http.put(ProfileService.customersUrl + '/' + customerProfile.CustomerID + '/profile', customerProfile).subscribe((result: Profile) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public deleteFile(uploadPath: string, fileName: string, subscriber?: Subscriber<any>) {
    const key = fileName.split('.').slice(0, -1).join('.');
    const observable = this.storageService.remove(uploadPath + '/' + key);
    if (subscriber) {
      observable.subscribe(() => {
        subscriber.next();
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    } else {
      return observable;
    }
  }

  public uploadFile(file: File, uploadPath: string, fileName: string, subscriber?: Subscriber<any>): Observable<void> | undefined {
    const contentType = this.getContentType(fileName);
    if (!contentType) {
        subscriber.error('Ungültiger Dateiname: ' + fileName);
        return;
    }
    const options = {
      contentType: contentType,
      metadata: {
        'customer': this.authService.getCurrentUserCustomerId(),
        'role': this.authService.getCurrentUserRole(),
        'author': this.authService.getCurrentUserName(),
        // HTTP-Header sind nur ASCII-kompatibel (https://tools.ietf.org/html/rfc7230#section-3.2.4)
        'file-name': encodeURIComponent(fileName.substr(-100))
      }
    };
    const key = fileName.split('.').slice(0, -1).join('.');
    const observable = this.storageService.put(uploadPath + '/' + key, file, options);
    if (subscriber) {
      observable.subscribe(() => {
        subscriber.next();
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    } else {
      return observable;
    }
  }

  private getContentType(fileName: string): string {
    const extension = /\.(png|jpe?g|gif|pdf)$/i.exec(fileName);
    if (!extension) {
      return null;
    }
    switch (extension[0].toLowerCase()) {
      case '.png':
        return 'image/png';
      case '.jpg':
      case '.jpeg':
        return 'image/jpeg';
      case '.gif':
        return 'image/gif';
      case '.pdf':
        return 'application/pdf';
      default:
        return null;
    }
  }
}
