import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatDialog } from '@angular/material';
import { CodeEditorDialogComponent } from '../../components/dialog/code-editor-dialog/code-editor-dialog.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-json-type',
  templateUrl: './form-type-json.component.html',
  styleUrls: ['./form-type-json.component.css']
})

export class FormTypeJsonComponent extends FieldType implements OnInit {
  constructor(
    private dialog: MatDialog
  ) {
    super();
  }

  private data: any;

  ngOnInit() {
    super.ngOnInit();
    this.data = {
      Code: '',
      Titel: this.field.templateOptions.title || 'JSON Editor',
      Language: 'json'
    };
  }

  openEditor(): void {
    this.data.Code = this.formControl.value;
    const dialogRef = this.dialog.open(CodeEditorDialogComponent, {
      panelClass: 'dialog-fullscreen',
      data: this.data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.formControl.setValue(this.data.Code);
      }
      console.log(`Dialog result: ${result}`);
    });
  }
}
