import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import * as _moment from 'moment';

import { Category } from '../../classes/category';
import { CategoryService } from '../../services/category/category.service';

@Component({
  selector: 'app-formly-category-section',
  templateUrl: './form-type-category.component.html',
  styleUrls: ['./form-type-category.component.css'],
})

export class FormTypeCategoryComponent extends FieldType implements OnInit {

  public category: Category;
  public categoryText = '';

  constructor(
    private categoryService: CategoryService
  ) {
    super();
  }

  ngOnInit() {
    const categoryList = this.categoryService.categoryList;
    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].ID.toString() === this.field.model.ID.toString()) {
        this.category = categoryList[i];
        break;
      }
    }
    if (!this.category) {
      this.categoryText = 'Diese Kategorie existiert nicht mehr. (' + this.field.model.ID.toString() + ')';
    }
  }
}
