import { Component, OnInit, Input } from '@angular/core';

import { ProductInfoEntryComponent } from '../product-info/product-info.component';
import { NavigationService } from '../../services/navigation/navigation.service';
import { YoutubeService } from '../../services/youtube/youtube.service';

import { Medium } from '../../classes/medium';

@Component({
  selector: 'app-product-videos',
  templateUrl: './product-videos.component.html',
  styleUrls: ['./product-videos.component.css', '../product-info/product-info-entry.component.css']
})
export class ProductVideosComponent extends ProductInfoEntryComponent implements OnInit {

  @Input() public media: Medium[];

  public Video: Medium;
  public videoId: string;

  constructor(public navigationService: NavigationService, private youtubeService: YoutubeService) {
    super();
    this.Video = null;
  }

  ngOnInit() {
    this.media = this.media || [];

    if (this.media.length > 0) {
      this.Video = this.media[0];
      this.videoId = this.youtubeService.getVideoId(this.Video.Link);
    }
  }
}
