export class Medium {
  ID: number;
  AnbieterID: string;
  AnbieterName: string;
  AnbieterRolle?: 'Versicherer' | 'Makler';
  Typ: string; // PDF, IMAGE, VIDEO
  Titel: string;
  InternerTitel: string;
  Link?: string;
  Bild?: string;
  Dateiname: string;
  Erstelldatum: Date;
  Aenderungsdatum: Date;
  Autor: string;
  Version: number;
  Stand: string;
  Beginn: Date;
  Ablauf: Date;
  Suchbegriffe: Array<string>;
  Berechtigungen: Array<string>;
  MediaSets?: Array<{ID: string; Typ: string; Sortierung: string}>;
}
