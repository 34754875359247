import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-field-file',
  templateUrl: './form-type-file.component.html',
  styleUrls: ['./form-type-file.component.css']
})

export class FormTypeFileComponent extends FieldType implements OnInit {
 @ViewChild('fileupload') fileuploadInput: ElementRef;
  @ViewChild('filename') filenameInput: ElementRef;
  constructor() {
    super();
  }

  public data = null;

  ngOnInit() {
    this.data = this.to;
  }

  openUpload() {
    this.fileuploadInput.nativeElement.click();
  }

  getEmptyText(): string {
    if (typeof this.data.emptyText === 'function') {
      return this.data.emptyText();
    }
    return this.data.emptyText;
  }

  onFileChange(event) {
    if (event.target.files[0]) {
      this.filenameInput.nativeElement.value = event.target.files[0].name;
    } else {
      this.filenameInput.nativeElement.value = '';
    }
  }
}
