import { Component, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { SearchFieldComponent } from '../search-field/search-field.component';
import { MediaSetItemComponent } from '../media-set-item/media-set-item.component';
import { ItemList } from '../../classes/item-list';
import { MediaSet } from '../../classes/media-set';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MediaSetService } from '../../services/media-set/media-set.service';
import { AuthService } from '../../services/auth/auth.service';
import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-media-set-list',
  templateUrl: './media-set-list.component.html',
  styleUrls: ['./media-set-list.component.css']
})
export class MediaSetListComponent extends ItemList<MediaSet> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;
  @ViewChildren(MediaSetItemComponent) public mediaSetComponents: QueryList<MediaSetItemComponent>;

  constructor(
    public mediaSetService: MediaSetService,
    public navigationService: NavigationService,
    public authService: AuthService,
    public messageService: MessageService,
    private router: Router
  ) {
      super('media_set', navigationService, messageService, 'Medien-Pakete verwalten');
  }

  loadItems(limit: number, offset: number) {
    return this.mediaSetService.loadMediaSetList(
        limit,
        offset,
        this.filter.selectedProviderId,
        this.filter.selectedCategoryId,
        this.searchField.searchTerm || null,
        this.filter.selectedSortingId,
        null).pipe(map(result => ({items: result.mediaSetList, maxCount: result.metaData.maxCount})));
  }

  addMediaSet(): () => void {
    return (() => {
      this.router.navigate(['/media-set-create']);
    });
  }

  resetEditors(): void {
    // #7607: Alle Editoren zurücksetzen, damit Produkte deren Zuordnung sich
    // geändert hat nicht mehrfach auftauchen
    this.mediaSetComponents.forEach(mediaSet => {
        mediaSet.editMediaSet(false);
    });
  }
}
