import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _moment from 'moment';

import { MediaSet } from '../../../classes/media-set';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { MediaSetService } from 'src/app/services/media-set/media-set.service';

@Component({
  selector: 'app-media-set-edit-dialog',
  templateUrl: './media-set-edit-dialog.component.html',
  styleUrls: ['./media-set-edit-dialog.component.css']
})
export class MediaSetEditDialogComponent implements OnInit {
  public mediaSet: MediaSet;
  public initialized: boolean;

  constructor(
    public dialogRef: MatDialogRef<MediaSetEditDialogComponent>,
    public navigationService: NavigationService,
    private mediaSetService: MediaSetService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.initialized = false;
    this.navigationService.startLoading('media_set_edit_init');

    this.mediaSetService.loadMediaSet(this.data.mediaSetId).subscribe((mediaSet: MediaSet) => {
      this.mediaSet = mediaSet;
      this.initialized = true;
      this.navigationService.stopLoading('media_set_edit_init');
    });
  }

}
