import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber, Subject } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { Consumer } from '../../classes/consumer';
import { AuthService } from '../auth/auth.service';
import { MessageService } from '../../services/message/message.service';
import { StorageService } from '../storage/storage.service';

import { environment } from '../../../environments/environment';
import { NotifyService, NotifyType } from '../notify/notify.service';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class ConsumerService {
  private static readonly consumersUrl = environment.api + '/consumer';

  private _initialized: boolean;
  get initialized(): boolean {
    return this._initialized;
  }

  private _consumerList: Array<Consumer>;
  get consumerList(): Array<Consumer> {
    return this._consumerList;
  }

  private _selectedConsumer: Consumer;
  get selectedConsumer(): Consumer {
    return this._selectedConsumer;
  }
  set selectedConsumer(consumer: Consumer) {
    this._selectedConsumer = consumer;
    this.selectedConsumerChange$.next(this.selectedConsumer);
  }

  /* EventEmitters */
  public initialize$: Subject<void>;
  public selectedConsumerChange$: Subject<Consumer>;
  public consumerListChange$: Subject<Array<Consumer>>;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService,
    private messageService: MessageService,
    private notifyService: NotifyService
  ) {
    this._initialized = false;
    this._consumerList = [];
    this._selectedConsumer = null;

    this.initialize$ = new Subject<void>();
    this.selectedConsumerChange$ = new Subject<Consumer>();
    this.consumerListChange$ = new Subject<Array<Consumer>>();

    if (this.authService.isLoggedIn()) {
      this.setupConsumerList(false).subscribe(() => {
        this._initialized = true;
        this.initialize$.next();
        this.initialize$.complete();
      }, () => {
        this.authService.logOut();
        this.notifyService.notify(NotifyType.Error, 'Es ist ein Fehler aufgetreten.');
      });
    }
    this.authService.authStateChange$.subscribe(state => {
      if (state === 'signedIn') {
        this.setupConsumerList(true).subscribe(() => {
          this._initialized = true;
          this.initialize$.next();
          this.initialize$.complete();
        }, () => {
          this.authService.logOut();
          this.notifyService.notify(NotifyType.Error, 'Es ist ein Fehler aufgetreten.');
        });
      }
    });
  }

  /**
   * Consumer CRUD
   */

  public create(consumerModel: any): Observable<Consumer> {
    const consumer: any = {
      ID: consumerModel.ID.trim(),
      Name: (consumerModel.Name || '').trim(),
      Suffix: (consumerModel.Suffix || '').trim(),
      Aktiv: consumerModel.Aktiv
    };

    const http = this.http;
    return new Observable(observer => {
      http.post(ConsumerService.consumersUrl, consumer).subscribe((result: Consumer) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public setupConsumerList(applyConsumerChange): Observable<Array<Consumer>> {
    return this.http.get<Array<Consumer>>(ConsumerService.consumersUrl).pipe(
      tap((consumerList: Array<Consumer>) => {
        this.consumerList.splice(0, this.consumerList.length);

        consumerList.pop();
        consumerList.forEach((consumer: Consumer) => {
          this.consumerList.push(consumer);
        });

        if (this.consumerList.length === 0) {
          throw new Error('setupConsumerList did not found any consumer!');
        }

        const nextSelectedConsumer: Consumer = this.selectedConsumer || this.consumerList[0];
        const oldSelectedConsumer: Consumer = this.selectedConsumer;
        this._selectedConsumer = null;
        for (let i = 0; i < this.consumerList.length; i++) {
          if (nextSelectedConsumer.ID === this.consumerList[i].ID) {
            this._selectedConsumer = this.consumerList[i];
            break;
          }
        }
        
        // #9736 Vorbelegung Snoopr wenn vorhanden
        for (let i = 0; i < this.consumerList.length; i++) {
          if (this.consumerList[i].Name === 'Snoopr') {
            this._selectedConsumer = this.consumerList[i];
            break;
          }
        }

        if (this.selectedConsumer === null) {
          console.log("set selected customer to ", this.consumerList[0]);
          
          this._selectedConsumer = this.consumerList[0];
        }

        if ((oldSelectedConsumer !== null && oldSelectedConsumer.ID !== this.selectedConsumer.ID) || applyConsumerChange) {
          this.selectedConsumerChange$.next(this.selectedConsumer);
        }
        this.consumerListChange$.next(this.consumerList);
      })
    );
  }

  public loadConsumer(id: string): Observable<Consumer> {
    return this.http.get<Consumer>(ConsumerService.consumersUrl + '/' + id).pipe(
      catchError(this.messageService.handleError('loadConsumer', null))
    );
  }

  public loadConsumerList(limit?: number, offset?: number, excludeConsumerIds?: Array<string>, filterSearch?: string): Observable<any> {
    let urlParameter = '';
    if (typeof limit !== 'undefined' && limit !== null) {
      urlParameter += (urlParameter.length > 0 ? '&' : '?') + 'limit=' + limit;
    }
    if (typeof offset !== 'undefined' && offset !== null) {
      urlParameter += (urlParameter.length > 0 ? '&' : '?') + 'offset=' + offset;
    }
    if (typeof excludeConsumerIds !== 'undefined' && excludeConsumerIds !== null) {
      // tslint:disable-next-line:max-line-length
      urlParameter += (urlParameter.length > 0 ? '&' : '?') + 'excludeConsumerIds=' + encodeURIComponent(JSON.stringify(excludeConsumerIds));
    }
    if (typeof filterSearch !== 'undefined' && filterSearch !== null) {
      urlParameter += (urlParameter.length > 0 ? '&' : '?') + 'filterSearch=' + filterSearch;
    }
    return this.http.get<any>(ConsumerService.consumersUrl + urlParameter).pipe(
      map(consumerList => {
        return {
          consumerList: consumerList,
          metaData: (<Array<any>>consumerList).pop()
        };
      }),
      catchError(this.messageService.handleError('loadConsumerList', []))
    );
  }

  public update(consumerModel: any): Observable<Consumer> {
    const category: any = {
      ID: consumerModel.ID.trim(),
      Name: (consumerModel.Name || '').trim(),
      Suffix: (consumerModel.Suffix || '').trim(),
      Aktiv: consumerModel.Aktiv
    };

    const http = this.http;
    return new Observable(observer => {
      http.put(ConsumerService.consumersUrl + '/' + category.ID, category).subscribe((result: Consumer) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  /**
   * Utils
   */

  public uploadConsumerIcon(file: File, consumer: Consumer, subscriber?: Subscriber<Consumer>): Observable<void> | undefined {
    const contentType = 'image/png';
    const options = {
      contentType: contentType,
      metadata: {
        'customer': this.authService.getCurrentUserCustomerId(),
        'role': this.authService.getCurrentUserRole(),
        'author': this.authService.getCurrentUserName(),
        'consumer-id': consumer.ID.toString()
      }
    };
    const observable = this.storageService.put('public/assets/consumer/' + consumer.Suffix + '/START', file, options);
    if (subscriber) {
      observable.subscribe(() => {
        subscriber.next(consumer);
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    } else {
      return observable;
    }
  }


  private getContentType(fileName: string): string {
    const extension = /\.(png|jpe?g|gif|pdf)$/i.exec(fileName);
    if (!extension) {
      return null;
    }
    switch (extension[0].toLowerCase()) {
      case '.png':
        return 'image/png';
      case '.jpg':
      case '.jpeg':
        return 'image/jpeg';
      case '.gif':
        return 'image/gif';
      case '.pdf':
        return 'application/pdf';
      default:
        return null;
    }
  }
}
