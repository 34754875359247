import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, of, forkJoin } from 'rxjs';
import * as _moment from 'moment';
import { Router } from '@angular/router';

import { ItemComponent } from '../../classes/item-component';
import { Customer } from '../../classes/customer';
import { Editor } from '../../classes/editor';

import { ProviderSelectDialogComponent } from '../dialog/provider-select-dialog/provider-select-dialog.component';
import { ConsumerSelectDialogComponent } from '../dialog/consumer-select-dialog/consumer-select-dialog.component';

import { CustomerService } from '../../services/customer/customer.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MessageService } from '../../services/message/message.service';
import { AuthService } from '../../services/auth/auth.service';
import { EnterpriseSearchService } from 'src/app/services/enterprise-search/enterprise-search.service';
import { EnterpriseSearch } from 'src/app/classes/enterprise-search';

import { GenericDialog } from '../../classes/generic-dialog';
import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';
import { EditorService } from 'src/app/services/editor/editor.service';

export class CustomerEditor extends Editor<CustomerItemComponent, any> {

	constructor(
		public component: CustomerItemComponent,
		private router: Router,
		private messageService: MessageService,
		public dialog: MatDialog,
		private customerService: CustomerService,
		private isNewCustomer: boolean,
		private enterpriseSearchService: EnterpriseSearchService,
		private editorService: EditorService
	) {
		super(component);
	}

	submit(model: any, type: string): Observable<void> {
		return new Observable(subscriber => {
			this.submitModel(model).subscribe((customer: Customer) => {
				model.Provider = customer.Provider;
				model.Consumer = customer.Consumer;
				model.Accounts = customer.Accounts;
				
				for (let i = 0; i < model.EnterpriseSearches.length; i++) {
					const enterpriseSearch = model.EnterpriseSearches[i];

					enterpriseSearch.IncludeFilter = (enterpriseSearch.IncludeFilter || '').split('\n');
					for (let i = 0; i < enterpriseSearch.IncludeFilter.length; i++) {
						enterpriseSearch.IncludeFilter[i] = enterpriseSearch.IncludeFilter[i].trim();
						if (enterpriseSearch.IncludeFilter[i].length === 0) {
							enterpriseSearch.IncludeFilter.splice(i--, 1);
						}
					}

					enterpriseSearch.ExcludeFilter = (enterpriseSearch.ExcludeFilter || '').split('\n');
					for (let i = 0; i < enterpriseSearch.ExcludeFilter.length; i++) {
						enterpriseSearch.ExcludeFilter[i] = enterpriseSearch.ExcludeFilter[i].trim();
						if (enterpriseSearch.ExcludeFilter[i].length === 0) {
							enterpriseSearch.ExcludeFilter.splice(i--, 1);
						}
					}

					enterpriseSearch.CategoryIncludeFilter = (enterpriseSearch.CategoryIncludeFilter || '').split('\n');
					for (let i = 0; i < enterpriseSearch.CategoryIncludeFilter.length; i++) {
						enterpriseSearch.CategoryIncludeFilter[i] = enterpriseSearch.CategoryIncludeFilter[i].trim();
						if (enterpriseSearch.CategoryIncludeFilter[i].length === 0) {
							enterpriseSearch.CategoryIncludeFilter.splice(i--, 1);
						}
					}
				}

				this.enterpriseSearchService.updateEnterpriseSearches(model.EnterpriseSearches, customer.ID).subscribe((enterpriseSearches: Array<EnterpriseSearch>) => {
					this.editorService.setModel(null);
					subscriber.next();
					subscriber.complete();


					model.EnterpriseSearches.splice(0, model.EnterpriseSearches.length);

					for (let i = 0; i < enterpriseSearches.length; i++) {
						const enterpriseSearch = enterpriseSearches[i];

						(<any>enterpriseSearch).IncludeFilter = enterpriseSearch.IncludeFilter.join('\n');
						(<any>enterpriseSearch).ExcludeFilter = enterpriseSearch.ExcludeFilter.join('\n');
						(<any>enterpriseSearch).CategoryIncludeFilter = enterpriseSearch.CategoryIncludeFilter.join('\n');

						model.EnterpriseSearches.push(enterpriseSearch);
					}

					if (this.isNewCustomer) {
						this.router.navigate(['/customer-edit', customer.ID]);
					} else if (this.router.url !== '/providers-management/customer') {
						this.router.navigate(['/providers-management', 'customer']);
					} else {
						this.component.editCustomer(false);
					}
				});
			}, error => {
				subscriber.error(error);

				this.messageService.showError('Beim Aktualisieren des Customers ist es zu einem Fehler gekommen: '
					+ error.error.message, error);
			});
		});
	}

	submitModel(model): Observable<Customer> {
		if (this.isNewCustomer) {
			return this.customerService.create(model);
		}
		return this.customerService.update(model);
	}

	preModelModifier(model: any): void {
		if (model.Rolle === '') {
			model.Rolle = 'default';
		}
	}
}


@Component({
	selector: 'app-customer-item',
	templateUrl: './customer-item.component.html',
	styleUrls: ['./customer-item.component.css']
})
export class CustomerItemComponent extends ItemComponent implements OnInit {

	@Input() public customer: Customer;
	@Input() public panelOpenState = false;
	@Input() public info = false;
	@Input() public edit = false;
	@Input() public cancelRedirectUrl = '';

	public editor: CustomerEditor;
	public editorInitialized = false;

	public isNewCustomer = false;

	constructor(
		public dialog: MatDialog,
		private customerService: CustomerService,
		private router: Router,
		private navigationService: NavigationService,
		private messageService: MessageService,
		private authService: AuthService,
		private enterpriseSearchService: EnterpriseSearchService,
		private editorService: EditorService
	) {
		super();
	}

	createEditorSteps(): void {

		if (this.customer)

			// create step 1
			this.editor.steps.push({
				label: 'Grundeinstellungen',
				fields: [{
					type: 'input',
					key: 'ID',
					templateOptions: {
						label: 'ID',
						appearance: 'outline',
						description: 'Die eindeutige ID ist der Schlüssel des Customers.',
						maxLength: 256,
						required: true,
						disabled: !this.isNewCustomer
					}
				}, {
					template: '<br>'
				}, {
					type: 'input',
					key: 'Name',
					templateOptions: {
						label: 'Name',
						appearance: 'outline',
						description: 'Der Name ist die dargestellte Bezeichnung des Customers.',
						maxLength: 256,
						required: true
					}
				}, {
					template: '<br>'
				}, {
					type: 'select',
					key: 'Rolle',
					templateOptions: {
						label: 'Rolle',
						appearance: 'outline',
						description: 'Die Rolle des Kunden',
						required: true,
						options: [
							{ value: 'default', label: 'Default (VU)' },
							{ value: 'broker', label: 'Vermittler' },
							{ value: 'enterprise', label: 'Enterprise (VU)' },
							{ value: 'system', label: 'System' },
							{ value: 'extern', label: 'Externes System' }
						],
						disabled: !this.isNewCustomer
					}
				}, {
					template: '<br>'
				}, {
					type: 'checkbox',
					key: 'Verifiziert',
					templateOptions: {
						label: 'Verifiziert',
						indeterminate: false
					},
					hideExpression: (model) => {
						return model.Rolle !== 'broker' || !this.authService.isSystem();
					}
				}]
			});
		// creaate step 2
		this.editor.steps.push({
			label: 'Enterprise Search - API Keys',
			fields: [{
				fieldGroup: [{
					key: 'EnterpriseSearches',
					type: 'repeat',
					fieldArray: {
						templateOptions: {
							label: 'API-Key hinzufügen',
							maxLength: 5,
							disabled: false,
							divider: true,
							space: '15px',
							customAdd: () => {
								const enterpriseSearch: EnterpriseSearch = new EnterpriseSearch();
								enterpriseSearch.CustomerID = this.customer.ID;
								enterpriseSearch.Kontext = "";
								enterpriseSearch.Schluessel = "";
								enterpriseSearch.Geheimnis = "";
								enterpriseSearch.Titel = "";
								enterpriseSearch.Inaktiv = false;
								(<any>enterpriseSearch).ExcludeFilter = "";
								(<any>enterpriseSearch).IncludeFilter = "";
								(<any>enterpriseSearch).CategoryIncludeFilter = "";
								(<any>enterpriseSearch).ModelIncludeFilter = ["FREE", "BUSINESS", "BUSINESS_PLUS", "BUSINESS_PRO"];
								enterpriseSearch.BrokerExcludeFilter = false;
								enterpriseSearch.StyleSnoopr = "";
								enterpriseSearch.StyleSuchfeld = "";
								enterpriseSearch.RedirectStyle = "snoopr";
								enterpriseSearch.Recaptcha = true;
								enterpriseSearch.Favicon16Link = "public/assets/customer/_DEFAULT/enterprise-search/favicon-16x16";
								enterpriseSearch.Favicon32Link = "public/assets/customer/_DEFAULT/enterprise-search/favicon-32x32";
								enterpriseSearch.Favicon48Link = "public/assets/customer/_DEFAULT/enterprise-search/favicon-48x48";
								enterpriseSearch.LogoLink = "public/assets/customer/_DEFAULT/enterprise-search/logo";
								enterpriseSearch.HomepageTitel = "Versicherungen suchen";
								return of(enterpriseSearch);
							},
							customRemove: (model) => {
								const genericDialog = new GenericDialog();

								const titel = model && model.Titel !== '' ? ' <strong>' + model.Titel + '</strong> ' : ' ';
								genericDialog.titel = 'Enterprise-Search Key löschen?';
								genericDialog.text = 'Soll der Enterprise-Search Key' + titel + 'endgültig gelöscht werden?';
								const dialogRef = this.dialog.open(GenericDialogComponent, {
									maxWidth: '500px',
									maxHeight: '100vh',
									data: genericDialog
								});

								return new Observable((subscriber) => {
									dialogRef.afterClosed().subscribe(result => {
										if (result === true) {
											if (model.Schluessel) {
												model.Inaktiv = true;
												subscriber.next(false);
											} else {
												subscriber.next(true);
											}
											subscriber.complete();
										}
									});
								});
							}
						},
						fieldGroup: [{
							template: '<p><strong>Allgemein</strong></p>'
						}, {
							type: 'input',
							key: 'Titel',
							templateOptions: {
								label: 'Eigene Bezeichnung für diesen API-Key',
								appearance: 'outline'
							},
						}, {
							type: 'select',
							key: 'Kontext',
							templateOptions: {
								label: 'Kontext',
								appearance: 'outline',
								description: 'In welchem Kontext der API Key verwendet wird. Verbraucher-Kontext: Einsprung eines Verbrauchers aus Webseite eines Versicherers. Makler-Kontext: Einsprung eines (Anonymen) Maklers, z.B. aus MVP oder Extranet.',
								required: true,
								options: [
									{ value: 'external-system', label: 'Makler-Kontext', disabled: this.customer.Rolle === 'broker' },
									{ value: 'external-website', label: 'Verbraucher-Kontext', disabled: this.customer.Rolle === 'broker' },
									{ value: 'homepage-program', label: 'Verbraucher-Kontext (Homepageprogramm)', disabled: this.customer.Rolle !== 'broker' }
								]
							},
							expressionProperties: {
								'templateOptions.disabled': (model) => !!model.Schluessel,
							}
						}, {
							template: '<br>'
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{
								type: 'input',
								key: 'Schluessel',
								className: 'flex-1',
								templateOptions: {
									label: 'API Key',
									appearance: 'outline',
									disabled: true
								}
							}, {
								type: 'input',
								key: 'Geheimnis',
								className: 'flex-1',
								templateOptions: {
									label: 'API Secret',
									appearance: 'outline',
									disabled: true
								}
							}],
						}, {
							template: '<br><p><strong>Anpassungen</strong></p>'
						}, {
							type: 'input',
							key: 'HomeURL',
							templateOptions: {
								label: 'Home URL',
								description: 'Welche Seite beim Klick auf das Logo geöffnet werden soll. (Funktioniert nur, wenn Logo hinterlegt ist)',
								appearance: 'outline'
							},
							validators: {
								video: {
									expression: (field) => {
										return !field.value || /^https?\:\/\//.exec(field.value) !== null;
									},
									message: () => {
										return 'Der Link muss mit "https://" oder "http://" beginnen.';
									}
								}
							}
						},
						{
							template: '<br/>'
						},
						{
							type: 'input',
							key: 'HomepageTitel',
							templateOptions: {
								label: 'Homepage Titel',
								description: 'Welchen Titel soll die Seite haben?',
								appearance: 'outline'
							}
						},
						{
							type: 'checkbox',
							key: 'Recaptcha',
							templateOptions: {
								label: 'Recaptcha anzeigen?',
								indeterminate: false
							}
						},
						{
							type: 'select',
							key: 'RedirectStyle',
							templateOptions: {
								label: 'Aussehen der Weiterleistungsseite',
								appearance: 'outline',
								description: 'Diese Seite erscheint wenn der Nutzer auf den Tarifrechner weitergeleitet wird. Wie soll diese aussehen?',
								required: true,
								options: [
									{ value: 'snoopr', label: 'Weiterleistungsseite wie bei Snoopr' },
									{ value: 'neutral', label: 'Neutrale Weiterleistungsseite mit dem Logo des Produktgebers' },
								]
							}
						},
						{
							template: '<p><strong>Logo</strong></p>'
						}, {
							key: 'LogoDatei',
							type: 'image-crop',
							className: 'flex-1',
							templateOptions: {
								label: 'Dateiauswahl',
								floatLabel: 'always',
								appearance: 'outline',
								accept: '.jpg,.jpeg,.png,.gif',
								image: (model) => { return model.LogoLink ? model.LogoLink : null },
								disabled: false,
								skipCropping: true,
								maintainAspectRatio: true,
								isRound: false,
								aspectRatio: 16 / 3,
								cropperMinWidth: 160,
								cropperMinHeight: 30,
								previewHeight: 50,
								onImageChange: (model: any, image: any, originalImage: any, isAppliedExternal: boolean) => {

								}
							}
						},

						{
							template: '<p><strong>Favicon 16x16</strong></p>'
						}, {
							key: 'Favicon16Datei',
							type: 'image-crop',
							className: 'flex-1',
							templateOptions: {
								label: 'Dateiauswahl',
								floatLabel: 'always',
								appearance: 'outline',
								accept: '.jpg,.jpeg,.png,.gif',
								image: (model) => { return model.Favicon16Link ? model.Favicon16Link : null },
								disabled: false,
								skipCropping: true,
								maintainAspectRatio: true,
								isRound: false,
								aspectRatio: 16 / 3,
								cropperMinWidth: 160,
								cropperMinHeight: 30,
								previewHeight: 50,
								onImageChange: (model: any, image: any, originalImage: any, isAppliedExternal: boolean) => {

								}
							}
						},
						{
							template: '<p><strong>Favicon 32x32</strong></p>'
						}, {
							key: 'Favicon32Datei',
							type: 'image-crop',
							className: 'flex-1',
							templateOptions: {
								label: 'Dateiauswahl',
								floatLabel: 'always',
								appearance: 'outline',
								accept: '.jpg,.jpeg,.png,.gif',
								image: (model) => { return model.Favicon32Link ? model.Favicon32Link : null },
								disabled: false,
								skipCropping: true,
								maintainAspectRatio: true,
								isRound: false,
								aspectRatio: 16 / 3,
								cropperMinWidth: 160,
								cropperMinHeight: 30,
								previewHeight: 50,
								onImageChange: (model: any, image: any, originalImage: any, isAppliedExternal: boolean) => {

								}
							}
						},
						{
							template: '<p><strong>Favicon 48x48</strong></p>'
						}, {
							key: 'Favicon48Datei',
							type: 'image-crop',
							className: 'flex-1',
							templateOptions: {
								label: 'Dateiauswahl',
								floatLabel: 'always',
								appearance: 'outline',
								accept: '.jpg,.jpeg,.png,.gif',
								image: (model) => { return model.Favicon48Link ? model.Favicon48Link : null },
								disabled: false,
								skipCropping: true,
								maintainAspectRatio: true,
								isRound: false,
								aspectRatio: 16 / 3,
								cropperMinWidth: 160,
								cropperMinHeight: 30,
								previewHeight: 50,
								onImageChange: (model: any, image: any, originalImage: any, isAppliedExternal: boolean) => {

								}
							}
						},
						{
							template: '<br><p><strong>Produktfilter</strong></p>'
						}, {
							type: 'textarea',
							key: 'IncludeFilter',
							templateOptions: {
								label: 'Produkt-IDs einschließen',
								appearance: 'outline',
								rows: 6,
								description: 'Welche Produktvarianten für diesen API-Key verfügbar sind. Wenn leer, dann sind alle Produktvarianten verfügbar. Beispiel: 5515.*',
							},
						}, {
							template: '<br>'
						}, {
							type: 'textarea',
							key: 'ExcludeFilter',
							templateOptions: {
								label: 'Produkt-IDs ausschließen',
								appearance: 'outline',
								rows: 6,
								description: 'Welche Produktvarianten für diesen API-Key ausgeschlossen sind. Wenn leer, dann keine Produktvarianten ausschließen. Beispiel: 5515.*',
							}
						}, {
							template: '<br><p><strong>Kategoriefilter</strong></p>'
						}, {
							type: 'textarea',
							key: 'CategoryIncludeFilter',
							templateOptions: {
								label: 'Kategorien einschließen',
								appearance: 'outline',
								rows: 6,
								description: 'Welche Kategorien für diesen API-Key verfügbar sind. Wenn leer, dann sind alle Kategorien verfügbar. Beispiel: PHV-SNOOPR',
							},
						}, {
							template: '<br><p><strong>Maklerfilter</strong></p>'
						}, {
							type: 'checkbox',
							key: 'BrokerExcludeFilter',
							templateOptions: {
								label: 'Andere Makler ausschließen',
								indeterminate: false
							}
						}, {
							template: '<br><p><strong>Modelfilter</strong></p>'
						}, {
							type: 'select',
							key: 'ModelIncludeFilter',
							templateOptions: {
							  multiple: true,
							  label: 'Modell einschließen',
							  required: false,
							  placeholder: '',
							  appearance: 'outline',
							  options: [
								{ value: 'FREE', label: 'FREE (gecrawlt)', disabled: false },
								{ value: 'BUSINESS', label: 'BUSINESS (E-Mail-/Chat-basierter Workflow)', disabled: false },
								{ value: 'BUSINESS_PLUS', label: 'BUSINESS PLUS (Tarifrechner-Verlinkung)', disabled: false },
								{ value: 'BUSINESS_PRO', label: 'BUSINESS PRO (vollintegrierte Folgeprozesse / API)', disabled: false}
							  ],
							  description: 'Welche Modelle für diesen API-Key verfügbar sind.',

							}
						  },{
							template: '<br><p><strong>Styles</strong></p>'
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{
								type: 'css',
								key: 'StyleSuchfeld',
								className: 'flex-1 full-width',
								templateOptions: {
									label: 'Suchfeld Style',
									appearance: 'outline',
									rows: 6
								}
							}, {
								type: 'css',
								key: 'StyleSnoopr',
								className: 'flex-1 full-width',
								templateOptions: {
									label: 'Snoopr Style',
									appearance: 'outline',
									rows: 6
								}
							}]
						}, {
							template: '<br />'
						}, {
							type: 'textarea',
							key: 'EmailFooter',
							templateOptions: {
								label: 'E-Mail Footer',
								appearance: 'outline',
								rows: 6,
								description: 'Footer für generierte E-Mails (Impressum)',
							}
						}, {
							type: 'textarea',
							key: 'EmailButtonStyle',
							templateOptions: {
								label: 'CSS Style für "Vorgang öffnen" Button',
								appearance: 'outline',
								rows: 6,
								description: 'Beispiel: background-color: red;',
							}
						}, {
							type: 'input',
							key: 'EmailAddress',
							templateOptions: {
								label: 'E-Mail Adresse',
								description: 'An diese E-Mail Adresse werden alle Anfragen gesendet.',
								appearance: 'outline',
								required: true
							}
						}, {
							template: '<br><p><strong>Angaben für Pools</strong></p>'
						},
						{
							type: 'input',
							key: 'PoolURL',
							templateOptions: {
								label: 'URL zum Abruf der Vermittlerdaten',
								appearance: 'outline',
							}
						},
						{
							type: 'checkbox',
							key: 'DisallowSetOrder',
							templateOptions: {
								label: 'Online-Abschluss abschalten',
								indeterminate: false
							}
						}, {
							type: 'textarea',
							key: 'SubmitionText',
							templateOptions: {
								label: 'Text der nach dem Antragsdruck angezeigt wird',
								appearance: 'outline',
								rows: 6,
								description: 'Was soll mit dem Antrag passieren?',
							}
						}, {
							template: '<br />'
						}]
					}
				}]
			}]
		});

		// create step 3
		this.editor.steps.push({
			label: 'Produktgeber',
			fields: [{
				fieldGroupClassName: 'display-flex',
				fieldGroup: [{
					key: 'Provider',
					type: 'repeat',
					className: 'flex-1',
					fieldArray: {
						fieldGroupClassName: 'display-flex',
						templateOptions: {
							label: 'Produktgeber hinzufügen',
							disabled: false,
							isCustom: true,
							customAdd: () => {
								const excludeIds = [];
								for (let i = 0; i < this.customer.Provider.length; i++) {
									excludeIds.push(this.customer.Provider[i].ID);
								}
								const dialogRef = this.dialog.open(ProviderSelectDialogComponent, {
									panelClass: 'provider-select-dialog-container',
									maxHeight: '100vh',
									data: {
										excludeProviderIds: excludeIds
									}
								});
								return dialogRef.afterClosed();
							}
						},
						fieldGroup: [{
							className: 'flex-1',
							type: 'provider'
						}]
					}
				}]
			}]
		});

		// create step 4
		this.editor.steps.push({
			label: 'Consumer',
			fields: [{
				fieldGroupClassName: 'display-flex',
				fieldGroup: [{
					key: 'Consumer',
					type: 'repeat',
					className: 'flex-1',
					fieldArray: {
						fieldGroupClassName: 'display-flex',
						templateOptions: {
							label: 'Consumer hinzufügen',
							disabled: false,
							isCustom: true,
							customAdd: () => {
								const excludeIds = [];
								for (let i = 0; i < this.customer.Consumer.length; i++) {
									excludeIds.push(this.customer.Consumer[i].ID);
								}
								const dialogRef = this.dialog.open(ConsumerSelectDialogComponent, {
									panelClass: 'customer-select-dialog-container',
									maxHeight: '100vh',
									data: {
										excludeConsumerIds: excludeIds
									}
								});
								return dialogRef.afterClosed();
							}
						},
						fieldGroup: [{
							className: 'flex-1',
							type: 'consumer'
						}]
					}
				}]
			}]
		});

		// create step 5
		this.editor.steps.push({
			label: 'Benutzerkonten',
			fields: [{
				fieldGroupClassName: 'display-flex',
				fieldGroup: [{
					key: 'Accounts',
					type: 'repeat',
					className: 'flex-1',
					fieldArray: {
						fieldGroupClassName: 'display-flex',
						templateOptions: {
							label: 'Benutzerkonto hinzufügen',
							disabled: false
						},
						fieldGroup: [{
							className: 'flex-2',
							type: 'input',
							key: 'Email',
							templateOptions: {
								label: 'E-Mail',
								appearance: 'outline',
								description: 'Die E-Mail Adresse des Benutzerkonots.',
								maxLength: 256,
								required: true
							},
							expressionProperties: {
								'templateOptions.disabled': (model) => !!model.ID,
							}
						}, {
							className: 'flex-1',
							type: 'select',
							key: 'Rolle',
							templateOptions: {
								label: 'Rolle',
								appearance: 'outline',
								description: 'Die Rolle des Benutzerkontos',
								required: true,
								options: [
									{ value: 'writer', label: 'Writer' },
									{ value: 'reader', label: 'Reader' },
									{ value: 'admin', label: 'Admin' }
								]
							},
							expressionProperties: {
								'templateOptions.disabled': (model) => !!model.ID,
							}
						}]
					}
				}]
			}]
		});

		// create step 6
		this.editor.steps.push({
			label: 'Rechnungsdaten',
			fields: [{
				fieldGroupClassName: 'display-flex',
				fieldGroup: [{
					key: 'Rechnungsdaten',
					type: 'repeat',
					className: 'flex-1',
					fieldArray: {
						templateOptions: {
							label: 'Rechnungsempfänger hinzufügen',
							disabled: false
						},
						fieldGroup: [{
							template: '<p><strong>Abrechnungsempfänger</strong></p>'
						}, {
							type: 'input',
							key: 'Name',
							templateOptions: {
								label: 'Name des Empfängers',
								appearance: 'outline',
								description: 'Der Name des Empfängers.',
								maxLength: 256,
								required: true
							},
							expressionProperties: {
								'templateOptions.disabled': (model) => !!model.ID,
							}
						}, {
							template: '</br>'
						},
						{
							type: 'input',
							key: 'ZHd',
							templateOptions: {
								label: 'zu Händen',
								appearance: 'outline',
								description: 'Der Name des Verantwortlichen.',
								maxLength: 256,
								required: false
							},
							expressionProperties: {
								'templateOptions.disabled': (model) => !!model.ID,
							}
						}, {
							template: '</br><p><strong>Anschrift</strong></p>'
						},
						{
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{
								className: 'flex-2',
								type: 'input',
								key: 'Strasse',
								templateOptions: {
									label: 'Strasse',
									appearance: 'outline',
									description: 'Die Strasse des Empfängers.',
									maxLength: 256,
									required: true
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => !!model.ID,
								}
							},
							{
								className: 'flex-1',
								type: 'input',
								key: 'Hausnummer',
								templateOptions: {
									label: 'Hausnummer',
									appearance: 'outline',
									description: 'Die Hausnummer des Empfängers.',
									maxLength: 256,
									required: true
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => !!model.ID,
								}
							}
							]
						}, {
							template: '</br>'
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{
								className: 'flex-1',
								type: 'input',
								key: 'PLZ',
								templateOptions: {
									label: 'PLZ',
									appearance: 'outline',
									description: 'Die Postleitzahl des Empfängers.',
									maxLength: 10,
									required: true
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => !!model.ID,
								}
							}, {
								className: 'flex-2',
								type: 'input',
								key: 'Ort',
								templateOptions: {
									label: 'Ort',
									appearance: 'outline',
									description: 'Der Ort des Empfängers.',
									maxLength: 256,
									required: true
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => !!model.ID,
								}
							}
							]
						}, {
							template: '</br>'
						}, {
							type: 'input',
							key: 'Bestellnummer',
							templateOptions: {
								label: 'statische Bestellnummer',
								appearance: 'outline',
								description: 'Die Bestellnummer des Kunden.',
								maxLength: 256,
								required: false
							},
							expressionProperties: {
								'templateOptions.disabled': (model) => !!model.ID,
							}
						}, {
							template: '</br>'
						},
						{
							type: 'input',
							key: 'Email',
							templateOptions: {
								label: 'E-Mail',
								appearance: 'outline',
								description: 'Die E-Mail Adresse des Empfängers.',
								maxLength: 256,
								required: false
							},
							expressionProperties: {
								'templateOptions.disabled': (model) => !!model.ID,
							}
						}, {
							template: '</br><hr></br>'
						}
						]
					}
				}]
			}]
		});
	}

	initEditor() {
		if (!this.editorInitialized) {
			this.navigationService.startLoading('customer_editor_init');

			this.editor = new CustomerEditor(
				this,
				this.router,
				this.messageService,
				this.dialog,
				this.customerService,
				this.isNewCustomer,
				this.enterpriseSearchService,
				this.editorService
			);


			// tslint:disable-next-line:max-line-length
			this.editor.setInfo1(() => '');
			this.editor.setInfo2(() => '');
			this.editor.title = 'EDITOR';

			if (!this.isNewCustomer) {
				forkJoin([this.customerService.loadCustomer(this.customer.ID), this.enterpriseSearchService.loadEnterpriseSearches(this.customer.ID)]).subscribe(result => {
					this.createEditorSteps();
					this.editorInitialized = true;
					this.customer = result[0];

					(<any>this.customer).EnterpriseSearches = result[1] || [];

					for (let i = 0; i < (<any>this.customer).EnterpriseSearches.length; i++) {
						const enterpriseSearch = (<any>this.customer).EnterpriseSearches[i];
						enterpriseSearch.IncludeFilter = (enterpriseSearch.IncludeFilter || []).join('\n');
						enterpriseSearch.ExcludeFilter = (enterpriseSearch.ExcludeFilter || []).join('\n');
						enterpriseSearch.CategoryIncludeFilter = (enterpriseSearch.CategoryIncludeFilter || []).join('\n');
					}

					setTimeout(() => {
						this.editorService.setModel(this.customer);
					  }, 0);

					this.navigationService.stopLoading('customer_editor_init');
				});
			} else {
				this.createEditorSteps();
				this.editorInitialized = true;
				setTimeout(() => {
					this.editorService.setModel(this.customer);
				  }, 0);
				this.navigationService.stopLoading('customer_editor_init');
			}
		}
	}

	ngOnInit() {
		this.isNewCustomer = (!this.customer || this.customer.ID === '');
		if (this.edit) {
			this.panelOpenState = true;
			this.initEditor();
		}
	}

	editCustomer(edit: boolean) {
		this.info = false;
		this.edit = edit;

		if (edit) {
			this.initEditor();
		} else if (this.cancelRedirectUrl) {
			this.router.navigate([this.cancelRedirectUrl]);
		} else if (this.editorInitialized) {
			this.editorInitialized = false;
		}
	}

	ngOnDestroy(): void {
		if (this.editorInitialized) {
		  this.editorService.setModel(null);
		}
	  }
}
