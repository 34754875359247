import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, forkJoin, Subscriber, Subscription } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { Provider } from '../../classes/provider';
import { Consumer } from '../../classes/consumer';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { MessageService } from '../../services/message/message.service';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';

import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  /* URL  Definitionen */
  private static readonly providerUrl = environment.api + '/provider';

  private _initialized: boolean;
  get initialized(): boolean {
    return this._initialized;
  }

  private _providerFilterList: Array<Provider>;
  get providerFilterList(): Array<Provider> {
    return this._providerFilterList;
  }

  private _selectedProvider: Provider;
  get selectedProvider(): Provider {
    return this._selectedProvider;
  }

  set selectedProvider(provider: Provider) {
    this._selectedProvider = provider;
    this.selectedProviderChange$.next(this.selectedProvider);
  }

  /* EventEmitters */
  public selectedProviderChange$: Subject<Provider> = new Subject();
  public providerListChange$: Subject<Array<Provider>> = new Subject();

  private providerAll: Provider = {
    ID: 'ALL',
    Name: 'Alle Anbieter',
    Bild: '',
    Vermittlernummer: false,
    Consumer: [],
    Aktiv: 0,
    BusinessPlusParameterListe: []
  };

  constructor(
    private http: HttpClient,
    private consumerService: ConsumerService,
    private messageService: MessageService,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this._initialized = false;
    this._providerFilterList = [
        this.providerAll
    ];
    this._selectedProvider = this.providerAll;

    if (this.consumerService.initialized) {
      this.setupProviderList().subscribe(() => {
        this._initialized = true;
      });
    } else {
      this.consumerService.initialize$.subscribe(() => {
        this.setupProviderList().subscribe(() => {
          this._initialized = true;
        });
      });
    }

    this.consumerService.selectedConsumerChange$.subscribe((consumer: Consumer) => {
      this.setupProviderList().subscribe();
    });
  }

  /**
   * Provider CRUD
   */

  public create(providerModel: any): Observable<Provider> {
    const provider: any = {
      ID: providerModel.ID.trim(),
      Name: (providerModel.Name || '').trim(),
      Vermittlernummer: providerModel.Vermittlernummer,
      Vertriebsvereinbarung: providerModel.Vertriebsvereinbarung || "",
      Aktiv: providerModel.Aktiv,
      Consumer: providerModel.Consumer
    };

    const http = this.http;
    return new Observable(observer => {
      http.post(ProviderService.providerUrl, provider).subscribe((result: Provider) => {
        observer.next(result);
        observer.complete();
        this.setupProviderList().subscribe();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public loadProviderList(limit?: number, offset?: number, excludeProviderIds?: Array<string>, filterSearch?: string, filterType?: string): Observable<any> {
    let urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID + '&simple=true';
    if (typeof limit !== 'undefined' && limit !== null) {
      urlParameter += '&limit=' + limit;
    }
    if (typeof offset !== 'undefined' && offset !== null) {
      urlParameter += '&offset=' + offset;
    }
    if (typeof excludeProviderIds !== 'undefined' && excludeProviderIds !== null) {
      urlParameter += '&excludeProviderIds=' + encodeURIComponent(JSON.stringify(excludeProviderIds));
    }
    if (typeof filterSearch !== 'undefined' && filterSearch !== null) {
      urlParameter += '&filterSearch=' + filterSearch;
    }
    if (typeof filterType !== 'undefined' && filterType !== null) {
      urlParameter += '&filterType=' + filterType;
    }
    return this.http.get<Array<Provider>>(ProviderService.providerUrl + urlParameter).pipe(
      map(providerList => {
        return {
          providerList: providerList,
          metaData: (<Array<any>>providerList).pop()
        };
      }),
      catchError(this.messageService.handleError('loadProviderList', []))
    );
  }

  public setupProviderList(): Observable<Array<Provider>> {
    return this.http.get<Array<Provider>>(ProviderService.providerUrl + '?consumerId=' + this.consumerService.selectedConsumer.ID).pipe(
      tap((providerList: Array<Provider>) => {
        providerList.pop();
        this.providerFilterList.splice(0, this.providerFilterList.length);
        this.providerFilterList.push(this.providerAll);
        providerList.forEach(p => this.providerFilterList.push(p));

        const nextSelectedProvider: Provider = this.selectedProvider || this.providerFilterList[0];
        const oldSelectedProvider: Provider = this.selectedProvider;
        this._selectedProvider = this.providerFilterList.find(provider => provider.ID === nextSelectedProvider.ID);

        if (this.selectedProvider === undefined) {
          this._selectedProvider = this.providerFilterList[0];
        }

        if (oldSelectedProvider !== null && oldSelectedProvider.ID !== this.selectedProvider.ID) {
          this.selectedProviderChange$.next(this.selectedProvider);
        }
        this.providerListChange$.next(this.providerFilterList);
      }),
      catchError(this.messageService.handleError('setupProviderList', []))
    );
  }

  public loadProvider(id: string): Observable<Provider> {
    return this.http.get<Provider>(ProviderService.providerUrl + '/' + id).pipe(
      catchError(this.messageService.handleError('getProvider', null))
    );
  }

  public update(providerModel: any): Observable<Provider> {
    const category: any = {
      ID: providerModel.ID.trim(),
      Name: (providerModel.Name || '').trim(),
      Vermittlernummer: providerModel.Vermittlernummer,
      Vertriebsvereinbarung: providerModel.Vertriebsvereinbarung || "",
      Aktiv: providerModel.Aktiv,
      Consumer: providerModel.Consumer,
      BusinessPlusParameterListe: providerModel.BusinessPlusParameterListe
    };

    console.log("provider to safe", category);

    const http = this.http;
    return new Observable(observer => {
      http.put(ProviderService.providerUrl + '/' + category.ID, category).subscribe((result: Provider) => {
        console.log("result", result);
        observer.next(result);
        observer.complete();
        this.setupProviderList().subscribe();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  /**
   * Utils
   */

  public uploadProviderIcon(file: File, provider: Provider, subscriber?: Subscriber<Provider>): Observable<void> | undefined {
    const filename = 'logo.png';
    const contentType = this.getContentType(filename);
    const options = {
      contentType: contentType,
      metadata: {
        'customer': this.authService.getCurrentUserCustomerId(),
        'role': this.authService.getCurrentUserRole(),
        'author': this.authService.getCurrentUserName(),
        'provider-id': provider.ID.toString(),
        // HTTP-Header sind nur ASCII-kompatibel (https://tools.ietf.org/html/rfc7230#section-3.2.4)
        'file-name': encodeURIComponent(filename.substr(-100))
      }
    };
    const observable = this.storageService.put('public/assets/provider/' + provider.ID + '/logo', file, options);
    if (subscriber) {
      observable.subscribe(() => {
        subscriber.next(provider);
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    } else {
      return observable;
    }
  }

  public uploadProviderAvatar(file: File, provider: Provider, subscriber?: Subscriber<Provider>): Observable<void> | undefined {
    const filename = 'avatar.png';
    const contentType = this.getContentType(filename);
    const options = {
      contentType: contentType,
      metadata: {
        'customer': this.authService.getCurrentUserCustomerId(),
        'role': this.authService.getCurrentUserRole(),
        'author': this.authService.getCurrentUserName(),
        'provider-id': provider.ID.toString(),
        // HTTP-Header sind nur ASCII-kompatibel (https://tools.ietf.org/html/rfc7230#section-3.2.4)
        'file-name': encodeURIComponent(filename.substr(-100))
      }
    };
    const observable = this.storageService.put('public/assets/provider/' + provider.ID + '/avatar', file, options);
    if (subscriber) {
      observable.subscribe(() => {
        subscriber.next(provider);
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    } else {
      return observable;
    }
  }

  private getContentType(fileName: string): string {
    const extension = /\.(png|jpe?g|gif|pdf)$/i.exec(fileName);
    if (!extension) {
      return null;
    }
    switch (extension[0].toLowerCase()) {
      case '.png':
        return 'image/png';
      case '.jpg':
      case '.jpeg':
        return 'image/jpeg';
      case '.gif':
        return 'image/gif';
      case '.pdf':
        return 'application/pdf';
      default:
        return null;
    }
  }
}
