import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import * as _moment from 'moment';

import { Product } from '../../classes/product';
import { ProductBenefits } from '../../classes/product-benefits';

import { ConsumerService } from '../consumer/consumer.service';
import { MediaSetService } from '../media-set/media-set.service';
import { MessageService } from '../message/message.service';
import { CategoryService } from '../category/category.service';

import { environment } from '../../../environments/environment';
import { MediaSet } from 'src/app/classes/media-set';

const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  /* URL  Definitionen */
  private static readonly productsUrl = environment.api + '/products';
  private static readonly benefitsUrl = environment.api + '/products/[ID]/benefits';

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private mediaSetService: MediaSetService,
    private consumerService: ConsumerService,
    private categoryService: CategoryService
  ) {
  }

  /**
   * Produkt CRUD
   */

  public create(productModel: Product): Observable<Product> {
    const product: any = {
      AnbieterID: productModel.AnbieterID.trim(),
      ProduktID: productModel.ProduktID.trim(),
      Bezeichnung: (productModel.Bezeichnung || '').trim(),
      Beschreibung: (productModel.Beschreibung || '').trim(),
      ProduktBezeichnung: (productModel.ProduktBezeichnung || '').trim(),
      Modell: productModel.Modell,
      Status: productModel.Status,
      Beginn: moment(productModel.Beginn).format('YYYY-MM-DD'),
      Ablauf: moment(productModel.Ablauf).format('YYYY-MM-DD'),
      Stages: productModel.Stages,
      Suchbegriffe: productModel.Suchbegriffe,
      Highlights: productModel.Highlights,
      Kategorien: productModel.Kategorien,
      Auftrag: productModel.Auftrag,
      AbrechnungsfreiBis: moment(productModel.AbrechnungsfreiBis).format('YYYY-MM-DD'),
      Berechtigungen: productModel.Berechtigungen,
      Risikotraeger: productModel.Risikotraeger,
      Provisionsregelung: productModel.Provisionsregelung,
      ProvisionsregelungsText: productModel.ProvisionsregelungsText
    };

    const budgetLimit = parseInt(<any>productModel.BudgetLimit, 10);
    if (!isNaN(budgetLimit)) {
      product.BudgetLimit = budgetLimit;
    }

    if (productModel.Leistungen) {
      product['Leistungen'] = productModel.Leistungen;
    }

    if (productModel.Defaults) {
      product['Defaults'] = productModel.Defaults;
    }

    if (productModel.Filter) {
      product['Filter'] = productModel.Filter;
    }

    if (product.Modell === 'BUSINESS') {
      product['Mapping'] = productModel.Mapping;
      product['Fragen'] = productModel.Fragen;
      product['Email'] = productModel.Email;
    } else if (product.Modell === 'BUSINESS_PRO') {
      product['Individuell'] = productModel.Individuell;
      product['Vorbehaltstarifierung'] = productModel.Vorbehaltstarifierung;
      product['TarifrechnerVersionMin'] = productModel.TarifrechnerVersionMin;
      product['TarifrechnerVersionMax'] = productModel.TarifrechnerVersionMax;
    } else if(product.Modell === 'BUSINESS_PLUS') {
        product['BusinessPlusParameter'] = productModel.BusinessPlusParameter;
    }

    if (productModel.MediaSet) {
      product.MediaSet = productModel.MediaSet;
    }

    const http = this.http;
    return new Observable(observer => {
      http.post(ProductService.productsUrl, product).subscribe((result: Product) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public loadArchievedProductList(): Observable<Product[]> {
    let urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID;
    urlParameter += '&categoryId=' + this.categoryService.selectedCategory.ID;
    urlParameter += '&archive=true';
    return this.http.get<Array<Product>>(ProductService.productsUrl + urlParameter).pipe(
      tap((productList: Array<Product>) => {
        productList.sort((l, r) => l.Bezeichnung < r.Bezeichnung ? -1 : +(l.Bezeichnung > r.Bezeichnung)); // Sortierung
      }),
      catchError(this.messageService.handleError('loadArchievedProducts', []))
    );
  }

  // tslint:disable-next-line: max-line-length
  public loadProductList(limit?: number, offset?: number, filterProvider?: string, filterCategory?: string, filterSearch?: string, filterState?: string, filterStages?: Array<{group: string, value: string}>, orderBy?: string, excludeProductIds?: Array<string>, includeModels?: Array<string>|string): Observable<any> {
    let urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID;

    if (typeof filterProvider !== 'undefined' && filterProvider !== null) {
      urlParameter += '&providerId=' + filterProvider;
    }
    if (typeof filterCategory !== 'undefined' && filterCategory !== null) {
      urlParameter += '&categoryId=' + filterCategory;
    }
    if (typeof limit !== 'undefined' && limit !== null) {
      urlParameter += '&limit=' + limit;
    }
    if (typeof offset !== 'undefined' && offset !== null) {
      urlParameter += '&offset=' + offset;
    }
    if (typeof filterSearch !== 'undefined' && filterSearch !== null) {
      urlParameter += '&filterSearch=' + filterSearch;
    }
    if (typeof filterState !== 'undefined' && filterState !== null) {
      urlParameter += '&filterState=' + filterState;
    }
    if (typeof filterStages !== 'undefined' && filterStages !== null) {
      const stages = {
        'include': [],
        'exclude': []
      };

      for (let i = 0; i < filterStages.length; i++) {
        stages[filterStages[i].group].push(filterStages[i].value);
      }

      urlParameter += '&filterStage=' + encodeURIComponent(JSON.stringify(stages));
    }
    if (typeof orderBy !== 'undefined' && orderBy !== null) {
      urlParameter += '&orderBy=' + orderBy;
    }
    if (typeof excludeProductIds !== 'undefined' && excludeProductIds !== null) {
      urlParameter += '&excludeProductIds=' + encodeURIComponent(JSON.stringify(excludeProductIds));
    }
    if (typeof includeModels !== 'undefined' && includeModels !== null) {
      // tslint:disable-next-line:max-line-length
      urlParameter += '&includeModels=' + encodeURIComponent(JSON.stringify(typeof includeModels === 'string' ? [includeModels] : includeModels));
    }

    return this.http.get(ProductService.productsUrl + urlParameter).pipe(
      map(productList => {
        return {
          productList: productList,
          metaData: (<Array<any>>productList).pop()
        };
      }),
      catchError(this.messageService.handleError('loadProductList', []))
    );
  }

  public loadProduct(id: string, allowDeleted: boolean = false): Observable<Product> {
    return this.http.get<Product>(ProductService.productsUrl + '/' + id + (allowDeleted ? '?allowDeleted=true' : '')).pipe(
      catchError(this.messageService.handleError<Product>('getProduct id=' + id))
    );
  }

  public loadProductListByIds(ids: Array<string>): Observable<any> {
    if (ids.length === 0) {
      return of({
        productList: [],
        metaData: {count: 0, limit: 0, maxCount: 0, offset: 0}
      });
    }

    let urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID;
    urlParameter += '&productIds=' + encodeURIComponent(JSON.stringify(ids));

    return this.http.get(ProductService.productsUrl + urlParameter).pipe(
      map(productList => {
        return {
          productList: productList,
          metaData: (<Array<any>>productList).pop()
        };
      }),
      catchError(this.messageService.handleError('loadProductListByIds', []))
    );
  }
  public update(productModel: Product): Observable<Product> {
    const product: any = {
      ID: productModel.ID.trim(),
      AnbieterID: productModel.AnbieterID.trim(),
      ProduktID: productModel.ProduktID.trim(),
      Bezeichnung: (productModel.Bezeichnung || '').trim(),
      Beschreibung: (productModel.Beschreibung || '').trim(),
      ProduktBezeichnung: (productModel.ProduktBezeichnung || '').trim(),
      Modell: productModel.Modell,
      Status: productModel.Status,
      Beginn: moment(productModel.Beginn).format('YYYY-MM-DD'),
      Ablauf: moment(productModel.Ablauf).format('YYYY-MM-DD'),
      Stages: productModel.Stages,
      Suchbegriffe: productModel.Suchbegriffe,
      Highlights: productModel.Highlights,
      Kategorien: productModel.Kategorien,
      Auftrag: productModel.Auftrag,
      AbrechnungsfreiBis: moment(productModel.AbrechnungsfreiBis).format('YYYY-MM-DD'),
      Berechtigungen: productModel.Berechtigungen,
      Risikotraeger: productModel.Risikotraeger,
      Provisionsregelung: productModel.Provisionsregelung,
      ProvisionsregelungsText: productModel.ProvisionsregelungsText
    };

    const budgetLimit = parseInt(<any>productModel.BudgetLimit, 10);
    if (!isNaN(budgetLimit)) {
      product.BudgetLimit = budgetLimit;
    }

    if (productModel.Leistungen) {
      product['Leistungen'] = productModel.Leistungen;
    }

    if (productModel.Defaults) {
      product['Defaults'] = productModel.Defaults;
    }

    if (productModel.Filter) {
      product['Filter'] = productModel.Filter;
    }

    if (product.Modell === 'BUSINESS') {
      product['Mapping'] = productModel.Mapping;
      product['Fragen'] = productModel.Fragen;
      product['Email'] = productModel.Email;
    } else if (product.Modell === 'BUSINESS_PRO') {
      product['Individuell'] = productModel.Individuell;
      product['Vorbehaltstarifierung'] = productModel.Vorbehaltstarifierung;
    } else  if(product.Modell === 'BUSINESS_PLUS') {
      product['BusinessPlusParameter'] = productModel.BusinessPlusParameter;
    }

    if (product.Modell === 'BUSINESS_PRO' || product.Modell === 'BUSINESS_PLUS') {
      product['TarifrechnerVersionMin'] = productModel.TarifrechnerVersionMin ? productModel.TarifrechnerVersionMin : '';
      product['TarifrechnerVersionMax'] = productModel.TarifrechnerVersionMax ? productModel.TarifrechnerVersionMax : '';
    }

    const mediaSet = productModel.MediaSetInstanz;

    // MediaSet updaten, falls die Inhalte geändert wurden oder es noch gar nicht existiert
    let mediaSetUpdate: Observable<MediaSet>;
    if (mediaSet) {
        mediaSet.Produkte = mediaSet.Produkte || [];
        const isLinked = mediaSet.Produkte.some(pid => pid === productModel.ID);
        if (!isLinked) {
          mediaSet.Produkte.push(productModel.ID);
        }
        mediaSetUpdate = (mediaSet.ID === '' ? this.mediaSetService.create(mediaSet) : this.mediaSetService.update(mediaSet));
      } else {
        mediaSetUpdate = of(null);
      }

      const http = this.http;
      return new Observable(observer => {
          mediaSetUpdate.subscribe((updatedMediaSet: MediaSet) => {
              product.MediaSet = updatedMediaSet ? updatedMediaSet.ID : '';
              http.put(ProductService.productsUrl + '/' + product.ID, product).subscribe((result: Product) => {
                  observer.next(result);
                  observer.complete();
              }, error => {
                  observer.error(error);
                  observer.complete();
              });
          }, (error) => {
            console.log('MediaSet Error: ', error);
            observer.error(error);
            observer.complete();
          });
      });
  }

  public delete(id: string): Observable<void> {
    return new Observable(subscriber => {
      this.http.delete(ProductService.productsUrl + '/' + id).subscribe(() => {
        subscriber.next();
        subscriber.complete();
      }, error => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }

  /**
   * Product Benefits read
   */

  public getBenefits(id: string): Observable<ProductBenefits> {
    const urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID;
    return this.http.get<ProductBenefits>(ProductService.benefitsUrl.replace('[ID]', id) + urlParameter).pipe(
      catchError(this.messageService.handleError<ProductBenefits>('getBenefits id=' + id))
    );
  }
}
