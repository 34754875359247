import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _moment from 'moment';

import { SearchFieldComponent } from '../../search-field/search-field.component';

import { ItemList } from '../../../classes/item-list';
import { CategorySelectDialog } from '../../../classes/category-select-dialog';
import { Category } from '../../../classes/category';

import { NavigationService } from '../../../services/navigation/navigation.service';
import { MessageService } from '../../../services/message/message.service';

@Component({
  selector: 'app-category-select-dialog',
  templateUrl: './category-select-dialog.component.html',
  styleUrls: ['./category-select-dialog.component.css']
})
export class CategorySelectDialogComponent extends ItemList<Category> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  public selectedCategory: Category = null;

  constructor(
    public dialogRef: MatDialogRef<CategorySelectDialogComponent>,
    public navigationService: NavigationService,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: CategorySelectDialog
  ) {
    super('category_select', navigationService, messageService);
  }

  onCloseClick(selectedCategory: Category = null): void {
    this.dialogRef.close(selectedCategory);
  }

  loadItems(limit: number, offset: number) {
    const searchFilterText = this.searchField ? this.searchField.searchTerm : '';
    const filteredCategories = this.data.Categories.filter((category) => {
      if (category.ID === 'ALL') {
        return false;
      }
      return (searchFilterText.length === 0 || this.categoryContainsText(category, searchFilterText));
    });
    if (searchFilterText) {
        filteredCategories.sort((m1, m2) => {
            const m1Rank = this.categoryGetRanking(m1, searchFilterText);
            const m2Rank = this.categoryGetRanking(m2, searchFilterText);
            if ((m1Rank === -1 && m2Rank >= 0) || m1Rank < m2Rank) {
                return -1;
            }
            if ((m2Rank === -1 && m1Rank >= 0) || m1Rank > m2Rank) {
                return 1;
            }
            return 0;
        });
    }
    const paginatedCategories = filteredCategories.filter((_, index) => {
        return index >= offset && index < offset + limit;
    });

    return {items: paginatedCategories, maxCount: filteredCategories.length};
  }

  categoryContainsText(category: Category, text: string) {
    const texts = text.toLocaleLowerCase().split(' ');
    const categoryText = category.Bezeichnung.toLocaleLowerCase();
    for (let i = 0; i < texts.length; i++) {
      if (categoryText.indexOf(texts[i]) === -1) {
        return false;
      }
    }
    return true;
  }

  categoryGetRanking(category: Category, text: string) {
    const texts = text.toLocaleLowerCase().split(' ');
    const categoryText = category.Bezeichnung.toLocaleLowerCase();
    if (texts.length === 0) {
      return -1;
    }

    let ranking = 0;
    for (let i = 0; i < texts.length; i++) {
      ranking += categoryText.indexOf(texts[i]);
    }
    ranking /= texts.length;
    return ranking;
  }
}
