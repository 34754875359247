import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CategoryService } from '../../services/category/category.service';
import { NavigationService } from '../../services/navigation/navigation.service';

import { Category } from '../../classes/category';

@Component({
  selector: 'app-category-edit-item',
  templateUrl: './category-edit-item.component.html',
  styleUrls: ['./category-edit-item.component.css']
})
export class CategoryEditItemComponent implements OnInit, OnDestroy {

  public category: Category;

  private parameterSubscription: Subscription;
  public initialized: boolean;

  constructor(
    private router: Router,
    private categoryService: CategoryService,
    private activatedRoute: ActivatedRoute,
    public navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.navigationService.title = 'Kategorie bearbeiten';
    this.initialized = false;
    this.navigationService.startLoading('category_edit_init');

    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {
      this.categoryService.loadCategory(params['categoryId']).subscribe((category: Category) => {
        this.navigationService.stopLoading('category_edit_init');

        this.category = category;
        this.initialized = true;
      });
    });
  }


  ngOnDestroy() {
    this.parameterSubscription.unsubscribe();
  }

  addCategory(): () => void {
    return (() => {
      this.router.navigate(['/category-create']);
    });
  }
}
