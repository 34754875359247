import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Medium } from '../../../classes/medium';
import { PendingMedium } from '../../../classes/media-set';
import { NavigationService } from '../../../services/navigation/navigation.service';

@Component({
  selector: 'app-media-create-dialog',
  templateUrl: './media-create-dialog.component.html',
  styleUrls: ['./media-create-dialog.component.css']
})
export class MediaCreateDialogComponent implements OnInit {
  public medium: Medium;

  constructor(
    public dialogRef: MatDialogRef<MediaCreateDialogComponent>,
    public navigationService: NavigationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.navigationService.startLoading('media_create_init');

    const type = this.data.type ? this.data.type : null;

    this.medium = new PendingMedium(type);

    this.navigationService.stopLoading('media_create_init');
  }

}
