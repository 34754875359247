import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FieldType } from '@ngx-formly/core';
import { MatDialog } from '@angular/material';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { StorageService } from '../../services/storage/storage.service';
import { PdfViewerService } from '../../services/pdfviewer/pdfviewer.service';
import { YoutubeService } from '../../services/youtube/youtube.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MediaService } from '../../services/media/media.service';

import { MediaSelectDialogComponent } from '../dialog/media-select-dialog/media-select-dialog.component';
import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';

import { MediaSelectDialog } from '../../classes/media-select-dialog';
import { Medium } from '../../classes/medium';
import { PendingMedium } from '../../classes/media-set';
import { GenericDialog } from '../../classes/generic-dialog';

const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-media-linking-section',
  templateUrl: './form-type-media-linking.component.html',
  styleUrls: ['./form-type-media-linking.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTypeMediaLinkingComponent extends FieldType implements OnInit {

  public preferredProviderId: string;

  public mediaIds: {
    Downloads: Array<number | PendingMedium>;
    Testberichte: Array<number | PendingMedium>;
    Videos: Array<number | PendingMedium>;
    Formulare: Array<number | PendingMedium>;
  };

  public media: {
    Downloads: Array<Medium>;
    Testberichte: Array<Medium>;
    Videos: Array<Medium>;
    Formulare: Array<Medium>;
  };

  public defaultLimits = {
    Downloads: 5,
    Testberichte: 5,
    Videos: 1,
    Formulare: 5,
  };

  public templateOptions: any;

  public pendingTooltip = 'Dieses Medium wurde noch nicht gespeichert. Es wird erzeugt, sobald Sie das Medien-Paket abspeichern.';

  constructor(
    public navigationService: NavigationService,
    private storageService: StorageService,
    private pdfViewer: PdfViewerService,
    private youtubeService: YoutubeService,
    private dialog: MatDialog,
    private mediaService: MediaService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.templateOptions = this.field.templateOptions;

    this.preferredProviderId = this.templateOptions.providerId;

    this.mediaIds = {
      Downloads: this.templateOptions.Downloads || [],
      Testberichte: this.templateOptions.Testberichte || [],
      Videos: this.templateOptions.Videos || [],
      Formulare: this.templateOptions.Formulare || []
    };

    this.media = {
      Downloads: [],
      Testberichte: [],
      Videos: [],
      Formulare: []
    };

    const allMediaIds = [];
    ['Downloads', 'Testberichte', 'Videos', 'Formulare'].forEach(field => {
        this.mediaIds[field].forEach((mediaId: number | PendingMedium) => {
            if (typeof mediaId === 'number') {
                allMediaIds.push(mediaId);
            }
        });
    });

    this.mediaService.loadMediaListByIds(allMediaIds).subscribe(result => {
      const referenceMedia = result.mediaList;

      this.mediaIds.Downloads.forEach((mediaId: number | PendingMedium) => {
          if (mediaId instanceof PendingMedium) {
              mediaId.Bild = '/assets/img/placeholder/product-download.jpg';
              this.media.Downloads.push(mediaId);
          } else {
              for (let i = 0; i < referenceMedia.length; i++) {
                  if (referenceMedia[i].ID === mediaId) {
                      const medium = referenceMedia[i];
                      this.media.Downloads.push(medium);
                      if (medium.Bild.indexOf('http') !== 0) {
                          const imageURL = medium.Bild;
                          medium.Bild = '/assets/img/placeholder/product-download.jpg';
                          this.storageService.getSignedUrl(imageURL).subscribe(url => {
                              medium.Bild = url;
                          }, error => {
                              console.log('error getting url: ' + error);
                          });
                      }
                      break;
                  }
              }
          }
      });

      this.mediaIds.Testberichte.forEach((mediaId: number | PendingMedium) => {
          if (mediaId instanceof PendingMedium) {
              mediaId.Link = '/assets/img/placeholder/product-download.jpg';
              this.media.Testberichte.push(mediaId);
          } else {
              for (let i = 0; i < referenceMedia.length; i++) {
                  if (referenceMedia[i].ID === mediaId) {
                      const medium = referenceMedia[i];
                      this.media.Testberichte.push(medium);
                      if (medium.Link.indexOf('http') !== 0) {
                          const imageURL = medium.Link;
                          medium.Link = '/assets/img/placeholder/product-download.jpg';
                          this.storageService.getSignedUrl(imageURL).subscribe(url => {
                              medium.Link = url;
                          }, error => {
                              console.log('error getting url: ' + error);
                          });
                      }
                      break;
                  }
              }
          }
      });

      this.mediaIds.Videos.forEach((mediaId: number | PendingMedium) => {
          if (mediaId instanceof PendingMedium) {
              this.media.Videos.push(mediaId);
          } else {
              for (let i = 0; i < referenceMedia.length; i++) {
                  if (referenceMedia[i].ID === mediaId) {
                      this.media.Videos.push(referenceMedia[i]);
                      break;
                  }
              }
          }
      });

      this.mediaIds.Formulare.forEach((mediaId: number | PendingMedium) => {
          if (mediaId instanceof PendingMedium) {
              this.media.Formulare.push(mediaId);
          } else {
              for (let i = 0; i < referenceMedia.length; i++) {
                  if (referenceMedia[i].ID === mediaId) {
                      this.media.Formulare.push(referenceMedia[i]);
                      break;
                  }
              }
          }
      });

      this.cdRef.detectChanges();
    });
  }

  isVisible(media: Medium) {
    const fromDate = new Date(media.Beginn);
    const toDate = new Date(media.Ablauf);
    const nowDate = new Date();
    return nowDate >= fromDate && nowDate <= toDate;
  }

  isPending(media: Medium) {
      return media instanceof PendingMedium;
  }

  getNotVisibleTooltip(media: Medium): string {
    const fromDate = new Date(media.Beginn);
    const toDate = new Date(media.Ablauf);
    const nowDate = new Date();
    if (nowDate < fromDate) {
      return 'Dieses Medium wird am ' + fromDate.toLocaleDateString('de-DE', dateOptions) + ' veröffentlicht.';
    }
    if (nowDate > toDate) {
      return 'Dieses Medium ist seit dem ' + fromDate.toLocaleDateString('de-DE', dateOptions) + ' nicht mehr öffentlich.';
    }
    return 'Dieses Medium ist noch nicht veröffentlicht.';
  }

  openPDF(media: Medium) {
    console.log('openPDF: ', media);
    if (media.Link) {
      this.storageService.getSignedUrl(media.Link).subscribe(url => {
        this.pdfViewer.openDoc(media.Titel, url);
      }, error => {
        console.log('error getting url: ' + error);
      });
    } else if (media instanceof PendingMedium && media.Datei && media.Datei[0]) {
        this.pdfViewer.openDoc(media.Titel, media.Datei[0]);
    }
  }

  openImage(media: Medium) {
    window.open(media.Link, '_blank');
  }

  getVideoId(video: Medium): string {
    return this.youtubeService.getVideoId(video.Link);
  }

  linkMedia(docType: string, mediaType: string) {
    const dialogRef = this.dialog.open(MediaSelectDialogComponent, {
      panelClass: 'media-select-dialog-container',
      data: <MediaSelectDialog> {
        type: docType,
        mediaType: mediaType,
        preferredProviderId: this.preferredProviderId,
        excludeMediaIds: this.mediaIds.Downloads
            .concat(this.mediaIds.Testberichte)
            .concat(this.mediaIds.Videos)
            .concat(this.mediaIds.Formulare)
            .filter(value => typeof value === 'number')
            .map(v => v.toString())
      },
      maxHeight: '100vh'
    });
    dialogRef.afterClosed().subscribe((mediaList: Array<Medium>) => {
      if (mediaList) {
        for (let i = 0; i < mediaList.length; i++) {
          const media = mediaList[i];
          switch (mediaType) {
            case 'Downloads':
              if (media instanceof PendingMedium) {
                media.Bild = '/assets/img/placeholder/product-download.jpg';
              } else if (media.Bild.indexOf('http') !== 0) {
                const imageURL = media.Bild;
                media.Bild = '/assets/img/placeholder/product-download.jpg';
                this.storageService.getSignedUrl(imageURL).subscribe(url => {
                  media.Bild = url;
                  this.cdRef.detectChanges();
                }, error => {
                    console.log('error getting url: ' + error);
                });
              }
            break;
            case 'Testberichte':
              if (media instanceof PendingMedium) {
                  media.Link = '/assets/img/placeholder/product-download.jpg';
                  const reader = new FileReader();
                  reader.onload = () => {
                      media.Link = <string> reader.result;
                      this.cdRef.detectChanges();
                  };
                  reader.readAsDataURL(media.Datei[0]);
              } else if (media.Link.indexOf('http') !== 0) {
                const imageURL = media.Link;
                media.Link = '/assets/img/placeholder/product-download.jpg';
                this.storageService.getSignedUrl(imageURL).subscribe(url => {
                  media.Link = url;
                  this.cdRef.detectChanges();
                }, error => {
                    console.log('error getting url: ' + error);
                });
              }
            break;
          }
          if (media instanceof PendingMedium) {
              this.media[mediaType].push(media);
              this.mediaIds[mediaType].push(media);
          } else {
              this.media[mediaType].push(media);
              this.mediaIds[mediaType].push(media.ID);
          }
          this.cdRef.detectChanges();
        }
      }
    });
  }

  drop(event: CdkDragDrop<string[]>, mediaType: string) {
    moveItemInArray(this.media[mediaType], event.previousIndex, event.currentIndex);
    moveItemInArray(this.mediaIds[mediaType], event.previousIndex, event.currentIndex);
    this.cdRef.detectChanges();
  }

  removeMedia(mediaType: string, index: number, pending?: boolean) {
    const genericDialog = new GenericDialog();
    if (pending === true) {
        genericDialog.titel = 'Medium löschen';
        genericDialog.text = 'Dieses Medium wurde noch nicht gespeichert. Wenn Sie es aus dem \
                            Medien-Paket entfernen, wird es dauerhaft gelöscht.';
    } else {
        genericDialog.titel = 'Medium lösen';
        genericDialog.text = 'Sie sind dabei ein Medium aus diesem Medien-Paket zu entfernen. \
                          Das Medium wird nicht gelöscht, sondern nur aus diesem Medien-Paket entfernt.';
    }
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      maxWidth: '500px',
      maxHeight: '100vh',
      data: genericDialog
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.media[mediaType].splice(index, 1);
        this.mediaIds[mediaType].splice(index, 1);
        this.cdRef.detectChanges();
      }
    });
  }

  editMedia(media: Medium) {
    const genericDialog = new GenericDialog();
    genericDialog.titel = 'Medium bearbeiten';
    genericDialog.text = '<b>Achtung!</b> Sie sind dabei in die Bearbeitenansicht eines Mediums zu wechseln. \
                          Alle ungespeicherten Änderungen des Medien-Pakets gehen dann verloren!';
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      maxWidth: '500px',
      maxHeight: '100vh',
      data: genericDialog
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.to.ref) {
          this.router.navigate(['/media-edit', media.ID], { queryParams: { ref: this.to.ref } });
        } else {
          this.router.navigate(['/media-edit', media.ID]);
        }
      }
    });
  }

  getLimit(mediaType) {
    if (typeof this.templateOptions !== 'undefined' &&
        typeof this.templateOptions.limits !== 'undefined' &&
        typeof this.templateOptions.limits[mediaType] !== 'undefined') {
      return this.templateOptions.limits[mediaType];
    }
    console.log('this.defaultLimits: ', this.defaultLimits);
    return this.defaultLimits[mediaType];
  }
}
