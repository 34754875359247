import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-icon-section',
  templateUrl: './form-type-icon.component.html',
  styleUrls: ['./form-type-icon.component.css'],
})

export class FormTypeIconComponent extends FieldType implements OnInit {
  public opt: any;

  constructor() {
    super();
    this.opt = {};
  }

  ngOnInit() {
    this.opt = this.to;
  }

  getIcon() {
    if (typeof this.opt.icon === 'function') {
      return this.opt.icon(this.model);
    }
    return this.opt.icon;
  }

  getTooltip() {
    if (typeof this.opt.tooltip === 'function') {
      return this.opt.tooltip(this.model);
    }
    return this.opt.tooltip;
  }
}
