import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public consumerService: ConsumerService,
    public navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.navigationService.title = 'Snoopr Search Console';
  }
}
