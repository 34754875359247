import { Provider } from './provider';
import { Consumer } from './consumer';
import { Account } from './account';

export class Customer {
  ID: string;
  Name: string;
  Aktiv: number;
  FreiKontingente: number;
  Rolle?: string;
  Provider?: Array<Provider>;
  Consumer?: Array<Consumer>;
  Accounts?: Array<Account>;
  Verifiziert?: boolean;
  IstAnbieter?: boolean;
  HomepageProgrammAktiv?: boolean;
  HomepageProgrammDaten?: any;
  IstPartner?: boolean;
  BerechtigtZumVeroeffentlichen?: boolean;
  HatBankkontoVerifiziert?: boolean;
  Rechnungsdaten: any;
}
