import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Consumer } from '../../classes/consumer';

import { ItemList } from '../../classes/item-list';

import { MessageService } from '../../services/message/message.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AuthService } from '../../services/auth/auth.service';
import { ConsumerService } from 'src/app/services/consumer/consumer.service';
import { map } from 'rxjs/operators';
import { SearchFieldComponent } from '../search-field/search-field.component';

@Component({
  selector: 'app-consumer-list',
  templateUrl: './consumer-list.component.html',
  styleUrls: ['./consumer-list.component.css']
})
export class ConsumerListComponent extends ItemList<Consumer> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  constructor(
    private router: Router,
    public navigationService: NavigationService,
    public messageService: MessageService,
    public authService: AuthService,
    public consumerService: ConsumerService
  ) {
    super('consumer', navigationService, messageService);
  }

  addConsumer(): () => void {
    return (() => {
      this.router.navigate(['/consumer-create']);
    });
  }

  loadItems(limit: number, offset: number) {
    return this.consumerService.loadConsumerList(limit, offset, null, this.searchField.searchTerm || null).pipe(
      map(result => ({items: result.consumerList, maxCount: result.metaData.maxCount}))
    );
  }
}
