import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Customer } from '../../classes/customer';
import { Consumer } from '../../classes/consumer';
import { Provider } from '../../classes/provider';

@Component({
  selector: 'app-providers-management',
  templateUrl: './providers-management.component.html',
  styleUrls: ['./providers-management.component.css']
})
export class ProvidersManagementComponent implements OnInit, OnDestroy {
  private parameterSubscription: Subscription;

  private tabs = [
    'customer',
    'provider',
    'consumer'
  ];
  public selectedTabIndex = 0;

  public customerList: Array<Customer>;
  public consumerList: Array<Consumer>;
  public providerList: Array<Provider>;

  public isLoading = true;

  constructor(
    public navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.navigationService.title = 'Kunden';
    this.navigationService.startLoading('providers_management_init');

    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {
      for (let i = 0; i < this.tabs.length; i++) {
        if (params['tabName'] === this.tabs[i]) {
          this.selectedTabIndex = i;
          break;
        }
      }
    });

    this.navigationService.stopLoading('providers_management_init');
  }

  ngOnDestroy() {
    this.parameterSubscription.unsubscribe();
  }

  onSelectIndexChange(index) {
    this.router.navigate(['/providers-management', this.tabs[index]]);
  }
}
