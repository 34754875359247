import { Component, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConsumerService } from './services/consumer/consumer.service';
import { CategoryService } from './services/category/category.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { CustomerService } from './services/customer/customer.service';
import { ProviderService } from './services/provider/provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public isLoginPage: boolean;
  public isMSIE = false;

  constructor(
    public consumerService: ConsumerService,
    public categoryService: CategoryService,
    public providerService: ProviderService,
    public authService: AuthService,
    public customerService: CustomerService, // wird nicht benutzt muss aber vorhanden sein
    public router: Router,
    private renderer: Renderer2,
    private matDialog: MatDialog
  ) {
    this.isLoginPage = false;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        this.isLoginPage = /^\/login(\?.*)?$/i.test(url);
      }
    });

    this.isMSIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    if (this.isMSIE) {
      this.renderer.addClass(document.body, 'msie');
    }
  }

  isAnyDialogOpen() {
    return this.matDialog.openDialogs.length > 0;
  }

  isLoaded() {
    return this.authService.initialized && ((
      this.authService.hasBrokerData &&
      this.providerService.initialized &&
      this.categoryService.initialized &&
      this.consumerService.initialized
    ) || this.isLoginPage);
  }
}
