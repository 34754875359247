import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Editor } from 'src/app/classes/editor';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ItemComponent } from 'src/app/classes/item-component';
import { Profile, Email } from 'src/app/classes/profile';

import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { Customer } from 'src/app/classes/customer';
import { StorageService } from 'src/app/services/storage/storage.service';
import { FormGroup, AbstractControl } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormTypeButtonComponent } from '../form-type-button/form-type-button.component';
import { NotifyService, NotifyType } from 'src/app/services/notify/notify.service';
import { EditorService } from 'src/app/services/editor/editor.service';


export class ProfileEditor extends Editor<ProfileComponent, Profile> {
  constructor(
    public component: ProfileComponent,
    public messageService: MessageService,
    public profileService: ProfileService,
    public authService: AuthService,
    public customerService: CustomerService,
    public notifyService: NotifyService,
    public navigationService: NavigationService,
    public avatarModel: any,
    public editorService: EditorService
  ) {
      super(component);
  }

  submitEmails(): Observable<Profile> {
    this.modifyEmails(this.model.Emails);
    this.navigationService.startLoading('profile-submit-emails');
    return this.profileService.updateEmails(this.model.CustomerID, this.model.Emails).pipe(finalize(() => {
      this.navigationService.stopLoading('profile-submit-emails');
    }));
  }

  submit(model: Profile, type: string): Observable<void> {
    return new Observable(subscriber => {
      if (model.Emails.some(email => !email.Verifiziert)) {
        this.notifyService.notify(NotifyType.Warning, 'Bitte die E-Mail-Adresse verifizieren.');
        this.editorComponent.gotoStep(1);
        this.editorService.setModel(null);
        subscriber.complete();
        return;
      }
      const avatarImage = this.avatarModel.image ? this.avatarModel.image : null;
      const avatarImageOriginal = this.avatarModel.imageOriginal ? this.avatarModel.imageOriginal : null;

      let avatarObserver = null;
      if (avatarImage != null) {
        avatarObserver = new Observable(s => {
          this.profileService.uploadFile(avatarImage, 'public/assets/broker/' + model.CustomerID, 'avatar.png', s);
        });
      }

      let avatarOriginalObserver = null;
      if (avatarImageOriginal !== null) {
        avatarOriginalObserver = this.profileService.uploadFile(avatarImageOriginal,
          'public/assets/broker/' + model.CustomerID, 'avatar-original.png');
      }

      forkJoin([
        avatarObserver != null ? avatarObserver : of(null),
        avatarOriginalObserver != null ? avatarOriginalObserver : of(null)
      ]).subscribe(() => {
        this.profileService.updateProfile(model).subscribe((profile: Profile) => {

          if (this.authService.getCurrentUserCustomerId() === model.CustomerID) {
            this.customerService.loadCustomer(model.CustomerID).subscribe((loadedCustomer) => {
              // tslint:disable-next-line:max-line-length
              this.authService.setBrokerData(loadedCustomer.IstAnbieter, loadedCustomer.Verifiziert, loadedCustomer.BerechtigtZumVeroeffentlichen);
              this.preModelModifier(profile);
              this.component.refreshEditor(profile);
              this.editorService.setModel(null);
              subscriber.next();
              subscriber.complete();
            });
          } else {
            this.preModelModifier(profile);
            this.component.refreshEditor(profile);
            this.editorService.setModel(null);
            subscriber.next();
            subscriber.complete();
          }
        });
      });
    });
  }

  preModelModifier(model: Profile): void {
    if (typeof model.Emails === 'undefined') {
      model.Emails = [];
    }
    this.modifyEmails(model.Emails);
  }

  modifyEmails(emails: Email[]): void {
    for (let i = 0; i < emails.length; i++) {
      if (emails[i].ValidierungsCode) {
        emails[i].ValidierungsCode = emails[i].ValidierungsCode.trim();
      }
      delete emails[i]['isNew'];
    }
  }

  postModelModifier(model: Profile, type: string): void {
    console.log('PostModelModifier');
    console.log('model: ', this.model);
    if (typeof model.Emails === 'undefined') {
      model.Emails = [];
    }

    if (model.Anrede !== '3') {
      this.model.Name = (<any>this.model).NameNP;
      this.model.Rechtsname = (<any>this.model).RechtsnameNP;
    } else {
      this.model.Name = (<any>this.model).NameFa;
      this.model.Rechtsname = (<any>this.model).RechtsnameFa;
    }
    console.log('model: ', this.model);
  }
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent extends ItemComponent implements OnInit, OnDestroy {

  public customer: Customer;
  public profile: Profile;
  public editor: ProfileEditor;
  public editorInitialized = false;

  private parameterSubscription: Subscription;

  public avatarImageUrl = '';

  public avatarForm: FormGroup;
  public avatarOptions: any;
  public avatarFields: Array<any>;
  public avatarModel: any = {};

  constructor(
    private navigationService: NavigationService,
    private profileService: ProfileService,
    private authService: AuthService,
    private notifyService: NotifyService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private storageService: StorageService,
    private editorService: EditorService
  ) {
    super();
  }

  ngOnInit() {
    this.navigationService.title = 'Mein Profil';
    this.navigationService.startLoading('profile_editor_init');

    this.editor = new ProfileEditor(
      this,
      this.messageService,
      this.profileService,
      this.authService,
      this.customerService,
      this.notifyService,
      this.navigationService,
      this.avatarModel,
      this.editorService
    );

    // tslint:disable-next-line:max-line-length
    this.editor.setInfo1(() => '');
    this.editor.setInfo2(() => '');
    this.editor.title = '';
    this.editor.hideHeader = true;


    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {
      let customerId = null;
      if (typeof params['customerId'] !== 'undefined' && this.authService.isSystem()) {
        customerId = params['customerId'];
      }

      if (customerId === null) {
        customerId = this.authService.getCurrentUserCustomerId();
      }

      this.customerService.loadCustomer(customerId, true).subscribe((customer: Customer) => {
        this.customer = customer;
        if (customer.Rolle === 'broker') {
          this.profileService.loadProfile(customerId).subscribe((profile) => {
            this.avatarImageUrl = 'public/assets/broker/' + profile.CustomerID + '/avatar';
            this.profile = profile;

            if (this.authService.getCurrentUserCustomerId() === customer.ID) {
              // tslint:disable-next-line:max-line-length
              this.authService.setBrokerData(customer.IstAnbieter, customer.Verifiziert, customer.BerechtigtZumVeroeffentlichen);
            }

            this.createEditorSteps();
            this.editorInitialized = true;
            setTimeout(() => {
              this.editorService.setModel(this.profile);
              }, 0);
            this.navigationService.stopLoading('profile_editor_init');
          });
        } else {
          console.log('Kein Makler Profil!');
          this.navigationService.stopLoading('profile_editor_init');
        }
      });
    });
  }

  ngOnDestroy() {
    this.parameterSubscription.unsubscribe();
  }


  refreshEditor(profile: Profile): void {
    const keys = Object.keys(profile);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      this.profile[key] = profile[key];
    }
    this.createEditorSteps();
  }

  submitEmailCode(model: Email, form: FormGroup): void {
    if (!this.profile.Emails.some(e => e.Primaer)) {
      model.Primaer = true;
    }
    this.editor.submitEmails().subscribe({
      next: (profile) => {
        this.notifyService.notify(NotifyType.Success, 'Die Adresse wurde verifiziert.');
        profile.Emails.forEach(email => {
          if (email.Email === model.Email) {
            model.Verifiziert = email.Verifiziert;
            model.Primaer = email.Primaer;
            form.controls.Primaer.patchValue(email.Primaer);
          }
        });
      },
      error: (error) => {
        console.error(error);
        this.notifyService.notify(NotifyType.Error, 'Der Code ist ungültig.');
      }
    });
  }

  requestEmailCode(model: Email): void {
    model.ValidierungsCodeSenden = true;
    this.editor.submitEmails().subscribe({
      next: () => {
        const addr = model.Email;
        this.notifyService.notify(NotifyType.Success, 'Ein Prüfcode wurde an ' + addr + ' versendet.');
      },
      error: (error) => {
        console.error(error);
        this.notifyService.notify(NotifyType.Error, 'Versenden des Prüfcodes fehlgeschlagen.');
      }
    });
    model.ValidierungsCodeSenden = false;
  }

  createEditorSteps(): void {
    this.avatarForm = new FormGroup({});
    this.avatarOptions = <FormlyFormOptions> {};
    this.avatarFields = [{
      key: 'image',
      type: 'image-crop',
      className: 'flex-1',
      templateOptions: {
        label: 'Dateiauswahl',
        floatLabel: 'always',
        appearance: 'outline',
        accept: '.jpg,.jpeg,.png,.gif',
        editInPreview: true,
        image: this.avatarImageUrl,
        maintainAspectRatio: true,
        isRound: true,
        aspectRatio: 1 / 1,
        resizeToWidth: 200,
        resizeToHeight: 200,
        cropperMinWidth: 100,
        cropperMinHeight: 100,
        previewHeight: 50,
        previewWidth: 50,
        onImageChange: (model: any, image: any, originalFile: any, isAppliedExternal: boolean) => {
          (<any>this.avatarModel).AvatarImageOriginal = originalFile;
        }
      }
    }];

    if (this.profile.Anrede !== '3') {
      (<any>this.profile).Rechtsname = this.profile.Vorname + ' ' + this.profile.Name;
    }

    (<any>this.profile).NameFa = this.profile.Name;
    (<any>this.profile).NameNP = this.profile.Name;
    (<any>this.profile).RechtsnameNP = this.profile.Rechtsname;
    (<any>this.profile).RechtsnameFa = this.profile.Rechtsname;

    this.editor.steps = [];
    // create step 1
    this.editor.steps.push({
      label: 'Stammdaten',
      fields: [{ // 1. Zeile
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{
          key: 'Anrede',
          type: 'select',
          className: 'flex-1',
          templateOptions: {
            label: 'Anrede',
            appearance: 'outline',
            floatLabel: 'always',
            description: '',
            required: true,
            options: [
              {label: 'Herr', value: '1'},
              {label: 'Frau', value: '2'},
              {label: 'Firma', value: '3'}
            ],
            disabled: !this.authService.isSystem(),
            change: (fc) => {
              console.log('Profile: ', this.profile);
              if (this.profile.Anrede !== '3') {
                fc.formControl.parent.controls['RechtsnameNP'].patchValue(this.profile.Vorname + ' ' + (<any>this.profile).NameNP);
              }
            }
          }
        }, {
          key: 'NameFa',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            label: 'Produktgeber Name',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 60,
            required: true,
            disabled: !this.authService.isSystem(),
            change: (fc, field) => {
              console.log('Profile: ', this.profile);
              (<any>this.profile).NameNP = (<any>this.profile).NameFa;
              (<any>this.profile).RechtsnameNP = this.profile.Vorname + ' ' + (<any>this.profile).NameFa;
            }
          },
          hideExpression: () => this.profile.Anrede !== '3'
        }, {
          key: 'RechtsnameNP',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            label: 'Produktgeber Name',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 60,
            disabled: true,
            change: (fc, field) => {
              console.log('Profile: ', this.profile);
              (<any>this.profile).RechtsnameFa = (<any>this.profile).RechtsnameNP;
            }
          },
          hideExpression: () => this.profile.Anrede === '3'
        }]
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{
          key: 'Vorname',
          type: 'input',
          className: 'flex-1 padding-right-25',
          templateOptions: {
            label: 'Vorname',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 50,
            required: true,
            disabled: !this.authService.isSystem(),
            change: (fc, field) => {
              console.log('Profile: ', this.profile);
              (<any>this.profile).RechtsnameFa = this.profile.Vorname + ' ' + (<any>this.profile).NameNP;
              if (typeof fc.formControl.parent.controls['RechtsnameNP'] !== 'undefined') {
                fc.formControl.parent.controls['RechtsnameNP'].patchValue(this.profile.Vorname + ' ' + (<any>this.profile).NameNP);
              }
            }
          },
          hideExpression: () => this.profile.Anrede === '3'
        }, {
          key: 'NameNP',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            label: 'Nachname',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 200,
            required: true,
            disabled: !this.authService.isSystem(),
            change: (fc, field) => {
              console.log('Profile: ', this.profile);
              (<any>this.profile).NameFa = (<any>this.profile).NameNP;
              (<any>this.profile).RechtsnameFa = this.profile.Vorname + ' ' + (<any>this.profile).NameNP;
              if (typeof fc.formControl.parent.controls['RechtsnameNP'] !== 'undefined') {
                fc.formControl.parent.controls['RechtsnameNP'].patchValue(this.profile.Vorname + ' ' + (<any>this.profile).NameNP);
              }
            }
          },
          hideExpression: () => this.profile.Anrede === '3'
        }]
      }, {
        key: 'RechtsnameFa',
        type: 'input',
        className: '',
        templateOptions: {
          label: 'Firmenname',
          floatLabel: 'always',
          appearance: 'outline',
          description: '',
          maxLength: 200,
          required: true,
          disabled: !this.authService.isSystem(),
          change: (fc, field) => {
            console.log('Profile: ', this.profile);
            (<any>this.profile).RechtsnameNP = (<any>this.profile).RechtsnameFa;
          }
        },
        hideExpression: () => this.profile.Anrede !== '3'
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{
          key: 'Strasse',
          type: 'input',
          className: 'flex-3',
          templateOptions: {
            label: 'Straße',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 256,
            required: true,
            disabled: !this.authService.isSystem()
          }
        }, {
          key: 'Hausnummer',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            label: 'Hausnummer',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 256,
            required: true,
            disabled: !this.authService.isSystem()
          }
        }]
      }, {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{
          key: 'Postleitzahl',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            label: 'Postleitzahl',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 256,
            required: true,
            disabled: !this.authService.isSystem()
          }
        }, {
          key: 'Ort',
          type: 'input',
          className: 'flex-1',
          templateOptions: {
            label: 'Ort',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 256,
            required: true,
            disabled: !this.authService.isSystem()
          }
        }]
      }, {
        // tslint:disable-next-line:max-line-length
        template: '<p><strong>Daten nicht korrekt?</strong></p><p>Bitte sende Änderungen per E-Mail an <a href="mailto:service@snoopr.de?subject=Kunden-ID: ' + this.customer.ID + ' - Änderung meiner Stammdaten&body=Hallo Ava,%0D%0A%0D%0Ameine Profil-Daten ändern sich wie folgt:%0D%0A">service@snoopr.de</a>. Änderungen können von uns nur durchgeführt werden, wenn du den Änderungsauftrag von der E-Mail-Adresse, die in deinem Profil hinterlegt ist, an uns versendest.</p>'
      }, {
        // Wird benötigt, damit Stepper funktioniert, wenn alle Felder deaktiviert sind
        key: 'enabled-element',
        type: 'input',
        className: 'hide'
      }]
    });

    const checkForDuplicates = (field: FormlyFieldConfig) => {
      const counts = {};
      this.profile.Emails.forEach(email => counts[email.Email] = (counts[email.Email] || 0) + 1);
      this.profile.Emails.forEach(email => {
        if (counts[email.Email] > 1) {
          email['Duplicate'] = true;
        } else {
          delete email['Duplicate'];
        }
        if (email.Email === field.model.Email) {
          field.formControl.updateValueAndValidity();
        }
      });
    };

    // create step 2
    this.editor.steps.push({
      label: 'Kommunikationsdaten',
      fields: [{
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{
          type: 'input',
          key: 'Telefon',
          className: 'flex-1',
          templateOptions: {
            label: 'Telefon',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 256,
            required: true
          }
        }, {
          type: 'input',
          key: 'TelefonMobil',
          className: 'flex-1',
          templateOptions: {
            label: 'Handynummer',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            maxLength: 256,
            required: false
          }
        }]
      }, {
        template: '<h3>E-Mail Adressen</h3>'
      }, {
        type: 'repeat',
        key: 'Emails',
        templateOptions: {
          minCount: 1
        },
        fieldArray: {
          fieldGroupClassName: 'display-flex flex-column',
          templateOptions: {
            label: 'E-Mail hinzufügen',
            divider: true,
            space: '20px',
            customAdd: () => {
              const hasPrimary = this.profile.Emails.some(email => email.Primaer);
              return new Observable((subscriber) => {
                subscriber.next({Email: '', isNew: true, Primaer: !hasPrimary, ValidierungsCodeSenden: false});
                subscriber.complete();
              });
            },
            hint: (model) => {
              if (!model.Verifiziert) {
                return 'Diese E-Mail-Adresse muss noch verifiziert werden bevor sie verwendet werden kann.';
              }
              return '';
            },
            buttonPadding: '0 0 26px 0',
            disableDeleteInRow: (model) => model.Primaer
          },
          fieldGroup: [{
            fieldGroupClassName: 'display-flex',
            fieldGroup: [{
              type: 'checkbox',
              className: 'flex-1',
              key: 'Primaer',
              templateOptions: {
                label: 'Hauptadresse',
                indeterminate: false,
                click: (field, $event) => {
                  if (field.formControl.disabled || field.formControl.value === true) {
                    return;
                  }

                  field.formControl['__tempCurrentFormControl'] = true;
                  const controls = field.formControl.parent.parent.controls;
                  for (let i = 0; i < controls.length; i++) {
                    const control = controls[i].controls.Primaer;
                    if (typeof control['__tempCurrentFormControl'] === 'undefined') {
                      control.patchValue(false);
                    } else {
                      delete control['__tempCurrentFormControl'];
                      control.patchValue(undefined);
                    }
                  }
                }
              },
              expressionProperties: {
                'templateOptions.disabled': (model) => {
                  return model.Primaer || !model.Verifiziert;
                }
              }
            }, { // Text
              className: 'flex-3',
              type: 'input',
              key: 'Email',
              templateOptions: {
                label: 'E-Mail-Adresse',
                floatLabel: 'always',
                required: true,
                placeholder: '',
                appearance: 'outline',
                keypress: (field, $event: KeyboardEvent) => {
                  if ($event.key === 'Enter') {
                    $event.preventDefault();
                    checkForDuplicates(field);
                    if (field.formControl.enabled && field.formControl.valid) {
                      this.requestEmailCode(field.model);
                    }
                  }
                },
                keyup: checkForDuplicates,
                change: checkForDuplicates
              },
              expressionProperties: {
                'templateOptions.disabled': (model) => {
                  return !model.isNew || this.navigationService.isLoading();
                }
              },
              validators: {
                noDupes: {
                  expression: (field, formControl) => {
                    return formControl.model.Duplicate !== true;
                  },
                  message: () => 'Diese E-Mail-Adresse ist bereits vorhanden.'
                }
              }
            }]
          }, {
              fieldGroupClassName: 'display-flex',
              fieldGroup: [{
                type: 'button',
                className: 'flex-1 padding-top-10',
                templateOptions: {
                  label: 'E-MAIL VERIFIZIEREN',
                  btnClass: 'mat-stroked-button save',
                  onClick: (component: FormTypeButtonComponent) => {
                    if (component.model.ValidierungsCode && /\d{4}/.test(component.model.ValidierungsCode.trim())) {
                      this.submitEmailCode(component.model, component.form);
                    } else {
                      this.requestEmailCode(component.model);
                    }
                  },
                  disableWhenParentInvalid: true
                },
                hideExpression: (model) => {
                  return model.Verifiziert;
                },
                expressionProperties: {
                  'templateOptions.label': (model) => {
                    if (model.ValidierungsCode && /\d{4}/.test(model.ValidierungsCode.trim())) {
                      return 'PRÜFCODE BESTÄTIGEN';
                    } else {
                      return 'E-MAIL VERIFIZIEREN';
                    }
                  },
                  'templateOptions.disabled': (model) => {
                    return this.navigationService.isLoading();
                  }
                }
              }, { // Text
                type: 'input',
                className: 'flex-3',
                key: 'ValidierungsCode',
                templateOptions: {
                  label: 'Prüfcode',
                  floatLabel: 'always',
                  required: false,
                  placeholder: '',
                  appearance: 'outline',
                  keypress: (field, $event: KeyboardEvent) => {
                  if ($event.key === 'Enter') {
                      $event.preventDefault();
                      const model = field.model;
                      if (model.ValidierungsCode && /\d{4}/.test(model.ValidierungsCode.trim())) {
                        const form = <FormGroup> field.formControl.parent;
                        this.submitEmailCode(model, form);
                      }
                    }
                  }
                },
                hideExpression: (model) => {
                  return model.Verifiziert;
                },
                expressionProperties: {
                  'templateOptions.disabled': (model) => {
                    return model.isNew;
                  }
                }
              }]
            }
          ]
        }
      }]
    });
  }
}
