import { Pipe, PipeTransform, Sanitizer, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'saveHtml'
})
export class SaveHtmlPipe implements PipeTransform {

  constructor(private sanitizer:DomSanitizer){}
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    // tslint:disable-next-line:max-line-length
    return this.sanitizer.sanitize(SecurityContext.HTML, value.replace(/<([^>]*)\ (?:[^=]+)=(?:'[^']*'|""[^""]*""|[^\s>]+)([^>]*)>/g, '<$1$2>'));
  }
}
