import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { PdfViewerDialog } from '../../../classes/pdfviewer-dialog';
import { MessageService } from '../../../services/message/message.service';
import { NotifyService, NotifyType } from '../../../services/notify/notify.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdfviewer-dialog',
  templateUrl: './pdfviewer-dialog.component.html',
  styleUrls: ['./pdfviewer-dialog.component.css']
})
export class PdfViewerDialogComponent implements OnInit, OnDestroy {

  public isPDFLoading: boolean;

  private pdf: PDFDocumentProxy;

  constructor(
    public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    private messageService: MessageService,
    private notifyService: NotifyService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: PdfViewerDialog
  ) {
  }

  ngOnInit() {
    this.isPDFLoading = true;
    this.authService.allowedToNavigate = false;
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.authService.allowedToNavigate = true;
    }, 1000);
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  afterLoadComplete(pdf: PDFDocumentProxy): void {
    this.isPDFLoading = false;
    this.pdf = pdf;
  }
  
  error($event): void {
    this.isPDFLoading = false;
    this.dialogRef.close();
    console.error('Error opening PDF!');
    console.error($event);
    this.messageService.showError('Das PDF konnte nicht geöffnet werden.');
    //this.notifyService.notify(NotifyType.Error, 'Öffnen des PDF fehlgeschlagen.');
  }

  download() {
    this.pdf.getData().then((u8) => {
      let blob = new Blob([<ArrayBuffer>u8.buffer], {
        type: 'application/pdf'
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, this.data.Titel + '.pdf');
      } else {
        let url = URL.createObjectURL(blob);
        this.openLink(url);
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 60 * 1000);
      }
    });
  }

  private openLink(url: string) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = this.data.Titel + '.pdf';
    a.click();
    document.body.removeChild(a);
  }
}
