import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MessageService } from '../message/message.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _title: String;
  get title(): String {
    return this._title;
  }
  set title(title: String) {
    setTimeout(() => {
      this._title = title;
    });
  }

  private progress: boolean[];

  constructor(private messageService: MessageService, private router: Router) {
    this.progress = [];
  }

  hasAlert(): boolean {
    return (this.messageService.getMessages().length > 0);
  }

  startLoading(progressId: string = '_default'): void {
    setTimeout(() => {
      this.progress[progressId] = true;
    });
  }

  stopLoading(progressId: string = '_default'): void {
    if (progressId === '_all') {
      const keys = Object.keys(this.progress);
      for (let i = 0; i < keys.length; i++) {
        setTimeout(() => {
          this.progress[keys[i]] = false;
        });
      }
    }

    setTimeout(() => {
      this.progress[progressId] = false;
    });
  }

  isLoading(progressId: string = '_any'): boolean {
    if (progressId === '_any') {
      const keys = Object.keys(this.progress);
      for (let i = 0; i < keys.length; i++) {
        if (this.progress[keys[i]]) {
          return true;
        }
      }
    }
    return this.progress[progressId] !== void 0 && this.progress[progressId];
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }
}
