import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';
import { MatPaginator, PageEvent } from '@angular/material';
import { Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-repeat-section',
  templateUrl: './form-type-repeat.component.html',
  styleUrls: ['./form-type-repeat.component.css'],
})

export class FormTypeRepeatComponent extends FieldArrayType implements OnInit {
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public pageSize = 5;
  public pageIndex = 0;
  public count = 0;

  constructor(builder: FormlyFormBuilder) {
    super(builder);
  }

  getModel(index: number) {
    return this.model[(this.pageSize * this.pageIndex) + index];
  }

  ngOnInit() {
    this.count = this.field.fieldGroup.length;
    while ((<number> this.field.templateOptions['minCount']) > this.count) {
      this.addItem();
    }
  }

  pageChanged(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  removeItem(itemIndex: number) {
    if (this.field.fieldArray.templateOptions.customRemove) {
      const _this = this;
      (<Observable<any>> this.field.fieldArray.templateOptions.customRemove(this.model[itemIndex])).subscribe((hardRemove: boolean) => {
        if (hardRemove) {
          _this.hardRemoveItem(itemIndex);
        } else {
          _this.softRemoveItem(itemIndex);
        }
      });
    } else {
      this.hardRemoveItem(itemIndex);
    }
  }

  hardRemoveItem(itemIndex: number) {
    this.remove(itemIndex);
    this.count--;

    let newPageIndex = 0;
    if (this.count > 0) {
      newPageIndex = Math.floor((this.count - 1) / this.pageSize);
    }

    if (this.pageIndex > newPageIndex) {
      this.pageIndex = newPageIndex;
    }

    setTimeout(() => {
      if (this.paginator) {
        this.paginator.pageIndex = this.pageIndex;
      }
    });
  }

  softRemoveItem(itemIndex: number) {
    this.model[itemIndex]['_hidden'] = true;
    this.count--;

    let newPageIndex = 0;
    if (this.count > 0) {
      newPageIndex = Math.floor((this.count - 1) / this.pageSize);
    }

    if (this.pageIndex > newPageIndex) {
      this.pageIndex = newPageIndex;
    }

    setTimeout(() => {
      if (this.paginator) {
        this.paginator.pageIndex = this.pageIndex;
      }
    });
  }

  addItem() {
    if (this.field.fieldArray.templateOptions.customAdd) {
      (<Observable<any>> this.field.fieldArray.templateOptions.customAdd()).subscribe((object: any) => {
        if (object) {
          this.add(null, object);
          this.count++;
          this.upadtePageIndex();
        }
      });
    } else {
      this.add();
      this.count++;
      this.upadtePageIndex();
    }
  }

  upadtePageIndex() {
    this.pageIndex = 0;
    if (this.count > 0) {
      this.pageIndex = Math.floor((this.count - 1) / this.pageSize);
    }

    setTimeout(() => {
      if (this.paginator) {
        this.paginator.pageIndex = this.pageIndex;
      }
    });
  }

  isDisabled() {
    return (this.field &&
      this.field.fieldArray &&
      this.field.fieldArray.templateOptions &&
      this.field.fieldArray.templateOptions.disabled
    );
  }

  isCustom() {
    return (this.field &&
      this.field.fieldArray &&
      this.field.fieldArray.templateOptions &&
      this.field.fieldArray.templateOptions.isCustom
    );
  }

  hasHint() {
    return (this.field &&
      this.field.fieldArray &&
      this.field.fieldArray.templateOptions &&
      typeof this.field.fieldArray.templateOptions.hint === 'function'
    );
  }

  getHint(index: number) {
    const hint = this.field.fieldArray.templateOptions.hint(this.getModel(index));
    if (typeof hint === 'string') {
      return hint;
    } else {
      return '';
    }
  }

  hasDivider(index: number) {
    if (this.model[index] && this.model[index]['_hidden']) {
      return false;
    }

    let isFirst = true;
    if (index !== 0) {
      let c = index;
      while (--c >= 0) {
        if (this.model[c] && this.model[c]['_hidden']) {
          continue;
        }
        isFirst = false;
        break;
      }
    }
    return (!isFirst &&
      this.field &&
      this.field.fieldArray &&
      this.field.fieldArray.templateOptions &&
      typeof this.field.fieldArray.templateOptions.divider !== 'undefined' &&
      this.field.fieldArray.templateOptions.divider === true
    );
  }

  getSpace(index: number) {
    if (index > 0 && this.field &&
      this.field.fieldArray &&
      this.field.fieldArray.templateOptions &&
      typeof this.field.fieldArray.templateOptions.space !== 'undefined') {
        return this.field.fieldArray.templateOptions.space;
    }
    return '0px';
  }

  getButtonPadding() {
    if (this.field &&
      this.field.fieldArray &&
      this.field.fieldArray.templateOptions &&
      typeof this.field.fieldArray.templateOptions.buttonPadding !== 'undefined') {
        return this.field.fieldArray.templateOptions.buttonPadding;
    }
    return '0 0 0 0';
  }

  isDisabledRow(index: number) {
    if (this.field &&
      this.field.fieldArray &&
      this.field.fieldArray.templateOptions &&
      this.field.fieldArray.templateOptions.disableDeleteInRow) {
        return this.field.fieldArray.templateOptions.disableDeleteInRow(this.getModel(index));
    }
    return false;
  }

  countHidden(startIndex, endIndex) {
    let counter = 0;
    for (let i = startIndex; i < endIndex; i++) {
      if (this.model[i] && this.model[i]['_hidden']) {
        counter++;
      }
    }
    return counter;
  }

  countMissing(startIndex, endIndex) {
    const needed = endIndex - startIndex;
    let counter = 0;
    let i = 0;
    for (i = startIndex; i < endIndex; i++) {
      if (this.model[i] && this.model[i]['_hidden']) {
        counter++;
      }
    }

    while (counter < needed && i < this.model.length) {
      if (this.model[i] && this.model[i]['_hidden']) {
        counter++;
      }
      i++;
    }

    return counter;
  }
}
