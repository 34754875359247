import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { SearchFieldComponent } from '../search-field/search-field.component';
import { ItemList } from '../../classes/item-list';
import { Medium } from '../../classes/medium';
import { MediaService } from '../../services/media/media.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AuthService } from '../../services/auth/auth.service';
import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.css']
})
export class MediaListComponent extends ItemList<Medium> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  constructor(
    private mediaService: MediaService,
    public navigationService: NavigationService,
    public authService: AuthService,
    public messageService: MessageService,
    private router: Router
  ) {
      super('media', navigationService, messageService, 'Medien verwalten');
  }

  loadItems(limit: number, offset: number) {
    console.log(this.filter.selectedMediaTypeId);
    return this.mediaService.loadMediaList(
        limit,
        offset,
        this.filter.selectedProviderId,
        this.filter.selectedCategoryId,
        this.searchField.searchTerm || null,
        this.filter.selectedStatusId,
        this.filter.selectedSortingId,
        null,
        this.filter.selectedMediaTypeId).pipe(map(result => ({items: result.mediaList, maxCount: result.metaData.maxCount})));
  }

  addMedia(): () => void {
    return (() => {
      this.router.navigate(['/media-create']);
    });
  }
}
