import { Component, OnInit, OnDestroy, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { AuthService } from '../../services/auth/auth.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { GenericDialog } from 'src/app/classes/generic-dialog';
import { MatDialog } from '@angular/material';
import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('username') usernameField: ElementRef;

  public errorMessage = '';
  public loginProgressing = false;
  public newPasswordRequired = false;
  public forgotPassword = false;
  public newPassword = '';

  private fwd = 'home';

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    public consumerService: ConsumerService,
    private httpClient: HttpClient,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    const username = this.route.snapshot.queryParamMap.get('u');
    if (username) {
      this.usernameField.nativeElement.value = decodeURIComponent(username);
    }
    const fwd = this.route.snapshot.queryParamMap.get('fwd');
    if (fwd) {
      this.fwd = fwd;
    } else {
      this.fwd = 'home';
    }
    if (this.authService.isLoggedIn()) {
      this.redirect();
    } else {
      this.renderer.addClass(document.body, 'blank');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'blank');
  }

  private redirect() {
    this.router.navigate(['/' + this.fwd]);
  }

  submit(username: string, password: string): void {
    if (!username || !password) {
      return;
    }
    this.loginProgressing = true;
    const stateChangeSubscriber = this.authService.authStateChange$.subscribe(state => {
      if (state === 'signedIn') {
        stateChangeSubscriber.unsubscribe();
        this.redirect();
      }
    });
    username = username.trim();
    password = password.trim();
    const signIn = (migrateIfFailed: boolean) => {
      this.authService.signIn(username, password).subscribe(
        (cognitoUser) => {
          console.log('logged in as:', this.authService.getCurrentUserName());
          console.log('logged in?:', this.authService.isLoggedIn());
          this.errorMessage = '';
          if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.newPasswordRequired = true;
            this.loginProgressing = false;
          }
        }, cognitoError => {
          if (cognitoError.code === 'PasswordResetRequiredException') {
            this.forgotPassword = true;
            this.errorMessage = '';
          } else if (cognitoError.code === 'TOUNotAccepted') {
            console.log('TOUNotAccepted!');
          } else if (migrateIfFailed) {
            console.log(cognitoError);
            const host = environment.production ? 'search.snoopr.de' : 'devel.fritz.box';
            const url = 'https://' + host + '/api/btix/tarifrechner/searchconsole/account/migrate';
            this.httpClient.post(url, {}, {
              headers: {
                'Authorization': `Basic ${window.btoa(username + ':' + password)}`
              }
            }).subscribe(
              (_) => {
                console.log('user successfully migrated to search console');
                signIn(false);
              },
              (snooprError) => {
                console.log(snooprError);
                this.errorMessage = cognitoError.message;
                this.loginProgressing = false;
                stateChangeSubscriber.unsubscribe();
              }
            );
            return;
          } else {
            console.log(cognitoError);
            this.errorMessage = cognitoError.message;
          }
          this.loginProgressing = false;
          stateChangeSubscriber.unsubscribe();
        });
    };
    signIn(true);

  }

  changePassword(username: string, password: string): void {
    if (!username || !password || !this.newPassword) {
      return;
    }
    this.loginProgressing = true;
    let observable: Observable<any>;
    if (this.forgotPassword === true) {
      observable = this.authService.completeForgotPassword(username, password, this.newPassword);
    } else {
      observable = this.authService.completeNewPassword(this.newPassword);
    }
    observable.subscribe(
      () => {
        this.errorMessage = '';
        this.submit(username, this.newPassword);
      }, err => {
        console.log(err);
        this.loginProgressing = false;
        this.errorMessage = err.message;
      }
    );
  }

  showLoginHelp() {
    const genericDialog = new GenericDialog();
    genericDialog.titel = 'Wie logge ich mich ein?';
    genericDialog.text = 'Als <strong>Makler</strong> kannst du dich mit deinen Zugangsdaten von Snoopr \
    (E-Mail-Adresse und Passwort) in der Search Console anmelden. Du hast noch kein Snoopr-Benutzerkonto? \
    <a href="https://www.snoopr.de/kostenlos-registrieren" target="_blank">Jetzt kostenlos registrieren</a>. \
    <br/>Du hast dein Passwort vergessen? Dann kannst du dein Passwort \
    <a href="https://search.snoopr.de/bos/login/" target="_blank">hier</a> zurücksetzen. Dein neues Passwort \
    gilt automatisch auch für die Search Console.<br/><br/>Als <strong>Versicherer</strong> bekommen Sie Ihre Zugangsdaten \
    direkt von einem Mitarbeiter der A-va GmbH. Falls Sie noch keine Zugangsdaten haben oder Änderungen \
    wünschen, wenden Sie sich bitte an <a href="mailto:service@snoopr.de">service@snoopr.de</a>.';
    genericDialog.hideCancel = true;

    this.dialog.open(GenericDialogComponent, {
      maxWidth: '500px',
      maxHeight: '100vh',
      data: genericDialog
    });
  }

}
