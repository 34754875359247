import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Medium } from '../../classes/medium';
import { MediaSet, PendingMedium } from '../../classes/media-set';

import { MediaService } from '../media/media.service';
import { MessageService } from '../message/message.service';
import { ConsumerService } from '../consumer/consumer.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaSetService {
  /* URL  Definitionen */
  private static readonly mediaSetUrl = environment.api + '/mediasets';

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private consumerService: ConsumerService,
    private mediaService: MediaService
  ) {

  }


    private prepareRequest(mediaSet: MediaSet, create: boolean): Observable<MediaSet> {

        const fields = ['Downloads', 'Testberichte', 'Videos', 'Formulare'];

        const observables: Observable<Medium>[] = [];

        fields.forEach(field => {
            const fieldContent = <Array<number | PendingMedium>> mediaSet[field];
            fieldContent.forEach((item: number | PendingMedium, index) => {
                if (item instanceof PendingMedium) {
                    observables.push(this.mediaService.createOrUpdateMedia(item).pipe(map(medium => {
                        fieldContent[index] = medium.ID;
                        return medium;
                    })));
                }
            });
        });

        if (observables.length === 0) {
            return of(<MediaSet> {
                ID: create ? undefined : mediaSet.ID.trim(),
                SetID: mediaSet.SetID.trim(),
                AnbieterID: mediaSet.AnbieterID,
                AnbieterName: mediaSet.AnbieterName,
                AnbieterRolle: mediaSet.AnbieterRolle,
                Beschreibung: mediaSet.Beschreibung,
                Kategorien: mediaSet.Kategorien,
                Produkte: mediaSet.Produkte,
                Downloads: mediaSet.Downloads,
                Formulare: mediaSet.Formulare,
                Videos: mediaSet.Videos,
                Testberichte: mediaSet.Testberichte
            });
        } else {
            return forkJoin(observables).pipe((map(_ => {
                return <MediaSet>{
                    ID: create ? undefined : mediaSet.ID,
                    SetID: mediaSet.SetID,
                    AnbieterID: mediaSet.AnbieterID,
                    AnbieterName: mediaSet.AnbieterName,
                    AnbieterRolle: mediaSet.AnbieterRolle,
                    Beschreibung: mediaSet.Beschreibung,
                    Kategorien: mediaSet.Kategorien,
                    Produkte: mediaSet.Produkte,
                    Downloads: mediaSet.Downloads,
                    Formulare: mediaSet.Formulare,
                    Videos: mediaSet.Videos,
                    Testberichte: mediaSet.Testberichte
                };
            })));
        }
    }

  /**
   * MediaSet CRUD
   */

  public create(mediaSet: MediaSet): Observable<MediaSet> {

    const http = this.http;
    return new Observable(observer => {
        this.prepareRequest(mediaSet, true).subscribe(body => {
            http.post(MediaSetService.mediaSetUrl, body).subscribe((result: MediaSet) => {
                observer.next(result);
                observer.complete();
            }, error => {
                observer.error(error);
                observer.complete();
            });
        });
    });
  }

  // tslint:disable-next-line: max-line-length
  public loadMediaSetList(limit?: number, offset?: number, filterProvider?: string, filterCategory?: string, filterSearch?: string, orderBy?: string, excludeMediaSetIds?: Array<string>): Observable<any> {
    let urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID;

    if (typeof filterProvider !== 'undefined' && filterProvider !== null) {
      urlParameter += '&providerId=' + filterProvider;
    }
    if (typeof filterCategory !== 'undefined' && filterCategory !== null) {
      urlParameter += '&categoryId=' + filterCategory;
    }
    if (typeof limit !== 'undefined' && limit !== null) {
      urlParameter += '&limit=' + limit;
    }
    if (typeof offset !== 'undefined' && offset !== null) {
      urlParameter += '&offset=' + offset;
    }
    if (typeof filterSearch !== 'undefined' && filterSearch !== null) {
      urlParameter += '&filterSearch=' + filterSearch;
    }
    if (typeof orderBy !== 'undefined' && orderBy !== null) {
      urlParameter += '&orderBy=' + orderBy;
    }
    if (typeof excludeMediaSetIds !== 'undefined' && excludeMediaSetIds !== null) {
      urlParameter += '&excludeMediaSetIds=' + encodeURIComponent(JSON.stringify(excludeMediaSetIds));
    }

    return this.http.get(MediaSetService.mediaSetUrl + urlParameter).pipe(
      map(mediaSetList => {
        return {
          mediaSetList: mediaSetList,
          metaData: (<Array<any>>mediaSetList).pop()
        };
      }),
      catchError(this.messageService.handleError('loadMediaSetList', []))
    );
  }

  public loadMediaSet(id: string): Observable<MediaSet> {
    return this.http.get<MediaSet>(MediaSetService.mediaSetUrl + '/' + id).pipe(
      catchError(this.messageService.handleError('loadMediaSetList', null))
    );
  }

  public update(mediaSet: MediaSet): Observable<MediaSet> {
    const http = this.http;
    return new Observable(observer => {
        this.prepareRequest(mediaSet, false).subscribe(body => {
            http.put(MediaSetService.mediaSetUrl + '/' + body.ID, body).subscribe((result: MediaSet) => {
                observer.next(result);
                observer.complete();
            }, error => {
                observer.error(error);
                observer.complete();
            });
        });
    });
  }

  public delete(id: string): Observable<void> {
    return new Observable(subscriber => {
      this.http.delete(MediaSetService.mediaSetUrl + '/' + id).subscribe(() => {
        subscriber.next();
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    });
  }
}
