import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation/navigation.service';

import { Consumer } from '../../classes/consumer';

@Component({
  selector: 'app-consumer-create-item',
  templateUrl: './consumer-create-item.component.html',
  styleUrls: ['./consumer-create-item.component.css']
})
export class ConsumerCreateItemComponent implements OnInit {
  public consumer: Consumer;

  constructor(
    public navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.navigationService.title = 'Consumer erstellen';
    this.navigationService.startLoading('consumer_create_init');

    this.consumer = {
      ID: '',
      Name: '',
      Suffix: '',
      Aktiv: 1
    };

    this.navigationService.stopLoading('consumer_create_init');
  }

}
