import { Component, OnInit, ViewEncapsulation, OnDestroy, SimpleChanges } from '@angular/core';
import { Editor } from 'src/app/classes/editor';
import { Observable, of, forkJoin, Subscription, Subject } from 'rxjs';

import { ItemComponent } from 'src/app/classes/item-component';
import { Profile, Bankverbindung } from 'src/app/classes/profile';

import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { MessageService } from 'src/app/services/message/message.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { ProviderService } from 'src/app/services/provider/provider.service';
import { Category } from 'src/app/classes/category';
import { CategoryService } from 'src/app/services/category/category.service';
import { MatDialog, MatCheckboxChange } from '@angular/material';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { FormTypeButtonComponent } from '../form-type-button/form-type-button.component';
import { NotifyType, NotifyService } from 'src/app/services/notify/notify.service';
import { GenericDialog } from 'src/app/classes/generic-dialog';
import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';
import { PdfViewerService } from 'src/app/services/pdfviewer/pdfviewer.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { finalize } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { CropValueChange } from '../form-type-image-crop/form-type-image-crop.component';
import { Customer } from 'src/app/classes/customer';
import { EditorService } from 'src/app/services/editor/editor.service';


export class ProgramsEditor extends Editor<ProgramsComponent, Profile> {
	constructor(
		public component: ProgramsComponent,
		public messageService: MessageService,
		public notifyService: NotifyService,
		public profileService: ProfileService,
		public customerService: CustomerService,
		public storageService: StorageService,
		public authService: AuthService,
		public navigationService: NavigationService,
		public dialog: MatDialog,
		public editorService: EditorService
	) {
		super(component);
	}

	submitBankAccounts(): Observable<Profile> {
		this.modifyBankAccounts(this.model.Konten);
		this.navigationService.startLoading('profile-submit-accounts');
		return this.profileService.updateBankAccounts(this.model.CustomerID, this.model.Konten).pipe(finalize(() => {
			this.navigationService.stopLoading('profile-submit-accounts');
		}));
	}

	submit(model: Profile, type: string): Observable<void> {
		return new Observable(subscriber => {
			if (model['IstAnbieter'] && model.Konten.some(konto => !konto.Verifiziert && !konto.ValidierungLaeuft)) {
				this.notifyService.notify(NotifyType.Warning, 'Bitte die Bankverbindung verifizieren.');
				this.editorComponent.gotoStep(2);
				subscriber.complete();
				return;
			}

			const homepageProgramActivated = ((<any>model).InitHomepageProgrammAktiv != (<any>model).HomepageProgrammAktiv && (<any>model).HomepageProgrammAktiv)

			this.profileService.updateProfile(model).subscribe((profile: Profile) => {
				this.customerService.loadCustomer(model.CustomerID).subscribe((loadedCustomer) => {
					loadedCustomer.IstPartner = model['IstPartner'];
					loadedCustomer.IstAnbieter = model['IstAnbieter'];
					loadedCustomer.HomepageProgrammAktiv = model['HomepageProgrammAktiv'];
					loadedCustomer.HomepageProgrammDaten = model['HomepageProgrammDaten'];
					loadedCustomer.Verifiziert = model['Verifiziert'];
					loadedCustomer.BerechtigtZumVeroeffentlichen = model['BerechtigtZumVeroeffentlichen'];
					loadedCustomer.HomepageProgrammDaten = model['HomepageProgrammDaten'];
					console.log('loadedCustomer: ', loadedCustomer);
					this.customerService.update(loadedCustomer).subscribe((updatedCustomer) => {

						if (this.authService.getCurrentUserCustomerId() === updatedCustomer.ID) {
							// tslint:disable-next-line:max-line-length
							this.authService.setBrokerData(updatedCustomer.IstAnbieter, updatedCustomer.Verifiziert, updatedCustomer.BerechtigtZumVeroeffentlichen);
						}

						// Wird auf Object geprüft, weil auch true sein kann
						const avatarImage = typeof model['AvatarImage'] === 'object' ? model['AvatarImage'] : null;
						const avatarImageOriginal = typeof model['AvatarImageOriginal'] === 'object' ? model['AvatarImageOriginal'] : null;
						const logoImage = typeof model['LogoImage'] === 'object' ? model['LogoImage'] : null;
						const verificationFile = model['VerificationFile'] &&
							model['VerificationFile'].length === 1 ? model['VerificationFile'][0] : null;

						let avatarObserver = null;
						if (avatarImage !== null) {
							avatarObserver = this.profileService.uploadFile(avatarImage,
								'public/assets/broker/' + profile.CustomerID, 'avatar.png');
						}

						let avatarOriginalObserver = null;
						if (avatarImageOriginal !== null) {
							avatarOriginalObserver = this.profileService.uploadFile(avatarImageOriginal,
								'public/assets/broker/' + profile.CustomerID, 'avatar-original.png');
						}

						let logoObserver = null;
						if (logoImage !== null) {
							logoObserver = this.profileService.uploadFile(logoImage,
								'public/assets/broker/' + profile.CustomerID, 'logo.png');
						}

						let verificationFileObserver = null;
						if (verificationFile != null) {
							const extension = verificationFile.name.split('.').pop();
							verificationFileObserver = this.profileService.uploadFile(verificationFile,
								'public/assets/broker/' + profile.CustomerID, 'dokument.' + extension);
						}

						forkJoin([
							avatarObserver != null ? avatarObserver : of(null),
							avatarOriginalObserver != null ? avatarOriginalObserver : of(null),
							logoObserver != null ? logoObserver : of(null),
							verificationFileObserver != null ? verificationFileObserver : of(null)
						]).subscribe(() => {
							this.preModelModifier(profile);
							(<any>profile).IstPartner = updatedCustomer.IstPartner;
							(<any>profile).IstAnbieter = updatedCustomer.IstAnbieter;
							(<any>profile).HomepageProgrammAktiv = updatedCustomer.HomepageProgrammAktiv;
							(<any>profile).InitHomepageProgrammAktiv = updatedCustomer.HomepageProgrammAktiv;
							(<any>profile).HomepageProgrammDaten = updatedCustomer.HomepageProgrammDaten;
							(<any>profile).Verifiziert = updatedCustomer.Verifiziert;
							this.storageService.exists('public/assets/broker/' + updatedCustomer.ID + '/dokument').subscribe(exists => {
								this.component.hasIdentificationDocument = exists;
							});
							this.component.refreshEditor(profile);

							this.storageService.exists('public/assets/broker/' + profile.CustomerID + '/logo').subscribe((exists) => {
								this.storageService.isPlaceholderFile('public/assets/broker/' + profile.CustomerID + '/logo').subscribe((placeholder) => {
									this.component.logoExists = exists && !placeholder;
								});
							});

							if (homepageProgramActivated) {
								const genericDialog = new GenericDialog();
								genericDialog.titel = 'Homepageprogramm wird aktiviert';
								genericDialog.text = 'Vielen Dank für deine Teilnahme an unserem Homepageprogramm. Wir werden uns bei dir mit weiteren Informationen melden.';

								genericDialog.okLabel = 'Ok';
								this.dialog.open(GenericDialogComponent, {
									maxWidth: '500px',
									maxHeight: '100vh',
									data: genericDialog
								});
							}
							this.editorService.setModel(null);
							subscriber.next();
							subscriber.complete();
						}, (error) => {
							this.messageService.showError('Beim Aktualisieren der Daten ist es zu einem Fehler gekommen: ' + error);
						});
					});
				});
			}, (error) => {
				const genericDialog = new GenericDialog();
				genericDialog.titel = 'Fehler';
				genericDialog.text = 'Es ist fogender Fehler aufgetreten: ' + error;
				genericDialog.okLabel = 'Ok';
				this.dialog.open(GenericDialogComponent, {
					maxWidth: '500px',
					maxHeight: '100vh',
					data: genericDialog
				});
			});
		});
	}

	modifyBankAccounts(accounts: Bankverbindung[]): void {
		for (let i = 0; i < accounts.length; i++) {
			if (accounts[i].ValidierungsCode) {
				accounts[i].ValidierungsCode = accounts[i].ValidierungsCode.trim();
			}
			delete accounts[i]['isNew'];
		}
	}

	preModelModifier(model: Profile): void {
		if (typeof model.Schlichtungsstellen === 'undefined') {
			model.Schlichtungsstellen = [];
		}

		if (typeof model.Konten === 'undefined') {
			model.Konten = [];
		}

		this.modifyBankAccounts(model.Konten);
	}

	postModelModifier(model: any, type: string): void {
		if (model.Kategorieauswahl) {
			for (let i = model.Kategorieauswahl.length - 1; i >= 0; i--) {
				if (model.Kategorieauswahl[i].trim().length === 0 || model.Kategorieauswahl[i].slice(0, 1) === '_') {
					model.Kategorieauswahl.splice(i, 1);
				}
			}
		}

		if (model.HomepageProgrammDaten && model.HomepageProgrammDaten.Kategorien) {
			for (let i = model.HomepageProgrammDaten.Kategorien.length - 1; i >= 0; i--) {
				if (model.HomepageProgrammDaten.Kategorien[i].trim().length === 0 || model.HomepageProgrammDaten.Kategorien[i].slice(0, 1) === '_') {
					model.HomepageProgrammDaten.Kategorien.splice(i, 1);
				}
			}
		}
	}
}

@Component({
	selector: 'app-programs',
	templateUrl: './programs.component.html',
	styleUrls: ['./programs.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class ProgramsComponent extends ItemComponent implements OnInit, OnDestroy {

	public profile: Profile;
	public editor: ProgramsEditor;
	public editorInitialized = false;

	public avatarImageUrl = '';
	private logoImageUrl = '';

	public avatarForm: FormGroup;
	public avatarOptions: any;
	public avatarFields: Array<any>;
	public avatarModel: any = {};

	public logoExists = false;

	private parameterSubscription: Subscription;

	public hasIdentificationDocument = true;

	private schlichtungsstellen = [{
		Name: 'Eigene Schlichtungsstelle',
		AdresseOderPostfach: '',
		Postleitzahl: '',
		Ort: '',
		Website: '',
		Email: '',
		Telefon: ''
	}, {
		Name: 'Versicherungsombudsmann e.V.',
		AdresseOderPostfach: 'Postfach 08 06 32',
		Postleitzahl: '10006',
		Ort: 'Berlin',
		Website: 'https://www.versicherungsombudsmann.de/',
		Email: 'beschwerde@versicherungsombudsmann.de',
		Telefon: '0800 3696000'
	}, {
		Name: 'Ombudsmann Private Kranken- und Pflegeversicherung',
		AdresseOderPostfach: 'Postfach 06 02 22',
		Postleitzahl: '10052',
		Ort: 'Berlin',
		Website: 'https://www.pkv-ombudsmann.de/',
		Email: 'ombudsmann@pkv-ombudsmann.de',
		Telefon: '0800 2 55 04 44'
	}];

	constructor(
		private navigationService: NavigationService,
		private profileService: ProfileService,
		private authService: AuthService,
		private messageService: MessageService,
		private notifyService: NotifyService,
		private pdfViewerService: PdfViewerService,
		private customerService: CustomerService,
		private providerService: ProviderService,
		private categoryService: CategoryService,
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private storageService: StorageService,
		private utilsService: UtilsService,
		private editorService: EditorService
	) {
		super();
	}

	ngOnInit() {
		this.navigationService.title = 'Meine Programme';
		this.navigationService.startLoading('programs_editor_init');

		this.editor = new ProgramsEditor(
			this,
			this.messageService,
			this.notifyService,
			this.profileService,
			this.customerService,
			this.storageService,
			this.authService,
			this.navigationService,
			this.dialog,
			this.editorService
		);

		// tslint:disable-next-line:max-line-length
		this.editor.setInfo1(() => '');
		this.editor.setInfo2(() => '');
		this.editor.title = '';
		this.editor.hideHeader = true;

		this.parameterSubscription = this.activatedRoute.params.subscribe(params => {

			let customerId = null;
			if (typeof params['customerId'] !== 'undefined' && this.authService.isSystem()) {
				customerId = params['customerId'];
			}

			if (customerId === null) {
				customerId = this.authService.getCurrentUserCustomerId();
			}

			this.customerService.loadCustomer(customerId, true).subscribe((customer: Customer) => {
				if (customer.Rolle === 'broker') {
					this.profileService.loadProfile(customerId).subscribe(profile => {
						this.profile = profile;

						this.avatarImageUrl = 'public/assets/broker/' + this.profile.CustomerID + '/avatar';
						this.logoImageUrl = 'public/assets/broker/' + this.profile.CustomerID + '/logo';

						(<any>this.profile).IstAnbieter = customer.IstAnbieter;
						(<any>this.profile).IstPartner = customer.IstPartner;
						(<any>this.profile).HomepageProgrammAktiv = customer.HomepageProgrammAktiv;
						(<any>this.profile).InitHomepageProgrammAktiv = customer.HomepageProgrammAktiv;
						(<any>this.profile).HomepageProgrammDaten = customer.HomepageProgrammDaten;
						(<any>this.profile).Verifiziert = customer.Verifiziert;

						if (this.authService.getCurrentUserCustomerId() === customer.ID) {
							// tslint:disable-next-line:max-line-length
							this.authService.setBrokerData(customer.IstAnbieter, customer.Verifiziert, customer.BerechtigtZumVeroeffentlichen);
						}

						this.storageService.exists('public/assets/broker/' + this.profile.CustomerID + '/dokument').subscribe(exists => {
							this.hasIdentificationDocument = exists;
						});

						this.storageService.exists('public/assets/broker/' + this.profile.CustomerID + '/logo').subscribe((exists) => {
							this.storageService.isPlaceholderFile('public/assets/broker/' + this.profile.CustomerID + '/logo').subscribe((placeholder) => {
								this.logoExists = exists && !placeholder;

								this.createEditorSteps();
								this.editorInitialized = true;
								setTimeout(() => {
									this.editorService.setModel(this.profile);
								}, 0);
								this.navigationService.stopLoading('programs_editor_init');
							});
						});
					});
				} else {
					console.log('Kein Makler Profil!');
					this.navigationService.stopLoading('programs_editor_init');
				}
			});
		});
	}

	ngOnDestroy() {
		this.parameterSubscription.unsubscribe();
		this.editorService.setModel(null);
	}

	refreshEditor(profile): void {
		this.profile = profile;
		this.createEditorSteps();
		this.editor.editorComponent.reload();
		const _this = this;
		setTimeout(() => _this.editor.editorComponent.touchAllSteps(), 300);
	}

	createEditorSteps(): void {
		this.avatarForm = new FormGroup({});
		this.avatarOptions = <FormlyFormOptions>{};

		this.avatarFields = [{
			key: 'image',
			type: 'image-crop',
			className: 'flex-1',
			templateOptions: {
				label: 'Dateiauswahl',
				floatLabel: 'always',
				appearance: 'outline',
				accept: '.jpg,.jpeg,.png,.gif',
				editInPreview: true,
				image: this.avatarImageUrl,
				maintainAspectRatio: true,
				isRound: true,
				aspectRatio: 1 / 1,
				resizeToWidth: 200,
				resizeToHeight: 200,
				cropperMinWidth: 100,
				cropperMinHeight: 100,
				previewHeight: 50,
				previewWidth: 50,
				onImageChange: (model: any, image: any, originalFile: any, isAppliedExternal: boolean) => {
					(<any>this.profile).AvatarImageOriginal = originalFile;

					if (!isAppliedExternal) {
						const avatarImageFc = this.editor.editorComponent.getControlByKey('AvatarImage');
						if (avatarImageFc) {
							const cropValueChange = new CropValueChange();
							cropValueChange.image = image;
							cropValueChange.originalImage = cropValueChange;
							cropValueChange.imageURL = null;
							avatarImageFc.patchValue(image);
						}
					}
				},
				onImageURLChange: (model: any, imageURL: string, isAppliedExternal: boolean) => {
					if (!isAppliedExternal) {
						const avatarImageFc = this.editor.editorComponent.getControlByKey('AvatarImage');
						if (avatarImageFc) {
							const cropValueChange = new CropValueChange();
							cropValueChange.image = null;
							cropValueChange.originalImage = null;
							cropValueChange.imageURL = imageURL;
							avatarImageFc.patchValue(cropValueChange);
						}
					}
				}
			}
		}];

		this.editor.steps = [];

		let selectedPrograms = '';

		if (this.profile.Kategorieauswahl) {
			for (let i = this.profile.Kategorieauswahl.length - 1; i >= 0; i--) {
				if (this.profile.Kategorieauswahl[i].trim().length === 0 || this.profile.Kategorieauswahl[i].slice(0, 1) === '_') {
					this.profile.Kategorieauswahl.splice(i, 1);
				}
			}
		}

		const schlichtungsstellenOptions = [];
		for (let i = 0; i < this.schlichtungsstellen.length; i++) {
			schlichtungsstellenOptions.push({
				label: this.schlichtungsstellen[i].Name,
				value: this.schlichtungsstellen[i].Name
			});
		}

		if (typeof this.profile.Schlichtungsstellen === 'undefined') {
			this.profile.Schlichtungsstellen = [];
		}

		for (let i = 0; i < this.profile.Schlichtungsstellen.length; i++) {
			const ps = this.profile.Schlichtungsstellen[i];
			(<any>ps).Vorauswahl = this.schlichtungsstellen[0].Name;
			for (let si = 0; si < this.schlichtungsstellen.length; si++) {
				const sv = this.schlichtungsstellen[si];
				if (sv.Name === ps.Name) {
					(<any>ps).Vorauswahl = sv.Name;
					break;
				}
			}
		}


		if ((<any>this.profile).IstPartner) {
			selectedPrograms += 'Partner';
		}

		if ((<any>this.profile).IstAnbieter) {
			if (selectedPrograms.length > 0) {
				selectedPrograms += ', ';
			}
			selectedPrograms += 'Anbieter';
		}

		if ((<any>this.profile).HomepageProgrammAktiv) {
			if (selectedPrograms.length > 0) {
				selectedPrograms += ', ';
			}
			selectedPrograms += 'Homepage';
		}

		if (selectedPrograms.length === 0) {
			selectedPrograms = '';
		}

		// create step 1
		this.editor.steps.push({
			label: 'Programme auswählen ' + (selectedPrograms ? '(' + selectedPrograms + ')' : ''),
			fields: [{
				template: '<p><strong>Was ist das Partnerprogramm?</strong></p>\
        <p>Als Teilnehmer unseres Partnerprogramms listen wir dich als \
        unabhängigen Versicherungsexperten bei Snoopr und vermitteln dir \
        Kontakte zu Verbrauchern, die über unseren Suchdienst nach Versicherungen suchen. \
        Die Kontaktherstellung erfolgt zum Zwecke einer kostenlosen Online-Beratung per Web-Chat, in \
        den wir dich einfach per E-Mail einladen. Hierfür musst du nichts installieren oder wissen.</p>\
        <p>Die Teilnahme am Partnerprogramm ist ganz \
        einfach und du entscheidest selber, in welchen Produktkategorien du Kontakte wünschst! \
        Unser System wählt für den Verbraucher automatisch den nächstgelegenen \
        Experten aus. Dabei wirst du umso wahrscheinlicher ausgewählt, je intensiver \
        du selbst unseren Suchdienst nutzt und je besser dich die Kunden bewerten.</p> \
        <p>Im Falle einer Kontaktherstellung fallen für dich Vermittlungsgebühren \
        in Höhe von <strong>5 Euro</strong> zzgl. MwSt. an. Wir treten hierbei als Tippgeber auf. \
        Unsere Leistung besteht ausschließlich darin, einen Chat-Kontakt zwischen dir und dem \
        potenziellen Kunden herzustellen. Wie du diesen Kommunikationskanal  nutzt, ist dir freigestellt. \
        Du kannst deine Teilnahme am Partnerprogramm jederzeit wieder deaktivieren.</p>'
			}, { // 1. Zeile
				key: 'IstPartner',
				type: 'checkbox',
				templateOptions: {
					label: 'Ich möchte am Partnerprogramm teilnehmen',
					description: '',
					indeterminate: false,
					change: (field: FormlyFieldConfig, event: Event) => {
						if (event instanceof MatCheckboxChange) {
							if ((<any>this.profile).IstPartner === true) {
								this.refreshEditor(this.profile);
							} else {
								if ((<any>this.profile).HomepageProgrammAktiv || (<any>this.profile).IstAnbieter) {
									const programs = [];
									if ((<any>this.profile).IstAnbieter) {
										programs.push('Anbieterprogramm');
									}
									if ((<any>this.profile).HomepageProgrammAktiv) {
										programs.push('Homepageprogramm');
									}

									const genericDialog = new GenericDialog();
									genericDialog.titel = 'Partnerprogramm deaktivieren';
									genericDialog.text = 'Mit der Deaktivierung des Partnerprogramms ' + (programs.length == 1 ? 'wird' : 'werden') + ' auch das ' + programs.join(' und ') + ' deaktiviert.';

									if ((<any>this.profile).IstAnbieter) {
										genericDialog.text += '<br><br><strong>Deaktivierung des Anbieterprogramms</strong><br>Mit der Deaktivierung des Anbieterprogramms werden auch deine \
                                          veröffentlichten Produkte deaktiviert. Bitte beachte, dass die Deaktivierung bis zu 24 \
                                          Stunden in Anspruch nimmt und du in diesem Zeitraum noch Anfragen bekommen kannst.';
									}
									if ((<any>this.profile).HomepageProgrammAktiv) {
										genericDialog.text += '<br><br><strong>Deaktivierung des Homepageprogramms</strong><br>Für eine erneute Aktivierung des Homepageprogramms können Kosten anfallen.';
									}

									genericDialog.okLabel = 'Deaktivieren';
									this.dialog.open(GenericDialogComponent, {
										maxWidth: '500px',
										maxHeight: '100vh',
										data: genericDialog
									}).afterClosed().subscribe((result: boolean) => {
										if (result === true) {
											this.profile['IstAnbieter'] = false;
											this.profile['HomepageProgrammAktiv'] = false;
											this.refreshEditor(this.profile);
										} else {
											this.profile['IstPartner'] = true;
											field.formControl.patchValue(true);
										}
									});
								}
							}
						}
					}
				},
				expressionProperties: {
					'templateOptions.disabled': () => {
						return !this.profile.Emails.some(e => e.Primaer);
					}
				}
			}, {
				template: '<p class="mat-error subscript-error-template">Bitte hinterlege \
          und verifiziere zuerst deine E-Mail-Adresse im Bereich "Mein Profil".</p>',
				hideExpression: () => {
					return this.profile.Emails.some(e => e.Primaer);
				}
			}, {
				template: '<p><strong>Was ist das Anbieterprogramm?</strong></p>\
        <p>Als Teilnehmer unseres Anbieterprogramms kannst du eigene Versicherungsprodukte für unseren \
        Suchdienst anmelden und so als Produktanbieter auftreten. Bei den Produkten kann es sich um \
        eigene Deckungskonzepte handeln oder um Standardprodukte von Versicherungsgesellschaften \
        oder Assekuradeuren, für die du besondere Bedingungen oder Konditionen vereinbart hast. \
        Im einfachsten Fall kann es sich aber auch um ganz normale Standardprodukte von Versicherern handeln, \
        für die du dich unseren Nutzern (Verbraucher und unabhängige Vermittler) gegenüber als Spezialist \
        präsentieren möchtest.</p>\
        <p>Die Teilnahme am Anbieterprogramm ist ganz einfach und du entscheidest selbst, welche \
        Produkte du einstellen möchtest. Deine Produkte können von Vermittlerkollegen und Verbrauchern \
        gefunden werden. Auf Wunsch stellen wir dann einen Kontakt zu dir per Web-Chat her, in den wir \
        dich einfach per E-Mail einladen. Hierfür musst du keine (!) Software installieren und nichts \
        Spezielles wissen.</p> \
        <p>Interessiert sich ein Verbraucher oder Vermittler für dein Produkt und klickt auf \
        "Preis anfragen", fällt für dich eine geringe Gebühr in Höhe von <strong>1 Euro</strong> zzgl. MwSt. an. \
        Erst dann, wenn wir einen Kontakt zwischen dir und dem Verbraucher oder Vermittler hergestellt haben, \
        fällt für dich eine zusätzliche Vermittlungsgebühr in Höhe von <strong>5 Euro</strong> zzgl. MwSt. an. \
        Du kannst deine Teilnahme am Anbieterprogramm jederzeit wieder deaktivieren. Voraussetzung für deine \
        Teilnahme am Anbieterprogramm ist die Teilnahme am Partnerprogramm, wobei du die Verbraucher-Kontakte \
        auf deine eigenen Produkte reduzieren kannst.</p>'
			}, {
				key: 'IstAnbieter',
				type: 'checkbox',
				templateOptions: {
					label: 'Ich möchte am Anbieterprogramm teilnehmen',
					description: '',
					indeterminate: false,
					change: (field: FormlyFieldConfig, event: Event) => {
						if (event instanceof MatCheckboxChange) {
							if ((<any>this.profile).IstAnbieter === true) {
								(<any>this.profile).IstPartner = true;
								this.refreshEditor(this.profile);
							} else {
								const genericDialog = new GenericDialog();
								genericDialog.titel = 'Anbieterprogramm deaktivieren';
								genericDialog.okLabel = 'Deaktivieren';
								genericDialog.text = 'Mit der Deaktivierung des Anbieterprogramms werden auch deine \
                  veröffentlichten Produkte deaktiviert. Bitte beachte, dass die Deaktivierung bis zu 24 \
                  Stunden in Anspruch nimmt und du in diesem Zeitraum noch Anfragen bekommen kannst.';
								this.dialog.open(GenericDialogComponent, {
									maxWidth: '500px',
									maxHeight: '100vh',
									data: genericDialog
								}).afterClosed().subscribe((result: boolean) => {
									if (result === true) {
										this.refreshEditor(this.profile);
									} else {
										this.profile['IstAnbieter'] = true;
										field.formControl.patchValue(true);
									}
								});
							}
						}
					}
				},
				expressionProperties: {
					'templateOptions.disabled': () => {
						return !this.profile.Emails.some(e => e.Primaer);
					}
				}
			}, {
				template: '<p class="mat-error subscript-error-template">Bitte hinterlege \
          und verifiziere zuerst deine E-Mail-Adresse im Bereich "Mein Profil".</p>',
				hideExpression: () => {
					return this.profile.Emails.some(e => e.Primaer);
				}
			}, {
				template: '<p><strong>Was ist das Homepageprogramm?</strong></p>\
        <p>Als Teilnehmer unseres Homepageprogramms kannst du Snoopr auf deiner Webseite einbinden \
		und den Suchdienst den Nutzern deiner Webseite anbieten. Dabei wird das Design von Snoopr an \
		deinen Webseiten-Auftritt angepasst. Du entscheidest, welche Produkte über den Suchdienst deiner \
		Webseite auffindbar sind. Dabei kann es sich um Produkte von dir handeln (s. Anbieterprogramm) \
		oder um Produkte von Versicherern, Assekuraderen oder Fachmaklern die ihre Produkte bei Snoopr zur Suche anbieten. \
		Findet ein Nutzer deiner Webseite ein passendes Produkt wird der Nutzer mittels eines Chats immer an eine \
		von dir definierte E-Mail-Adresse zwecks Angebotserstellung bzw. Beratung vermittelt. \
		Keine Angst: Die Einbindung von Snoopr auf deiner Webseite ist kinderleicht!</p>\
        <p>Für die Ersteinrichtung des Homepageprogramms fallen Kosten in Höhe von <strong>149 Euro</strong> zzgl. MwSt. an. \
		Damit ist die Individualisierung des Designs, die Konfiguration, sowie das zur Verfügung stellen der Dokumentation \
		zur Einbindung von Snoopr auf der Webseite abgegolten. Monatlich laufende Kosten gibt es keine. Du zahlst lediglich für \
		jede Kontaktherstellung zwischen einem Nutzer deiner Webseite und dir als Experte. Haben wir einen Kontakt zwischen dir \
		und dem Nutzer hergestellt, fällt für dich eine zusätzliche Vermittlungsgebühr in Höhe von <strong>5 Euro</strong> zzgl. MwSt. an. \
		Bist du zeitgleich Anbieter eigener Produkte zahlst du zusätzlich <strong>1 Euro</strong> zzgl. MwSt Cost-per-Klick, wie du es bereits kennst. \
		Du kannst deine Teilnahme am Homepageprogramm jederzeit wieder deaktivieren. Damit verschwindet jedoch auch das Suchfeld von der Webseite. \
		Voraussetzung für deine Teilnahme am Homepageprogramm ist die Teilnahme am Partnerprogramm, \
		damit du die Kontakte deiner Nutzer empfangen kannst.</p>'
			}, {
				key: 'HomepageProgrammAktiv',
				type: 'checkbox',
				templateOptions: {
					label: 'Ich möchte am Homepageprogramm teilnehmen',
					description: '',
					indeterminate: false,
					change: (field: FormlyFieldConfig, event: any) => {
						if (event instanceof MatCheckboxChange) {
							if ((<any>this.profile).HomepageProgrammAktiv === true) {
								(<any>this.profile).IstPartner = true;
								this.refreshEditor(this.profile);
							} else {
								const genericDialog = new GenericDialog();
								genericDialog.titel = 'Homepageprogramm deaktivieren';
								genericDialog.okLabel = 'Deaktivieren';
								genericDialog.text = 'Bist du sicher, dass du deine Teilnahme am Homepageprogramm deaktivieren möchtest? Für eine erneute Aktivierung können Kosten anfallen.';
								this.dialog.open(GenericDialogComponent, {
									maxWidth: '500px',
									maxHeight: '100vh',
									data: genericDialog
								}).afterClosed().subscribe((result: boolean) => {
									if (result === true) {
										this.refreshEditor(this.profile);
									} else {
										this.profile['IstAnbieter'] = true;
										field.formControl.patchValue(true);
									}
								});
							}
						}
					}
				},
				expressionProperties: {
					'templateOptions.disabled': () => {
						return !this.profile.Emails.some(e => e.Primaer);
					}
				}
			}, {
				key: 'Verifiziert',
				type: 'checkbox',
				templateOptions: {
					label: 'Legitimationsprüfung erfolgreich durchgeführt',
					description: '',
					indeterminate: false
				},
				hideExpression: () => !this.authService.isSystem() || !(<any>this.profile).IstAnbieter
			}, {
				template: '<p class="warn">Dieses Benutzerkonto ist noch nicht legitimiert.</p>',
				hideExpression: () => !(<any>this.profile).IstAnbieter || ((<any>this.profile).IstAnbieter && (<any>this.profile).Verifiziert)
			}, {
				template: '<p>Dieses Benutzerkonto ist legitimiert.</p>',
				hideExpression: () => !(<any>this.profile).IstAnbieter || ((<any>this.profile).IstAnbieter && !(<any>this.profile).Verifiziert)
			}
			]
		});

		if ((<any>this.profile).IstPartner || (<any>this.profile).IstAnbieter) {
			// create step 2
			this.editor.steps.push({
				label: 'Erstinformation hinterlegen',
				fields: [{
					type: 'input',
					key: 'CustomerID',
					templateOptions: {
						label: 'IHK-Registrierungsnummer',
						appearance: 'outline',
						floatLabel: 'always',
						description: '',
						placeholder: 'z. B. D-A1BCD-GHD5S-31',
						maxLength: 256,
						required: true,
						disabled: true
					}
				}, {
					type: 'input',
					key: 'Geschaeftsfuehrer',
					templateOptions: {
						label: 'Name des Geschäftsführers / Inhabers',
						appearance: 'outline',
						floatLabel: 'always',
						description: '',
						placeholder: 'z. B. Max Mustermann',
						maxLength: 200,
						required: true
					}
				}, {
					type: 'select',
					key: 'Taetigkeitsart',
					templateOptions: {
						label: 'Tätigkeitsart nach §34d GewO',
						multiple: false,
						required: true,
						floatLabel: 'always',
						appearance: 'outline',
						description: '',
						options: [{
							label: 'Versicherungsmakler mit Erlaubnis (§34d Abs. 1)', value: 'makler34d1'
						}, {
							label: 'Versicherungsvertreter für mehrere Gesellschaften (Mehrfachagent) mit Erlaubnis (§34d Abs. 1)', value: 'vertreter34d1'
						}]
					}
				}, {
					template: '<p>Als Anschrift werden die Daten aus "Mein Profil" verwendet.<br/>&nbsp;</p>'
				}, {
					template: '<h3>IHK-Registrierungsstelle</h3>'
				}, { // Text
					type: 'input',
					key: 'IHK.Name',
					templateOptions: {
						label: 'Name der Registrierungsstelle',
						floatLabel: 'always',
						required: true,
						placeholder: 'z. B. Industrie- und Handelskammer Mittlerer Niederrhein',
						appearance: 'outline',
					}
				}, {
					fieldGroupClassName: 'display-flex',
					fieldGroup: [{ // Text
						type: 'input',
						key: 'IHK.Strasse',
						className: 'flex-3',
						templateOptions: {
							label: 'Straße',
							floatLabel: 'always',
							required: true,
							placeholder: 'z. B. Nordwall',
							appearance: 'outline',
						}
					}, { // Text
						type: 'input',
						key: 'IHK.Hausnummer',
						className: 'flex-1',
						templateOptions: {
							label: 'Hausnummer',
							floatLabel: 'always',
							required: true,
							placeholder: 'z. B. 39',
							appearance: 'outline',
						}
					}]
				}, {
					fieldGroupClassName: 'display-flex',
					fieldGroup: [{ // Text
						type: 'input',
						key: 'IHK.Postleitzahl',
						className: 'flex-1',
						templateOptions: {
							label: 'Postleitzahl',
							floatLabel: 'always',
							required: true,
							placeholder: 'z. B. 47798',
							appearance: 'outline',
						}
					}, { // Text
						type: 'input',
						key: 'IHK.Ort',
						className: 'flex-3',
						templateOptions: {
							label: 'Ort',
							floatLabel: 'always',
							required: true,
							placeholder: 'z. B. Krefeld',
							appearance: 'outline',
						}
					}]
				}, {
					template: '<h3>Schlichtungsstellen</h3>\
          <p>Bitte eine vordefinierte Schlichtsstelle auswählen oder eigene Angaben machen.</p>'
				}, {
					type: 'repeat',
					key: 'Schlichtungsstellen',
					fieldArray: {
						fieldGroupClassName: 'display-flex flex-column',
						templateOptions: {
							label: 'Schlichtungsstelle hinzufügen',
							divider: true,
							space: '20px',
							customAdd: () => {
								return new Observable((subscriber) => {
									subscriber.next({
										Vorauswahl: this.schlichtungsstellen[0].Name,
										Name: '',
										AdresseOderPostfach: '',
										Postleitzahl: '',
										Ort: '',
										Website: '',
										Email: '',
										Telefon: '',
										isNew: true
									});
									subscriber.complete();
								});
							},
						},
						fieldGroup: [{
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{ // Text
								className: 'flex-1',
								key: 'Vorauswahl',
								type: 'select',
								templateOptions: {
									multiple: false,
									required: true,
									label: 'Schlichtungsstelle',
									floatLabel: 'always',
									appearance: 'outline',
									description: '',
									options: schlichtungsstellenOptions,
									change: (field) => {
										const value = field.formControl.value;
										for (let i = 0; i < this.schlichtungsstellen.length; i++) {
											const schlichtungsstelle = this.schlichtungsstellen[i];
											if (schlichtungsstelle.Name === value) {
												const keys = Object.keys(schlichtungsstelle);
												for (let ki = 0; ki < keys.length; ki++) {
													const key = keys[ki];
													let newValue = schlichtungsstelle[key];
													if (key === 'Name' && newValue === this.schlichtungsstellen[0].Name) {
														newValue = '';
													}
													field.model[key] = newValue;
													if (typeof field.formControl.parent.controls[key] !== 'undefined') {
														field.formControl.parent.controls[key].patchValue(newValue);
													}
												}
												break;
											}
										}
									}
								}
							}]
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{ // Text
								className: 'flex-1',
								type: 'input',
								key: 'Name',
								templateOptions: {
									label: 'Name der Schlichtungsstellen',
									floatLabel: 'always',
									required: true,
									placeholder: 'z. B. Musterschlichtungsstelle',
									appearance: 'outline',
								},
								hideExpression: (model) => {
									return !model.Vorauswahl || (model.Vorauswahl === model.Name && model.Name !== this.schlichtungsstellen[0].Name);
								}
							}]
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{ // Text
								className: 'flex-1',
								type: 'input',
								key: 'AdresseOderPostfach',
								templateOptions: {
									label: 'Adresse oder Postfach',
									floatLabel: 'always',
									required: true,
									placeholder: 'z. B. Musterallee',
									appearance: 'outline'
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => {
										return !model.Vorauswahl || (model.Vorauswahl === model.Name && model.Name !== this.schlichtungsstellen[0].Name);
									}
								}
							}]
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{ // Text
								className: 'flex-1',
								type: 'input',
								key: 'Postleitzahl',
								templateOptions: {
									label: 'Postleitzahl',
									floatLabel: 'always',
									required: true,
									placeholder: 'z. B. 12345',
									appearance: 'outline'
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => {
										return !model.Vorauswahl || (model.Vorauswahl === model.Name && model.Name !== this.schlichtungsstellen[0].Name);
									}
								}
							}, { // Text
								className: 'flex-3',
								type: 'input',
								key: 'Ort',
								templateOptions: {
									label: 'Ort',
									floatLabel: 'always',
									required: true,
									placeholder: 'z. B. Musterhausen',
									appearance: 'outline'
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => {
										return !model.Vorauswahl || (model.Vorauswahl === model.Name && model.Name !== this.schlichtungsstellen[0].Name);
									}
								}
							}]
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{ // Text
								className: 'flex-1',
								type: 'input',
								key: 'Website',
								templateOptions: {
									label: 'Webseite',
									floatLabel: 'always',
									required: false,
									placeholder: 'z. B. www.max-mustermann.de',
									appearance: 'outline'
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => {
										return !model.Vorauswahl || (model.Vorauswahl === model.Name && model.Name !== this.schlichtungsstellen[0].Name);
									}
								}
							}]
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{ // Text
								className: 'flex-1',
								type: 'input',
								key: 'Email',
								templateOptions: {
									label: 'E-Mail',
									floatLabel: 'always',
									required: false,
									placeholder: 'z. B. max@mustermann.de',
									appearance: 'outline'
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => {
										return !model.Vorauswahl || (model.Vorauswahl === model.Name && model.Name !== this.schlichtungsstellen[0].Name);
									}
								}
							}, { // Text
								className: 'flex-1',
								type: 'input',
								key: 'Telefon',
								templateOptions: {
									label: 'Geschäftliche Telefonnummer',
									floatLabel: 'always',
									required: false,
									placeholder: 'z. B. 012345 54321',
									appearance: 'outline'
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => {
										return !model.Vorauswahl || (model.Vorauswahl === model.Name && model.Name !== this.schlichtungsstellen[0].Name);
									}
								}
							}]
						}]
					}
				}, {
					template: '<h3>Beteiligungen</h3>\
          <p>Wenn dein Unternehmen mit 10% oder mehr am Kapital oder den Stimmrechten eines Versicherers \
          beteiligt ist oder umgekehrt ein Versicherer mit 10% oder mehr an deinem Unternehmen beteiligt ist, \
          musst du dies in den Erstinformationen angeben:</p>'
				}, {
					key: 'HatBeteiligungen',
					type: 'select',
					templateOptions: {
						label: 'Beteiligungen',
						multiple: false,
						required: true,
						floatLabel: 'always',
						appearance: 'outline',
						description: '',
						options: [{
							value: true, label: 'Mein Unternehmen hat Beteiligungen oder ein Versicherer ist an uns beteiligt'
						}, {
							value: false, label: 'Mein Unternehmen hat weder Beteiligungen noch ist ein Versicherer an uns beteiligt'
						}]
					}
				}, {
					key: 'Beteiligungen',
					type: 'textarea',
					templateOptions: {
						label: 'Bitte beschreibe die Beteiligungen deines Unternehmens:',
						floatLabel: 'always',
						appearance: 'outline',
						description: '',
						required: true,
						maxLength: 1000
					},
					hideExpression: (model: Profile) => model.HatBeteiligungen !== true,
					validators: {
						maxLength: {
							expression: (c) => !c.value || c.value.length <= 1000,
							message: 'Maximal 1000 Zeichen'
						}
					}
				},
				{
					template: '<h3>EU-Transparenzverordnung (TVO)</h3>'
				}, 
				{
					key: 'TVO',
					type: 'textarea',
					templateOptions: {
						label: 'Kundeninformationen gemäß EU-Transparenzverordnung (TVO)',
						floatLabel: 'always',
						appearance: 'outline',
						description: '',
						required: false,
						maxLength: 1000
					},
					validators: {
						maxLength: {
							expression: (c) => !c.value || c.value.length <= 1000,
							message: 'Maximal 1000 Zeichen'
						}
					}
				}, {
					template: '<h3>Vorschau</h3>\
          <p>Hier kannst du dir anschauen wie die Erstinformationen dem Kunden präsentiert werden.</p>'
				}, {
					type: 'button',
					templateOptions: {
						label: 'VORSCHAU ERZEUGEN',
						btnClass: 'mat-stroked-button',
						onClick: () => {
							this.navigationService.startLoading('programs_legalinfo_preview_generate');
							this.utilsService.getLegalInformationPdf(this.profile).subscribe(base64 => {
								const binary_string = atob(base64);
								const len = binary_string.length;
								const bytes = new Uint8Array(len);
								for (let i = 0; i < len; i++) {
									bytes[i] = binary_string.charCodeAt(i);
								}
								this.pdfViewerService.openDoc('Vorschau', <ArrayBuffer>bytes.buffer);
							}, error => {
								console.error(error);
								this.notifyService.notify(NotifyType.Error, 'Die Vorschau konnte nicht erzeugt werden.');
							}, () => {
								this.navigationService.stopLoading('programs_legalinfo_preview_generate');
							});
						}
					},
					expressionProperties: {
						'templateOptions.disabled': () => {
							return !this.editor.editorComponent.isStepValid(1);
						}
					}
				}]
			});

			const checkForDuplicates = (field: FormlyFieldConfig) => {
				const counts = {};
				this.profile.Konten.forEach(account => counts[account.IBAN] = (counts[account.IBAN] || 0) + 1);
				this.profile.Konten.forEach(account => {
					if (counts[account.IBAN] > 1) {
						account['Duplicate'] = true;
					} else {
						delete account['Duplicate'];
					}
					if (account.IBAN === field.model.IBAN) {
						field.formControl.updateValueAndValidity();
					}
				});
			};

			// create step 3
			this.editor.steps.push({
				label: 'Bankverbindung hinterlegen',
				fields: [{
					template: '<h3>Bankverbindungen</h3>'
				}, {
					type: 'repeat',
					key: 'Konten',
					templateOptions: {
						minCount: 1
					},
					fieldArray: {
						fieldGroupClassName: 'display-flex flex-column',
						templateOptions: {
							label: 'Bankverbindung hinzufügen',
							divider: true,
							space: '20px',
							customAdd: () => {
								const hasPrimary = this.profile.Konten.some(konto => konto.Primaer);
								return new Observable((subscriber) => {
									subscriber.next({
										Kontoinhaber: '', IBAN: '', Institut: '',
										Primaer: !hasPrimary, ValidierungsCode: '', ValidierungsCodeSenden: false, isNew: true
									});
									subscriber.complete();
								});
							},
							hint: (model) => {
								if (this.profile['IstAnbieter'] && !model.Verifiziert) {
									if (model.ValidierungLaeuft) {
										this.editor.editorComponent.setStepColor(2, 'yellow');
										return 'Diese Bankverbindung wird zur Zeit verifiziert.';
									}
									return 'Diese Bankverbindung muss noch verifiziert werden.';
								}
								this.editor.editorComponent.setStepColor(2, null);
								return '';
							},
							disableDeleteInRow: (model) => model.Primaer
						},
						fieldGroup: [{
							key: 'KontoValidator',
							type: 'hidden',
							validators: {
								required: {
									expression: (fc, field) => !(this.profile['IstAnbieter'] && !field.model.Verifiziert && !field.model.ValidierungLaeuft),
									message: () => null
								}
							}
						}, {
							type: 'checkbox',
							key: 'Primaer',
							templateOptions: {
								label: 'Dieses Konto ist das aktuelle Hauptkonto',
								indeterminate: false,
								click: (field, $event) => {
									if (field.formControl.disabled || field.formControl.value === true) {
										return;
									}

									field.formControl['__tempCurrentFormControl'] = true;
									const controls = field.formControl.parent.parent.controls;
									for (let i = 0; i < controls.length; i++) {
										const control = controls[i].controls.Primaer;
										if (typeof control['__tempCurrentFormControl'] === 'undefined') {
											control.patchValue(false);
										} else {
											delete control['__tempCurrentFormControl'];
											control.patchValue(undefined);
										}
									}
								}
							},
							expressionProperties: {
								'templateOptions.disabled': (model) => {
									return model.Primaer || !model.IBAN || !model.Kontoinhaber;
								}
							}
						}, {
							type: 'input',
							key: 'Kontoinhaber',
							templateOptions: {
								label: 'Kontoinhaber',
								floatLabel: 'always',
								required: true,
								placeholder: 'z. B. Max Mustermann',
								appearance: 'outline',
							},
							expressionProperties: {
								'templateOptions.disabled': (model) => {
									return !model.isNew;
								}
							}
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{ // Text
								className: 'flex-1',
								type: 'input',
								key: 'IBAN',
								templateOptions: {
									label: 'IBAN',
									floatLabel: 'always',
									required: true,
									placeholder: 'z. B. DE12500105170648489890',
									appearance: 'outline',
									change: (fc, field) => {
										checkForDuplicates(fc);
										const val = fc.model.IBAN;
										const instituteFc = (<any>fc.formControl.parent.controls).Institut;
										if (this.isIBANFormat(val) && this.validateIbanChecksum(val)) {
											instituteFc.patchValue('Wird ermittelt ...');
											this.utilsService.getBankInformation(val).subscribe((res) => {
												if (res.Status === 'OK') {
													instituteFc.patchValue(res.Bank.Name);
												} else {
													console.log(res);
													instituteFc.patchValue('');
												}
											}, (error) => {
												console.error(error);
												instituteFc.patchValue('');
											});
										} else {
											instituteFc.patchValue('');
										}
									},
									keyup: checkForDuplicates
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => {
										return !model.isNew;
									}
								},
								validators: {
									format: {
										expression: (c) => !c.value || this.isIBANFormat(c.value),
										message: (error, field) => 'Das Format der IBAN ist ungültig. Bitte ohne Leerzeichen und Sonderzeichen eingeben.',
									},
									checksum: {
										expression: (c) => !c.value || (this.isIBANFormat(c.value) && this.validateIbanChecksum(c.value)),
										message: (error, field) => 'Die eingegebene IBAN ist ungültig.',
									},
									deOnly: {
										expression: (c) => !c.value || c.value.substr(0, 2).toUpperCase() === 'DE',
										message: (error, field) => 'Es sind nur deutsche Bankkonten erlaubt.',
									},
									noDupes: {
										expression: (field, formControl) => {
											return formControl.model.Duplicate !== true;
										},
										message: () => 'Diese IBAN ist bereits vorhanden.'
									}
								}
							}, {
								className: 'flex-1',
								type: 'input',
								key: 'Institut',
								templateOptions: {
									label: 'Geldinstitut',
									floatLabel: 'always',
									disabled: true,
									placeholder: 'Wird automatisch ermittelt',
									appearance: 'outline',
								}
							}]
						}, {
							fieldGroupClassName: 'display-flex',
							fieldGroup: [{
								type: 'button',
								className: 'flex-1 padding-top-10',
								templateOptions: {
									label: 'KONTO VERIFIZIEREN',
									btnClass: 'mat-stroked-button save',
									onClick: (component: FormTypeButtonComponent) => {
										const model = <Bankverbindung>component.model;
										if (model.ValidierungLaeuft) {
											if (!this.profile.Konten.some(e => e.Primaer)) {
												model.Primaer = true;
											}
											this.editor.submitBankAccounts().subscribe({
												next: (profile) => {
													this.notifyService.notify(NotifyType.Success, 'Das Konto wurde verifiziert.');
													profile.Konten.forEach(account => {
														if (account.IBAN === model.IBAN) {
															model.Verifiziert = account.Verifiziert;
															model.Primaer = account.Primaer;
															model.ValidierungLaeuft = account.ValidierungLaeuft;
															component.form.controls.Primaer.patchValue(account.Primaer);
														}
													});
												},
												error: (error) => {
													console.error(error);
													this.notifyService.notify(NotifyType.Error, 'Der Code ist ungültig.');
												}
											});
										} else {
											const genericDialog = new GenericDialog();
											genericDialog.titel = 'Bankverbindung verifizieren';
											genericDialog.text = 'Um die Bankverbindung zu verifizieren, wird die A-va GmbH dir 0,01€ auf dein \
                      Konto überweisen. Im Verwendungszweck der Überweisung findest du einen vierstelligen-Prüfcode. Diesen \
                      musst du hier eingeben, um die Verifikation abzuschließen. Bitte beachte, dass die Überweisung einige \
                      Tage dauern kann. Möchtest du dein Konto jetzt verifizieren?';
											const dialogRef = this.dialog.open(GenericDialogComponent, {
												maxWidth: '500px',
												maxHeight: '100vh',
												data: genericDialog
											});
											dialogRef.afterClosed().subscribe(result => {
												if (result === true) {
													component.model.ValidierungsCodeSenden = true;
													this.editor.submitBankAccounts().subscribe({
														next: (profile) => {
															this.notifyService.notify(NotifyType.Success, 'Ein Prüfcode wurde versendet.');
															profile.Konten.forEach(account => {
																if (account.IBAN === model.IBAN) {
																	model.Verifiziert = account.Verifiziert;
																	model.Primaer = account.Primaer;
																	model.ValidierungLaeuft = account.ValidierungLaeuft;
																	component.form.controls.Primaer.patchValue(account.Primaer);
																}
															});
														},
														error: (error) => {
															console.error(error);
															this.notifyService.notify(NotifyType.Error, 'Versenden des Prüfcodes fehlgeschlagen.');
														}
													});
													component.model.ValidierungsCodeSenden = false;
												}
											});
										}
									},
									disableWhenParentInvalid: true,
									ignoreParentInvalid: ['KontoValidator']
								},
								hideExpression: (model) => {
									return model.Verifiziert || !this.profile['IstAnbieter'];
								},
								expressionProperties: {
									'templateOptions.label': (model) => {
										if (model.ValidierungLaeuft) {
											return 'PRÜFCODE BESTÄTIGEN';
										} else {
											return 'KONTO VERIFIZIEREN';
										}
									},
									'templateOptions.disabled': (model) => {
										return this.navigationService.isLoading() || (model.ValidierungLaeuft &&
											!(model.ValidierungsCode && /\d{4}/.test(model.ValidierungsCode.trim())));
									}
								}
							}, { // Text
								type: 'input',
								className: 'flex-3',
								key: 'ValidierungsCode',
								templateOptions: {
									label: 'Prüfcode',
									floatLabel: 'always',
									required: false,
									placeholder: '',
									appearance: 'outline'
								},
								hideExpression: (model) => {
									return model.Verifiziert || !this.profile['IstAnbieter'];
								},
								expressionProperties: {
									'templateOptions.disabled': (model) => {
										return model.isNew;
									}
								}
							}]
						}]
					}
				}]
			});

			// create step 4
			this.editor.steps.push({
				label: 'Anzeigedaten bearbeiten',
				fields: [{
					className: 'flex-1',
					template: '<h3 style="margin-top: 10px;">Dein persönlicher Avatar</h3>\
            <p>Der Kontakt zwischen einem Verbraucher bzw. Vermittler und dir erfolgt per Web-Chat. Bitte lade hier \
            ein professionelles Portraitbild von dir hoch und schneide das Bild so zu, dass dein Gesicht \
            optimal im Kreis erscheint. Deine Chat-Nachrichten werden mit diesem Bild erscheinen. Am besten \
            funktionieren quardratische Formate. Wir unterstützen die Dateitypen JPG, PNG und GIF.</p>\
            <p>&nbsp;</p>'
				}, { // Dateiupload
					key: 'AvatarImage',
					type: 'image-crop',
					className: 'flex-1',
					templateOptions: {
						label: 'Dateiauswahl',
						floatLabel: 'always',
						appearance: 'outline',
						accept: '.jpg,.jpeg,.png,.gif',
						previewImage: 'public/assets/broker/_DEFAULT/avatar',
						image: this.avatarImageUrl,
						maintainAspectRatio: true,
						isRound: true,
						aspectRatio: 1 / 1,
						resizeToWidth: 200,
						resizeToHeight: 200,
						cropperMinWidth: 100,
						cropperMinHeight: 100,
						previewHeight: 80,
						previewWidth: 80,
						onImageChange: (model: any, image: any, originalFile: any, isAppliedExternal: boolean) => {
							this.editor.editorComponent.validateControlByKey('AvatarImageValidator');
							(<any>this.profile).AvatarImageOriginal = originalFile;

							if (!isAppliedExternal) {
								const cropValueChange = new CropValueChange();
								cropValueChange.image = image;
								cropValueChange.originalImage = cropValueChange;
								cropValueChange.imageURL = null;
								this.avatarForm.controls['image'].patchValue(image);
							}
						},
						onImageURLChange: (model: any, imageURL: string, isAppliedExternal: boolean) => {
							if (!isAppliedExternal) {
								const cropValueChange = new CropValueChange();
								cropValueChange.image = null;
								cropValueChange.originalImage = null;
								cropValueChange.imageURL = imageURL;
								this.avatarForm.controls['image'].patchValue(cropValueChange);
							}
						}
					}
				}, {
					key: 'AvatarImageValidator',
					type: 'hidden',
					validators: {
						required: {
							expression: (field) => {
								return typeof (<any>this.editor.model).AvatarImage !== 'undefined' && (<any>this.editor.model).AvatarImage !== null;
							},
							message: () => 'Es muss ein Avatar hochgeladen werden.'
						}
					}
				}, {
					fieldGroupClassName: 'display-flex',
					fieldGroup: [{
						type: 'button',
						className: 'flex-auto',
						templateOptions: {
							color: 'primary',
							label: 'Avatar herunterladen',
							onClick: (field) => {
								// tslint:disable-next-line:max-line-length
								this.storageService.download('public/assets/broker/' + this.profile.CustomerID + '/avatar').subscribe((success) => {
									if (success) {
										this.notifyService.notify(NotifyType.Success, 'Der Avatar wird heruntergeladen.');
									} else {
										// tslint:disable-next-line:max-line-length
										this.notifyService.notify(NotifyType.Error, 'Der Avatar konnte nicht heruntergeladen werden. Kein Avatar hinterlegt?');
									}
								});
							}
						},
						hideExpression: () => !this.authService.isSystem()
					}, {
						type: 'button',
						className: 'flex-auto',
						templateOptions: {
							color: 'primary',
							label: 'Original Avatar herunterladen',
							onClick: (field) => {
								// tslint:disable-next-line:max-line-length
								this.storageService.download('public/assets/broker/' + this.profile.CustomerID + '/avatar-original').subscribe((success) => {
									if (success) {
										this.notifyService.notify(NotifyType.Success, 'Das originale Avatarbild wird heruntergeladen.');
									} else {
										// tslint:disable-next-line:max-line-length
										this.notifyService.notify(NotifyType.Error, 'Das originale Avatarbild konnte nicht heruntergeladen werden. Kein originales Avatarbild hinterlegt?');
									}
								});
							}
						},
						hideExpression: () => !this.authService.isSystem()
					}]
				}, {
					template: '<br/>&nbsp;<br/>'
				}, {
					className: 'flex-1',
					template: '<h3 style="margin-top: 10px;">Logo deines Unternehmens</h3>\
            <p>Das Logo erscheint prominent in der Produktdarstellung (auch als Werbefläche bezeichnet).\
            Bitte achte auf eine gute Qualität und darauf, dass es linksbündig auf \
            weißen Hintergrund positioniert ist. Wir unterstützen die Dateitypen JPG, PNG sowie \
            GIF und unterziehen es einer professionellen Bildbearbeitung. Dabei wird \
            es auf das Seitenverhältnis 16:3 und mindestens 160 x 30 Pixel gebracht.</p>\
            <p>&nbsp;</p>'
				}, { // Dateiupload
					key: 'LogoImage',
					type: 'image-crop',
					className: 'flex-1',
					templateOptions: {
						label: 'Dateiauswahl',
						floatLabel: 'always',
						appearance: 'outline',
						accept: '.jpg,.jpeg,.png,.gif',
						previewImage: 'public/assets/broker/_DEFAULT/logo',
						image: this.logoImageUrl,
						disabled: !this.authService.isSystem(),
						enableIfEmpty: true,
						skipCropping: true,
						maintainAspectRatio: true,
						isRound: false,
						aspectRatio: 16 / 3,
						cropperMinWidth: 160,
						cropperMinHeight: 30,
						previewHeight: 50,
						onImageChange: (model, image) => {
							this.editor.editorComponent.validateControlByKey('LogoImageValidator');
						}
					}
				}, {
					key: 'LogoImageValidator',
					type: 'hidden',
					validators: {
						required: {
							expression: (field) => {
								return typeof (<any>this.editor.model).LogoImage !== 'undefined' && (<any>this.editor.model).LogoImage !== null;
							},
							message: () => 'Es muss ein Logo hochgeladen werden.'
						}
					}
				}, {
					type: 'button',
					className: 'flex-1',
					templateOptions: {
						color: 'primary',
						label: 'Logo herunterladen',
						onClick: (field) => {
							this.storageService.download('public/assets/broker/' + this.profile.CustomerID + '/logo').subscribe((success) => {
								if (success) {
									this.notifyService.notify(NotifyType.Success, 'Das Logo wird heruntergeladen.');
								} else {
									this.notifyService.notify(NotifyType.Error, 'Das Logo konnte nicht heruntergeladen werden. Kein Logo hinterlegt?');
								}
							});
						}
					},
					hideExpression: () => !this.authService.isSystem()
				}, {
					template: '<p><strong>Logo ändern?</strong></p><p>Bitte sende dein neues Logo als Bilddatei per \
              E-Mail an <a href="mailto:service@snoopr.de?subject=Kunden-ID: ' + this.profile.CustomerID +
						' - Änderung meines Logos&body=Hallo Ava,%0D%0A%0D%0Aals Anlage findest du mein neues Logo.%0D%0A">\
              service@snoopr.de</a>. Bitte beachte, dass eine Änderung von uns nur durchgeführt werden kann, \
              wenn du den Änderungsauftrag von der E-Mail-Adresse versendest, die auch in deinem Profil hinterlegt ist.</p>',
					hideExpression: () => !this.logoExists
				}
				]
			});
		}

		if ((<any>this.profile).IstPartner) {
			// create step 5
			this.editor.steps.push({
				label: 'Partnerprogramm aktivieren',
				fields: [{
					template: '<p><strong>Bedingungen</strong></p>\
          <p>Alle Einzelheiten sind in den <a href="https://www.snoopr.de/nutzungsbedingungen/console/partnerprogramm" \
            target="_blank">Bedingungen für die Teilnahme am Partnerprogramm</a> geregelt. (Zugangscode: o4sc$now)</p>'
				}, {
					type: 'checkbox',
					key: 'Bedingungen.Leadkauf',
					templateOptions: {
						label: 'Ich akzeptiere die Bedingungen für die Teilnahme am Partnerprogramm',
						description: '',
						indeterminate: false
					},
					validators: {
						validation: ['checkboxRequiredTrue']
					}
				}, {
					template: '<p><strong>SEPA-Lastschriftermächtigung</strong></p>\
          <p>Wir buchen fällige Gebühren automatisch von deinem Konto ab. Hiermit ermächtigst du uns, \
          Zahlungen von deinem Hauptkonto mittels Lastschrift einzuziehen. Zugleich weist du dein Kreditinstitut an, \
          die von uns auf dein Konto gezogenen Lastschriften einzulösen. Du kannst innerhalb von acht Wochen, \
          beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei \
          die mit deinem Kreditinstitut vereinbarten Bedingungen. Im Falle von Rückbuchungen sind wir zur Erhebung \
          einer Bearbeitungsgebühr in Höhe von 20 Euro berechtigt.</p>'
				}, {
					type: 'checkbox',
					key: 'Bedingungen.SEPALastschrift',
					templateOptions: {
						label: 'SEPA-Lastschriftermächtigung',
						description: '',
						indeterminate: false
					},
					validators: {
						validation: ['checkboxRequiredTrue']
					}
				}, {
					template: '<p><strong>Registrierung für Produktkategorien</strong></p>\
            <p>Versicherer oder Assekuradeure ordnen ihre Produkte bei der Anmeldung einer bestimmten Produktkategorie zu. \
            Hier kannst du dich für eine oder mehrere Produktkategorien registrieren. Wählt ein Verbraucher ein \
            Produkt aus, ermittelt unser System automatisch die entsprechende Produktkategorie und sucht nach Teilnehmern \
            aus unserem Partnerprogramm, die sich für diese Kategorie registriert haben. Es ist also nicht notwendig, dass du \
            dich auf einzelne Produkte von einzelnen Anbietern registrierst. </p>\
            <p>Es kann vorkommen, dass du Anfragen zu Produkten bekommst, die zwar deiner Kategorie zugeordnet sind, \
            aber für die du tatsächlich keine Beratung anbieten möchtest. Dieses Risiko musst du in Kauf nehmen. \
            Natürlich steht es dir frei, den Kunden im Chat von ähnlichen, vielleicht auch besseren Produkten \
            zu überzeugen. Außerdem kann es vorkommen, dass du mit dem Produktanbieter noch keine Vertriebsvereinbarung \
            geschlossen hast. Das ist nicht weiter schlimm, denn nahezu alle Anbieter sind bereit, dir im konkreten Fall eine Vereinbarung \
            anzubieten.</p>'
				}, {
					key: 'Kategorieauswahl',
					type: 'category-select',
					templateOptions: {
						multiple: true,
						required: (<any>this.profile).IstPartner && !(<any>this.profile).IstAnbieter,
						label: 'Kategorieauswahl',
						floatLabel: 'always',
						appearance: 'outline',
						description: '',
						single: false,
						categories: new Observable(subscriber => {
							const result = [];

							if ((<any>this.profile).IstAnbieter) {
								result.push({
									value: '_MEINE_PROGRAMME',
									label: 'Meine Produkte',
									disabled: true,
									selected: true,
									group: '_ALLGEMEIN'
								});
							}

							this.categoryService.categoryList.forEach((category: Category) => {
								if (category.ID.indexOf('-SNOOPR') > 0) {
									result.push({
										value: category.ID,
										label: category.Bezeichnung,
										group: category.ProduktgruppenID,
										selected: (<any>this.profile).Kategorieauswahl ? (<any>this.profile).Kategorieauswahl.indexOf(category.ID) >= 0 : true
									});
								}
							});
							subscriber.next(result);
							subscriber.complete();
						})
					}
				}]
			});
		}

		if ((<any>this.profile).IstAnbieter) {
			// create step 6
			this.editor.steps.push({
				label: 'Anbieterkonto aktivieren',
				fields: [{
					template: '<p><strong>Bedingungen</strong></p>\
          <p>Alle Einzelheiten sind in den <a href="https://www.snoopr.de/nutzungsbedingungen/console/anbieterprogramm" \
            target="_blank">Bedingungen für die Teilnahme am Anbieterprogramm</a> geregelt. (Zugangscode: o4sc$now)</p>'
				}, {
					type: 'checkbox',
					key: 'Bedingungen.WerbeflaechenPreise',
					templateOptions: {
						label: 'Ich akzeptiere die Bedingungen für die Teilnahme am Anbieterprogramm',
						description: '',
						indeterminate: false
					},
					validators: {
						validation: ['checkboxRequiredTrue']
					}
				}, {
					template: '<p><strong>Legitimationsprüfung</strong></p>\
          <p>Mit der Aktivierung des Anbieterprogramms kannst du bereits Produkte anmelden, aber noch nicht veröffentlichen. \
          Hierfür müssen wir dich zuerst im Rahmen einer vereinfachten Legitimationsprüfung identifizieren.\
          Für diese Prüfung benötigen wir lediglich ein Foto von der Vorderseite deines Personalausweises, wenn du ein \
          Einzelunternehmer bzw. als natürliche Person handelst. Bist du ein Unternehmen im Sinne einer juristischen Person, \
          dann benötigen wir von dir ein Foto von deinem Handelsregisterauszug. Aus dem Abbbild muss der letzte Stand hervorgehen, d. h. \
          Name und Anschrift des Unternhemens inkl. Handelsregisternummer sowie der Name des Geschäftsführers zu sehen sein. \
          Die Datei kannst du in den Formaten PNG, JPG oder PDF hochladen.</p>',
					hideExpression: () => this.profile['Verifiziert'] === true
				}, {
					template: '<strong>Falls du dein hochgeladenes Dokument ersetzen willst, wende dich bitte an ' +
						'<a href="mailto:service@snoopr.de">service@snoopr.de</a>.</strong>',
					hideExpression: () => !this.hasIdentificationDocument || this.profile['Verifiziert'] === true
				}, { // Dateiupload
					key: 'VerificationFile',
					type: 'file',
					templateOptions: {
						class: 'flex-1',
						label: 'Dateiauswahl',
						floatLabel: 'always',
						appearance: 'outline',
						required: true,
						accept: '.png,.jpg,.jpeg,.pdf',
						filename: '',
						emptyText: () => 'Bitte ein Dokument zum Hochladen auswählen'
					},
					hideExpression: () => this.hasIdentificationDocument || this.profile['Verifiziert'] === true,
					validators: {
						isValidFormat: {
							expression: (field) => {
								return (!field.value || !field.value[0] || field.value[0].name.match(/\.(pdf|png|jpe?g)$/i));
							},
							message: () => {
								return 'Dokumente müssen PNG-, JPEG-, oder PDF-Dateien sein.';
							}
						}
					},
				}, {
					type: 'button',
					className: 'flex-1',
					templateOptions: {
						color: 'primary',
						label: 'Dokument herunterladen',
						onClick: (field) => {
							this.storageService.download('public/assets/broker/' + this.profile.CustomerID + '/dokument').subscribe((success) => {
								if (success) {
									this.notifyService.notify(NotifyType.Success, 'Das Dokument wird heruntergeladen.');
								} else {
									// tslint:disable-next-line:max-line-length
									this.notifyService.notify(NotifyType.Error, 'Das Dokument konnte nicht heruntergeladen werden. Kein Dokument hinterlegt?');
								}
							});
						}
					},
					hideExpression: () => !this.authService.isSystem() || !this.hasIdentificationDocument
				}]
			});
		}

		if ((<any>this.profile).HomepageProgrammAktiv) {
			this.editor.steps.push({
				label: 'Homepageprogramm aktivieren',
				fields: [{
					template: '<p><strong>Bedingungen</strong></p>\
            <p>Alle Einzelheiten sind in den <a href="https://www.snoopr.de/nutzungsbedingungen/console/homepageprogramm" \
            target="_blank">Bedingungen für die Teilnahme am Homepageprogramm</a> geregelt. (Zugangscode: o4sc$now)</p>'
				}, {
					key: 'HomepageProgrammDaten.Bedingungen',
					type: 'checkbox',
					templateOptions: {
						label: 'Ich akzeptiere die Bedingungen für die Teilnahme am Homepageprogramm',
						description: '',
						indeterminate: false,
						disabled: (<any>this.profile).InitHomepageProgrammAktiv === (<any>this.profile).HomepageProgrammAktiv && !this.authService.isSystem()
					},
					validators: {
						validation: ['checkboxRequiredTrue']
					}
				}, {
					template: '<p><strong>Webseite</strong></p>\
            <p>Bitte gib hier die URL deiner Webseite ein, auf der die Suchmaschine integriert werden soll.</p>'
				}, {
					key: 'HomepageProgrammDaten.Webseite',
					type: 'input',
					templateOptions: {
						label: 'Webseite',
						description: '',
						appearance: 'outline',
						floatLabel: 'always',
						placeholder: 'https://www.snoopr.de',
						required: true,
						disabled: (<any>this.profile).InitHomepageProgrammAktiv === (<any>this.profile).HomepageProgrammAktiv && !this.authService.isSystem()
					},
					validators: {
						http: {
							expression: (field) => {
								return !field.value || /^https?\:\/\//.exec(field.value) !== null;
							},
							message: () => {
								return 'Der Link muss mit "https://" oder "http://" beginnen.';
							}
						}
					}
				}, {
					template: '<p><strong>Kategorien</strong></p>\
            <p>Bitte wähle hier die Kategorien aus, die über die Suchmaschine auf deiner Webseite gefunden werden sollen.</p>'
				}, /*, {
          key: 'HomepageProgrammDaten.Anbieter',
          type: 'provider-select',
          templateOptions: {
            multiple: true,
            required: true,
            label: 'Anbieterauswahl',
            floatLabel: 'always',
            appearance: 'outline',
            description: '',
            single: false,
            disabled: (<any>this.profile).InitHomepageProgrammAktiv === (<any>this.profile).HomepageProgrammAktiv && !this.authService.isSystem(),
            providers: new Observable(subscriber => {
              this.providerService.loadProviderList().subscribe(providerData => {
                const providerList = providerData.providerList;
                const result = [];
                for (let i = 0; i < providerList.length; i++) {
                  const provider = providerList[i];
                  if (provider.AnbieterRolle !== 'Versicherer' || provider.ID === 'SNOOPR') {
                    continue;
                  }
                  result.push({
                    value: provider.ID,
                    label: provider.Name,
                    disabled: false,
                    selected: (<any>this.profile).InitHomepageProgrammAktiv ? (<any>this.profile).HomepageProgrammDaten.Anbieter.indexOf(provider.ID) >= 0 : false
                  });
                }

                result.unshift({
                  value: this.profile.CustomerID,
                  label: 'Eigene Produkte',
                  disabled: true,
                  selected: (<any>this.profile).InitHomepageProgrammAktiv ? (<any>this.profile).HomepageProgrammDaten.Anbieter.indexOf(this.profile.CustomerID) >= 0 : true
                });

                subscriber.next(result);
                subscriber.complete();
              });

            })
          }
        }*/{
					key: 'HomepageProgrammDaten.Kategorien',
					type: 'category-select',
					templateOptions: {
						multiple: true,
						required: true,
						label: 'Kategorieauswahl',
						floatLabel: 'always',
						appearance: 'outline',
						description: '',
						single: false,
						disabled: (<any>this.profile).InitHomepageProgrammAktiv === (<any>this.profile).HomepageProgrammAktiv && !this.authService.isSystem(),
						categories: new Observable(subscriber => {
							const result = [];

							if ((<any>this.profile).IstAnbieter) {
								result.push({
									value: '_MEINE_PROGRAMME',
									label: 'Meine Produkte',
									disabled: true,
									selected: true,
									group: '_ALLGEMEIN'
								});
							}

							this.categoryService.categoryList.forEach((category: Category) => {
								if (category.ID.indexOf('-SNOOPR') > 0) {
									result.push({
										value: category.ID,
										label: category.Bezeichnung,
										group: category.ProduktgruppenID,
										selected: (<any>this.profile).InitHomepageProgrammAktiv ? (<any>this.profile).HomepageProgrammDaten.Kategorien.indexOf(category.ID) >= 0 : false
									});
								}
							});
							subscriber.next(result);
							subscriber.complete();
						})
					}
				}, {
					template: '<br><p><strong>Datenschutz - Textbaustein</strong></p><p>Diesen Textbaustein musst du in deine Datenschutzerklärung aufnehmen, damit die Nutzer deiner Webseite wissen, wie Snoopr funktioniert und mit den Daten deiner Kunden umgeht:</p><br>' +
						'<p><strong>Suchmaschine Snoopr</strong></p><p class="copy-text-1">Wir nutzen auf unserer Website die Suchtechnologie der A-va GmbH, Lindenstr. 48-52, 40233 Düsseldorf, Deutschland (nachfolgend als „Snoopr“ bezeichnet). Snoopr ermöglicht Ihnen eine Online-Recherche in unserem Produktportfolio und einen direkten, produktbezogenen Online-Kontakt per Web-Chat zu uns aufzubauen. Mit Snoopr lassen sich Produktinformationen aus unsem Portfolio an Versicherungsprodukten nach Stichworten durchsuchen und anzeigen. Die Suche wird über ein Suchfeld oder durch Klick auf entsprechende Links oder Schaltflächen gestartet. Dazu werden Ihre Eingaben in die Suchmaschine, Ihre IP-Adresse, die von Ihnen aufgerufene Seite sowie der Zugriffszeitpunkt und Ihre Browserkonfiguration an Snoopr übertragen. Snoopr kann in Kontakt zu persönlichen Daten unserer Kunden und Interessenten kommen. Aus diesem Grund haben wir mit dem Dienstleister eine Auftragsverarbeitung gemäß EU-DSGVO vereinbart. Wir bleiben entsprechend für den Schutz Ihrer Daten verantwortlich. Der Dienstleister arbeitet ausschließlich gemäß unserer Weisungen.<br>' +
						'Weitere Informationen zu den Bedingungen der Nutzung finden sie unter <a href="https://www.snoopr.de/nutzungsbedingungen" target="_blank">www.snoopr.de/nutzungsbedingungen</a>. Informationen zur weiteren Datenerhebung und -verarbeitung finden Sie in der Datenschutzerklärung von Snoopr, die Sie jederzeit unter <a href="https://www.snoopr.de/datenschutzerklaerung" target="_blank">www.snoopr.de/datenschutzerklaerung</a> abrufen können. Die rechtliche Grundlage für die Verarbeitung Ihrer Daten in Bezug auf den Dienst „Snoopr“ ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt sich aus unserem Bedarf, Ihnen eine nutzerfreundliche Webseite mit einem breiten Funktionsumfang und in einer ansprechenden Darstellung anbieten zu können.</p>',
				}, {
					type: 'button',
					className: 'flex-3',
					templateOptions: {
						color: 'primary',
						label: 'Text kopieren',
						onClick: (field) => {
							let element = document.querySelectorAll('.copy-text-1')[0];
							if ((<any>document.body).createTextRange) {
								let range = (<any>document.body).createTextRange();
								range.moveToElementText(element);
								range.select();
								document.execCommand("Copy");
								this.notifyService.notify(NotifyType.Success, "Text wurde kopiert");
							} else if (window.getSelection) {
								let selection = window.getSelection();
								let range = document.createRange();
								range.selectNodeContents(element);
								selection.removeAllRanges();
								selection.addRange(range);
								document.execCommand("Copy");
								this.notifyService.notify(NotifyType.Success, "Text wurde kopiert");
							}
						}
					}
				}, {
					template: '<br><br>',
				}, {
					template: '<p><strong>Wende dich für Änderungen an deinem Homepageprogramm bitte an <a href="mailto:support@snoopr.de?subject=Änderungen am Homepageprogramm von ' + encodeURIComponent(this.profile.Name + ' (' + this.profile.CustomerID + ')') + '&body=' + encodeURIComponent('Hallo, ich möchte folgende Änderungen an meinem Homepageprogramm vornehmen: ') + '">support@snoopr.de</a></strong>.</p><br><br>',
					hideExpression: () => (<any>this.profile).InitHomepageProgrammAktiv !== (<any>this.profile).HomepageProgrammAktiv || !(<any>this.profile).HomepageProgrammAktiv
				}]
			});

		}
	}

	isIBANFormat(str) {
		return /^([A-Z]{2}[0-9]{2})(?=(?:[A-Z0-9]){9,30}$)((?:[A-Z0-9]{3,5}){2,7})([A-Z0-9]{1,3})?$/.test(str);
	}

	validateIbanChecksum(iban) {
		const ibanStripped = iban.replace(/[^A-Z0-9]+/gi, '').toUpperCase();
		const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
		if (!m) {
			return false;
		}
		const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, (ch) => (ch.charCodeAt(0) - 55));
		const mod97 = numbericed.match(/\d{1,7}/g).reduce((total, curr) => Number(total + curr) % 97, '');
		return (mod97 === 1);
	}
}
