import { Component, ViewChild, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-from-type-extended-datepicker',
  templateUrl: 'from-type-extended-datepicker.component.html',
  styleUrls: ['./from-type-extended-datepicker.component.css']
})
export class FromTypeExtendedDatepickerComponent extends FieldType implements OnInit {
  @ViewChild(MatInput) formFieldControl: MatInput;

  templateOptions: any;

  ngOnInit() {
    super.ngOnInit();
    this.templateOptions = this.to;
  }

  onChangeInput(event) {
    if (/^\d{2}\.?\d{2}\.?\d+$/g.test(event.target.value)) {
      event.target.value = event.target.value.replace(/^(\d{2})\.?(\d{2})\.?(\d{1,4})(.+)?$/, '$1.$2.$3');
    } else if (/^\d{2}\d+$/g.test(event.target.value)) {
      event.target.value = event.target.value.replace(/^(\d{2})\.?(\d{1,2})$/, '$1.$2');
    }
  }
}
