// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_beVK8CemU',
    userPoolWebClientId: '5mpot1f0a4d7pcjkhhvbhimr2v',
    identityPoolId: 'eu-central-1:ff8bb783-f336-41db-b68a-9d47ebe815da'
  },
  api: 'https://8yhhgky631.execute-api.eu-central-1.amazonaws.com/test',
  s3bucket: 'console.snoopr.de-media'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
