import { Component, ChangeDetectionStrategy, ViewEncapsulation, OnInit, Input } from '@angular/core';

import { ProductInfoEntryPDFComponent } from '../product-info/product-info.component';
import { PdfViewerService } from '../../services/pdfviewer/pdfviewer.service';
import { StorageService } from '../../services/storage/storage.service';
import { NavigationService } from '../../services/navigation/navigation.service';

import { Medium } from '../../classes/medium';

@Component({
  selector: 'app-product-downloads',
  templateUrl: './product-downloads.component.html',
  styleUrls: ['./product-downloads.component.css', '../product-info/product-info-entry.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDownloadsComponent extends ProductInfoEntryPDFComponent implements OnInit {

  @Input() public media: Medium[];

  constructor(pdfViewer: PdfViewerService, storageService: StorageService, public navigationService: NavigationService) {
    super(storageService, pdfViewer);
  }

  ngOnInit() {
    this.media = this.media || [];

    this.media.forEach((download) => {
      if (download.Bild.indexOf('http') !== 0) {
        const imageURL = download.Bild;
        download.Bild = '/assets/img/placeholder/product-download.jpg';
        this.storageService.getSignedUrl(imageURL).subscribe(url => {
            download.Bild = url;
        }, error => {
            console.log('error getting url: ' + error);
        });
      }
    });
  }

}
