import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin, of, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import * as _moment from 'moment';

import { ItemComponent } from '../../classes/item-component';
import { Editor } from '../../classes/editor';
import { Product } from '../../classes/product';
import { Category } from '../../classes/category';

import { ProductService } from '../../services/product/product.service';
import { EditorService } from '../../services/editor/editor.service';
import { ProviderService } from '../../services/provider/provider.service';
import { CategoryService } from '../../services/category/category.service';
import { AuthService } from '../../services/auth/auth.service';
import { MessageService } from '../../services/message/message.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Provider } from 'src/app/classes/provider';

const moment = _moment;

export class ProductCreateItemEditor extends Editor<ProductCreateItemComponent, any> {

  public title = '...';

  constructor(
    public component: ProductCreateItemComponent,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private productService: ProductService,
    private messageService: MessageService
  ) {
      super(component);
  }

  submit(model: any, type: string): Observable<void> {
    console.log('submit: ' + JSON.stringify(model, null, 2));
    return new Observable(subscriber => {
      this.submitModel(model).subscribe((product: Product) => {
        subscriber.next();
        subscriber.complete();

        this.router.navigate(['/product-edit', product.ID]);
      }, error => {
        subscriber.error(error);
        this.messageService.showError('Beim Anlegen des Produktes ist es zu einem Fehler gekommen: '
         + error.error.message, error);
      });
    });
  }

  submitModel(model): Observable<Product> {
    console.log('submitModel: ' + JSON.stringify(model, null, 2));
    return this.productService.create(model);
  }

  preModelModifier(model: any): void {
    model.Anbieter = {
      ID: model.AnbieterID,
      Name: model.AnbieterName,
      AnbieterRolle: model.AnbieterRolle
    };
    model.KategorieEnterprise = null;
    model.KategorieSnoopr = null;
    if (model.Kategorien) {
      for (let index = 0; index < model.Kategorien.length; index++) {
        const categoryId = model.Kategorien[index].ID;
        const split = /^(.{1,})-(.*)$/.exec(categoryId);
        if (split != null && split.length === 3) {
          const consumerSuffix = split[2];
          if (consumerSuffix !== 'SNOOPR') {
            model.KategorieEnterprise = categoryId;
          } else {
            model.KategorieSnoopr = categoryId;
          }
        }
      }
    }
  }

  postModelModifier(model: any, type: string): void {
    model.AnbieterID = model.Anbieter.ID;
    model.AnbieterName = model.Anbieter.Name;
    model.AnbieterRolle = model.Anbieter.AnbieterRolle;

    if (typeof model.ProduktIDShort !== 'undefined' && model.Modell !== 'BUSINESS_PRO') {
      let prefix = model.KategorieSnoopr.split('-');
      prefix.pop();
      prefix = prefix.join('-');
      model.ProduktID = prefix + '.' + model.ProduktIDShort;
    }

    model.Kategorien = [];
    if (model.KategorieEnterprise) {
      model.Kategorien.push({
        ID: model.KategorieEnterprise,
        Sortierung: 0
      });
    }
    if (model.KategorieSnoopr) {
      model.Kategorien.push({
        ID: model.KategorieSnoopr,
        Sortierung: 0
      });
    }
  }
}

@Component({
  selector: 'app-product-create-item',
  templateUrl: './product-create-item.component.html',
  styleUrls: ['./product-create-item.component.css']
})
export class ProductCreateItemComponent extends ItemComponent implements OnInit {

  public product: Product;
  public editor: ProductCreateItemEditor;
  public productShortcuts: Object[];

  public isRoot = false;
  public isEnterprise = false;
  public isBroker = false;

  public initialized = false;
  public hasOnlySnooprCategories = true;

  public previousModel = null;

  private parameterSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public editorService: EditorService,
    private router: Router,
    private location: Location,
    private providerService: ProviderService,
    public authService: AuthService,
    private productService: ProductService,
    private categoryService: CategoryService,
    private messageService: MessageService,
    public navigationService: NavigationService,
    public activatedRoute: ActivatedRoute
  ) {
    super();
    this.initialized = false;
  }

  ngOnInit() {
    this.navigationService.title = 'Produkt erstellen';
    this.productShortcuts = [];
    this.navigationService.startLoading('product_create_init');

    // default product
    this.product = {
      ID: '',
      Modell: this.authService.isBroker() ? 'BUSINESS' : 'BUSINESS_PRO',
      Status: 'ENTWURF',
      AnbieterID: '',
      AnbieterName: '',
      ProduktID: '',
      Bezeichnung: 'Titel',
      Beschreibung: 'Untertitel',
      ProduktBezeichnung: '',
      Individuell: false,
      Erstelldatum: moment().toDate(),
      Aenderungsdatum: null,
      Autor: '',
      Version: 1,
      TarifrechnerVersionMin: '',
      TarifrechnerVersionMax: '',
      Beginn: moment().toDate(),
      Ablauf: moment('2099-01-01').toDate(),
      Suchbegriffe: [],
      Kategorien: [],
      Stages: [
        'Entwicklung'
      ],
      Highlights: [
        { Text: 'Highlight1' },
        { Text: 'Highlight2' },
        { Text: 'Highlight3' },
        { Text: 'Highlight4' },
        { Text: 'Highlight5' }
      ],
      Filter: {},
      Leistungen: {},
      Defaults: {},
      Auftrag: '',
      AbrechnungsfreiBis: moment('1970-01-01').toDate(),
      Mapping: 'if (this.VermittlernummerVU)\n' +
               '\tinput.URL = input.URL.replace(\'$VermittlernummerVU\', this.VermittlernummerVU);\n' +
               'if (this.VermittlernummerVM)\n' +
               '\tinput.URL = input.URL.replace(\'$VermittlernummerVM\', this.VermittlernummerVM);\n' +
               'return input;',
      Berechtigungen: ['ROLE_BROKER', 'ROLE_CUSTOMER'],
      BusinessPlusParameter: {
        LinkID: 0,
        Wert: ''
      }
    };

    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {
      forkJoin([
        params['productId'] ? this.productService.loadProduct(params['productId'], true) : of(null)
      ]).subscribe((copyResult) => {
        this.navigationService.stopLoading('product_create_init');

        if (copyResult[0]) {
          this.product = copyResult[0];
          this.product['ID'] = '';
          this.product['Status'] = 'ENTWURF';
          this.product['Erstelldatum'] = moment().toDate();
          this.product['Aenderungsdatum'] = null;
          this.product['Version'] = 1;
          this.product['TarifrechnerVersionMin'] = '';
          this.product['TarifrechnerVersionMax'] = '';
          this.product['Beginn'] = moment().toDate();
          this.product['Ablauf'] = moment('2099-01-01').toDate();
          this.product['Stages'] = ['Entwicklung'];
          this.product['AbrechnungsfreiBis'] = moment('1970-01-01').toDate();

          this.previousModel = this.product.Modell;
        }

        /* TODO:  productShortcuts mit möglichen Produkt Anfängen füllen via. Backend */
        this.productShortcuts = [];

        const _this = this;
        this.categoryService.loadCategoryList(true, true).subscribe(categoryList => {
          this.finalizeInit(this.providerService.providerFilterList.slice(1), categoryList, false, function() {
            _this.initialized = true;
          });
        });
      });
    });

    this.isEnterprise = this.authService.isEnterprise();
    this.isBroker = this.authService.isBroker();
    this.isRoot = this.authService.isSystem();
  }

  finalizeInit(providers: Array<Provider>, categories: Array<Category>, isRetry: boolean, callback: any): void {
    // Anbieter
    const anbieterOptions = providers.slice();
    let containsAnbieterID = null;

    if (this.product.AnbieterID) {
      containsAnbieterID = false;
      for (let i = 0; i < anbieterOptions.length; i++) {
        if (anbieterOptions[i].ID === this.product.AnbieterID) {
          containsAnbieterID = true;
        }
      }
    }

    if ((anbieterOptions.length === 0 || containsAnbieterID === false) && !isRetry) {
      this.providerService.setupProviderList().subscribe(() => {
        this.finalizeInit(this.providerService.providerFilterList.slice(1), categories, true, callback);
      });
      return;
    }

    // Anbieter Vorauswahl
    if (!this.product.AnbieterID && anbieterOptions.length === 1) {
      this.product.AnbieterID = anbieterOptions[0].ID;
      this.product.AnbieterName = anbieterOptions[0].Name;
      this.product.AnbieterRolle = anbieterOptions[0].AnbieterRolle;
    }

    // Kategorien
    let hasSnooprSelected = false;
    let hasEnterpriseSelected = false;

    if (this.product.Kategorien) {
      for (let index = 0; index < this.product.Kategorien.length; index++) {
        const element = this.product.Kategorien[index];
        const split = /^(.{1,})-(.*)$/.exec(element.ID);
        if (split != null && split.length === 3) {
          const consumerSuffix = split[2];
          if (consumerSuffix !== 'SNOOPR') {
            hasEnterpriseSelected = true;
          } else {
            hasSnooprSelected = true;
          }
        }
      }
    }

    this.hasOnlySnooprCategories = true;
    categories.forEach((category: Category) => {
      if (category.ID === 'ALL' || category.ID === 'NONE') {
        return;
      }

      const isSnoopr = category.ID.indexOf('-SNOOPR') > 0;
      if (!isSnoopr && this.hasOnlySnooprCategories) {
        this.hasOnlySnooprCategories = false;
      }
    });

    // Produkt ID
    const presetProductId = this.product.ProduktID;

    // configure editor
    this.editor = new ProductCreateItemEditor(
      this,
      this.router,
      this.location,
      this.dialog,
      this.productService,
      this.messageService
    );
    this.editor.title = 'EDITOR';

      this.editor.steps.push({
        label: 'Modell auswählen',
        fields: [
          { // Tipp
            type: 'tip',
            templateOptions: {
              label: 'Tipps & Tricks',
              open: true,
              description: (!this.authService.isBroker() ? '<p>Kennen Sie das? Sie haben tolle Produkte mit innovativen Optionen, \
              aber im klassischen Versicherungsvergleich gehen Sie unter? Mit Snoopr® ist \
              das anders. Snoopr® ist kein Vergleicher, sondern eine Suchmaschine für \
              Versicherungen. Wir setzen auf künstliche Intelligenz, um das Unvergleichliche \
              zu finden und aktiv zu vermarkten. Je genauer Sie eine Zielgruppe adressieren, \
              desto höher wird unsere KI Ihr Produkt im Ranking positionieren. Es ist also \
              empfehlenswert, ein Produkt in mehreren Ausprägungen für bestimmte Zielgruppen \
              zu hinterlegen.</p> \
              <p>Beispiel: Natürlich können Sie Ihre Privathaftpflichtversicherung als \
              Standardprodukt hinterlegen. Aber wie wäre es, wenn Sie \
              verschiedene Pakete schnüren? Nein, nicht Pakete wie "Klassik", "Komfort" oder \
              "Top". Schnüren Sie doch eine "Privathaftpflicht für Vermieter" und schließen \
        Sie das Vermieterrisiko in diesem Fall mit ein. Dieses Prinzip ähnelt sehr dem \
        Konzeptverkauf. Heben Sie diese Besonderheiten \
              in den Produkthighlights hervor und hinterlegen Sie Informationen, die für \
              Vermieter interessant sind. Unsere KI wird das mit einem höheren Snoopr-Faktor \
              belohnen.</p>'
              :
              '<p>Hier kannst du deine eigenen Produkte hinterlegen. Dabei kann es sich um \
              eigene Deckungskonzepte handeln, die du selbst mit einem oder mehreren Versicherern \
              oder Assekuradeuren ausgehandelt hast oder auch um Standardprodukte, für die du \
              vielleicht besondere Klauseln und Konditionen vereinbart hast oder um klassische \
              Produkte von Produktgebern, für die zwar keine Besonderheiten gelten, du aber aufgrund \
              deines Spezialwissens als Experte anderen Maklern und Verbrauchern gegenüber \
              auftreten möchtest.</p>')
            }
          },

          {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [

            {
              className: 'flex-1',
              key: 'Modell',
              type: 'select',
              templateOptions: {
                label: 'Modell',
                floatLabel: 'always',
                appearance: 'outline',
                required: true,
                options: [
                  { value: 'BUSINESS', label: 'BUSINESS (E-Mail-/Chat-basierter Workflow)', disabled: this.isEnterprise },
                  { value: 'BUSINESS_PLUS', label: 'BUSINESS PLUS (Tarifrechner-Verlinkung)', disabled: this.isEnterprise || this.isBroker},
                  { value: 'BUSINESS_PRO', label: 'BUSINESS PRO (vollintegrierte Folgeprozesse / API)', disabled: false || this.isBroker }
                ],
                description: 'Modellbeschreibung: www.snoopr.de/vertrieb/' + (!this.isBroker ? 'versicherer' : 'vermittler')
              },
              defaultValue: 'BUSINESS'
            },

            {
              className: 'flex-1 padding-top-4',
              template: 'Es gelten unsere <a target="_blank" href="https://www.snoopr.de/nutzungsbedingungen/console">Nutzungsbedingungen \
                für die Search Console</a> und die <a target="_blank" href="https://www.snoopr.de/nutzungsbedingungen/werbeflaechen"> \
                Bedingungen für Werbeflächen und Preise</a> (Zugangscode: o4sc$now)</a>.',
              hideExpression: () => this.authService.isEnterprise()
            }, {
              className: 'flex-1 padding-top-4',
              template: 'Es gelten unsere <a target="_blank" href="https://www.b-tix.de/nutzungsbedingungen/console">Nutzungsbedingungen \
                für die Search Console</a> und die <a target="_blank" href="https://www.b-tix.de/nutzungsbedingungen/werbeflaechen"> \
                Bedingungen für Werbeflächen und Preise</a> (Zugangscode: o4sc$now)</a>.',
              hideExpression: () => !this.authService.isEnterprise()
            }
          ]
        }]
      });
    // }
    this.editor.steps.push({
      label: 'Zuordnungsdaten festlegen',
      fields: [
        { // Tipp
          type: 'tip',
          templateOptions: {
            label: 'Tipps & Tricks',
            open: true
          },
          expressionProperties: {
            'templateOptions.description': () => {
              if (this.product.Modell === 'BUSINESS_PRO' || !this.hasOnlySnooprCategories) {
                return '<p>Jedes Produkt wird im System eindeutig gekennzeichnet. Die Identifikation erfolgt \
                  über einen zusammen gesetzten Schlüssel bestehend aus der Kennung des Anbieters \
                  und der des Produktes. Während die Kennung des Anbieters und die für Sie verfügbaren \
                  Anbieter bei der Anlage Ihres Benutzerkontos bereits von uns festgelegt \
                  wurden, haben Sie die Möglichkeit, die Kennung des Produktes (Produkt-ID) \
                  selbstständig zu vergeben.</p>\
                  <p>Die Produkt-ID besteht aus zwei Teilen, die durch einen Punkt (.) voneinander getrennt werden. Der \
                  erste Teil vor dem Punkt bezeichnet die Produktkennung, die wir im Falle des Modells BUSINESS PRO \
                  bereits während der technischen Anbindung Ihrer Schnittstelle festgelegt haben und entsprechend für \
                  Ihre Produkte fest vergeben sind. Wenn Sie nicht sicher sind, wie Ihre Kennung \
                  lautet, wenden Sie sich bitte an \
                  <a href="mailto:support@snoopr.de">support@snoopr.de</a>. Den zweiten Teil \
                  nach dem Punkt können Sie individuell vergeben. Wählen Sie eine sinnvolle ID. Beim Modell BUSINESS\
                  wird sie auch in der Kommunikation verwendet, damit Sie immer eindeutig feststellen können,\
                  auf welches Produkt sich Anfragen beziehen.</p>\
                  <p>Bitte beachten Sie, dass diese Identifikation - bestehend aus Anbieter- und \
                  Produktkennung - nach der Anlage nicht mehr änderbar ist.</p>';
              }
              if (this.isBroker || this.isRoot) {
                return '<p>Jedes Produkt muss eine eindeutige Bezeichnung (Produkt-ID) haben. Du kannst \
                  eine beliebige Produkt-ID ausdenken. Jedes Produkt kann genau einer Produktkategorie zugeordnet \
                  werden. Bitte wähle eine Kategorie, die deinem Produkt bzw. Deckungskonzept am nächsten \
                  kommt. Dein Produkt wird in der Suchmaschine mit Hinweis auf dich als Anbieter gelistet.</p>';
              }
              return '<p>Jedes Produkt muss eine eindeutige Bezeichnung (Produkt-ID) haben. Sie können \
                sich eine beliebige Produkt-ID ausdenken (z.B. interne Produktbezeichnung). Jedes \
                Produkt kann genau einer Produktkategorie zugeordnet werden. Bitte wählen Sie eine \
                Kategorie, die Ihrem Produkt bzw. Deckungskonzept am nächsten kommt.</p>';
            }
          }
        },

        {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'Anbieter',
            type: 'select',
            templateOptions: {
              label: 'Anbieter',
              floatLabel: 'always',
              appearance: 'outline',
              required: true,
              options: anbieterOptions,
              disabled: anbieterOptions.length === 1,
              focus: () => { },
              blur: () => { },
              labelProp: (item) => item.Name + ' (' + item.ID + ')',
              valueProp: (item) => item,
              compareWith: (o1, o2) => {
                if (o1 === null || o2 === null) {
                  return o1 === o2;
                }
                return o1.ID === o2.ID;
              },
              change: (event) => {
                this.product.AnbieterID = event.formControl.value.ID;
                this.product.AnbieterName = event.formControl.value.Name;
                this.product.AnbieterRolle = event.formControl.value.AnbieterRolle;
              }
            },
            validators: {
              required: {
                expression: (field) => {
                  return field.value.ID.length > 0;
                },
                message: () => 'Bitte Anbieter auswählen.'
              }
            },
            defaultValue: ''
          },

          {
            className: 'flex-1',
            key: 'ProduktID',
            type: 'autocomplete',
            templateOptions: {
              label: 'Produkt-ID (eindeutige Bezeichnung)',
              floatLabel: 'always',
              appearance: 'outline',
              placeholder: 'z.B. PHV.Vermieter',
              // tslint:disable-next-line:max-line-length
              description: 'Eine Produkt-ID besteht aus mindestens einem großen alphanumerischen Zeichen (A-Z, 0-9), einem Punkt (.) und mindestens einem weiteren alphanumerischen Zeichen (A-Z, a-z, 0-9). Beispiel: PHV.Vermieter',
              filter: (term) => this.filterProductShortcuts(term, this.product.AnbieterID)
            },
            hideExpression: () => this.product.Modell !== 'BUSINESS_PRO' && this.hasOnlySnooprCategories,
            expressionProperties: {
              'templateOptions.required': () => !(this.product.Modell !== 'BUSINESS_PRO' && this.hasOnlySnooprCategories)
            },
            validators: {
              ip: {
                expression: (c) => !c.value || /^[A-Z,0-9]{1,}\.[A-z,0-9]{1,}$/.test(c.value),
                // tslint:disable-next-line:max-line-length
                message: (error, field) => 'Eine Produkt-ID besteht aus mindestens einem großen alphanumerischen Zeichen (A-Z, 0-9), einem Punkt (.) und mindestens einem weiteren alphanumerischen Zeichen (A-Z, a-z, 0-9). Beispiel: PHV.Vermieter',
              },
              notSame: {
                expression: (c) => !presetProductId || c.value !== presetProductId,
                message: (error, field) => 'Diese Produkt-ID ist bereits vergeben.',
              },
              notInUse: {
                expression: (c) => {
                  const products = []; // TODO: this.productService.getBareProducts();
                  const currentId = ((this.product.AnbieterID ? this.product.AnbieterID : '') + '.' + c.value).trim();
                  for (let i = 0; i < products.length; i++) {
                    if (products[i].ID === currentId) {
                      return false;
                    }
                  }
                  return true;
                },
                message: (error, field) => 'Diese Produkt-ID ist bereits vergeben.',
              },
            }
          }
        ]
      },

      { // Überschrift
        template: '<br/><p><strong>Produkt einer Produktkategorie zuordnen</strong><br/>&nbsp;</p>',
        hide: this.hasOnlySnooprCategories,
      },

      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [

          { // Verkaufsprodukt
            key: 'KategorieEnterprise',
            type: 'category-select',
            className: 'flex-1',
            templateOptions: {
              single: true,
              required: !this.hasOnlySnooprCategories,
              label: 'Verknüpfung zur Produktkategorie (Verkaufsprodukt)',
              floatLabel: 'always',
              appearance: 'outline',
              description: 'Verknüpfen Sie das Produkt mit einem Kategorie (auch Verkaufsprodukt genannt), \
                in der es gelistet werden soll. Überlicherweise wird ein Produkt \
                nur in einer Kategorie gelistet. Es kann sein, dass Ihnen nicht \
                alle Kategorien zur Verfügung stehen. ',
              categories: new Observable(subscriber => {
                const result = [];

                categories.forEach((category: Category) => {
                  if (category.ID === 'ALL' || category.ID === 'NONE') {
                    return;
                  }
            
                  if (category.ID.indexOf('-SNOOPR') >= 0) {
                    return;
                  }
                  console.log('Category: ', category);
                  result.push({
                    value: category.ID,
                    group: category.ProduktgruppenID,
                    label: category.Bezeichnung + ' (' + category.ID + ')',
                    disabled: false,
                    selected: (<any>this.product).KategorieEnterprise === category.ID
                  });
                });

                subscriber.next(result);
                subscriber.complete();
              })
            },
            hide: this.hasOnlySnooprCategories,
            defaultValue: []
          }
        ]
      },

      { // Überschrift
        template: '<br/><p><strong>Produkt in Snoopr-Kategorie zuordnen</strong></p>',
        hideExpression: () => !this.isRoot || this.hasOnlySnooprCategories
      },

      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [

          { // Verkaufsprodukt
            key: 'KategorieSnoopr',
            type: 'category-select',
            className: 'flex-1',
            templateOptions: {
              single: true,
              required: !this.isEnterprise || (this.isRoot && !this.hasOnlySnooprCategories),
              label: 'Verknüpfung zur Produktkategorie (Verkaufsprodukt)',
              floatLabel: 'always',
              appearance: 'outline',
              isEdit: false,
              description: 'Verknüpfe das Produkt mit einer Produktkategorie, \
                in der es gelistet werden soll. Wähle die Kategorie, die deinem Produkt am nächsten kommt.',
              categories: new Observable(subscriber => {
                const result = [];

                categories.forEach((category: Category) => {
                  if (category.ID === 'ALL' || category.ID === 'NONE') {
                    return;
                  }
            
                  if (category.ID.indexOf('-SNOOPR') < 0) {
                    return;
                  }
                  result.push({
                    value: category.ID,
                    group: category.ProduktgruppenID,
                    label: category.Bezeichnung,
                    disabled: false,
                    selected: (<any>this.product).KategorieSnoopr === category.ID
                  });
                });

                subscriber.next(result);
                subscriber.complete();
              })
            },
            hide: this.isEnterprise,
            defaultValue: []
          }
        ]
      }, {
        template: '<br>&nbsp;<br>'
      }, {
        key: 'ProduktIDShort',
        type: 'input',
        templateOptions: {
          label: 'Eindeutige Produktkennung (Produkt-ID)',
          floatLabel: 'always',
          appearance: 'outline',
          placeholder: 'z.B. Cyperkonzept',
          // tslint:disable-next-line:max-line-length
          description: 'Anhand dieser kurzen Bezeichnung kannst du dein Produkt von anderen unterscheiden. Du kannst sie dir selbst ausdenken.',
        },
        hideExpression: () => !this.hasOnlySnooprCategories || this.product.Modell === 'BUSINESS_PRO',
        expressionProperties: {
          'templateOptions.required': () => !(!this.hasOnlySnooprCategories || this.product.Modell === 'BUSINESS_PRO')
        },
        validators: {
          ip: {
            expression: (c) => !c.value || /^[A-Z,0-9]{1,}[A-z,0-9]{1,}$/.test(c.value),
            // tslint:disable-next-line:max-line-length
            message: (error, field) => 'Eine Produkt-ID beginnt mit einem Großbuchstaben und darf keine Sonderzeichen, Umlaute oder Leerzeichen besitzen.',
          }
        }
      }]
    });

    if (this.authService.isSystem()) {
      this.editor.steps.push({
        label: 'Abrechnung',
        fields: [
          {
            key: 'AbrechnungsfreiBis',
            type: 'extended-datepicker',
            templateOptions: {
              label: 'Abrechnungsfrei bis',
              floatLabel: 'always',
              placeholder: 'TT.MM.JJJJ',
              appearance: 'outline',
              description: 'Bis wann das Produkt abrechnungsfrei sein soll.',
              required: true,
              datepickerOptions: {
                startAt: moment(),
                startView: 'month',
                touchUi: true
              }
            }
          }
        ]
      });
    }

    if (!this.authService.isSystem()) {
      this.editor.steps.push({
        label: 'Kostenpflichtig bestellen',
        fields: [
          {
            template: '<p><strong>Bedingungen</strong></p>\
              <p>Für die Anlage von neuen Produkten gelten die <a href="https://www.snoopr.de/nutzungsbedingungen/console" \
              target="_blank">Nutzungsbedingungen für die Search Console</a> und die \
              <a href="https://www.snoopr.de/nutzungsbedingungen/werbeflaechen" \
              target="_blank">Bedingungen für Werbeflächen und Preise</a> \
              in ihrer jeweils zuletzt gültigen Fassung (Zugangscode: o4sc$now).</p>',
            hideExpression: () => this.authService.isEnterprise()
          },

          {
            template: '<p><strong>Bedingungen</strong></p>\
              <p>Für die Anlage von neuen Produkten gelten die <a href="https://www.b-tix.de/nutzungsbedingungen/console" \
              target="_blank">Nutzungsbedingungen für die Search Console</a> und die \
              <a href="https://www.b-tix.de/nutzungsbedingungen/werbeflaechen" \
              target="_blank">Bedingungen für Werbeflächen und Preise</a> \
              in ihrer jeweils zuletzt gültigen Fassung (Zugangscode: o4sc$now).</p>',
            hideExpression: () => !this.authService.isEnterprise()
          },

          {
            template: '<p>&nbsp;<br/><strong>Erfolgsabhängige Gebühren für Klicks</strong></p>\
			<p>Für die Dauer des Mietverhältnisses erheben wir eine erfolgsabhängige Vergütung \
			für den Klick eines Nutzers auf die Schaltfläche "Preis anfragen" innerhalb der Werbefläche. \
			Der Cost per Click (CPC) beträgt 1 Euro zzgl. der zum Zeitpunkt der Erhebung gültigen \
			gesetzlichen Mehrwertsteuer. Ein pauschaler monatlicher Mietzins gilt darüber hinaus \
			nicht als vereinbart.</p>',
            hideExpression: (model) => (model.Modell === 'BUSINESS' ? false : true)
          },

          {
            template: '<p>&nbsp;<br/><strong>Erfolgsabhängige Gebühren für Klicks</strong></p>\
			<p>Für die Dauer des Mietverhältnisses erheben wir eine erfolgsabhängige Vergütung \
			für den Klick eines Nutzers auf die Schaltfläche "Preis berechnen" innerhalb der Werbefläche. \
			Der Cost per Click (CPC) beträgt 1 Euro zzgl. der zum Zeitpunkt der Erhebung gültigen \
			gesetzlichen Mehrwertsteuer. Ein pauschaler monatlicher Mietzins gilt darüber hinaus \
			nicht als vereinbart.</p>',
            hideExpression: (model) => (model.Modell === 'BUSINESS_PLUS' ? false : true)
          },

          {
            template: '<p>&nbsp;<br/><strong>Pauschale Gebühr für die Produktplatzierung</strong></p>\
			<p>Für die Dauer des Mietverhältnisses erheben wir einen pauschalen monatlichen Mietzins \
			für die Werbefläche in Höhe von 35 Euro zzgl. der zum Zeitpunkt der Erhebung gültigen \
			gesetzlichen Mehrwertsteuer. Eine gesondere Vergütung für den Klick eines Nutzers auf \
			eine Schaltfläche innerhalb der Werbefläche gilt nicht als vereinbart.</p><p>Unberührt von \
			dieser Regelung bleiben die Kosten für die einmalige Integration und fortlaufende Wartung \
			der technischen Schnittstelle, die in einer gesonderten Anbindungsvereinbarung zu regeln \
			sind.</p><p>Mit der Speicherung Ihres Produktes stimmen Sie diesen Bedingungen zu. \
            Bitte beachten Sie, dass die Abrechnung der Gebühren für das neue Produkt \
            beginnt, sobald das Produkt gespeichert und damit angemeldet wurde. Die Abrechnung \
            ist unabängig vom Zeitpunkt der Veröffentlichung Ihres Produktes und endet \
            mit Ablauf der Veröffentlichung oder wenn Sie das Produkt aktiv löschen.</p>',
            hideExpression: (model) => (model.Modell === 'BUSINESS_PRO' ? false : true)
          },

          {
            key: 'Bestaetigung',
            type: 'checkbox',
            templateOptions: {
              label: 'Hiermit bestätige ich, dass ich die Bedingungen und Kosten akzeptiere.',
              // description: 'In order to proceed, please accept terms',
              indeterminate: false,
              pattern: 'true',
              required: true,
            },
            validation: {
              messages: {
                pattern: 'Bedingungen und Kosten nicht akzeptiert.',
              }
            }
          }
        ]
      });
    }

    if (typeof callback === 'function') {
      callback();
    }
  }

  filterProductShortcuts(name: string, provider: string): string[] {
    if (name && name.length !== 0) {
      return this.productShortcuts.filter((shortcut: {short: string; provider: string}) => {
        // tslint:disable-next-line:max-line-length
        return shortcut.short.length > name.length && shortcut.short.toLowerCase().indexOf(name.toLowerCase()) === 0 && (provider.length === 0 || provider === shortcut.provider);
      }).map((shortcut: {short: string; provider: string}) => shortcut.short).sort();
    }
    return this.productShortcuts.filter((shortcut: {short: string; provider: string}) => {
      return provider.length === 0 || provider === shortcut.provider;
    }).map((shortcut: {short: string; provider: string}) => shortcut.short).sort();
  }
}
