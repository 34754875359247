import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewChecked } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { QuillEditorComponent } from 'ngx-quill';

import { AuthService } from '../../services/auth/auth.service';

import Quill from 'quill';
import { NotifyService, NotifyType } from 'src/app/services/notify/notify.service';

const Inline = Quill.import('blots/inline');
const Block = Quill.import('blots/block');

class SimpleHeadlineBlot extends Block {
  static create(url) {
    const node = super.create();
    return node;
  }
}
SimpleHeadlineBlot.blotName = 'simple-headline';
SimpleHeadlineBlot.tagName = 'h1';
Quill.register(SimpleHeadlineBlot);

class BosTitleBlot extends Block {
  static create(url) {
    const node = super.create();
    node.setAttribute('class', 'bos-salesproduct-title');
    return node;
  }
}
BosTitleBlot.blotName = 'bos-title';
BosTitleBlot.tagName = 'h1';
BosTitleBlot.className = 'bos-salesproduct-title';
Quill.register(BosTitleBlot);

class BosInfoBlot extends Block {
  static create(url) {
    const node = super.create();
    node.setAttribute('class', 'bos-salesproduct-info');
    return node;
  }
}
BosInfoBlot.blotName = 'bos-info';
BosInfoBlot.tagName = 'p';
BosInfoBlot.className = 'bos-salesproduct-info';
Quill.register(BosInfoBlot);

class BosExamplesBlot extends Inline {
  static create(url) {
    const node = super.create();
    node.setAttribute('class', 'bos-salesproduct-examples');
    return node;
  }
}
BosExamplesBlot.blotName = 'bos-examples';
BosExamplesBlot.tagName = 'ul';
BosExamplesBlot.className = 'bos-salesproduct-examples';
Quill.register(BosExamplesBlot);

class BosExample1Blot extends Inline {
  static create(url) {
    const node = super.create();
    node.setAttribute('class', 'bos-salesproduct-example-1');
    return node;
  }
}
BosExample1Blot.blotName = 'bos-example-1';
BosExample1Blot.tagName = 'li';
BosExample1Blot.className = 'bos-salesproduct-example-1';
Quill.register(BosExample1Blot);

class BosExample2Blot extends Inline {
  static create(url) {
    const node = super.create();
    node.setAttribute('class', 'bos-salesproduct-example-2');
    return node;
  }
}
BosExample2Blot.blotName = 'bos-example-2';
BosExample2Blot.tagName = 'li';
BosExample2Blot.className = 'bos-salesproduct-example-2';
Quill.register(BosExample2Blot);


const icons = Quill.import('ui/icons');
icons['header'][3] = require('!html-loader!quill/assets/icons/header-3.svg');
icons['simple-headline'] = 'Überschrift';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-quill-type',
  templateUrl: './form-type-quill.component.html',
  styleUrls: ['./form-type-quill.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FormTypeQuillComponent extends FieldType implements OnInit, AfterViewChecked {
  @ViewChild(QuillEditorComponent) public quillEditor: QuillEditorComponent;

  public modules: Object;
  public editorContent: string;

  public stopApplingChanges = false;
  public preventChangeLoop = false;
  public focusOnValueChange = true;

  constructor(
    public authService: AuthService,
    private notifyService: NotifyService
  ) {
    super();

    this.modules = {
      syntax: false,
      clipboard: {
        matchVisual: false
      }
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.editorContent = this.value;

    const _this = this;
    this.formControl.valueChanges.subscribe((value) => {
      if (typeof value === 'object') {
        _this.focusOnValueChange = value.focus || false;
        _this.formControl.patchValue(value.value);
        return;
      }

      if (!_this.preventChangeLoop) {
        _this.stopApplingChanges = true;
        _this.quillEditor.quillEditor.deleteText(0, _this.quillEditor.quillEditor.getLength() - 1);
        _this.stopApplingChanges = false;
        _this.quillEditor.quillEditor.pasteHTML(value);
      }
      _this.preventChangeLoop = false;
    });
  }

  change(event) {
    if (typeof this.to.maxTextLength !== 'undefined') {
      if (event.editor.getLength() - 1 > this.to.maxTextLength) {
        if (event.delta.ops.length > 0 && typeof event.delta.ops[0].retain !== 'undefined') {
          let length = 0;
          for (let i = 1; i < event.delta.ops.length; i++) {
            if (typeof event.delta.ops[i].insert !== 'undefined') {
              length += event.delta.ops[i].insert.length;
            }
            if (typeof event.delta.ops[i].retain !== 'undefined') {
              break;
            }
          }
          const from = (event.delta.ops[0].retain + length) - ((event.editor.getLength() - 1) - this.to.maxTextLength);
          const to = ((event.editor.getLength() - 1) - this.to.maxTextLength);
          // DeleteText löst change erneut aus
          event.editor.deleteText(from, to);
          setTimeout(() => { event.editor.setSelection(from, 0); }, 5);

          if (length > 2) {
            this.notifyService.notify(NotifyType.Warning, 'Maximale Textlänge überschritten. Der Text wurde gekürzt!');
          } else {
            this.notifyService.notify(NotifyType.Warning, 'Maximale Textlänge erriecht.');
          }
          return;
        }
      }
    }

    let value = event.editor.root.innerHTML;
    if (this.to.prefixTag) {
      value = this.to.prefixTag + value;
    }
    if (this.to.suffixTag) {
      value = value + this.to.suffixTag;
    }

    if (!this.stopApplingChanges) {
      this.preventChangeLoop = true;
      this.formControl.setValue(value);
    }

    if (this.focusOnValueChange) {
      this.focusOnValueChange = false;
      setTimeout(() => { event.editor.setSelection((event.editor.getLength() - 1), 0); }, 50);
    }
  }

  containsTool(toolNames) {
    if (typeof this.to.tools === 'undefined') {
      return true;
    }

    if (typeof toolNames === 'string') {
      return this.to.tools.indexOf(toolNames) >= 0;
    }

    for (let i = 0; i < toolNames.length; i++) {
      if (this.to.tools.indexOf(toolNames[i]) !== -1) {
        return true;
      }
    }
    return false;
  }

}
