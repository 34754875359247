import { Component, OnDestroy, ViewChild } from '@angular/core';
import { SearchFieldComponent } from '../search-field/search-field.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Category } from '../../classes/category';

import { ItemList } from '../../classes/item-list';

import { NavigationService } from '../../services/navigation/navigation.service';
import { CategoryService } from '../../services/category/category.service';
import { AuthService } from '../../services/auth/auth.service';
import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent extends ItemList<Category> implements OnDestroy {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  private categoryListChangeSubscription: Subscription;

  constructor(
    private router: Router,
    public navigationService: NavigationService,
    private categoryService: CategoryService,
    public authService: AuthService,
    public messageService: MessageService
  ) {
      super('category', navigationService, messageService, 'Kategorien verwalten');
      this.categoryListChangeSubscription = this.categoryService.categoryListChange$.subscribe(_ => {
        this.initList();
      });
      this.navigationService.startLoading('category_initial_list_loading');
      this.categoryService.setupCategoryList().subscribe(_ => {
          this.navigationService.stopLoading('category_initial_list_loading');
      });
  }

  ngOnDestroy() {
    this.categoryListChangeSubscription.unsubscribe();
  }

  addCategory(): () => void {
    return (() => {
      this.router.navigate(['/category-create']);
    });
  }

  loadItems(limit: number, offset: number) {
    const filteredCategories = this.categoryService.categoryList.filter((category) => {
      if (category.ID === 'ALL') {
        return false;
      }
      const searchFilterText = this.searchField.searchTerm;
      return (searchFilterText.length === 0 || this.categoryContainsText(category, searchFilterText));
    });
    const paginatedCategories = filteredCategories.filter((_, index) => {
        return index >= offset && index < offset + limit;
    });

    return {items: paginatedCategories, maxCount: filteredCategories.length};
  }

  categoryContainsText(category: Category, text: string) {
    const texts = text.toLocaleLowerCase().split(' ');
    for (let i = 0; i < texts.length; i++) {
      if (
        category.Bezeichnung.toLocaleLowerCase().indexOf(texts[i]) !== -1 ||
        category.ID.toLocaleLowerCase().indexOf(texts[i]) !== -1
      ) {
        return true;
      }
    }
    return false;
  }
}
