import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation/navigation.service';

import { Category } from '../../classes/category';

@Component({
  selector: 'app-category-create-item',
  templateUrl: './category-create-item.component.html',
  styleUrls: ['./category-create-item.component.css']
})
export class CategoryCreateItemComponent implements OnInit {
  public category: Category;

  constructor(
    public navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.navigationService.title = 'Kategorie erstellen';
    this.navigationService.startLoading('category_create_init');

    this.category = {
      ID: '',
      Controller: '',
      Bezeichnung: '',
      Kurzbezeichnung: '',
      Highlights: '',
      MediaSet: null,
      ProduktgruppenID: null,
      ProduktgruppenSortierung: null,
      Leistungen: []
    };

    this.navigationService.stopLoading('category_create_init');
  }

}
