import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import { Consumer } from '../../classes/consumer';

@Component({
  selector: 'app-formly-consumer-section',
  templateUrl: './form-type-consumer.component.html',
  styleUrls: ['./form-type-consumer.component.css'],
})

export class FormTypeConsumerComponent extends FieldType implements OnInit {

  public consumer: Consumer;

  constructor() {
    super();
  }

  ngOnInit() {
    this.consumer = this.field.model;
  }
}
