import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.css']
})
export class SearchFieldComponent {

    @Output() public searchTermChange$: EventEmitter<string> = new EventEmitter();
    public searchTerm = '';
    @Input() public debounce = 0;
    @Input() public styles: any = {};

    private timeout: NodeJS.Timer;

    public updateSearchfilter(event: KeyboardEvent) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        const term = (<HTMLInputElement>event.target).value;
        if (event.key !== 'Enter' && this.debounce > 0) {
            this.timeout = setTimeout(_ => {
                this.searchTerm = term;
                this.searchTermChange$.next(term);
                this.timeout = null;
            }, this.debounce);
        } else {
            this.searchTerm = term;
            this.searchTermChange$.next(term);
        }
    }

    public clearSearchfilter() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.searchTerm === '') {
            return;
        }
        this.searchTerm = '';
        this.searchTermChange$.next('');
    }

}
