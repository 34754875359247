import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import { Provider } from '../../classes/provider';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'app-formly-provider-section',
  templateUrl: './form-type-provider.component.html',
  styleUrls: ['./form-type-provider.component.css'],
})

export class FormTypeProviderComponent extends FieldType implements OnInit {

  public provider: Provider;

  constructor(
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    this.provider = this.field.model;
    const providerImage = this.provider.Bild;
    this.provider.Bild = '';
    this.storageService.getSignedUrl(providerImage).subscribe(url => {
      this.provider.Bild = url;
    });
  }
}
