import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {

  @Input() public title: string;
  @Input() public actionTitle: string;
  @Input() public actionCallback: Function;

  loading = false;

  constructor() { }

  ngOnInit() { }

  doAction() {
    this.actionCallback();
  }
}
