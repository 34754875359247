import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _moment from 'moment';

import { SearchFieldComponent } from '../../search-field/search-field.component';
import { FilterComponent } from '../../filter/filter.component';
import { ProductSelectDialog } from '../../../classes/product-select-dialog';
import { ProductService } from '../../../services/product/product.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { Product } from '../../../classes/product';
import { ItemList } from 'src/app/classes/item-list';
import { map } from 'rxjs/operators';
import { MessageService } from 'src/app/services/message/message.service';
import { forkJoin, of } from 'rxjs';
import { NotifyService, NotifyType } from 'src/app/services/notify/notify.service';

const moment = _moment;

@Component({
  selector: 'app-product-select-dialog',
  templateUrl: './product-select-dialog.component.html',
  styleUrls: ['./product-select-dialog.component.css']
})
export class ProductSelectDialogComponent extends ItemList<Product> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;
  @ViewChild(FilterComponent) public filter: FilterComponent;

  public selectedProductList: Array<Product> = [];
  public isSubmitting: boolean;

  constructor(
    public productService: ProductService,
    public navigationService: NavigationService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<ProductSelectDialogComponent>,
    public notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: ProductSelectDialog
  ) {
    super('product_select', navigationService, messageService);
  }

  onCloseClick(apply): void {
    if (apply && this.selectedProductList.length !== 0) {
      const loadProductJoin = [];
      this.isSubmitting = true;
      for (let i = 0; i < this.selectedProductList.length; i++) {
        loadProductJoin.push(this.productService.loadProduct(this.selectedProductList[i].ID));
      }
      forkJoin(loadProductJoin).subscribe((results) => {
        this.dialogRef.close(results);
        this.isSubmitting = false;
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  getProductVisibility(product: Product) {
    if (!product.Beginn) {
      product.Beginn = moment().toDate();
    }
    if (!product.Ablauf) {
      product.Ablauf = moment('2099-12-31').toDate();
    }
    return moment() >= moment(product.Beginn) && moment() < moment(product.Ablauf);
  }

  loadItems(limit: number, offset: number) {
    
    if (this.filter.selectedSelectionTypeId == 'SELECTED') {
      return of(this.selectedProductList.slice(offset, offset + limit)).pipe(map(data => {
        return {
          items: data,
          maxCount: this.selectedProductList.length
        }
      }));
    }
    return this.productService.loadProductList(
        limit,
        offset,
        this.data.providerId,
        this.filter.selectedCategoryId,
        this.searchField.searchTerm || null,
        null,
        null,
        this.filter.selectedSortingId,
        this.data.excludeProductIds,
        this.data.includeModels).pipe(map(result => ({items: result.productList, maxCount: result.metaData.maxCount})));
  }

  clickProduct(product: Product) {
    for (let i = 0; i < this.selectedProductList.length; i++) {
      if (this.selectedProductList[i].ID === product.ID) {
        this.selectedProductList.splice(i, 1);
        return;
      }
    }

    if (typeof this.data.selectLimit !== 'undefined') {
      if (this.data.selectLimit === 1) {
        for (let i = 0; i < this.selectedProductList.length; i++) {
            this.selectedProductList.splice(i, 1);
        }
        this.selectedProductList.push(product);
        return;
      }

      if (this.selectedProductList.length >= this.data.selectLimit) {
        this.notifyService.notify(NotifyType.Warning, 'Es können nicht mehr als ' + this.data.selectLimit + ' Produkte ausgewählt werden.');
        return;
      }
    }
    this.selectedProductList.push(product);
  }

  isSelected(product: Product) {
    for (let i = 0; i < this.selectedProductList.length; i++) {
      if (this.selectedProductList[i].ID === product.ID) {
        return true;
      }
    }
    return false;
  }
}
