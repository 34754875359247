import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, from, of, throwError, Subscriber } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';

import { AmplifyService } from 'aws-amplify-angular';
import { Auth } from 'aws-amplify';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';
import { AuthState } from 'aws-amplify-angular/dist/src/providers/auth.state';

import { GenericDialog } from '../../classes/generic-dialog';
import { GenericDialogComponent } from '../../components/dialog/generic-dialog/generic-dialog.component';

import { NavigationService } from '../../services/navigation/navigation.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TOUDialog } from 'src/app/classes/tou-dialog';
import { TOUDialogComponent } from 'src/app/components/dialog/tou-dialog/tou-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

  private _touIsOpen: boolean;
  private _initialized: boolean;
  private _hasBrokerData: boolean;
  private _brokerData: any;
  get initialized(): boolean {
    return this._initialized;
  }

  get hasBrokerData(): boolean {
    return this._hasBrokerData;
  }

  private _allowedToNavigate: boolean;
  get allowedToNavigate(): boolean {
    return this._allowedToNavigate;
  }
  set allowedToNavigate(allowedToNavigate) {
    this._allowedToNavigate = allowedToNavigate;
  }

  private authState: AuthState = null;
  private session: CognitoUserSession = null;
  public authStateChange$: Subject<string>;

  private showLogoutDialog = false;
  private lastTokenExp = null;

  constructor(
    private amplifyService: AmplifyService,
    private navigationService: NavigationService,
    private router: Router,
    public dialog: MatDialog,
    private http: HttpClient
  ) {
    this._allowedToNavigate = true;
    this._initialized = false;
    this._hasBrokerData = false;
    this._touIsOpen = false;
    this._brokerData = {};

    this.navigationService.startLoading('auth_init');

    this.authStateChange$ = new Subject<string>();
    this.amplifyService.authStateChange$
      .subscribe((authState: AuthState) => {
        this.authState = authState;
        this.session = this.authState.user ? authState.user.signInUserSession : null;
        this.authStateChange$.next(this.authState.state);

    });
    this.getCurrentSession().then();
  }

  routeToLogout(): void {
    console.log('routeToLogout');

    if (this.showLogoutDialog) {
      return;
    }

    const genericDialog = new GenericDialog();
    genericDialog.hideCancel = true;
    genericDialog.titel = 'Keine gültige Session';
    genericDialog.text = 'Leider ist die Session ausgelaufen. Sie müssen sich neu anmelden.';
    this.showLogoutDialog = true;
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      maxWidth: '500px',
      data: genericDialog
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.showLogoutDialog = false;
        this.logOut();
        this.navigationService.stopLoading('_all');
        // OK -> route observable from mediaService
      }
    });
  }

  logOut(): void {
    Auth.signOut().then(data => {
      console.log(data);
      this.authState = null;
      this.session = null;
      this.lastTokenExp = null;
      this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
    }).catch(err => {
      console.log(err);
    });
  }

  signIn(username: string, password: string): Observable<any> {
    const _this = this;
    return new Observable((subscriber) => {
      from(Auth.signIn(username, password)).pipe(catchError(this.handleAmplifyError)).subscribe(
        (cognitoUser) => {
          if (cognitoUser.challengeName !== 'NEW_PASSWORD_REQUIRED' && !_this._touIsOpen) {
            _this.checkAndHandleTermsOfUsage().subscribe(() => {
              subscriber.next(cognitoUser);
              subscriber.complete();
            }, () => {
              subscriber.error({ code: 'TOUNotAccepted'});
              subscriber.complete();
            });
          } else {
            subscriber.next(cognitoUser);
            subscriber.complete();
          }
        },
        (cognitoError) => {
          subscriber.error(cognitoError);
          subscriber.complete();
        }
      );
    });
  }

  completeForgotPassword(username: string, resetcode: string, newPassword: string): Observable<void> {
    return from(Auth.forgotPasswordSubmit(username, resetcode, newPassword)).pipe(catchError(this.handleAmplifyError));
  }

  completeNewPassword(newPassword: string): Observable<any> {
    return from(Auth.completeNewPassword(this.authState.user, newPassword, {})).pipe(catchError(this.handleAmplifyError));
  }


  getCurrentUserName(): string {
    if (this.isLoggedIn() === false) {
      return null;
    }
    return this.authState.user.username;
  }

  getCurrentUserRole(): string {
    if (this.isLoggedIn() === false) {
      return null;
    }
    return this.session.getIdToken().payload['custom:role'];
  }

  getCurrentUserCustomerId(): string {
    return this.session.getIdToken().payload['custom:customer'];
  }

  getCurrentUserCustomerRole(): string {
    if (this.isLoggedIn() === false) {
      return '';
    }
    const arn = this.session.getIdToken().payload['custom:customer-role'];
    if (!arn) {
      return 'default';
    }
    return /.*\/ConsoleUserPool(\w+)Role/.exec(arn)[1].toLowerCase();
  }

  isDefault(): boolean {
    return this.getCurrentUserCustomerRole() === 'default';
  }

  isEnterprise(): boolean {
    return this.getCurrentUserCustomerRole() === 'enterprise';
  }

  isSystem(): boolean {
    return this.getCurrentUserCustomerRole() === 'system';
  }

  isBroker(): boolean {
    return this.getCurrentUserCustomerRole() === 'broker';
  }

  canEditProducts(): boolean {
    const userRole = this.getCurrentUserRole();
    return (userRole === 'writer' || userRole === 'admin') && (!this.isBroker() || this.isBrokerProvider());
  }

  canEditMedia(): boolean {
    const userRole = this.getCurrentUserRole();
    return (userRole === 'writer' || userRole === 'admin') && !this.isBroker();
  }

  canEditMediaSets(): boolean {
    const userRole = this.getCurrentUserRole();
    return (userRole === 'writer' || userRole === 'admin') && !this.isBroker();
  }

  canEditCategories(): boolean {
    const userRole = this.getCurrentUserRole();
    return (userRole === 'writer' || userRole === 'admin') && (this.isSystem() || this.isEnterprise());
  }

  isLoggedIn(): boolean {
    return this.session !== null && this.authState !== null;
  }

  getCurrentSession(): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session: CognitoUserSession) => {
        const firstSessionRequest = !this._initialized;
        this._initialized = true;

        if (session.isValid()) {
          const sessionExp = session.getAccessToken().getExpiration();
          this.session = session;
          if (this.lastTokenExp === null) {
            this.lastTokenExp = sessionExp;
          }
          if ((sessionExp !== this.lastTokenExp || firstSessionRequest) && this.isLoggedIn() && !this._touIsOpen) {
            this.checkAndHandleTermsOfUsage().subscribe(() => {
              this.lastTokenExp = sessionExp;
              resolve(session);
            }, () => {
              reject('Terms of usage not accepted.');
            });
          } else {
            resolve(session);
          }
        } else {
          reject('Session is invalid');
          this.routeToLogout();
        }
      }, (error) => {
        this._initialized = true;
        this.logOut();
      });
    });
  }

  setBrokerData(isProvider, isVerified, isEligibleToPublish) {
    this.navigationService.stopLoading('auth_init');
    this._hasBrokerData = true;
    this._brokerData = {
      isProvider: isProvider,
      isVerified: isVerified,
      isEligibleToPublish: isEligibleToPublish
    };
  }

  isBrokerProvider() {
    return this.isBroker() && this._brokerData.isProvider;
  }

  isBrokerVerified() {
    return this.isBroker() && this._brokerData.isVerified;
  }

  isBrokerEligibleToPublish() {
    return this.isBroker() && this._brokerData.isEligibleToPublish;
  }

  private handleAmplifyError(error: any): Observable<any> {
    const code = error.code;
    let message = null;
    switch (code) {
      case 'UserNotFoundException':
      case 'NotAuthorizedException':
        message = 'Benutzername oder Passwort nicht korrekt';
      break;
      case 'NotAuthorizedException':
        message = 'Der Benutzer wurde gesperrt';
      break;
      case 'CodeMismatchException':
        message = 'Das temporäre Passwort ist inkorrekt';
      break;
    }
    if (message == null) {
      return throwError(error);
    } else {
      return throwError({
        code: code,
        message: message,
        name: error.name
      });
    }
  }

  // TODO: Dialog anpassen
  showTermsOfUsageDialog(tou, changed) {
    return new Observable((observable) => {
      const touDialog = new TOUDialog();
      touDialog.tou = tou;
      touDialog.changed = changed;
      touDialog.isEnterprise = this.isEnterprise();
      const dialogRef = this.dialog.open(TOUDialogComponent, {
        maxWidth: '800px',
        data: touDialog,
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          observable.next();
        } else {
          observable.error();
        }
        observable.complete();
      });
    });
  }

  checkAndHandleTermsOfUsage() {
    const _this = this;
    return new Observable((subscriber) => {
      const timestampNow = (new Date()).getTime();
      function handleTermOfUsage(cognitoUser, logoutOnDecline, changed, touText, versionDate) {
        _this._touIsOpen = true;
        _this.showTermsOfUsageDialog(touText, changed).subscribe(() => {
          Auth.updateUserAttributes(cognitoUser, { 'custom:touAcceptedAt': versionDate.toString() });
          Auth.currentAuthenticatedUser({ bypassCache: true });

          _this._touIsOpen = false;
          subscriber.next();
          subscriber.complete();
        }, () => {
          _this._touIsOpen = false;
          if (logoutOnDecline) {
            _this.logOut();
            subscriber.error();
          } else {
            subscriber.next();
          }
          subscriber.complete();
        });
      }

      Auth.currentAuthenticatedUser({ bypassCache: true }).then((cognitoUser: CognitoUser) => {
        const attributes = (<any>cognitoUser).attributes;
        if (typeof attributes['custom:touAcceptedAt'] === 'undefined' || attributes['custom:touAcceptedAt'] === '0') {
          this.getTermsOfUsage().subscribe((res) => {
            handleTermOfUsage(cognitoUser, true, false, (<any>res).tou, (<any>res).versionDate);
          }, () => {
            console.error('Search Console Nutzungsbedingungen konnten nicht geladen werden.');
            _this.logOut();
            subscriber.error();
            subscriber.complete();
          });
          return;
        }

        this.getTermsOfUsage().subscribe((res) => {
          const lastTOUAcceptedAt = parseInt(attributes['custom:touAcceptedAt'], 10);
          if ((<any>res).versionDate > lastTOUAcceptedAt) {
            handleTermOfUsage(cognitoUser, (<any>res).versionDate - timestampNow < 0, true, (<any>res).tou, (<any>res).versionDate);
          } else {
            subscriber.next();
            subscriber.complete();
          }
        });
      });
    });
  }

  getTermsOfUsage() {
    return new Observable((subscriber) => {
      //const tosUrl = this.isEnterprise() ? 'https://www.b-tix.de/wp-json/wp/v2/pages/23779?password=o4sc$now' : 'https://www.snoopr.de/wp-json/wp/v2/pages/30084?password=o4sc$now';
      const tosUrl = 'https://www.snoopr.de/wp-json/wp/v2/pages/30084?password=o4sc$now';
      this.http.get(tosUrl).subscribe(
        (res) => {
          const content = (<any>res).content.rendered;
          const escaptedContent = content.replace(/\r?\n|\r/g, '');
          const regex = /version-date.+?(\d+-\d+-\d+)/g;
          const result = regex.exec(escaptedContent);
          let versionDate = 0;
          if (result !== null && result.length === 2) {
            versionDate = Date.parse(result[1]);
          }
          subscriber.next({ tou: content, versionDate: versionDate });
          subscriber.complete();
        },
        (err) => {
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
  }
}
