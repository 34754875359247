import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { ItemComponent } from './item-component';
import { EditorComponent } from '../components/editor/editor.component';

export interface StepType {
  label: string;
  html?: string;
  fields?: FormlyFieldConfig[];
}

export abstract class Editor<T extends ItemComponent, M> {

    private info1: string | (() => string);
    private info2: string | (() => string);
    private info1Tooltip: string | (() => string);
    private info2Tooltip: string | (() => string);

    public title = '';
    public steps: StepType[] = [];
    public model: M;
    public hideHeader = false;

    public editorComponent: EditorComponent<M>;

    constructor(public component: T) {

    }

    private getInfo = function(getter: string | (() => string)): string {
        return typeof getter === 'string' ? getter : (typeof getter === 'function' ? getter() : '');
    };

    getInfo1(): string {
        return this.getInfo(this.info1);
    }
    getInfo2(): string {
        return this.getInfo(this.info2);
    }

    setInfo1(func: string | (() => string)): void {
        this.info1 = func;
    }
    setInfo2(func: string | (() => string)): void {
        this.info2 = func;
    }
    getInfo1Tooltip(): string {
        return this.getInfo(this.info1Tooltip);
    }
    getInfo2Tooltip(): string {
        return this.getInfo(this.info2Tooltip);
    }
    setInfo1Tooltip(func: string | (() => string)): void {
        this.info1Tooltip = func;
    }
    setInfo2Tooltip(func: string | (() => string)): void {
        this.info2Tooltip = func;
    }

    abstract submit(model: M, type?: string): Observable<void>;
    delete?(model: M): Observable<void>;
    preModelModifier(model: M): void {
        // on init
    }
    postModelModifier(model: M, type?: string): void {
        // before Submit
    }
}
