import { Component, OnInit } from '@angular/core';

import { Provider } from '../../classes/provider';
import { Consumer } from '../../classes/consumer';
import { NavigationService } from '../../services/navigation/navigation.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-provider-create-item',
  templateUrl: './provider-create-item.component.html',
  styleUrls: ['./provider-create-item.component.css']
})
export class ProviderCreateItemComponent implements OnInit {
  public provider: Provider;
  public consumerList: Array<Consumer>;

  constructor(
    public navigationService: NavigationService,
    private consumerService: ConsumerService
  ) { }

  ngOnInit() {
    this.navigationService.title = 'Produktgeber erstellen';
    this.navigationService.startLoading('provider_create_dialog_init');

    this.provider = {
      ID: '',
      Name: '',
      Bild: '',
      Aktiv: 1,
      Vermittlernummer: true,
      Consumer: [{ID: '775d894039e81626ee51ce0b233f016e', Name: 'Snoopr', Suffix: 'SNOOPR', Aktiv: 1}],
      BusinessPlusParameterListe: []
    };

    this.consumerList = this.consumerService.consumerList;

    this.navigationService.stopLoading('provider_create_dialog_init');
  }

}
