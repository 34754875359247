import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import * as _moment from 'moment';
import { map } from 'rxjs/operators';

import { ItemList } from '../../../classes/item-list';
import { SearchFieldComponent } from '../../search-field/search-field.component';
import { MediaSelectDialog } from '../../../classes/media-select-dialog';
import { Medium } from '../../../classes/medium';
import { MediaCreateDialogComponent } from '../media-create-dialog/media-create-dialog.component';

import { StorageService } from '../../../services/storage/storage.service';
import { YoutubeService } from '../../../services/youtube/youtube.service';
import { PdfViewerService } from '../../../services/pdfviewer/pdfviewer.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { MessageService } from '../../../services/message/message.service';
import { MediaService } from '../../../services/media/media.service';

import { YtPlayerDialogComponent } from '../../../components/dialog/ytplayer-dialog/ytplayer-dialog.component';
import { PendingMedium } from 'src/app/classes/media-set';
import { forkJoin, of } from 'rxjs';
import { NotifyService, NotifyType } from 'src/app/services/notify/notify.service';
import { ProviderService } from 'src/app/services/provider/provider.service';

const moment = _moment;

@Component({
  selector: 'app-media-select-dialog',
  templateUrl: './media-select-dialog.component.html',
  styleUrls: ['./media-select-dialog.component.css']
})
export class MediaSelectDialogComponent extends ItemList<Medium> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  public selectedMediaList: Array<Medium> = [];
  public isSubmitting: boolean;

  constructor(
    private dialog: MatDialog,
    private storageService: StorageService,
    private youtubeService: YoutubeService,
    private pdfViewer: PdfViewerService,
    public navigationService: NavigationService,
    private notifyService: NotifyService,
    private mediaService: MediaService,
    public messageService: MessageService,
    private providerService: ProviderService,
    public dialogRef: MatDialogRef<MediaSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MediaSelectDialog
  ) {
    super('media_select', navigationService, messageService);
    this.isSubmitting = false;
  }

  ngOnInit() {
    if (this.data.preferredProviderId) {
      for (let i = 0; i < this.providerService.providerFilterList.length; i++) {
        const provider = this.providerService.providerFilterList[i];
        if (provider.ID === this.data.preferredProviderId) {
          this.filter.selectedProvider = provider;
          break;
        }
      }
    }
    super.ngOnInit();
  }

  onCloseClick(apply): void {
    if (apply && this.selectedMediaList.length !== 0) {
      const loadMediaJoin = [];
      this.isSubmitting = true;
      for (let i = 0; i < this.selectedMediaList.length; i++) {
        loadMediaJoin.push(this.mediaService.loadMedia(this.selectedMediaList[i].ID));
      }
      forkJoin(loadMediaJoin).subscribe((results) => {
        this.dialogRef.close(results);
        this.isSubmitting = false;
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  createNewMedia(): void {
    const dialogRef = this.dialog.open(MediaCreateDialogComponent, {
      panelClass: 'media-create-dialog-container',
      maxHeight: '100vh',
      data: {
          providerId: this.data.providerId,
          preferredProviderId: this.data.preferredProviderId,
          providerRole: this.data.providerRole,
          type: this.data.type
      }
    });

    dialogRef.afterClosed().subscribe((medium: PendingMedium) => {
      if (typeof medium !== 'undefined' && medium !== null) {
          this.dialogRef.close([medium]);
      }
    });
  }


  getMediaIcon(medium: Medium): string {
    switch (medium.Typ) {
      case 'PDF': return 'insert_drive_file';
      case 'VIDEO': return 'movie';
      case 'IMAGE': return 'insert_photo';
      default: return 'help';
    }
  }

  getMediaText(medium: Medium): string {
    switch (medium.Typ) {
      case 'PDF': return 'PDF';
      case 'VIDEO': return 'Video';
      case 'IMAGE': return 'Testsiegel';
      default: return 'Medium';
    }
  }

  getMediaVisibility(medium: Medium) {
    if (!medium.Beginn) {
      medium.Beginn = moment().toDate();
    }
    if (!medium.Ablauf) {
      medium.Ablauf = moment('2099-12-31').toDate();
    }
    return moment() > moment(medium.Beginn) && moment() < moment(medium.Ablauf);
  }

  openMedium(event, medium: Medium): void {
    event.stopPropagation();

    if (medium.Typ === 'VIDEO') { // video
      const tmpYouTubeID = this.youtubeService.getVideoId(medium.Link);
      if (!tmpYouTubeID) {
        console.log('No YouTube-Link found.');
      } else {
        this.dialog.open(YtPlayerDialogComponent, {
          panelClass: 'dialog-large',
          maxHeight: '80%',
          data: {
            VideoId: tmpYouTubeID
          }
        });
      }
    } else if (medium.Typ === 'PDF') { // pdf
      this.storageService.getSignedUrl(medium.Link).subscribe(url => {
        this.pdfViewer.openDoc(medium.Titel, url);
      }, error => {
        console.error('Error getting URL: ' + error);
      });
    }  else if (medium.Typ === 'IMAGE') { // image
      this.storageService.getSignedUrl(medium.Link).subscribe(url => {
        window.open(url, '_blank');
      }, error => {
        console.error('Error getting URL: ' + error);
      });
    } else { // no support
      console.error('Media type not Supported: ' + medium.Typ);
    }
  }

  loadItems(limit: number, offset: number) {
    const providerId = this.data.providerId ? this.data.providerId : this.filter.selectedProviderId;
    if (this.filter.selectedSelectionTypeId == 'SELECTED') {
      return of(this.selectedMediaList.slice(offset, offset + limit)).pipe(map(data => {
        return {
          items: data,
          maxCount: this.selectedMediaList.length
        }
      }));
    }
    return this.mediaService.loadMediaList(
        limit,
        offset,
        providerId,
        null,
        this.searchField.searchTerm || null,
        'laufend',
        this.filter.selectedSortingId,
        this.data.excludeMediaIds,
        this.data.type).pipe(map(result => ({items: result.mediaList, maxCount: result.metaData.maxCount})));
  }

  clickMedium(medium: Medium) {
    for (let i = 0; i < this.selectedMediaList.length; i++) {
      if (this.selectedMediaList[i].ID === medium.ID) {
        this.selectedMediaList.splice(i, 1);
        return;
      }
    }
    
    this.selectedMediaList.push(medium);
  }

  isSelected(medium: Medium) {
    for (let i = 0; i < this.selectedMediaList.length; i++) {
      if (this.selectedMediaList[i].ID === medium.ID) {
        return true;
      }
    }
    return false;
  }

  getSelected() {
    return this.selectedMediaList;
  }
}
