import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { Provider } from '../../classes/provider';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { ProviderService } from '../../services/provider/provider.service';

@Component({
  selector: 'app-provider-edit-item',
  templateUrl: './provider-edit-item.component.html',
  styleUrls: ['./provider-edit-item.component.css']
})
export class ProviderEditItemComponent implements OnInit, OnDestroy {
  private parameterSubscription: Subscription;

  public provider: Provider;

  constructor(
    public navigationService: NavigationService,
    private consumerService: ConsumerService,
    private providerService: ProviderService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.navigationService.title = 'Produktgeber bearbeiten';
    this.navigationService.startLoading('provider_edit_init');

    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {

      this.providerService.loadProvider(params['providerId']).subscribe((prodiver: Provider) => {
        this.provider = prodiver;
        this.navigationService.stopLoading('provider_edit_init');
      });
    });
  }

  ngOnDestroy() {
    this.parameterSubscription.unsubscribe();
  }
}
