import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _moment from 'moment';

import { SearchFieldComponent } from '../../search-field/search-field.component';
import { ConsumerSelectDialog } from '../../../classes/consumer-select-dialog';
import { Consumer } from '../../../classes/consumer';
import { ItemList } from 'src/app/classes/item-list';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { MessageService } from 'src/app/services/message/message.service';
import { ConsumerService } from 'src/app/services/consumer/consumer.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-consumer-select-dialog',
  templateUrl: './consumer-select-dialog.component.html',
  styleUrls: ['./consumer-select-dialog.component.css']
})
export class ConsumerSelectDialogComponent extends ItemList<Consumer> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  public selectedConsumer: Consumer = null;

  constructor(
    public navigationService: NavigationService,
    private consumerService: ConsumerService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<ConsumerSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConsumerSelectDialog
  ) {
    super('consumer_select', navigationService, messageService);
  }

  onCloseClick(selectedConsumer: Consumer = null): void {
    this.dialogRef.close(selectedConsumer);
  }

  loadItems(limit: number, offset: number) {
    return this.consumerService.loadConsumerList(
      limit,
      offset,
      this.data.excludeConsumerIds,
      this.searchField.searchTerm || null).pipe(
        map(result => ({items: result.consumerList, maxCount: result.metaData.maxCount}))
      );
  }
}
