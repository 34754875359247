import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { FullscreenDialog } from '../../../classes/fullscreen-dialog';

@Component({
  selector: 'app-fullscreen-dialog',
  templateUrl: './fullscreen-dialog.component.html',
  styleUrls: ['./fullscreen-dialog.component.css']
})
export class FullscreenDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<FullscreenDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: FullscreenDialog
  ) {

  }

  ngOnInit(): void { }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
