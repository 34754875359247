import { Component, ViewChild, ElementRef, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material';
import { ImageCropDialogComponent } from '../dialog/image-crop-dialog/image-crop-dialog.component';
import { ImageCropDialog } from 'src/app/classes/image-crop-dialog';
import { StorageService } from 'src/app/services/storage/storage.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { EventEmitter } from 'protractor';

export class CropValueChange {
  image: any;
  originalImage: any;
  imageURL: string;
  propagation: boolean;
};

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-field-image-crop',
  templateUrl: './form-type-image-crop.component.html',
  styleUrls: ['./form-type-image-crop.component.css']
})

export class FormTypeImageCropComponent extends FieldType implements OnInit, OnDestroy {
  @ViewChild('fileupload') fileuploadInput: ElementRef;

  public canvasImage = '';
  public originalImage = null;
  public isEmpty: boolean;
  public wasInitialEmpty: boolean;
  public opts: any;

  private valueChangeSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    this.opts = this.to;
    this.isEmpty = true;
    this.wasInitialEmpty = true;

    if (this.opts.image || this.opts.previewImage) {
      const image = (typeof this.opts.image === 'function') ? this.opts.image(this.model) : this.opts.image;
      const previewImage = (typeof this.opts.previewImage === 'function') ? this.opts.previewImage(this.model) : this.opts.previewImage;
      (image ? this.storageService.exists(image) : of(false)).subscribe(imageExists => {
        if (imageExists) {
          this.initImage(image, true);
        } else {
          (previewImage ? this.storageService.exists(previewImage) : of(false)).subscribe(previewImageExists => {
            if (previewImageExists) {
              this.initImage(previewImage, false);
            }
          });
        }
      }, () => {
        console.log('exists error!');
      });
    }

    this.valueChangeSubscription = this.formControl.valueChanges.subscribe((value) => {
      this.onValueChanges(value);
    });
  }

  ngOnDestroy() {
    this.valueChangeSubscription.unsubscribe();
  }

  onValueChanges(value) {
    if (value instanceof CropValueChange) {
      if ((<CropValueChange>value).image && (<CropValueChange>value).originalImage) {
        this.formControl.patchValue((<CropValueChange>value).image);
        this.to.onImageChange(this.model, (<CropValueChange>value).image, (<CropValueChange>value).originalImage, true);
      }
      if ((<CropValueChange>value).imageURL) {
        this.canvasImage = (<CropValueChange>value).imageURL;
        this.to.onImageURLChange(this.model, this.canvasImage, true);
      }
    }
  }

  initImage(image, isRealImage) {
    forkJoin([
      this.storageService.isPlaceholderFile(image),
      this.storageService.getSignedUrl(image)
    ]).subscribe((result) => {
      const isPlaceholder = result[0];
      const imageUrl = result[1];

      const imageObj = new Image;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const _this = this;

      imageObj.onload = function() {
        canvas.width = imageObj.naturalWidth;
        canvas.height = imageObj.naturalHeight;
        ctx.drawImage(imageObj, 0, 0);
        canvas.toBlob(function(blob) {
          if (!isPlaceholder && isRealImage) {
            _this.formControl.patchValue(true);
            _this.isEmpty = false;
            _this.wasInitialEmpty = false;
            if (typeof _this.to.onImageChange !== 'undefined') {
              _this.to.onImageChange(_this.model, true, true, false);
            }
          }

          const reader = new FileReader;
          reader.onload = () => {
            _this.canvasImage = reader.result.toString();
            if (typeof _this.to.onImageURLChange !== 'undefined') {
              _this.to.onImageURLChange(_this.model, _this.canvasImage, false, false);
            }
          };
          reader.readAsDataURL(blob);
        }, 'image/png');
      };
      imageObj.crossOrigin = '';
      imageObj.src = imageUrl;
    });
  }

  openUpload() {
    this.fileuploadInput.nativeElement.click();
  }

  fileChangeEvent(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const _this = this;
    reader.onload = function () {
      event.target.value = "";
      if (_this.to.skipCropping) {
        _this.canvasImage = reader.result.toString();
        if (typeof _this.to.onImageURLChange !== 'undefined') {
          _this.to.onImageURLChange(_this.model, _this.canvasImage, false);
        }
        _this.formControl.patchValue(file);
        _this.isEmpty = false;
        if (typeof _this.to.onImageChange !== 'undefined') {
          _this.to.onImageChange(_this.model, file, file, false);
        }
      } else {
        const dialogRef = _this.dialog.open(ImageCropDialogComponent, {
          panelClass: 'image-crop-dialog-container',
          maxHeight: '100vh',
          data: (<ImageCropDialog>{
            ImageBase64: reader.result,
            MaintainAspectRatio: _this.to.maintainAspectRatio,
            AspectRatio: _this.to.aspectRatio,
            IsRound: _this.to.isRound,
            MinWidth: _this.to.cropperMinWidth,
            MinHeight: _this.to.cropperMinHeight,
            ResizeToWidth: _this.to.resizeToWidth,
            ResizeToHeight: _this.to.resizeToHeight
          })
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result !== null) {
            _this.canvasImage = result.base64;
            if (typeof _this.to.onImageURLChange !== 'undefined') {
              _this.to.onImageURLChange(_this.model, _this.canvasImage, false);
            }
            _this.formControl.patchValue(result.file);
            _this.isEmpty = false;
            if (typeof _this.to.onImageChange !== 'undefined') {
              _this.to.onImageChange(_this.model, result.file, file, false);
            }
          }
        });
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  deleteImage() {
    if (this.to.previewImage) {
      this.storageService.getSignedUrl(this.to.previewImage).subscribe(signedUrl => {

        const image = new Image;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const _this = this;

        image.onload = function() {
          canvas.width = image.naturalWidth;
          canvas.height = image.naturalHeight;
          ctx.drawImage(image, 0, 0);
          canvas.toBlob(function(blob) {
            _this.formControl.patchValue(null);
            _this.isEmpty = true;
            if (typeof _this.to.onImageChange !== 'undefined') {
              _this.to.onImageChange(_this.model, null, null, false);
            }

            const reader = new FileReader;
            reader.onload = () => {
              _this.canvasImage = reader.result.toString();
              if (typeof _this.to.onImageURLChange !== 'undefined') {
                _this.to.onImageURLChange(_this.model, _this.canvasImage, false);
              }
            };
            reader.readAsDataURL(blob);
          }, 'image/png');
        };
        image.crossOrigin = '';
        image.src = signedUrl;
      });
    }
  }

  hasPreviewImage() {
    return !!this.to.previewImage;
  }

  isDisabled() {
    if (!!this.opts.enableIfEmpty && this.wasInitialEmpty) {
      return false;
    }
    return !!this.opts.disabled;
  }
}
