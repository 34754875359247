import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { Customer } from '../../classes/customer';

import { NavigationService } from '../../services/navigation/navigation.service';
import { CustomerService } from '../../services/customer/customer.service';

@Component({
  selector: 'app-customer-edit-item',
  templateUrl: './customer-edit-item.component.html',
  styleUrls: ['./customer-edit-item.component.css']
})
export class CustomerEditItemComponent implements OnInit, OnDestroy {
  private parameterSubscription: Subscription;
  public customer: Customer;

  public initialized = false;

  constructor(
    public navigationService: NavigationService,
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.initialized = false;
    this.navigationService.title = 'Kunde bearbeiten';
    this.navigationService.startLoading('customer_edit_init');

    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {

      this.customerService.loadCustomer(params['customerId']).subscribe((customer: Customer) => {
        this.customer = customer;
        this.navigationService.stopLoading('customer_edit_init');
        this.initialized = true;
      });
    });
  }

  ngOnDestroy() {
    this.parameterSubscription.unsubscribe();
  }
}
