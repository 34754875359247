import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatSelectChange, MatSelect } from '@angular/material';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-icon-select-section',
  templateUrl: './form-type-icon-select.component.html',
  styleUrls: ['./form-type-icon-select.component.css'],
})

export class FormTypeIconSelectComponent extends FieldType implements OnInit {
  @ViewChild(MatSelect) formFieldControl!: MatSelect;
  defaultOptions = {
    templateOptions: { options: [] },
  };

  public data = null;

  constructor() {
    super();
  }

  getSelected() {
    for (let i = 0; i < this.data.options.length; i++) {
      if (this.compareWith(this.formControl.value, this.data.options[i].value)) {
        return this.data.options[i];
      }
    }
    return {icon: '', value: ''};
  }

  ngOnInit() {
    this.data = this.to;
  }

  change($event: MatSelectChange) {
    if (this.data.change) {
      this.data.change(this.field, $event);
    }
  }

  compareWith(o1: any, o2: any) {
    return o1 === o2;
  }

  test(o) {
    return true;
  }
}
