import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as _moment from 'moment';

import { Provider } from '../../../classes/provider';
import { NavigationService } from '../../../services/navigation/navigation.service';


@Component({
  selector: 'app-provider-create-dialog',
  templateUrl: './provider-create-dialog.component.html',
  styleUrls: ['./provider-create-dialog.component.css']
})
export class ProviderCreateDialogComponent implements OnInit {
  public provider: Provider;

  constructor(
    public dialogRef: MatDialogRef<ProviderCreateDialogComponent>,
    public navigationService: NavigationService
  ) {

  }

  ngOnInit() {
    this.navigationService.startLoading('provider_create_init');

    this.provider = {
      ID: '',
      Name: '',
      Bild: '',
      Aktiv: 1,
      Vermittlernummer: true,
      Consumer: [{ID: '775d894039e81626ee51ce0b233f016e', Name: 'Snoopr', Suffix: 'SNOOPR', Aktiv: 1}],
      BusinessPlusParameterListe: []
    };

    this.navigationService.stopLoading('provider_create_init');
  }
}
