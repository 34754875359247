import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanDeactivate } from '@angular/router';
import { EditorComponent } from 'src/app/components/editor/editor.component';
import { Observable } from 'rxjs';
import { EditorService } from 'src/app/services/editor/editor.service';
import { GenericDialog } from 'src/app/classes/generic-dialog';
import { MatDialog } from '@angular/material';
import { GenericDialogComponent } from 'src/app/components/dialog/generic-dialog/generic-dialog.component';
import { log } from 'console';

@Injectable()
export class ChangesGuard implements CanDeactivate<EditorComponent<any>> {
    constructor(
        private editorService: EditorService,
        public dialog: MatDialog,
    ) { }
    canDeactivate(component: EditorComponent<any>): boolean | Observable<boolean> | Promise<boolean> {
        if (this.editorService.hasChanges()) {
            const genericDialog = new GenericDialog();
            genericDialog.titel = 'Ungespeicherte Änderungen';
            genericDialog.text = 'Es gibt <strong>ungespeicherte</strong> Änderungen. Soll die Navigation dennoch durchgeführt werden?';
            const dialogRef = this.dialog.open(GenericDialogComponent, {
                maxWidth: '500px',
                maxHeight: '100vh',
                data: genericDialog
            });

            return new Observable((subscriber) => {
                dialogRef.afterClosed().subscribe(result => {
                    subscriber.next(result);
                    subscriber.complete();
                });
            });
        } else {
            return true;
        }
    }
}
