import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Help } from '../../classes/help';
import { MessageService } from '../../services/message/message.service';

@Injectable({
  providedIn: 'root'
})
export class EditorService implements OnDestroy {

  public focusOnItem: string | number = '';
  public focusOnField = '';
  public help: Help = null;

  private title: string;
  private headlineInfo1: string;
  private headlineInfo2: string;
  private headlineInfoTooltip: string;
  private model: any;
  private initalModelStr: String;

  constructor(private http: HttpClient, private messageService: MessageService) { 
    this.model = null;
    this.initalModelStr = null;
  }

  setModel(model: any): void {
    if (model === null) {
      this.model = null;
      this.initalModelStr = null;
    } else {
      this.model = model;
      this.initalModelStr = JSON.stringify(model);
    }
  }

  hasChanges(): boolean {
    if (this.model == null) {
      return false;
    }
    if(this.model && this.model.BudgetLimit === null) {
      delete this.model.BudgetLimit;
    }
    return JSON.stringify(this.model) !== this.initalModelStr;
  }

  setTitle(text: string): void {
    this.title = text;
  }

  getTitle(): string {
    return this.title;
  }

  setHeadlineInfo1(text: string): void {
    this.headlineInfo1 = text;
  }

  getHeadlineInfo1(): string {
    return this.headlineInfo1;
  }

  setHeadlineInfo2(text: string): void {
    this.headlineInfo2 = text;
  }

  getHeadlineInfo2(): string {
    return this.headlineInfo2;
  }

  setHeadlineInfoTooltip(text: string): void {
    this.headlineInfoTooltip = text;
  }

  getHeadlineInfoTooltip(): string {
    return this.headlineInfoTooltip;
  }

  enter(itemId: string | number, fieldname: string): void {
    if (itemId) { this.focusOnItem = itemId; }
    if (fieldname) { this.focusOnField = fieldname; }
  }

  leave(): void {
    this.focusOnItem = '';
    this.focusOnField = '';
    this.help = null;
  }

  showHelp(helpID: string): void {
    if (helpID) { this.getHelp(helpID).subscribe(help => { this.help = help[0]; }); }
  }

  hasFocus(itemId: string | number, fieldname: string): boolean {
    return this.focusOnItem === itemId && (this.focusOnField.toLowerCase() === fieldname.toLowerCase());
  }

  private getHelp(fieldname: string): Observable<Help> {
    return this.http.get<Help>('api/help?Feld=' + fieldname).pipe(
      catchError(this.messageService.handleError<Help>('getHelp Feld=' + fieldname))
    );
  }

  ngOnDestroy() { }

}
