import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Consumer } from '../../classes/consumer';
import { NavigationService } from '../../services/navigation/navigation.service';
import { ConsumerService } from '../../services/consumer/consumer.service';

@Component({
  selector: 'app-consumer-edit-item',
  templateUrl: './consumer-edit-item.component.html',
  styleUrls: ['./consumer-edit-item.component.css']
})
export class ConsumerEditItemComponent implements OnInit, OnDestroy {
  private parameterSubscription: Subscription;
  public consumer: Consumer;

  constructor(
    public navigationService: NavigationService,
    private consumerService: ConsumerService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.navigationService.title = 'Consumer bearbeiten';
    this.navigationService.startLoading('consumer_edit_init');

    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {
      this.consumerService.loadConsumer(params['consumerId']).subscribe((consumer: Consumer) => {
        this.consumer = consumer;
        this.navigationService.stopLoading('consumer_edit_init');
      });
    });

  }

  ngOnDestroy() {
    this.parameterSubscription.unsubscribe();
  }
}
