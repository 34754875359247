import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-hidden-section',
  templateUrl: './form-type-hidden.component.html',
  styleUrls: ['./form-type-hidden.component.css'],
})

export class FormTypeHiddenComponent extends FieldType {

  constructor() {
    super();
  }
}
