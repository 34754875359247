import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retryWhen, switchMap, tap, share } from 'rxjs/operators';

import { AuthService } from '../../services/auth/auth.service';

import { environment } from '../../../environments/environment';
import { CognitoUserSession } from 'amazon-cognito-identity-js';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  private retryRequest = Symbol('reload');

  constructor(public authService: AuthService) {
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.startsWith(environment.api)) {
      return next.handle(request);
    }

    const _this = this;
    const request$ = new Observable<HttpRequest<any>>(observer => {
      _this.authService.getCurrentSession().then((session: CognitoUserSession) => {
        observer.next(this.addToken(request, session.getIdToken().getJwtToken()));
        observer.complete();
      });
    });

    return request$.pipe(
      switchMap(req => next.handle(req)),
      catchError((error: Error) => {
        throw error;
      }),
      retryWhen(err$ =>
        err$.pipe(
          tap(err => {
            if (err === this.retryRequest) {
              return;
            }
            throw err;
          })
        )
      )
    );
  }

}
