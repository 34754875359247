import { Component, OnInit, Input, Output, ViewChild, AfterViewInit, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { MatDialog, MatStepper } from '@angular/material';

import { Editor } from '../../classes/editor';
import { MessageService } from '../../services/message/message.service';
import { NotifyService, NotifyType } from '../../services/notify/notify.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AuthService } from '../../services/auth/auth.service';
import { String } from 'aws-sdk/clients/support';
import { ActivatedRoute } from '@angular/router';
import { GenericDialog } from 'src/app/classes/generic-dialog';
import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class EditorComponent<M> implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;

  @Input() public model: M & { submitType?: string };
  @Input() public editor?: Editor<any, M>;

  @Input() public canPublish = false;
  @Input() public canConfirm = false;
  @Input() public isAllowedToPublish = false;

  @Input() public isCreating = false;
  @Input() public initialValidation = false;

  @Output() init: EventEmitter<any> = new EventEmitter();

  form: FormArray;
  options: any;

  public notifyMessages = true;
  private changeSubscribtions: Array<Subscription>;
  private stepperAnimationDoneSubscriber: Subscription;

  public isLoading: boolean;

  private _modelString: String;

  constructor(
    public dialog: MatDialog,
    public messageService: MessageService,
    public notifyService: NotifyService,
    public navigationService: NavigationService,
    public authService: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.isLoading = false;
    this.changeSubscribtions = [];
    this.stepperAnimationDoneSubscriber = null;

    this.init.emit();
    // console.log('Model: ' + JSON.stringify(this.model, null, 2));
    if (this.editor) {
      this.editor.model = this.model;
      this.editor.preModelModifier(this.model);
      this.editor.editorComponent = this;
      this._modelString = JSON.stringify(this.editor.model);
    }
    this.model.submitType = '';
    this.reload();
    this.route.queryParams.subscribe(params => {
      const step = params.step || 1;
      this.stepper.selectedIndex = step - 1;

      if (this.initialValidation) {
        setTimeout(() => {
          for (let i = 0; i < this.editor.steps.length; i++) {
            this.touchStep(i);
          }
        }, 300);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.stepperAnimationDoneSubscriber !== null) {
      this.stepperAnimationDoneSubscriber.unsubscribe();
    }
    for (let i = 0; i < this.changeSubscribtions.length; i++) {
      this.changeSubscribtions[i].unsubscribe();
    }
    this.changeSubscribtions = [];
  }

  touchAllSteps() {
    this.stepper._steps.forEach((step) => {
      step.interacted = true;
    });
    
    for (let i = 0; i < this.form.length; i++) {
      this.touchStep(i);
    }
  }

  reload(): void {
    this.form = new FormArray(this.editor.steps.map(() => new FormGroup({})));
    this.options = this.editor.steps.map(() => <FormlyFormOptions> {});
    this.initChangeListener();
  }

  initChangeListener() {
    for (let i = 0; i < this.changeSubscribtions.length; i++) {
      this.changeSubscribtions[i].unsubscribe();
    }
    this.changeSubscribtions = [];
    for (let i = 0; i < this.form.length; i++) {
      this.changeSubscribtions.push(this.form.at(i).statusChanges.subscribe((status) => {
        this.onStepStatusChange(i, status);
      }));
    }
  }

  ngAfterViewInit() {
    const editorInstance = this;
    let counter = 0;
    this.stepper._steps.forEach(step => {
      const selectMethod = step.select;
      const newIndex = counter++;
      step.select = function() {
        const oldIndex = this._stepper.selectedIndex;
        editorInstance.touchStep(oldIndex);
        if (newIndex > oldIndex) {
          setTimeout(() => {
            // if (editorInstance.form.at(oldIndex).valid) {
              selectMethod.apply(step);
              setTimeout(() => {
                for (let i = newIndex - 1; i >= 0; i--) {
                  editorInstance.touchStep(i);
                }
              }, 100);
            // }
          }, 100);
        } else {
          selectMethod.apply(step);
        }
      };
    });
  }

  onStepStatusChange(index, status): void {
    // tslint:disable-next-line:max-line-length
    if (typeof this.stepper === 'undefined' || typeof this.stepper._steps === 'undefined' || typeof (<any>this.stepper._steps)._results === 'undefined') {
      return;
    }

    const step = (<any>this.stepper._steps)._results[index];
    if (typeof step === 'undefined') {
      return;
    }

    const form = this.form.at(index);
    const stepRef = <any>step._stepper._elementRef.nativeElement.children[index];

    if (!form.valid && form.enabled && form.touched) {
      if (stepRef.className.indexOf('invalid') < 0) {
        stepRef.className += ' invalid';
      }
    } else {
      stepRef.className = stepRef.className.replace(/ invalid/g, '');
    }
  }

  isValid(): boolean {
    return this.form.valid;
  }

  isStepValid(index: number): boolean {
    return this.form.at(index).valid;
  }



  gotoStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  validateAndSubmit(type: string): void {
    this.model.submitType = type || '';
    this.editor.steps.forEach((value, key) => {
      this.touchStep(key);
    });
    setTimeout(() => {
      if (this.isValid()) {
        this.submit(type);
      } else {
        for (let i = 0; i < this.form.controls.length; i++) {
          const control = this.form.controls[i];
          if (control.invalid) {
            this.stepper.selectedIndex = i;
            break;
          }
        }
        this.notifyService.notify(NotifyType.Warning, 'Es sind noch nicht alle Felder korrekt ausgefüllt.');
      }
    }, 100);
  }

  submit(type: String): void {
    if (this.editor) {
      this.navigationService.startLoading('editor_submit');
      this.editor.postModelModifier(this.model, type);
      this.editor.submit(this.model, type).subscribe(
        _ => {
          console.log('EditorComponent.submit(): next');
          this.editor.component.submitted$.emit();
          if (this.notifyMessages) {
            this.notifyService.notify(NotifyType.Success, 'Erfolgreich gespeichert.');
            this._modelString = JSON.stringify(this.editor.model);
          }
          // Lädt die Liste neu
          // TODO: bessere Lösung finden
          // this.consumerService.selectConsumer(this.consumerService.selectedConsumer());
        },
        error => {
          console.log('EditorComponent.submit(): error');
          console.error(error);
          this.navigationService.stopLoading('editor_submit');
          if (this.notifyMessages) {
            this.notifyService.notify(NotifyType.Error, 'Speichern fehlgeschlagen.');
          }
        },
        () => {
          this.navigationService.stopLoading('editor_submit');
          console.log('EditorComponent.submit(): complete');
        }
      );
    }
  }

  delete(model): void {
    this.editor.delete(model).subscribe(
      _ => {
        console.log('EditorComponent.delete(): next');
        this.editor.component.deleted$.emit();
        if (this.notifyMessages) {
          this.notifyService.notify(NotifyType.Success, 'Erfolgreich gelöscht.');
        }
        // this.consumerService.selectConsumer(this.consumerService.selectedConsumer());
        // TODO
        // this.categoryService.selectCategory(this.categoryService.selectedCategory());
      },
      error => {
        console.log(error);
        console.log('EditorComponent.delete(): error');
        if (this.notifyMessages) {
          this.notifyService.notify(NotifyType.Error, 'Löschen fehlgeschlagen.');
        }
      },
      () => {
        console.log('EditorComponent.delete(): complete');
      }
    );
  }

  stepChanged(event): void {
  // tslint:disable-next-line:max-line-length
    if (typeof this.stepper === 'undefined' || typeof this.stepper._steps === 'undefined' || typeof (<any>this.stepper._steps)._results === 'undefined') {
      return;
    }

    const step = (<any>this.stepper._steps)._results[event.selectedIndex];
    if (typeof step === 'undefined') {
      return;
    }
    const stepRef = <any>step._stepper._elementRef.nativeElement.children[event.selectedIndex];
    const stepHeaderRef = stepRef.querySelector('mat-step-header');

    let scrollTimeout = null;
    const stepperAnimationDoneSubscriber = event.selectedStep._stepper._animationDone.subscribe(() => {
      stepHeaderRef.scrollIntoView({behavior: 'smooth'});
      if (scrollTimeout !== null) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        stepperAnimationDoneSubscriber.unsubscribe();
        scrollTimeout = null;
      }, 500);
    });
  }

  validateControls(formControls, key?: string) {
    const keys = Object.keys(formControls.controls);
    keys.forEach((value) => {
      if (formControls.controls[value] instanceof FormArray) {
        this.validateControls(formControls.controls[value]);
      } else if (formControls.controls[value] instanceof FormGroup) {
        this.validateControls(formControls.controls[value]);
      } else if (formControls.controls[value] instanceof FormControl) {
        const fc = <FormControl> formControls.controls[value];
        if ((typeof key !== 'undefined' && key === value) || (typeof key === 'undefined')) {
          fc.markAsDirty();
          fc.markAsTouched();
          fc.updateValueAndValidity();
        }
      } else {
        console.log('FormControls has an unknown instance.');
      }
    });
  }

  validateControlByKey(key) {
    for (let i = 0; i < this.editor.steps.length; i++) {
      const formStep = <any>this.form.at(i);
      this.validateControls(formStep, key);
    }
  }

  touchStep(index: number): void {
    const formStep = <any>this.form.at(index);
    this.validateControls(formStep);
  }

  private searchControlByKey(formControls, key) {
    const keys = Object.keys(formControls.controls);

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (formControls.controls[k] instanceof FormArray || formControls.controls[k] instanceof FormGroup) {
        this.searchControlByKey(formControls.controls[k], key);
      } else if (formControls.controls[k] instanceof FormControl) {
        const fc = <FormControl> formControls.controls[k];
        if (key === k) {
          return fc;
        }
      }
    }
    return null;
  }

  getControlByKey(key: string): FormControl {
    for (let i = 0; i < this.editor.steps.length; i++) {
      const formStep = <any>this.form.at(i);
      const control = this.searchControlByKey(formStep, key);
      if (control !== null) {
        return control;
      }
    }
    return null;
  }

  nextStep(stepper: MatStepper, index: number): void {
    this.touchStep(index);
    setTimeout(() => {
      // if (this.form.at(index).valid) {
        stepper.next();
      // }
    }, 100);
  }

  prevStep(stepper: MatStepper, index: number): void {
    stepper.previous();
  }

  notifyNotAllowedToPublish() {
    const genericDialog = new GenericDialog();
    genericDialog.titel = 'Benutzerkonto noch nicht legitimiert';
    genericDialog.text = 'Schön, dass du ein Produkt bei Snoopr einstellen willst. \
                          Du musst dich jedoch noch etwas gedulden, da die Legitimation \
                          deines Kontos noch nicht abgeschlossen ist. Sobald dein Konto legitimiert \
                          ist informieren wir dich per E-Mail darüber. Bis es soweit ist, kannst \
                          du dein Produkt in diesem Status speichern.';
    this.dialog.open(GenericDialogComponent, {
      maxWidth: '500px',
      maxHeight: '100vh',
      data: genericDialog
    });
  }

  setStepColor(index, color) {
    // tslint:disable-next-line:max-line-length
    if (typeof this.stepper === 'undefined' || typeof this.stepper._steps === 'undefined' || typeof (<any>this.stepper._steps)._results === 'undefined') {
      return;
    }

    const step = (<any>this.stepper._steps)._results[index];
    if (typeof step === 'undefined') {
      return;
    }

    const stepRef = <any>step._stepper._elementRef.nativeElement.children[index];
    if (color != null) {
      if (stepRef.className.indexOf('stepcolor-' + color) < 0) {
        stepRef.className += ' stepcolor-' + color;
      }
    } else {
      stepRef.className = stepRef.className.replace(/ stepcolor-((?! ).)*/g, '');
    }
  }

 
}
