import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-tou-password-dialog',
  templateUrl: './tou-password-dialog.component.html',
  styleUrls: ['./tou-password-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TOUPasswordDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TOUPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  close() {
    this.dialogRef.close();
  }
}
