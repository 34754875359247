import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin, Subscriber, Subscription, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { ConsumerService } from '../consumer/consumer.service';
import { MessageService } from '../message/message.service';
import { AuthService } from '../auth/auth.service';

import { Category } from '../../classes/category';
import { Product } from '../../classes/product';
import { Consumer } from '../../classes/consumer';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  /* URL  Definitionen */
  private static readonly categoryUrl: string = environment.api + '/categories';


  public static categoryGroups = [
    { label: 'Allgemein', value: '_ALLGEMEIN' },
    { label: 'Haftpflicht & Recht', value: 'HAFTUNG-SNOOPR' },
    { label: 'Risiko & Vorsorge', value: 'VORSORGE-SNOOPR' },
    { label: 'Gesundheit', value: 'GESUNDHEIT-SNOOPR' },
    { label: 'Zuhause & Unterwegs', value: 'HAUS-SNOOPR' },
    { label: 'Betriebliche Altersversorgung', value: 'BAV-SNOOPR' },
    { label: 'Gewerbliche Versicherungen', value: 'GEWERBE-SNOOPR' },

    { label: 'Betriebliche Altersversorgung', value: 'BAV-CONTI' },
    { label: 'Gesundheit', value: 'GESUNDHEIT-CONTI' },
    { label: 'Gewerbe', value: 'GEWERBE-CONTI' },
    { label: 'Hinterbliebenenschutz', value: 'HINTERBLIEBENENSCHUTZ-CONTI' },
    { label: 'Haus, Haftpflicht & Recht', value: 'KOMPOSIT-CONTI' },
    { label: 'Private Altersvorsorge', value: 'RENTE-CONTI' },
    { label: 'Unfall', value: 'UNFALL-CONTI' },
    { label: 'Arbeitskraft-Absicherung', value: 'VORSORGE-CONTI' },

    { label: 'Hinterbliebenenschutz', value: 'HINTERBLIEBENENSCHUTZ-CONTIAT' },
    { label: 'Private Altersvorsorge', value: 'RENTE-CONTIAT' },
    { label: 'Arbeitskraft-Absicherung', value: 'VORSORGE-CONTIAT' },

    { label: 'Betriebliche Altersversorgung', value: 'BAV-MV' },
    { label: 'Gesundheit', value: 'GESUNDHEIT-MV' },
    { label: 'Gewerbeversicherung', value: 'GEWERBE-MV' },
    { label: 'Unfallversicherung', value: 'UNFALL-MV' },
    { label: 'Risiko & Vorsorge', value: 'VORSORGE-MV' },

    { label: 'KFZ-Versicherung', value: 'KFZ-VERTI' },
    { label: 'Lebensversicherung', value: 'LEBEN-VERTI' },

    { label: 'Sonstige', value: '_SONSTIGE' },
  ];

  private _initialized: boolean;
  get initialized(): boolean {
    return this._initialized;
  }

  private _categoryList: Array<Category>;
  get categoryList(): Array<Category> {
    return this._categoryList;
  }

  private _selectedCategory: Category;
  get selectedCategory(): Category {
    return this._selectedCategory;
  }
  set selectedCategory(category: Category) {
    this._selectedCategory = category;
    this.selectedCategoryChange$.next(this.selectedCategory);
  }

  /* EventEmitters */
  public selectedCategoryChange$: Subject<Category>;
  public categoryListChange$: Subject<Array<Category>>;

  private categoryAll: Category = {
    ID: 'ALL',
    Bezeichnung: 'Alle Kategorien',
    Kurzbezeichnung: 'Alle Kategorien',
    Controller: '',
    Highlights: '',
    ProduktgruppenID: ''
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private consumerService: ConsumerService,
    private authService: AuthService

  ) {
    this._initialized = false;
    this._categoryList = [
        this.categoryAll
    ];
    this._selectedCategory = this.categoryAll;

    this.selectedCategoryChange$ = new Subject<Category>();
    this.categoryListChange$ = new Subject<Array<Category>>();

    if (this.consumerService.initialized) {
      this.setupCategoryList().subscribe(() => {
        this._initialized = true;
      });
    } else {
      this.consumerService.initialize$.subscribe(() => {
        this.setupCategoryList().subscribe(() => {
          this._initialized = true;
        });
      });
    }

    this.consumerService.selectedConsumerChange$.subscribe((consumer: Consumer) => {
      this.setupCategoryList().subscribe();
    });
  }

  /**
   * Kategorie CRUD
   */

  public create(categoryModel: any): Observable<Category> {
    const category: any = {
      ID: categoryModel.ID.trim(),
      Bezeichnung: (categoryModel.Bezeichnung || '').trim(),
      Kurzbezeichnung: (categoryModel.Kurzbezeichnung || '').trim(),
      Controller: categoryModel.Controller,
      Highlights: categoryModel.Highlights,
      ProduktgruppenID: categoryModel.ProduktgruppenID,
      ProduktgruppenSortierung: categoryModel.ProduktgruppenSortierung,
      Leistungen: categoryModel.Leistungen || []
    };

    const http = this.http;
    return new Observable(observer => {
      http.post(CategoryService.categoryUrl, category).subscribe((result: Category) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public setupCategoryList(): Observable<Array<Category>> {
    this.categoryList.splice(0, this.categoryList.length);
    const urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID;
    return this.http.get<Array<Category>>(CategoryService.categoryUrl + urlParameter).pipe(
      tap((categoryList: Array<Category>) => {
        this.categoryList.splice(0, this.categoryList.length);
        categoryList.forEach((category: Category) => {
          this.categoryList.push(category);
        });
        this.categoryList.sort((l, r) => {
          // tslint:disable-next-line:max-line-length
          return l.Bezeichnung.toLowerCase() < r.Bezeichnung.toLowerCase() ? -1 : +(l.Bezeichnung.toLowerCase() > r.Bezeichnung.toLowerCase());
        }); // Alphabetische sortierung
        this.categoryList.unshift(this.categoryAll);

        if (this.categoryList.length === 0) {
          throw new Error('loadCategoryList did not found any category!');
        }

        const nextSelectedCategory: Category = this.selectedCategory || this.categoryList[0];
        const oldSelectedCategory: Category = this.selectedCategory;
        this._selectedCategory = null;
        for (let i = 0; i < this.categoryList.length; i++) {
          if (nextSelectedCategory.ID === this.categoryList[i].ID) {
            this._selectedCategory = this.categoryList[i];
            break;
          }
        }

        if (this.selectedCategory === null) {
          this._selectedCategory = this.categoryList[0];
        }

        if (oldSelectedCategory !== null && oldSelectedCategory.ID !== this.selectedCategory.ID) {
          this.selectedCategoryChange$.next(this.selectedCategory);
        }
        this.categoryListChange$.next(this.categoryList);
      })
    );
  }

  public loadCategoryList(includeSnoopr?, bare?): Observable<Array<Category>> {
    let urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID;
    if (typeof includeSnoopr !== 'undefined' && includeSnoopr) {
      urlParameter += '&includeSnoopr=true';
    }
    if (typeof bare !== 'undefined' && bare) {
      urlParameter += '&bare=true';
    }
    return this.http.get<Array<Category>>(CategoryService.categoryUrl + urlParameter).pipe(
      tap((categoryList: Array<Category>) => {
        categoryList.sort((l, r) => {
          // tslint:disable-next-line:max-line-length
          return l.Bezeichnung.toLowerCase() < r.Bezeichnung.toLowerCase() ? -1 : +(l.Bezeichnung.toLowerCase() > r.Bezeichnung.toLowerCase());
        }); // Alphabetische sortierung
      })
    );
  }

  public loadCategory(id: string): Observable<Category> {
    return this.http.get<Category>(CategoryService.categoryUrl + '/' + id).pipe(
      catchError(this.messageService.handleError('getCategory', null))
    );
  }

  public update(categoryModel: any): Observable<Category> {
    const category: any = {
      ID: categoryModel.ID.trim(),
      Bezeichnung: (categoryModel.Bezeichnung || '').trim(),
      Kurzbezeichnung: (categoryModel.Kurzbezeichnung || '').trim(),
      Controller: categoryModel.Controller,
      Highlights: categoryModel.Highlights,
      ProduktgruppenID: categoryModel.ProduktgruppenID,
      ProduktgruppenSortierung: categoryModel.ProduktgruppenSortierung,
      Leistungen: categoryModel.Leistungen || []
    };

    const http = this.http;
    return new Observable(observer => {
      http.put(CategoryService.categoryUrl + '/' + category.ID, category).subscribe((result: Category) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
