// core modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, FormControl } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';

// add-on modules
import { FormlyModule, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { YTPlayerModule, YTPlayerConfig } from 'angular-youtube-player';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { QuillModule } from 'ngx-quill';
import { ImageCropperModule } from 'ngx-image-cropper';
//import { NgxEchartsModule } from 'ngx-echarts';
//import * as echarts from 'echarts';

// locale
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

// own moduls and app components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// material design modules
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
// mocks
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryProductService } from './services/mocks/in-memory-product.service';

// components
import { HomeComponent } from './components/home/home.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HelpComponent } from './components/help/help.component';
import { MessagesComponent } from './components/messages/messages.component';
import { GenericDialogComponent } from './components/dialog/generic-dialog/generic-dialog.component';
import { TOUDialogComponent } from './components/dialog/tou-dialog/tou-dialog.component';
import { TOUPasswordDialogComponent } from './components/dialog/tou-password-dialog/tou-password-dialog.component';
import { FormlyDialogComponent } from './components/dialog/formly-dialog/formly-dialog.component';
import { MediaSelectDialogComponent } from './components/dialog/media-select-dialog/media-select-dialog.component';
import { MediaSetSelectDialogComponent } from './components/dialog/media-set-select-dialog/media-set-select-dialog.component';
import { MediaListComponent } from './components/media-list/media-list.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductInfoComponent } from './components/product-info/product-info.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { ProductFreeItemComponent } from './components/product-free-item/product-free-item.component';
import { ProductBenefitsComponent } from './components/product-benefits/product-benefits.component';
import { ProductDownloadsComponent } from './components/product-downloads/product-downloads.component';
import { ProductSignetsComponent } from './components/product-signets/product-signets.component';
import { ProductVideosComponent } from './components/product-videos/product-videos.component';
import { ProductFormsComponent } from './components/product-forms/product-forms.component';
import { EditorComponent } from './components/editor/editor.component';
import { FilterComponent } from './components/filter/filter.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { MediaItemComponent } from './components/media-item/media-item.component';
// import { MediaEditorComponent } from './components/media-editor/media-editor.component';
import { FormTypeRepeatComponent } from './components/form-type-repeat/form-type-repeat.component';
import { FormTypeRepeatDragDropComponent } from './components/form-type-repeat-drag-drop/form-type-repeat-drag-drop.component';
import { FormTypeAutocompleteComponent } from './components/form-type-autocomplete/form-type-autocomplete.component';
import { FormTypeButtonComponent } from './components/form-type-button/form-type-button.component';
import { FormTypeHiddenComponent } from './components/form-type-hidden/form-type-hidden.component';
import { FormTypeCategoryComponent } from './components/form-type-category/form-type-category.component';
import { FormTypeCategorySelectComponent } from './components/form-type-category-select/form-type-category-select.component';
import { FormTypeProductComponent } from './components/form-type-product/form-type-product.component';
import { FormTypeProviderComponent } from './components/form-type-provider/form-type-provider.component';
import { FormTypeProviderSelectComponent } from './components/form-type-provider-select/form-type-provider-select.component';
import { FormTypeConsumerComponent } from './components/form-type-consumer/form-type-consumer.component';
import { FormTypeTipComponent } from './components/form-type-tip/form-type-tip.component';
import { FormTypeIconComponent } from './components/form-type-icon/form-type-icon.component';
import { FormTypeJsonComponent } from './components/form-type-json/form-type-json.component';
import { FormTypeJavascriptComponent } from './components/form-type-javascript/form-type-javascript.component';
import { FormTypeCSSComponent } from './components/form-type-css/form-type-css.component';
import { FormTypeIconSelectComponent } from './components/form-type-icon-select/form-type-icon-select.component';
import { FormTypeMediaLinkingComponent } from './components/form-type-media-linking/form-type-media-linking.component';
import { FormTypeQuillComponent } from './components/form-type-quill/form-type-quill.component';
import { FormTypeDragDropComponent } from './components/form-type-drag-drop/form-type-drag-drop.component';
import { FormTypeMediaSetEditComponent } from './components/form-type-media-set-edit/form-type-media-set-edit.component';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { ContentTypeInterceptor } from './interceptors/content-type/content-type.interceptor';
import { PdfViewerDialogComponent } from './components/dialog/pdfviewer-dialog/pdfviewer-dialog.component';
import { FullscreenDialogComponent } from './components/dialog/fullscreen-dialog/fullscreen-dialog.component';
import { CodeEditorDialogComponent } from './components/dialog/code-editor-dialog/code-editor-dialog.component';
import { FormEditorDialogComponent } from './components/dialog/form-editor-dialog/form-editor-dialog.component';
import { YtPlayerComponent } from './components/ytplayer/ytplayer.component';
import { YtPlayerDialogComponent } from './components/dialog/ytplayer-dialog/ytplayer-dialog.component';
import { LeistungsEditorDialogComponent } from './components/dialog/leistungseditor-dialog/leistungseditor-dialog.component';
import { FormTypeFileComponent } from './components/form-type-file/form-type-file.component';
import { FormTypeImageCropComponent } from './components/form-type-image-crop/form-type-image-crop.component';
import { FormTypeFileAccessorDirective } from './components/form-type-file/form-type-file-accessor.directive';
import { MediaCreateItemComponent } from './components/media-create-item/media-create-item.component';
import { MediaEditItemComponent } from './components/media-edit-item/media-edit-item.component';
import { LoginComponent } from './components/login/login.component';
import { ProductSelectDialogComponent } from './components/dialog/product-select-dialog/product-select-dialog.component';
import { ProductEditItemComponent } from './components/product-edit-item/product-edit-item.component';
import { ProductCreateItemComponent } from './components/product-create-item/product-create-item.component';
import { MediaSetItemComponent } from './components/media-set-item/media-set-item.component';
import { MediaSetListComponent } from './components/media-set-list/media-set-list.component';
import { MediaSetCreateItemComponent } from './components/media-set-create-item/media-set-create-item.component';
import { MediaSetEditItemComponent } from './components/media-set-edit-item/media-set-edit-item.component';
import { SaveHtmlPipe } from './pipes/saveHtml/save-html.pipe';
import { AwsSignPipe } from './pipes/awsSign/sign.pipe';
import { CategorySelectDialogComponent } from './components/dialog/category-select-dialog/category-select-dialog.component';
// tslint:disable-next-line: max-line-length
import { GroupedCategorySelectDialogComponent } from './components/dialog/grouped-category-select-dialog/grouped-category-select-dialog.component';
import { UngroupedProviderSelectDialogComponent } from './components/dialog/ungrouped-provider-select-dialog/ungrouped-provider-select-dialog.component';
import { CategoryItemComponent } from './components/category-item/category-item.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { CategoryCreateItemComponent } from './components/category-create-item/category-create-item.component';
import { CategoryEditItemComponent } from './components/category-edit-item/category-edit-item.component';
import { FromTypeExtendedDatepickerComponent } from './components/from-type-extended-datepicker/from-type-extended-datepicker.component';
import { ProvidersManagementComponent } from './components/providers-management/providers-management.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { ProviderListComponent } from './components/provider-list/provider-list.component';
import { ConsumerListComponent } from './components/consumer-list/consumer-list.component';
import { CustomerItemComponent } from './components/customer-item/customer-item.component';
import { ProviderItemComponent } from './components/provider-item/provider-item.component';
import { ConsumerItemComponent } from './components/consumer-item/consumer-item.component';
import { ConsumerCreateItemComponent } from './components/consumer-create-item/consumer-create-item.component';
import { ProviderCreateItemComponent } from './components/provider-create-item/provider-create-item.component';
import { CustomerCreateItemComponent } from './components/customer-create-item/customer-create-item.component';
import { ConsumerEditItemComponent } from './components/consumer-edit-item/consumer-edit-item.component';
import { ProviderEditItemComponent } from './components/provider-edit-item/provider-edit-item.component';
import { CustomerEditItemComponent } from './components/customer-edit-item/customer-edit-item.component';
import { ProviderSelectDialogComponent } from './components/dialog/provider-select-dialog/provider-select-dialog.component';
import { ProviderCreateDialogComponent } from './components/dialog/provider-create-dialog/provider-create-dialog.component';
import { ConsumerSelectDialogComponent } from './components/dialog/consumer-select-dialog/consumer-select-dialog.component';
import { MediaSetCreateDialogComponent } from './components/dialog/media-set-create-dialog/media-set-create-dialog.component';
import { MediaSetEditDialogComponent } from './components/dialog/media-set-edit-dialog/media-set-edit-dialog.component';
import { MediaCreateDialogComponent } from './components/dialog/media-create-dialog/media-create-dialog.component';
import { ImageCropDialogComponent } from './components/dialog/image-crop-dialog/image-crop-dialog.component';
import { ImagePreviewDialogComponent } from './components/dialog/image-preview-dialog/image-preview-dialog.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { NavigationGuard } from './guards/navigation/navigation.guard';
import { ChangesGuard } from './guards/changes/changes.guard';
//import { AnalyseDashboardComponent } from './components/analyse-dashboard/analyse-dashboard.component';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function CheckboxRequiredTrue(control: FormControl) {
    return control.value !== true ? { 'checkboxRequiredTrue': true } : null;
}

export function MinMessage(error: any, field: FormlyFieldConfig) {
  return 'Mindestwert von ' + error.min + ' unterschritten';
}

export function MaxMessage(error: any, field: FormlyFieldConfig) {
  return 'Maximalwert von ' + error.max + ' überschritten';
}

@NgModule({
  declarations: [
    AppComponent,
    ProductListComponent,
    ProductItemComponent,
    ProductFreeItemComponent,
    MessagesComponent,
    HomeComponent,
    NavigationComponent,
    SidenavComponent,
    HelpComponent,
    ProductInfoComponent,
    ProductBenefitsComponent,
    ProductDownloadsComponent,
    ProductSignetsComponent,
    ProductVideosComponent,
    ProductFormsComponent,
    EditorComponent,
    GenericDialogComponent,
    TOUDialogComponent,
    TOUPasswordDialogComponent,
    FormlyDialogComponent,
    MediaSelectDialogComponent,
    MediaListComponent,
    FilterComponent,
    SearchFieldComponent,
    PageHeaderComponent,
    MediaItemComponent,
    // MediaEditorComponent,
    FormTypeRepeatComponent,
    FormTypeRepeatDragDropComponent,
    FormTypeAutocompleteComponent,
    FormTypeButtonComponent,
    FormTypeHiddenComponent,
    FormTypeProductComponent,
    FormTypeCategoryComponent,
    FormTypeCategorySelectComponent,
    FormTypeProviderComponent,
    FormTypeProviderSelectComponent,
    FormTypeConsumerComponent,
    FormTypeTipComponent,
    FormTypeIconComponent,
    FormTypeJsonComponent,
    FormTypeJavascriptComponent,
    FormTypeCSSComponent,
    FormTypeIconSelectComponent,
    FormTypeMediaLinkingComponent,
    FormTypeQuillComponent,
    FormTypeDragDropComponent,
    FormTypeMediaSetEditComponent,
    PdfViewerDialogComponent,
    FullscreenDialogComponent,
    CodeEditorDialogComponent,
    FormEditorDialogComponent,
    YtPlayerComponent,
    YtPlayerDialogComponent,
    LeistungsEditorDialogComponent,
    FormTypeFileComponent,
    FormTypeImageCropComponent,
    FormTypeFileAccessorDirective,
    MediaCreateItemComponent,
    MediaEditItemComponent,
    LoginComponent,
    ProductSelectDialogComponent,
    ProductEditItemComponent,
    ProductCreateItemComponent,
    SaveHtmlPipe,
    AwsSignPipe,
    MediaSetItemComponent,
    MediaSetListComponent,
    MediaSetCreateItemComponent,
    MediaSetEditItemComponent,
    CategorySelectDialogComponent,
    GroupedCategorySelectDialogComponent,
    UngroupedProviderSelectDialogComponent,
    CategoryItemComponent,
    CategoryListComponent,
    CategoryCreateItemComponent,
    CategoryEditItemComponent,
    FromTypeExtendedDatepickerComponent,
    ProvidersManagementComponent,
    CustomerListComponent,
    ProviderListComponent,
    ConsumerListComponent,
    CustomerItemComponent,
    ProviderItemComponent,
    ConsumerItemComponent,
    ConsumerCreateItemComponent,
    ProviderCreateItemComponent,
    CustomerCreateItemComponent,
    ConsumerEditItemComponent,
    ProviderEditItemComponent,
    CustomerEditItemComponent,
    ProviderSelectDialogComponent,
    ProviderCreateDialogComponent,
    ConsumerSelectDialogComponent,
    MediaSetSelectDialogComponent,
    MediaSetCreateDialogComponent,
    MediaSetEditDialogComponent,
    MediaCreateDialogComponent,
    ImageCropDialogComponent,
    ImagePreviewDialogComponent,
    ProfileComponent,
    ProgramsComponent,
  //  AnalyseDashboardComponent
  ],
  entryComponents: [
    GenericDialogComponent,
    TOUDialogComponent,
    TOUPasswordDialogComponent,
    FormlyDialogComponent,
    FullscreenDialogComponent,
    CodeEditorDialogComponent,
    FormEditorDialogComponent,
    PdfViewerDialogComponent,
    YtPlayerDialogComponent,
    LeistungsEditorDialogComponent,
    MediaSelectDialogComponent,
    CategorySelectDialogComponent,
    GroupedCategorySelectDialogComponent,
    UngroupedProviderSelectDialogComponent,
    ProductSelectDialogComponent,
    ProviderSelectDialogComponent,
    ProviderCreateDialogComponent,
    ConsumerSelectDialogComponent,
    MediaSetSelectDialogComponent,
    MediaSetCreateDialogComponent,
    MediaSetEditDialogComponent,
    MediaCreateDialogComponent,
    ImageCropDialogComponent,
    ImagePreviewDialogComponent
  ],
  imports: [
    // core
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ScrollDispatchModule,

    // add-on modules
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    HttpClientModule,
    AmplifyAngularModule,
    PdfViewerModule,
    YTPlayerModule,
    ImageCropperModule,
    //NgxEchartsModule.forRoot({
  //    echarts,
//    }),

    // formly
    FormlyModule.forRoot({
      validators: [
        {
          name: 'checkboxRequiredTrue',
          validation: CheckboxRequiredTrue
        },
      ],
      validationMessages: [
        { name: 'checkboxRequiredTrue', message: 'Pflichtfeld. Dieses Feld muss ausgewählt sein.' },
        { name: 'required', message: 'Pflichtfeld, bitte ausfüllen' },
        { name: 'maxlength', message: 'Maximale Länge überschritten' },
        { name: 'min', message: MinMessage },
        { name: 'max', message: MaxMessage }
      ],
      types: [
        { name: 'file', component: FormTypeFileComponent },
        { name: 'image-crop', component: FormTypeImageCropComponent },
        { name: 'repeat', component: FormTypeRepeatComponent },
        { name: 'repeat-drag-drop', component: FormTypeRepeatDragDropComponent },
        { name: 'autocomplete', component: FormTypeAutocompleteComponent },
        { name: 'button', component: FormTypeButtonComponent },
        { name: 'hidden', component: FormTypeHiddenComponent },
        { name: 'product', component: FormTypeProductComponent },
        { name: 'category', component: FormTypeCategoryComponent },
        { name: 'category-select', component: FormTypeCategorySelectComponent },
        { name: 'provider', component: FormTypeProviderComponent },
        { name: 'provider-select', component: FormTypeProviderSelectComponent },
        { name: 'consumer', component: FormTypeConsumerComponent },
        { name: 'tip', component: FormTypeTipComponent },
        { name: 'icon', component: FormTypeIconComponent },
        { name: 'json', component: FormTypeJsonComponent },
        { name: 'javascript', component: FormTypeJavascriptComponent },
        { name: 'css', component: FormTypeCSSComponent },
        { name: 'icon-select', component: FormTypeIconSelectComponent },
        { name: 'media-linking', component: FormTypeMediaLinkingComponent },
        { name: 'quill', component: FormTypeQuillComponent },
        { name: 'extended-datepicker', component: FromTypeExtendedDatepickerComponent },
        { name: 'drag-drop', component: FormTypeDragDropComponent },
        { name: 'media-set-edit', component: FormTypeMediaSetEditComponent },
      ]
    }),

    // Monaco Editor
    MonacoEditorModule.forRoot(),
    QuillModule.forRoot(),

    // material
    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatExpansionModule,
    MatTooltipModule,
    MatMenuModule,
    MatDividerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatCardModule,
    MatStepperModule,
    FormlyMatDatepickerModule,
    MatNativeDateModule,
    FormlyMatToggleModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatGridListModule,
    MatSnackBarModule,
    MatSelectModule,
    MatCheckboxModule,

    FormlySelectModule,
    DragDropModule,
    MatDatepickerModule,
    MatChipsModule

    // mocks
    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    // HttpClientInMemoryWebApiModule.forRoot(
    //   InMemoryProductService, { dataEncapsulation: false },
    // )

  ],
  providers: [
    AmplifyService,
    NavigationGuard,
    ChangesGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true} },
    { provide: YTPlayerConfig, useValue: { shouldLoadAPI: true, multiplePlaying: false } },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'de'}
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
