import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentTypeInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'PUT' || req.method === 'POST') {
      req = req.clone({
        setHeaders: { 'Content-Type': 'application/json;charset=UTF-8' }
      });
    }
    return next.handle(req);
  }

}
