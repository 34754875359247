export class EnterpriseSearch {
  Schluessel?: string;
  CustomerID: string;
  Kontext: string;
  Geheimnis?: string;
  Inaktiv?: boolean;
  Titel?: string;
  IncludeFilter?: Array<string>;
  ExcludeFilter?: Array<string>;
  CategoryIncludeFilter?: Array<string>;
  ModelIncludeFilter?: Array<string>;
  BrokerExcludeFilter?: boolean;
  StyleSnoopr?: string;
  StyleSuchfeld?: string;
  LogoLink?: string;
  Favicon16Link?: string;
  Favicon32Link?: string;
  Favicon48Link?: string;
  HomeURL?: string;
  HomepageTitel?: string;
  Recaptcha: boolean;
  RedirectStyle?: string;
  EmailButtonStyle?: string;
  EmailFooter?: string;
  EmailAddress?: string;
  PoolURL?: string;
  DisallowSetOrder?: boolean;
  SubmitionText?: string;
}

export class EnterpriseSearchFiles {
  LogoDatei: File;
  Favicon16Datei: File;
  Favicon32Datei: File;
  Favicon48Datei: File;
}