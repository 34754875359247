import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { YtPlayerDialogComponent } from '../../components/dialog/ytplayer-dialog/ytplayer-dialog.component';

@Component({
  selector: 'app-ytplayer',
  templateUrl: './ytplayer.component.html',
  styleUrls: ['./ytplayer.component.css']
})
export class YtPlayerComponent implements OnInit {
  @Input() public videoId: string;
  public thumbnail: string;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
      this.thumbnail = 'https://i.ytimg.com/vi/' + this.videoId + '/mqdefault.jpg';
  }

  openVideo() {
    const dialogRef = this.dialog.open(YtPlayerDialogComponent, {
        panelClass: 'dialog-large',
        maxHeight: '100vh',
        data: {
            VideoId: this.videoId
        }
    });
  }
}
