import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import * as _moment from 'moment';

import { GenericDialogComponent } from '../../components/dialog/generic-dialog/generic-dialog.component';
import { GenericDialog } from '../../classes/generic-dialog';
import { Observable } from 'rxjs';

import { NavigationService } from '../navigation/navigation.service';
import { AuthService } from '../auth/auth.service';

const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class ChangelogService {

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private navigationService: NavigationService,
    private authService: AuthService
  ) {
    if (this.authService.isLoggedIn()) {
      this.checkLatestSeenVersion();
    }
    this.authService.authStateChange$.subscribe(() => {
      if (this.authService.isLoggedIn()) {
        this.checkLatestSeenVersion();
      }
    });
  }

  public show() {
    this.navigationService.startLoading('changelog_dialog_loading');
    const date = new Date();
    this.http.get('/assets/changelog.json?' + date.getTime(), { responseType: 'json' }).subscribe((changelog: Array<any>) => {
      let latestSeenVersion = '0.0.0';
      if (changelog.length > 0) {
        const genericDialog = new GenericDialog();
        genericDialog.titel = 'Changelog';
        genericDialog.text = '';
        genericDialog.hideCancel = true;

        for (let i = 0; i < changelog.length; i++) {
          const changelogItem = changelog[i];

          // tslint:disable-next-line:max-line-length
          genericDialog.text += '<h3 class="changelog-entry"><strong>Version ' + changelogItem.version + '</strong><span></span><small>' + moment(changelogItem.date).format('DD.MM.YYYY') + '</small></h3>';
          genericDialog.text += '<ul class="changelog-changes">';
          for (let c = 0; c < changelogItem.changes.length; c++) {
            genericDialog.text += '<li>' + changelogItem.changes[c] + '</li>';
          }
          if (changelogItem.url) {
            genericDialog.text += '<li><a href="' + changelogItem.url + '" target="_blank">Mehr Details</a></li>';
          }
          genericDialog.text += '</ul>';
          genericDialog.text += '<br>';

          if (changelogItem.version > latestSeenVersion) {
            latestSeenVersion = changelogItem.version;
          }
        }

        this.dialog.open(GenericDialogComponent, {
          maxWidth: '500px',
          data: genericDialog
        });
      }

      localStorage.setItem('lastSeenChangelog', JSON.stringify({
        'lastCheck': date.getTime(),
        'version': latestSeenVersion
      }));
      this.navigationService.stopLoading('changelog_dialog_loading');
    });
  }

  public checkLatestSeenVersion() {
    const date = new Date();
    let lastSeenChangelogItem: any = localStorage.getItem('lastSeenChangelog');
    if (lastSeenChangelogItem) {
      lastSeenChangelogItem = JSON.parse(lastSeenChangelogItem);
      if (lastSeenChangelogItem.lastCheck + (1000 * 60) < date.getTime()) {
        this.getLatestVersion().subscribe((latestSeenVersion: string) => {
          if (latestSeenVersion > lastSeenChangelogItem.version) {
            this.show();
          } else {
            localStorage.setItem('lastSeenChangelog', JSON.stringify({
              'lastCheck': date.getTime(),
              'version': lastSeenChangelogItem.version
            }));
          }
        });
      }
    } else {
      this.show();
    }
  }

  private getLatestVersion(): Observable<string> {
    return new Observable((subscriber) => {
      const date = new Date();
      this.http.get('/assets/changelog.json?' + date.getTime(), { responseType: 'json' }).subscribe((changelog: Array<any>) => {
        let latestVersion = '0.0.0';
        for (let i = 0; i < changelog.length; i++) {
          const changelogItem = changelog[i].version;
          let itemVersionArr = changelogItem.split('.');
          let latestVersionnArr = latestVersion.split('.');
          for (let index = 0; index < itemVersionArr.length; index++) {
            const element = itemVersionArr[index];
            if (element > latestVersionnArr[index]) {
              latestVersion = changelogItem;
            }
          }
          
        }
        subscriber.next(latestVersion);
        subscriber.complete();
      });
    });
  }
}
