import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import * as _moment from 'moment';

import { Product } from '../../classes/product';
import { ProductService } from '../../services/product/product.service';
import { StorageService } from '../../services/storage/storage.service';
import { forkJoin } from 'rxjs';

const moment = _moment;

@Component({
  selector: 'app-formly-product-section',
  templateUrl: './form-type-product.component.html',
  styleUrls: ['./form-type-product.component.css'],
})

export class FormTypeProductComponent extends FieldType implements OnInit {

  public product: Product;
  public productText = '';

  constructor(
    private productService: ProductService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    const referenceProducts: Array<Product> = this.field.templateOptions.referenceProducts;
    for (let i = 0; i < referenceProducts.length; i++) {
      if (referenceProducts[i].ID.toString() === this.field.model.ID.toString()) {
        this.product = referenceProducts[i];
        break;
      }
    }

    if (!this.product) {
      this.productText = 'Dieses Produkt existiert nicht mehr. (' + this.field.model.ID.toString() + ')';
    }
  }

  getProductVisibility(product: Product) {
    if (!product.Beginn) {
      product.Beginn = moment().toDate();
    }
    if (!product.Ablauf) {
      product.Ablauf = moment('2099-12-31').toDate();
    }
    return moment() >= moment(product.Beginn) && moment() < moment(product.Ablauf);
  }
}
