import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _moment from 'moment';

import { UngroupedProviderSelectDialog } from '../../../classes/ungrouped-provider-select-dialog';

import { NavigationService } from '../../../services/navigation/navigation.service';
import { MessageService } from '../../../services/message/message.service';

@Component({
  selector: 'app-ungrouped-provider-select-dialog',
  templateUrl: './ungrouped-provider-select-dialog.component.html',
  styleUrls: ['./ungrouped-provider-select-dialog.component.css']
})
export class UngroupedProviderSelectDialogComponent {
  @ViewChild('selectForm') public selectForm: ElementRef;

  public searchterm = '';
  public searchCount = 0;

  constructor(
    public dialogRef: MatDialogRef<UngroupedProviderSelectDialogComponent>,
    public navigationService: NavigationService,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: UngroupedProviderSelectDialog
  ) {
  }

  onChange(): void {
    for (let i = 0; i < this.selectForm.nativeElement.length; i++) {
      const input = this.selectForm.nativeElement[i];
      for (let c = 0; c < this.data.providers.length; c++) {
        if (this.data.providers[c].value === input.value) {
          this.data.providers[c].selected = input.checked;
          break;
        }
      }
    }
  }

  onCloseClick(apply): void {
    if (apply) {
      const selectedProviders = [];
      for (let i = 0; i < this.selectForm.nativeElement.length; i++) {
        const input = this.selectForm.nativeElement[i];
        if (input.name !== 'providers') {
          continue;
        }

        if (input.checked) {
          for (let c = 0; c < this.data.providers.length; c++) {
            if (this.data.providers[c].value === input.value) {
              selectedProviders.push(this.data.providers[c]);
              break;
            }
          } 
        }
      }
      this.dialogRef.close(selectedProviders);
    } else {
      this.dialogRef.close();
    }
  }

  updateSearchterm(term) {
    this.searchterm = term;

    this.searchCount = 0;
    if (this.searchterm) {
      for (let c = 0; c < this.data.providers.length; c++) {
        const provider = this.data.providers[c];
        let io = 0;
        while ((io = provider.label.toLowerCase().indexOf(this.searchterm.toLowerCase(), io)) !== -1) {
          io += this.searchterm.length;
          this.searchCount++;
        }
      }
    }
  }

  getMarkedLabel(label) {
    const escapedSearchterm = this.searchterm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    label = label.replace(new RegExp('(' + escapedSearchterm + ')', 'gi'), '<em>$1</em>')
    return label;
  }

  isAnySelected(): boolean {
    for (let i = 0; i < this.data.providers.length; i++) {
      if (this.data.providers[i].selected) {
        return true;
      }
    }
    return false;
  }

  isUnselectAllAvailable(): boolean {
    for (let i = 0; i < this.data.providers.length; i++) {
      if (this.data.providers[i].selected && !this.data.providers[i].disabled) {
        return true;
      }
    }
    return false;
  }

  isSelectAllAvailable(): boolean {
    for (let i = 0; i < this.data.providers.length; i++) {
      if (!this.data.providers[i].selected && !this.data.providers[i].disabled) {
        return true;
      }
    }
    return false;
  }

  selectAll(): void {
    for (let i = 0; i < this.data.providers.length; i++) {
      if (!this.data.providers[i].disabled) {
        this.data.providers[i].selected = true;
      }
    }
  }

  unselectAll(): void {
    for (let i = 0; i < this.data.providers.length; i++) {
      if (!this.data.providers[i].disabled) {
        this.data.providers[i].selected = false;
      }
    }
  }
}
