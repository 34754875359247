import { Component, ViewChild, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-button-type',
  templateUrl: './form-type-button.component.html',
  styleUrls: ['./form-type-button.component.css']
})

export class FormTypeButtonComponent extends FieldType implements OnInit {

  @ViewChild(MatInput) formFieldControl: MatInput;

  filter: Observable<any[]>;
  public opt: any;

  constructor() {
    super();
    this.opt = {};
  }

  ngOnInit() {
    super.ngOnInit();
    this.opt = this.to;
  }

  disabledByParent(): boolean {
    if (this.opt.disableWhenParentInvalid !== true) {
      return false;
    }

    const controlKeys = Object.keys(this.form.controls);
    for (let i = 0; i < controlKeys.length; i++) {
      const key = controlKeys[i];
      const control = this.form.controls[key];
      if (typeof this.opt.ignoreParentInvalid !== 'undefined' && this.opt.ignoreParentInvalid.indexOf(key) >= 0) {
        continue;
      }

      if (control.status === 'INVALID') {
        return true;
      }
    }
    return false;
  }

  onClick() {
    if (typeof this.to.onClick) {
      this.to.onClick(this);
    }
  }
}
