import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscriber, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageService } from '../../services/message/message.service';

import { environment } from '../../../environments/environment';
import { EnterpriseSearch, EnterpriseSearchFiles } from 'src/app/classes/enterprise-search';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseSearchService {
  /* URL  Definitionen */
  private static readonly customersUrl = environment.api + '/customer';

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private authService: AuthService,
    private storageService: StorageService
  ) {

  }

  public loadEnterpriseSearches(customerId: string): Observable<Array<EnterpriseSearch>> {
    return this.http.get<Array<EnterpriseSearch>>(EnterpriseSearchService.customersUrl + '/' + customerId + '/enterprisesearch').pipe(
      catchError(this.messageService.handleError('loadEnterpriseSearches', null))
    );
  }

  public updateEnterpriseSearches(enterpriseSearchesModel: Array<EnterpriseSearch & EnterpriseSearchFiles>, customerId: string): Observable<Array<EnterpriseSearch>> {

    const enterpriseSearches: Array<EnterpriseSearch & EnterpriseSearchFiles> = [];

    for (let i = 0; i < enterpriseSearchesModel.length; i++) {
      const enterpriseSearchModel: (EnterpriseSearch  & EnterpriseSearchFiles) = enterpriseSearchesModel[i];
      const enterpriseSearch: (EnterpriseSearch  & EnterpriseSearchFiles) = { 
        CustomerID: customerId.trim(),
        Titel: (enterpriseSearchModel.Titel || '').trim(),
        Inaktiv: enterpriseSearchModel.Inaktiv || false,
        Kontext: enterpriseSearchModel.Kontext || '',
        IncludeFilter: enterpriseSearchModel.IncludeFilter || [],
        ExcludeFilter: enterpriseSearchModel.ExcludeFilter || [],
        CategoryIncludeFilter: enterpriseSearchModel.CategoryIncludeFilter || [],
        ModelIncludeFilter: enterpriseSearchModel.ModelIncludeFilter || [],
        BrokerExcludeFilter: enterpriseSearchModel.BrokerExcludeFilter || false,
        StyleSnoopr: enterpriseSearchModel.StyleSnoopr || '',
        StyleSuchfeld: enterpriseSearchModel.StyleSuchfeld || '',
        LogoDatei: enterpriseSearchModel.LogoDatei && enterpriseSearchModel.LogoDatei ? enterpriseSearchModel.LogoDatei : null,
        LogoLink: enterpriseSearchModel.LogoLink || '',
        Favicon16Datei: enterpriseSearchModel.Favicon16Datei && enterpriseSearchModel.Favicon16Datei ? enterpriseSearchModel.Favicon16Datei : null,
        Favicon16Link: enterpriseSearchModel.Favicon16Link || '',
        Favicon32Datei: enterpriseSearchModel.Favicon32Datei && enterpriseSearchModel.Favicon32Datei ? enterpriseSearchModel.Favicon32Datei : null,
        Favicon32Link: enterpriseSearchModel.Favicon32Link || '',
        Favicon48Datei: enterpriseSearchModel.Favicon48Datei && enterpriseSearchModel.Favicon48Datei ? enterpriseSearchModel.Favicon48Datei : null,
        Favicon48Link: enterpriseSearchModel.Favicon48Link || '',
        HomeURL: enterpriseSearchModel.HomeURL || '',
        HomepageTitel: enterpriseSearchModel.HomepageTitel || '',
        Recaptcha: enterpriseSearchModel.Recaptcha || false,
        RedirectStyle: enterpriseSearchModel.RedirectStyle || '',
        EmailButtonStyle: enterpriseSearchModel.EmailButtonStyle || '',
        EmailFooter: enterpriseSearchModel.EmailFooter || '',
        EmailAddress: enterpriseSearchModel.EmailAddress || '',
        PoolURL: enterpriseSearchModel.PoolURL || '',
        DisallowSetOrder: enterpriseSearchModel.DisallowSetOrder || false,
        SubmitionText: enterpriseSearchModel.SubmitionText || '',
      };

      if (enterpriseSearchModel.Schluessel) {
        enterpriseSearch.Schluessel = enterpriseSearchModel.Schluessel;
      }
      if (enterpriseSearchModel.Geheimnis) {
        enterpriseSearch.Geheimnis = enterpriseSearchModel.Geheimnis;
      }

      enterpriseSearches.push(enterpriseSearch);
    }

    const http = this.http;
    return new Observable(observer => {
      // tslint:disable-next-line:max-line-length
      http.put(EnterpriseSearchService.customersUrl + '/' + customerId + '/enterprisesearch', enterpriseSearches).subscribe((result: Array<EnterpriseSearch>) => {

        const uploadObervables: Array<Observable<void>> = [];

        let c = 0;
        for (let i = 0; i < enterpriseSearches.length; i++) {
          const enterpriseSearch: (EnterpriseSearch  & EnterpriseSearchFiles) = enterpriseSearches[i];

          let resultEnterpriseSearch = null;
          for (let r = 0; r < result.length; r++) {
            if (enterpriseSearch.Titel === result[r].Titel && !enterpriseSearch.Inaktiv) {
              resultEnterpriseSearch = result[r];
              break;
            }
          }

          /* (<any>enterpriseSearch).LogoDatei !== true, weil die Image Crop Komponente das initiale Logo auf true setzt */
          if (enterpriseSearch.LogoDatei && (<any>enterpriseSearch).LogoDatei !== true) {
            resultEnterpriseSearch.LogoLink = 'public/assets/customer/' + customerId + '/enterprise-search/' + resultEnterpriseSearch.Schluessel + '/logo';
            uploadObervables.push(this.uploadFile(customerId, resultEnterpriseSearch.Schluessel, enterpriseSearch.LogoDatei, resultEnterpriseSearch.LogoLink));
          }

          /* (<any>enterpriseSearch).Favicon16Datei !== true, weil die Image Crop Komponente das initiale Logo auf true setzt */
          if (enterpriseSearch.Favicon16Datei && (<any>enterpriseSearch).Favicon16Datei !== true) {
            resultEnterpriseSearch.Favicon16Link = 'public/assets/customer/' + customerId + '/enterprise-search/' + resultEnterpriseSearch.Schluessel + '/favicon16';
            uploadObervables.push(this.uploadFile(customerId, resultEnterpriseSearch.Schluessel, enterpriseSearch.Favicon16Datei, resultEnterpriseSearch.Favicon16Link));
          }

          /* (<any>enterpriseSearch).Favicon32Datei !== true, weil die Image Crop Komponente das initiale Logo auf true setzt */
          if (enterpriseSearch.Favicon32Datei && (<any>enterpriseSearch).Favicon32Datei !== true) {
            resultEnterpriseSearch.Favicon32Link = 'public/assets/customer/' + customerId + '/enterprise-search/' + resultEnterpriseSearch.Schluessel + '/favicon32';
            uploadObervables.push(this.uploadFile(customerId, resultEnterpriseSearch.Schluessel, enterpriseSearch.Favicon32Datei, resultEnterpriseSearch.Favicon32Link));
          }

          /* (<any>enterpriseSearch).Favicon48Datei !== true, weil die Image Crop Komponente das initiale Logo auf true setzt */
          if (enterpriseSearch.Favicon48Datei && (<any>enterpriseSearch).Favicon48Datei !== true) {
            resultEnterpriseSearch.Favicon48Link = 'public/assets/customer/' + customerId + '/enterprise-search/' + resultEnterpriseSearch.Schluessel + '/favicon48';
            uploadObervables.push(this.uploadFile(customerId, resultEnterpriseSearch.Schluessel, enterpriseSearch.Favicon48Datei, resultEnterpriseSearch.Favicon48Link ));
          }
        }

        if (uploadObervables.length > 0) {
          forkJoin(uploadObervables).subscribe(() => {
            http.put(EnterpriseSearchService.customersUrl + '/' + customerId + '/enterprisesearch', result).subscribe((finalResult: Array<EnterpriseSearch>) => {
              observer.next(finalResult);
              observer.complete();
            });
          }, (error) => {
            observer.error(error);
            observer.complete();
          });
        } else {
          observer.next(result);
          observer.complete();
        }
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public uploadFile(customerId: string, enterpriseSearchKey: string, file: File, key: string): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      const contentType = this.getContentType(file.name);
      if (!contentType) {
          subscriber.error('Ungültiger Dateiname: ' + file.name);
          return;
      }

      const options = {
        contentType: contentType,
        metadata: {
          'customer': this.authService.getCurrentUserCustomerId(),
          'role': this.authService.getCurrentUserRole(),
          'author': this.authService.getCurrentUserName(),
          'customer-id': customerId,
          'enterprise-search-key': enterpriseSearchKey,
          // HTTP-Header sind nur ASCII-kompatibel (https://tools.ietf.org/html/rfc7230#section-3.2.4)
          'file-name': encodeURIComponent(file.name.substr(-100))
        }
      };

      const observable = this.storageService.put(key, file, options);
      observable.subscribe(() => {
        subscriber.next();
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    });
  }

  /**
   * Utils
   */

  private getContentType(fileName: string): string {
    const extension = /\.(png|jpe?g|gif|pdf)$/i.exec(fileName);
    if (!extension) {
      return null;
    }
    switch (extension[0].toLowerCase()) {
      case '.png':
        return 'image/png';
      case '.jpg':
      case '.jpeg':
        return 'image/jpeg';
      case '.gif':
        return 'image/gif';
      case '.pdf':
        return 'application/pdf';
      default:
        return null;
    }
  }
}
