import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

export enum NotifyType {
  Error,
  Warning,
  Success
}

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  constructor(private snackBar: MatSnackBar) {

  }

  notify(type: NotifyType, message: string, duration = 5000) {
    const config = new MatSnackBarConfig();
    config.duration = duration;
    config.panelClass = (type === NotifyType.Success ? 'notify-success' : type === NotifyType.Warning ? 'notify-warning' : 'notify-error');
    this.snackBar.open(message, undefined, config);
  }
}
