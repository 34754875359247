import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../../services/storage/storage.service';

@Pipe({
  name: 'awsSign'
})
export class AwsSignPipe implements PipeTransform {

    private signedUrl: Promise<string>;
    private unsignedUrl: string;

  constructor(private storageService: StorageService) {
  }

  transform(value: any, args?: any): any {
    if (!value) {
      return Promise.resolve('');
    }
    if (!this.signedUrl || value !== this.unsignedUrl) {
        this.unsignedUrl = value;
        this.signedUrl = this.storageService.getSignedUrl(value).toPromise();
    }
    return this.signedUrl;
  }
}
