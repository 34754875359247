import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { NavigationService } from '../../services/navigation/navigation.service';
import { CategoryService } from '../../services/category/category.service';
import { ProductService } from '../../services/product/product.service';

import { MediaSet } from '../../classes/media-set';
import { Category } from '../../classes/category';
import { Product } from '../../classes/product';

@Component({
  selector: 'app-media-set-create-item',
  templateUrl: './media-set-create-item.component.html',
  styleUrls: ['./media-set-create-item.component.css']
})
export class MediaSetCreateItemComponent implements OnInit {

  public mediaSet: MediaSet;
  public categories: Category[];
  public products: Product[];

  constructor(
    private categoryService: CategoryService,
    private productService: ProductService,
    public navigationService: NavigationService,
    public activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.navigationService.title = 'Medien-Paket erstellen';
    this.navigationService.startLoading('media_set_create_init');

    this.mediaSet = {
      ID: '',
      SetID: '',
      AnbieterID: '',
      AnbieterName: '',
      Beschreibung: '',
      Produkte: [],
      Kategorien: [],
      Downloads: [],
      Videos: [],
      Formulare: [],
      Testberichte: []
    };

    this.navigationService.stopLoading('media_set_create_init');
  }
}
