import { Component, Inject, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { TOUDialog } from '../../../classes/tou-dialog';
import { TOUPasswordDialogComponent } from '../tou-password-dialog/tou-password-dialog.component';

@Component({
  selector: 'app-tou-dialog',
  templateUrl: './tou-dialog.component.html',
  styleUrls: ['./tou-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TOUDialogComponent implements AfterViewInit {
  @ViewChild('touDialogScroll') elementRef: ElementRef;

  public showDetails: boolean;
  public touText: string;

  constructor(
    public dialogRef: MatDialogRef<TOUDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: TOUDialog) {

    this.touText = data.tou;

    const host = this.data.isEnterprise ? 'https://www.b-tix.de' : 'https://www.snoopr.de';

    let resultA;
    const aTags = [];
    const regexA = /<a(.+?)>(.+?)<\/a>/g;
    while ((resultA = regexA.exec(this.touText)) !== null) {
      if (resultA.index === regexA.lastIndex) {
        regexA.lastIndex++;
      }

      if (resultA.length !== 3) {
        continue;
      }

      const tag = {
        index: resultA.index,
        length: resultA[0].length,
        content: resultA[2],
        params: []
      };

      let resultP;
      const regexP = /(.+?)=(?:"(.+?)"|'(.+?)')/g;
      while ((resultP = regexP.exec(resultA[1])) !== null) {
        if (resultP.index === regexP.lastIndex) {
          regexP.lastIndex++;
        }

        if (resultP.length !== 4) {
          continue;
        }

        tag.params.push({
          name: resultP[1].trim(),
          value: (resultP[2] || resultP[3] || '').trim(),
          quotes: typeof resultP[2] !== 'undefined' ? 2 : 1
        });
      }

      aTags.push(tag);
    }

    for (let i = aTags.length - 1; i >= 0; i--) {
      const tag = aTags[i];

      let tagElement = '<a';

      let hasTarget = false;
      let isIdLink = false;
      for (let tpi = 0; tpi < tag.params.length; tpi++) {
        const param = tag.params[tpi];
        let value = param.value;
        if (value.length === 0) {
          continue;
        }

        if (param.name.toLowerCase() === 'href') {
          const hasProtocol = value.indexOf('https://') === 0 || value.indexOf('http://') === 0;
          const firstChar = value.substr(0, 1);
          if (firstChar !== '#' && !hasProtocol) {
            value = host + (firstChar === '/' ? '' : '/') + value;
          } else if (firstChar === '#') {
            isIdLink = true;
          }
        }

        if (param.name.toLowerCase() === 'target') {
          hasTarget = true;
        }

        tagElement += ' ' + param.name + '=' + (param.quotes === 1 ? '\'' : '"') + value + (param.quotes === 1 ? '\'' : '"');
      }

      if (!hasTarget && !isIdLink) {
        tagElement += ' target="_blank"';
      }

      tagElement += '>';
      tagElement += tag.content;
      tagElement += '</a>';

      this.touText = this.touText.substr(0, tag.index) + tagElement + this.touText.substr(tag.index + tag.length);
    }

    let resultDiv;
    const divTags = [];
    const regexDiv = /<div(.+?)>/g;
    while ((resultDiv = regexDiv.exec(this.touText)) !== null) {
      if (resultDiv.index === regexDiv.lastIndex) {
        regexDiv.lastIndex++;
      }

      if (resultDiv.length !== 2) {
        continue;
      }

      const tag = {
        index: resultDiv.index,
        length: resultDiv[0].length,
        params: []
      };

      let resultP;
      const regexP = /(.+?)=(?:"(.+?)"|'(.+?)')/g;
      while ((resultP = regexP.exec(resultDiv[1])) !== null) {
        if (resultP.index === regexP.lastIndex) {
          regexP.lastIndex++;
        }

        if (resultP.length !== 4) {
          continue;
        }

        tag.params.push({
          name: resultP[1].trim(),
          value: (resultP[2] || resultP[3] || '').trim(),
          quotes: typeof resultP[2] !== 'undefined' ? 2 : 1
        });
      }

      divTags.push(tag);
    }

    for (let i = divTags.length - 1; i >= 0; i--) {
      const tag = divTags[i];

      let tagElement = '<div';

      let classValue = '';
      let idValue = '';

      for (let tpi = 0; tpi < tag.params.length; tpi++) {
        const param = tag.params[tpi];
        const value = param.value;
        if (value.length === 0) {
          continue;
        }

        if (param.name.toLowerCase() === 'id') {
          idValue = value;
          continue;
        } else if (param.name.toLowerCase() === 'class') {
          classValue = value;
          continue;
        }

        tagElement += ' ' + param.name + '=' + (param.quotes === 1 ? '\'' : '"') + value + (param.quotes === 1 ? '\'' : '"');
      }

      if (classValue.length > 0 || idValue.length > 0) {
        // tslint:disable-next-line:max-line-length
        tagElement += ' class="' + (classValue.length > 0 ? classValue : '') + (classValue.length > 0 ? ' ' : '') + (idValue.length > 0 ? ('tou-id-' + idValue) : '') + '"';
      }

      tagElement += '>';
      this.touText = this.touText.substr(0, tag.index) + tagElement + this.touText.substr(tag.index + tag.length);
    }
  }

  ngAfterViewInit() {
    const aElements = this.elementRef.nativeElement.querySelectorAll('a');
    const basisUrl = this.data.isEnterprise ? 'https://www.b-tix.de/nutzungsbedingungen/console' : 'https://www.snoopr.de/nutzungsbedingungen/console';
    for (let i = 0; i < aElements.length; i++) {
      aElements[i].addEventListener('click', (event) => {
        const href = (event.target.getAttribute('href') || '').trim();
        if (href.indexOf(basisUrl) === 0) {
          const data = {
            link: href,
            password: 'o4sc$now'
          };
          this.dialog.open(TOUPasswordDialogComponent, {
            maxWidth: '500px',
            maxHeight: '100vh',
            data: data
          });
          event.preventDefault();
        } else if (href.substr(0, 1) === '#') {
          const targetElements = document.getElementsByClassName('tou-id-' + href.substr(1));
          if (targetElements.length > 0) {
            targetElements[0].scrollIntoView();
            event.preventDefault();
          }
        }
        if (href.length === 0) {
          event.preventDefault();
        }
      });
    }
  }
}
