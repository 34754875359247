import { Component, ChangeDetectionStrategy, ViewEncapsulation, OnInit, Input } from '@angular/core';

import { ProductInfoEntryComponent } from '../product-info/product-info.component';

import { StorageService } from '../../services/storage/storage.service';
import { NavigationService } from '../../services/navigation/navigation.service';

import { Medium } from '../../classes/medium';

@Component({
  selector: 'app-product-signets',
  templateUrl: './product-signets.component.html',
  styleUrls: ['./product-signets.component.css', '../product-info/product-info-entry.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductSignetsComponent extends ProductInfoEntryComponent implements OnInit {
  @Input() public media: Medium[];

  constructor(private storageService: StorageService, public navigationService: NavigationService) {
    super();
  }

  ngOnInit() {
    this.media = this.media || [];

    this.media.forEach((signet) => {
      if (signet.Link.indexOf('http') !== 0) {
        const imageURL = signet.Link;
        signet.Link = '/assets/img/placeholder/product-signet.jpg';
        this.storageService.getSignedUrl(imageURL).subscribe(url => {
            signet.Link = url;
        }, error => {
            console.log('error getting url: ' + error);
        });
      }
    });
  }
}
