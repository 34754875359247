import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable, forkJoin, of } from 'rxjs';
import * as _moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

import { Medium } from '../../classes/medium';
import { Editor } from '../../classes/editor';
import { ItemComponent } from '../../classes/item-component';
import { Provider } from '../../classes/provider';
import { GenericDialog } from '../../classes/generic-dialog';

import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';
import { YtPlayerDialogComponent } from '../../components/dialog/ytplayer-dialog/ytplayer-dialog.component';
import { PdfViewerService } from '../../services/pdfviewer/pdfviewer.service';
import { MediaService } from '../../services/media/media.service';
import { StorageService } from '../../services/storage/storage.service';
import { ProviderService } from '../../services/provider/provider.service';
import { AuthService } from '../../services/auth/auth.service';
import { YoutubeService } from '../../services/youtube/youtube.service';
import { MessageService } from '../../services/message/message.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EditorService } from 'src/app/services/editor/editor.service';

const moment = _moment;

export class MediaEditor extends Editor<MediaItemComponent, any> {

  constructor(
    public component: MediaItemComponent,
    private mediaService: MediaService,
    private router: Router,
    public dialog: MatDialog,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<any>,
    private route: ActivatedRoute,
    private editorService: EditorService
  ) {
      super(component);
  }

  submit(model: any, type: string): Observable<void> {
    return new Observable(subscriber => {

    if (!this.dialogRef) {
      if (this.model.EndOriginalMedia && this.component.originalMedium !== null) {
        this.component.originalMedium.Ablauf = this.model.Beginn;
      }

      forkJoin([
        this.submitModel(model),
        this.model.EndOriginalMedia ? this.submitModel(this.component.originalMedium) : of(null)
      ]).subscribe((result: Array<Medium>) => {
        const medium: Medium = result[0];
        model.Version = medium.Version;
        model.Aenderungsdatum = medium.Aenderungsdatum;
        this.editorService.setModel(null);
        subscriber.next();
        subscriber.complete();


        if (!model.ID) {
          this.router.navigate(['/media-edit', medium.ID]);
        } else if (this.router.url !== '/media-list') {
          this.route.queryParams.subscribe(params => {
            const ref = params.ref || null;
            if (ref !== null) {
              this.router.navigateByUrl(params.ref);
            } else {
              this.router.navigate(['/media-list']);
            }
          });
        } else {
          this.component.editMedium(false);
        }
      }, error => {
        subscriber.error(error);
        this.messageService.showError('Beim Aktualisieren des Mediums ist es zu einem Fehler gekommen: '
         + error.error.message, error);
      });
    } else {
        this.editorComponent.notifyMessages = false;

        subscriber.next();
        subscriber.complete();

        this.dialogRef.close(model);
    }
    });
  }

  submitModel(model): Observable<Medium> {
    // TODO: Warndialog?
    return this.mediaService.createOrUpdateMedia(model);
  }

  delete(model): Observable<void> {
    console.log('delete -> ' + model.ID);
    const genericDialog = new GenericDialog();
    genericDialog.titel = 'Löschvorgang bestätigen';
    genericDialog.text = 'Sie sind im Begriff, das Medium zu löschen. \
      Dieser Schritt ist unwiderruflich. Möchten Sie den \
      Löschvorgang fortsetzen?';
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      maxWidth: '500px',
      maxHeight: '100vh',
      data: genericDialog
    });
    return new Observable(subscriber => {
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.mediaService.delete(model.ID).subscribe(() => {
            subscriber.next();
            subscriber.complete();
            this.route.queryParams.subscribe(params => {
              const ref = params.ref || null;
              if (ref !== null) {
                this.router.navigateByUrl(params.ref);
              } else {
                this.router.navigate(['/media-list']);
              }
            });
          }, error => {
            subscriber.error(error);
            this.messageService.showError('Beim Löschen des Mediums ist es zu einem Fehler gekommen: '
            + error.error.message, error);
          }); // OK -> route observable from mediaService
        } else {
          // CANCEL -> not error, not next, instead call complete
          subscriber.complete();
        }
      });
    });
  }

  preModelModifier(model: any): void {
    if (model.Suchbegriffe instanceof Array) {
      model.Suchbegriffe = model.Suchbegriffe.join(' ');
    }
    model.Anbieter = {
      ID: model.AnbieterID,
      Name: model.AnbieterName,
      AnbieterRolle: model.AnbieterRolle
    };

    model.EndOriginalMedia = false;
  }

  postModelModifier(model: any, type: string): void {
    model.Suchbegriffe = model.Suchbegriffe.toString().trim().split(' ');
    for (let i = model.Suchbegriffe.length - 1; i >= 0; i--) {
      if (model.Suchbegriffe[i].trim().length === 0) {
        model.Suchbegriffe.splice(i, 1);
      }
    }

    model.AnbieterID = model.Anbieter.ID;
    model.AnbieterName = model.Anbieter.Name;
    model.AnbieterRolle = model.Anbieter.AnbieterRolle;
  }
}


@Component({
  selector: 'app-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.css']
})
export class MediaItemComponent extends ItemComponent implements OnInit {

  @Input() public medium: Medium;
  @Input() public typeLocked = false;
  @Input() public originalMedium: Medium = null;
  @Input() public panelOpenState = false;
  @Input() public info = false;
  @Input() public edit = false;
  @Input() public cancelRedirectUrl = '';
  @Input() public dialogRef: MatDialogRef<any>;
  @Input() public providerId: string;
  @Input() public preferredProviderId: string;

  public mediumText: string;
  public mediumIcon: string;
  public isVisible: boolean;
  public isEnded: boolean;
  public editor: MediaEditor;

  public editorInitialized = false;

  private providerList: Array<Provider> = [];

  constructor(
    public dialog: MatDialog,
    public pdfViewer: PdfViewerService,
    private mediaService: MediaService,
    private router: Router,
    private storageService: StorageService,
    private providerService: ProviderService,
    public authService: AuthService,
    private youtubeService: YoutubeService,
    private messageService: MessageService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private editorService: EditorService,
  ) {
      super();
  }

  createEditorSteps(): void {

    const anbieterOptions = [];
    this.providerList.forEach((provider: Provider) => {
        if (!this.providerId || this.providerId === provider.ID) {
            anbieterOptions.push(provider);
        }
    });

    // Anbieter Vorauswahl
    if (!this.medium.AnbieterID && anbieterOptions.length === 1) {
      this.medium.AnbieterID = anbieterOptions[0].ID;
      this.medium.AnbieterName = anbieterOptions[0].Name;
      this.medium.AnbieterRolle = anbieterOptions[0].AnbieterRolle;
    } else if (!this.medium.AnbieterID && anbieterOptions.length > 1 && this.preferredProviderId) {
      for (let i = 0; i < this.providerList.length; i++) {
        const provider = this.providerList[i];
        if (provider.ID === this.preferredProviderId) {
          this.medium.AnbieterID = provider.ID;
          this.medium.AnbieterName = provider.Name;
          this.medium.AnbieterRolle = provider.AnbieterRolle;
          break;
        }
      }
    }

    // create step 1
    this.editor.steps.push({
      label: 'Datei hochladen',
        fields: [
          { // Tipp
            type: 'tip',
            templateOptions: {
              label: 'Hinweis',
              open: true,
              description: '<p>Die Veröffentlichung von Antragsformularen oder vergleichbaren Dokumenten sowie \
                            (dynamischen) Dokumenten, die der Ermittlung von individuellen Preisen dienen, ist unzulässig.</p>'
            },
            hideExpression: () => this.authService.isEnterprise()
          },
          { // 1. Zeile
            fieldGroupClassName: 'display-flex',
            fieldGroup: [

              { // Dateityp
                key: 'Typ',
                type: 'select',
                className: 'flex-1',
                templateOptions: {
                  label: 'Dateityp',
                  floatLabel: 'always',
                  appearance: 'outline',
                  placeholder: 'Bitte auswählen',
                  description: 'Es gibt die Möglichkeit, Dokumente im PDF-Format, Prüfsiegel als \
                    Bilddateien in den Formaten GIF, PNG und JPG sowie Links zu YouTube-Videos \
                    zu hinterlegen.',
                  required: true,
                  options: [
                    { value: 'PDF', label: 'Dokument (PDF)'  },
                    { value: 'IMAGE', label: 'Prüf- / Testsiegel (GIF, PNG, JPG)' },
                    { value: 'VIDEO', label: 'YouTube-Video (URL)'  }
                  ]
                },
                expressionProperties: {
                  'templateOptions.disabled': (model) => (model.ID !== 0 || this.typeLocked),
                }
              },

              { // Link
                key: 'Link',
                type: 'input',
                className: 'flex-1',
                templateOptions: {
                  label: 'YouTube-Video Link',
                  type: 'text',
                  floatLabel: 'always',
                  appearance: 'outline',
                  placeholder: 'https://www.youtube.com/watch?v=...',
                  description: 'Bitte Video auf YouTube öffnen, auf "Teilen" und anschließend \
                    auf "Kopieren" klicken. Danach in dieses Feld klicken und mit STRG+V bzw. \
                    über rechte Maustause einfügen.',
                  required: true,
                  maxLength: 100,
                  focus: () => { },
                  blur: () => { }
                },
                hideExpression: '(!model.Typ || model.Typ !== "VIDEO" ? true : false)',
                validators: {
                  video: {
                    expression: (field) => {
                      return this.medium.Typ !== 'VIDEO' || this.youtubeService.isValidYoutubeLink(field.value);
                    },
                    message: () => {
                      return 'Kein korrekter YouTube-Link. Beispiel: https://www.youtube.com/watch?v=...';
                    }
                  }
                }
              },

              { // Dateiupload
                key: 'Datei',
                type: 'file',
                className: 'flex-1',
                templateOptions: {
                  label: 'Dateiauswahl',
                  floatLabel: 'always',
                  appearance: 'outline',
                  accept: '.xls,.xlsx',
                  filename: this.medium.Dateiname,
                  emptyText: this.medium.ID ? 'Neue Datei hochladen' : 'keine Datei ausgewählt'
                },
                hideExpression: '!model.Typ || model.Typ === "VIDEO"',
                validators: {
                  isPdf: {
                    expression: (field) => {
                      return (!field.value || !field.value[0] || this.medium.Typ !== 'PDF' ||
                      field.value[0].name.match(/\.pdf$/i));
                    },
                    message: () => {
                      return 'Dokumente müssen PDF-Dateien sein.';
                    }
                  },
                  isImage: {
                    expression: (field) => {
                      return (!field.value || !field.value[0] || this.medium.Typ !== 'IMAGE' ||
                      field.value[0].name.match(/\.(png|jpe?g|gif)$/i));
                    },
                    message: () => {
                      return 'Auszeichnungen müssen PNG-, JPEG- oder GIF-Dateien sein.';
                    }
                  }
                },
                expressionProperties: {
                  'templateOptions.required': (model) => (model.Typ !== '' && model.ID !== 0 ? false : true),
                  'templateOptions.accept': (model) => (model.Typ === 'PDF' ? '.pdf' : '.jpg,.jpeg,.gif'),
                }
              }
            ]
          },

          { // Leerzeile
            template: '<br/>&nbsp;<br/><p><strong>Identifikation</strong></p>',
            // hideExpression: '(!model.Link || model.Link === "")'
          },

          { // 2. Zeile
            fieldGroupClassName: 'display-flex',
            fieldGroup: [

              { // Anbieter
                key: 'Anbieter',
                type: 'select',
                className: 'flex-1',
                templateOptions: {
                  label: 'Anbieter',
                  floatLabel: 'always',
                  appearance: 'outline',
                  placeholder: 'Anbieter des Mediums',
                  description: 'Anbieter des Mediums. In den meisten Fällen steht hier nur ein \
                    Anbieter zur Auswahl.',
                  required: true,
                  options: anbieterOptions,
                  focus: () => { },
                  blur: () => { },
                  labelProp: (item) => item.Name + ' (' + item.ID + ')',
                  valueProp: (item) => item,
                  compareWith: (o1, o2) => {
                    if (o1 === null || o2 === null) {
                      return o1 === o2;
                    }
                    return o1.ID === o2.ID;
                  },
                  change: (field: FormlyFieldConfig, event?: any) => {
                    this.medium.AnbieterID = field.formControl.value.ID;
                    this.medium.AnbieterName = field.formControl.value.Name;
                    this.medium.AnbieterRolle = field.formControl.value.AnbieterRolle;
                  }
                },
                expressionProperties: {
                  'templateOptions.disabled': (model) => (model.Typ !== '' && model.ID !== 0 ? true : false),
                },
                validators: {
                  required: {
                    expression: (field) => {
                      return field.value.ID.length > 0;
                    },
                    message: () => 'Bitte Anbieter auswählen.'
                  }
                }
              },

              { // Titel
                key: 'Titel',
                type: 'input',
                className: 'flex-1',
                templateOptions: {
                  floatLabel: 'always',
                  appearance: 'outline',
                  placeholder: 'Bezeichnung des Mediums',
                  description: 'Der Titel sollte unbedingt kurz und prägnant sein. \
                    Je kürzer er ist, desto besser wirkt er in der Suche und Anzeige. \
                    Der Text darf eine maximale Länge von 50 Zeichen nicht überschreiten.',
                  required: true,
                  maxLength: 50,
                  focus: () => { },
                  blur: () => { },
                  change: (field: FormlyFieldConfig, event?: any) => {
                    const itfc = this.editor.editorComponent.getControlByKey('InternerTitel');
                    if (!itfc.value) {
                      itfc.patchValue(event.target.value);
                    }
                  }
                },
                expressionProperties: {
                  'templateOptions.label': (model) => 'Titel (' + model.Titel.length + ' / 50 Zeichen)'
                }
              },
            ]
          },

          { // Leerzeile / Zwischenüberschrift
            template: '<br/>&nbsp;'
          },
          
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [{ // InternerTitel
              key: 'InternerTitel',
              type: 'input',
              className: 'flex-1',
              templateOptions: {
                floatLabel: 'always',
                appearance: 'outline',
                placeholder: 'Interne Bezeichnung des Mediums',
                description: 'Die interne Bezeichnung dient zur schnelleren Identifizierung des Mediums in der Search Console. Dieser hat keine Auswirkung auf die Suche. \
                  Der Text darf eine maximale Länge von 50 Zeichen nicht überschreiten.',
                required: true,
                maxLength: 50,
                focus: () => { },
                blur: () => { }
              },
              expressionProperties: {
                'templateOptions.label': (model) => 'Interne Bezeichnung (' + model.InternerTitel.length + ' / 50 Zeichen)'
              }
            }, {
              type: 'button',
              className: 'apply-text-button',
              templateOptions: {
                icon: 'keyboard_arrow_left',
                tooltip: 'Titel als Interne Bezeichnung übernehmen',
                onClick: (field) => {
                  const itfc = this.editor.editorComponent.getControlByKey('InternerTitel');
                  const tfc = this.editor.editorComponent.getControlByKey('Titel');
                  itfc.patchValue(tfc.value);
                }
              }
            }]
          },

          { // Leerzeile / Zwischenüberschrift
            template: '<br/>&nbsp;'
          },

          { // 3. Zeile
            fieldGroupClassName: 'display-flex',
            fieldGroup: [

              { // Stand
                key: 'Stand',
                type: 'input',
                className: 'flex-1',
                templateOptions: {
                  label: 'Stand / Version',
                  floatLabel: 'always',
                  appearance: 'outline',
                  placeholder: 'z.B. 01.2019',
                  description: 'Diese Angabe zeigt an, von wann das Medium ist, und ermöglicht \
                    Rückschlüsse auf die Aktualität und Gültigkeit der Quelle. Bei ähnlichen Dateien \
                    dient die Angabe auch als Unterscheidungsmerkmal.',
                  required: true,
                  maxLength: 30
                }
              },

              { // Stichworte
                key: 'Suchbegriffe',
                type: 'input',
                className: 'flex-1',
                templateOptions: {
                  floatLabel: 'always',
                  appearance: 'outline',
                  placeholder: 'z. B. finanztest haftpflicht bedingungen',
                  description: 'Stichworte, unter denen das Medium in der Suchmaschine gefunden werden soll. \
                    Stichworte sind durch Leerzeichen voneiner zu trennen.'
                },
                expressionProperties: {
                  'templateOptions.label': (model) => {
                    let count = 0;
                    if (typeof model.Suchbegriffe === 'string') {
                      const split = model.Suchbegriffe.split(' ');
                      for (let i = 0; i < split.length; i++) {
                        if (split[i].trim().length > 0) {
                          count++;
                        }
                      }
                    }
                    return 'Suchbegriffe / Stichworte (' + count + ' / 10 Begriffe)';
                  }
                },
                validators: {
                  specialCharacter: {
                    expression: (field) => {
                      if (field.value !== '') {
                        return (/^[A-Za-z0-9äÄöÖüÜß\-\ ]*$/.test(field.value));
                      } else {
                        return true;
                      }
                    },
                    message: () => 'Umlaute und Sonderzeichen sind unzulässig.'
                  },
                  maxWords: {
                    expression: (field) => {
                      let count = 0;
                      const split = field.value.split(' ');
                      for (let i = 0; i < split.length; i++) {
                        if (split[i].trim().length > 0) {
                          count++;
                        }
                      }
                      return count <= 10;
                    },
                    message: () => 'Maximal 10 Suchbegriffe zulässig.'
                  },
                  uniqueWords: {
                    expression: (field) => {
                      const split = (field.value || '').split(' ');
                      for (let i = 0; i < split.length; i++) {
                        split[i] = split[i].trim().toLowerCase();
                      }

                      for (let i = 0; i < split.length; i++) {
                        if (split[i].length > 0 && split.indexOf(split[i], i + 1) !== -1) {
                          return false;
                        }
                      }
                      return true;
                    },
                    message: () => 'Suchbegriffe dürfen nicht mehrmals angegeben werden.'
                  }
                }
              }

            ]
          },

          { // Leerzeile
            template: '<br/>'
          }
        ]
    });

    if (this.medium.MediaSets && this.medium.MediaSets.length > 0) {
      this.editor.steps.push({
        label: 'Verknüpfte MediaSets',
        html: '',
        fields: [
          {
            key: '_hidden',
            type: 'hidden'
          },
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              {
                key: 'MediaSets',
                type: 'repeat',
                className: 'flex-1',
                fieldArray: {
                  fieldGroupClassName: 'display-flex',
                  templateOptions: {
                    label: 'Produkt hinzufügen',
                    maxLength: 999,
                    disabled: true
                  },
                  fieldGroup: [
                    {
                      className: 'flex-1',
                      type: 'input',
                      key: 'ID',
                      templateOptions: {
                        label: 'Medien-Paket Bezeichnung',
                        appearance: 'outline',
                        disabled: true
                      }
                    }, {
                      className: 'flex-1',
                      type: 'select',
                      key: 'Typ',
                      templateOptions: {
                        label: 'Eingeordnet als',
                        appearance: 'outline',
                        disabled: true,
                        options: [
                          { value: 'DOWNLOAD', label: 'Download' },
                          { value: 'FORM', label: 'Formular' },
                          { value: 'IMAGE', label: 'Testbericht' },
                          { value: 'VIDEO', label: 'Video' }
                        ]
                      }
                    }, {
                      className: 'flex-1',
                      type: 'input',
                      key: 'Sortierung',
                      templateOptions: {
                        label: 'Position',
                        appearance: 'outline',
                        disabled: true
                      }
                    }, {
                      className: 'flex-1 media-item-preview-button',
                      type: 'button',
                      templateOptions: {
                        label: 'Medien-Paket bearbeiten',
                        color: 'default',
                        onClick: (field) => {
                          const genericDialog = new GenericDialog();
                          genericDialog.titel = 'Medien-Paket Bearbeitung';
                          genericDialog.text = 'Sie sind dabei in die Medien-Paket Bearbeitung zu wechseln. \
                                                <strong>Alle ungespeicherten Änderungen in diesem Medium gehen verloren.</strong>';
                          const dialogRef = this.dialog.open(GenericDialogComponent, {
                            maxWidth: '500px',
                            maxHeight: '100vh',
                            data: genericDialog
                          });
                          dialogRef.afterClosed().subscribe(result => {
                            if (result === true) {
                              this.router.navigate(['/media-set-edit', field.form.controls.ID.value], { queryParams: { ref: this.router.url }});
                            }
                          });
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      });
    }

    // create step 2
    this.editor.steps.push({
      label: 'Veröffentlichen',
      html: '',
        fields: [

          { // Leerzeile / Zwischenüberschrift
            template: '<p><strong>Zeitraum festlegen</strong></p>'
          },

          { // 1. Zeile
            // key: 'Veroeffentlichung',
            fieldGroupClassName: 'display-flex',
            fieldGroup: [

              { // Beginn / Von
                key: 'Beginn',
                type: 'extended-datepicker',
                className: 'flex-1',
                templateOptions: {
                  label: 'Von',
                  floatLabel: 'always',
                  placeholder: 'TT.MM.JJJJ',
                  appearance: 'outline',
                  description: 'Tag, ab dem das Medium öffentlich verfügbar sein soll. \
                    Die Ressource ist ab 0 Uhr des Tages verfügbar, aber i. d. R. nicht \
                    sofort in der Anwendung sichtbar. Maximale Vorausdatierung: 3 Monate.',
                  required: true,
                  datepickerOptions: {
                    min: moment(),
                    max: moment().add(3, 'months'),
                    startAt: moment(),
                    startView: 'month',
                    touchUi: true
                  },
                  // tslint:disable-next-line:max-line-length
                  disabled: ((this.medium.Typ !== '' && this.medium.ID !== 0 && (!moment(this.medium.Beginn).isAfter(moment())))  ? true : false),
                }
              },

              { // Ablauf / Bis
                key: 'Ablauf',
                type: 'extended-datepicker',
                className: 'flex-1',
                templateOptions: {
                  label: 'Bis',
                  floatLabel: 'always',
                  placeholder: 'TT.MM.JJJJ',
                  appearance: 'outline',
                  description: 'Tag, ab dem das Medium öffentlich nicht mehr verfügbar sein soll. \
                    Die Ressource ist ab 0 Uhr des Tages nicht mehr veröffentlicht. Eine \
                    unbegrenzte Dauer ist über ein spätes Ablaufdatum einzustellen.',
                  required: true,
                  datepickerOptions: {
                    initDate: moment('2099-12-31'),
                    min: moment().add(1, 'days'),
                    max: moment('2099-12-31'),
                    startAt: moment().add(1, 'years'),
                    startView: 'year',
                    touchUi: true,
                    required: true
                  }
                },
                defaultValue: moment('2099-12-31') // TODO: defaultValue doesn't work
              }
            ]
          },

          { // 1. Zeile
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              {
                key: 'EndOriginalMedia',
                type: 'checkbox',
                className: 'flex-1',
                templateOptions: {
                  label: '',
                  description: ''
                },
                hideExpression: this.originalMedium === null
              },
              { // Leerzeile / Zwischenüberschrift
                className: 'flex-12',
                // tslint:disable-next-line:max-line-length
                template: '<p>Dieses Medium ist eine Kopie des Mediums <strong>' + (this.originalMedium !== null ? this.originalMedium.InternerTitel : '') +
                          '</strong>. Möchten Sie das originale Medium zum Beginn dieses Mediums ablaufen lassen?</p>',
                hideExpression: this.originalMedium === null
              }
            ]
          },

          { // Leerzeile / Zwischenüberschrift
            template: '<p><strong>Sichtberechtigungen</strong></p>'
          },

          { // 2. Zeile
            // key: 'Veroeffentlichung',
            fieldGroupClassName: 'display-flex',
            fieldGroup: [

              { // Sichtberechtigungen
                key: 'Berechtigungen',
                type: 'select',
                className: 'flex-1',
                templateOptions: {
                  multiple: true,
                  required: true,
                  label: 'Sichtberechtigung',
                  floatLabel: 'always',
                  appearance: 'outline',
                  description: 'Im Normalfall sollte ein Medium sowohl für Vermittler als \
                    auch für Verbraucher sichtbar sein. In seltenen Ausnahmen kann es sinnvoll sein, \
                    Inhalte nur für Vermittler oder für Verbraucher zu veröffentlichen. ',
                  options: [
                    { value: 'ROLE_BROKER', label: 'Makler' },
                    { value: 'ROLE_CUSTOMER', label: 'Verbraucher' },
                  ]
                },
                defaultValue: ['ROLE_BROKER', 'ROLE_CUSTOMER']
              },

              {
                className: 'flex-1',
                template: '&nbsp;'
              }
            ]
          },

          { // Leerzeile
            template: '<br/>&nbsp;<br/>'
          }
        ]
    });
  }

  initEditor() {
    if (!this.editorInitialized) {
      this.navigationService.startLoading('media_editor_init');

      this.editor = new MediaEditor(this,
        this.mediaService,
        this.router,
        this.dialog,
        this.messageService,
        this.dialogRef,
        this.route,
        this.editorService
        );

      // tslint:disable-next-line:max-line-length
      this.editor.setInfo1(() => 'Version ' + this.medium.Version.toString() + ' vom ' + moment(this.medium.Aenderungsdatum).format('DD.MM. [um] HH:mm [Uhr]'));
      this.editor.setInfo2(() => 'Erstelldatum ' + moment(this.medium.Erstelldatum).format('DD.MM.YYYY'));
      this.editor.title = 'EDITOR';

      (this.medium.ID ? this.mediaService.loadMedia(this.medium.ID) : of(this.medium)).subscribe((medium: Medium) => {
        this.medium = medium;
        this.providerList = this.providerService.providerFilterList.slice(1);

        this.createEditorSteps();
        this.editorInitialized = true;
        setTimeout(() => {
          this.editorService.setModel(this.medium);
        }, 0);

        this.navigationService.stopLoading('media_editor_init');
      });
    }
  }

  ngOnInit() {
    if (this.edit) {
      this.panelOpenState = true;
      this.initEditor();
    }
    if (!this.medium.Beginn) {
      this.medium.Beginn = moment().toDate();
    }
    if (!this.medium.Ablauf) {
      this.medium.Ablauf = moment('2099-12-31').toDate();
    }
    if (moment() > moment(this.medium.Beginn) && moment() < moment(this.medium.Ablauf)) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }

    this.isEnded = (moment() >= moment(this.medium.Ablauf));

    switch (this.medium.Typ) {
      case 'PDF':
        this.mediumText = 'PDF';
        this.mediumIcon = 'insert_drive_file';
      break;
      case 'VIDEO':
        this.mediumText = 'Video';
        this.mediumIcon = 'movie';
      break;
      case 'IMAGE':
        this.mediumText = 'Testsiegel';
        this.mediumIcon = 'insert_photo';
      break;
      default:
        this.mediumText = 'Medium';
        this.mediumIcon = 'help';
      break;
    }
  }

  ngOnDestroy(): void {
    if (this.editorInitialized) {
      this.editorService.setModel(null);
    }
  }

  openMedium(): void {
    console.log('M:', this.medium);
    if (this.medium.Typ === 'VIDEO') { // video
      const tmpYouTubeID = this.youtubeService.getVideoId(this.medium.Link);
      if (!tmpYouTubeID) {
        console.log('No YouTube-Link found.');
      } else {
        this.dialog.open(YtPlayerDialogComponent, {
          panelClass: 'dialog-large',
          maxHeight: '100vh',
          data: {
            VideoId: tmpYouTubeID
          }
        });
      }
    } else if (this.medium.Typ === 'PDF') { // pdf
      this.storageService.getSignedUrl(this.medium.Link).subscribe(url => {
        this.pdfViewer.openDoc(this.medium.Titel, url);
      }, error => {
        console.error('Error getting URL: ' + error);
      });
    }  else if (this.medium.Typ === 'IMAGE') { // image
      this.storageService.getSignedUrl(this.medium.Link).subscribe(url => {
        window.open(url, '_blank');
      }, error => {
        console.error('Error getting URL: ' + error);
      });
    } else { // no support
      console.error('Media type not Supported: ' + this.medium.Typ);
    }
  }

  editMedium(edit: boolean) {
    this.info = false;
    this.edit = edit;

    if (edit) {
      this.initEditor();
    } else if (this.dialogRef) {
      this.dialogRef.close(null);
    } else {
      this.route.queryParams.subscribe(params => {
        const ref = params.ref || null;
        if (ref !== null) {
          this.router.navigateByUrl(params.ref);
        } else if (this.cancelRedirectUrl) {
            this.router.navigate([this.cancelRedirectUrl]);
        } else if (this.editorInitialized) {
          this.editorInitialized = false;
        }
      });
    }
  }

}
