import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as moment from 'moment';

import { Medium } from '../../classes/medium';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';
import { ConsumerService } from '../consumer/consumer.service';
import { CategoryService } from '../category/category.service';
import { MessageService } from '../message/message.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  /* URL  Definitionen */
  private static readonly mediaUrl = environment.api + '/media';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private authService: AuthService,
    private consumerService: ConsumerService,
    private categoryService: CategoryService,
    private messageService: MessageService
  ) {

  }

  /**
   * Media CRUD
   */

    public createOrUpdateMedia(medium: Medium & {Datei: FileList}): Observable<Medium> {
        const file: File = medium.Datei && medium.Datei[0] ? medium.Datei[0] : null;
        const title: string = (medium.Titel || '').trim();
        const internTitle: string = (medium.InternerTitel || '').trim();
        const type: string = medium.Typ;
        const providerId: string = medium.AnbieterID.trim();
        const tags: Array<string> = medium.Suchbegriffe;
        const permissions: string[] = medium.Berechtigungen;
        const begin: string = moment(medium.Beginn).format('YYYY-MM-DD');
        const end: string = moment(medium.Ablauf).format('YYYY-MM-DD');
        const contentVersion: string = medium.Stand || null;
        const link: string = medium.Link;
        const mediaSets: Array<Object> = medium.MediaSets;
        if (type === 'VIDEO') {
            if (medium.ID) {
                return this.updateVideo(medium.ID, link, title, internTitle, providerId,
                    permissions, begin, end, contentVersion, tags);
            } else {
                return this.createVideo(link, title, internTitle, providerId, permissions, begin, end, contentVersion, tags, []);
            }
        } else {
            if (medium.ID) {
                if (file) {
                    return new Observable(subscriber => {
                        // tslint:disable-next-line:max-line-length
                        this.update(file, medium.ID, title, internTitle, type, providerId, permissions, begin, end, contentVersion, tags, mediaSets).subscribe((updatedMedium: Medium) => {
                            this.updateFile(updatedMedium.ID, file).subscribe(() => {
                                subscriber.next(updatedMedium);
                                subscriber.complete();
                            }, subscriber.error);
                        }, subscriber.error);
                    });
                } else {
                    return this.update(null, medium.ID, title, internTitle, type, providerId,
                        permissions, begin, end, contentVersion, tags, mediaSets);
                }
            } else {
                return this.create(file, title, internTitle, type, providerId, permissions, begin, end, contentVersion, tags, mediaSets);
            }
        }
    }

  public create(file: File, title: string, internTitle: string, type: string, providerId: string, permissions: string[],
    begin: string, end: string, contentVersion?: string, tags?: Array<string>, mediaSets?: Array<Object>): Observable<Medium> {
    const body = {
      AnbieterID: providerId,
      Suchbegriffe: (tags ? tags : []),
      Titel: (title || '').trim(),
      InternerTitel: (internTitle || '').trim(),
      Berechtigungen: permissions,
      Typ: type,
      Beginn: begin,
      Ablauf: end,
      MediaSets: (mediaSets ? mediaSets : [])
    };
    if (contentVersion) {
      body['Stand'] = contentVersion;
    }

    if (file) {
      body['Dateiname'] = file.name;
      body['Groesse'] = file.size;
    }

    return new Observable<Medium>(subscriber => {
      this.http.post(MediaService.mediaUrl, body).subscribe((result: Medium) => {
        this.uploadFile(file, result).subscribe(_ => {
          subscriber.next(result);
          subscriber.complete();
        }, error => {
          subscriber.error(error);
          this.delete(result.ID).subscribe(_ => {
            console.log('Unvollständiger Eintrag ' + result.ID + ' wurde entfernt');
          }, deleteError => {
            console.error('Fehler beim Entfernen des unvollständigen Eintrags ' + result.ID + ':');
            console.error(deleteError);
          });
        });
      }, error => {
        subscriber.error(error);
      });
    });
  }

  public loadMediaList(limit?: number, offset?: number, filterProvider?: string,
    filterCategory?: string, filterSearch?: string, filterState?: string,
    orderBy?: string, excludeMediaIds?: Array<string>, type?: string): Observable<any> {
    let urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID;

    if (typeof filterProvider !== 'undefined' && filterProvider !== null) {
      urlParameter += '&providerId=' + filterProvider;
    }
    if (typeof filterCategory !== 'undefined' && filterCategory !== null) {
      urlParameter += '&categoryId=' + filterCategory;
    }
    if (typeof limit !== 'undefined' && limit !== null) {
      urlParameter += '&limit=' + limit;
    }
    if (typeof offset !== 'undefined' && offset !== null) {
      urlParameter += '&offset=' + offset;
    }
    if (typeof filterSearch !== 'undefined' && filterSearch !== null) {
      urlParameter += '&filterSearch=' + filterSearch;
    }
    if (typeof filterState !== 'undefined' && filterState !== null) {
      urlParameter += '&filterState=' + filterState;
    }
    if (typeof orderBy !== 'undefined' && orderBy !== null) {
      urlParameter += '&orderBy=' + orderBy;
    }
    if (typeof excludeMediaIds !== 'undefined' && excludeMediaIds !== null) {
      urlParameter += '&excludeMediaIds=' + encodeURIComponent(JSON.stringify(excludeMediaIds));
    }
    if (typeof type !== 'undefined' && ['PDF', 'VIDEO', 'IMAGE'].includes(type)) {
      urlParameter += '&type=' + type;
    }

    return this.http.get(MediaService.mediaUrl + urlParameter).pipe(
      map(mediaList => {
        return {
          mediaList: mediaList,
          metaData: (<Array<any>>mediaList).pop()
        };
      }),
      catchError(this.messageService.handleError('loadMediaList', []))
    );
  }

  public loadMedia(id: number, allowDeleted: boolean = false): Observable<Medium> {
    return this.http.get<Medium>(MediaService.mediaUrl + '/' + id + (allowDeleted ? '?allowDeleted=true' : '')).pipe(
      catchError(this.messageService.handleError('getMedia', null))
    );
  }

  public loadMediaListByIds(ids: Array<string>): Observable<any> {
    if (ids.length === 0) {
      return of({
        mediaList: [],
        metaData: {count: 0, limit: 0, maxCount: 0, offset: 0}
      });
    }

    let urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID;
    urlParameter += '&mediaIds=' + encodeURIComponent(JSON.stringify(ids));

    return this.http.get(MediaService.mediaUrl + urlParameter).pipe(
      map(mediaList => {
        return {
          mediaList: mediaList,
          metaData: (<Array<any>>mediaList).pop()
        };
      }),
      catchError(this.messageService.handleError('loadMediaListByIds', []))
    );
  }

  public createVideo(link: string, title: string, internTitle: string, providerId: string, permissions: string[],
    begin: string, end: string, contentVersion?: string, tags?: Array<string>, mediaSets?: Array<Object>): Observable<Medium> {
    const body = {
      AnbieterID: providerId,
      Link: link,
      Suchbegriffe: (tags ? tags : []),
      Titel: (title || '').trim(),
      InternerTitel: (internTitle || '').trim(),
      Berechtigungen: permissions,
      Typ: 'VIDEO',
      Beginn: begin,
      Ablauf: end,
      MediaSets: mediaSets
    };
    if (contentVersion) {
      body['Stand'] = contentVersion;
    }
    return new Observable(subscriber => {
      this.http.post(MediaService.mediaUrl, body).subscribe((medium: Medium) => {
        subscriber.next(medium);
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    });
  }

  public update(file: File, id: number, title: string, internTitle: string, type: string, providerId: string, permissions: string[],
    begin: string, end: string, contentVersion?: string, tags?: Array<string>, mediaSets?: Array<Object>): Observable<Medium> {
    const body = {
      AnbieterID: providerId,
      Suchbegriffe: (tags ? tags : []),
      Titel: (title || '').trim(),
      InternerTitel: (internTitle || '').trim(),
      Berechtigungen: permissions,
      Typ: type,
      Beginn: begin,
      Ablauf: end,
      MediaSets: mediaSets
    };
    if (file) {
      body['Dateiname'] = file.name;
      body['Groesse'] = file.size;
    }

    if (contentVersion) {
      body['Stand'] = contentVersion;
    }
    const http = this.http;
    return new Observable(observer => {
      http.put(MediaService.mediaUrl + '/' + id.toString(), body).subscribe((result: Medium) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  public updateVideo(id: number, link: string, title: string, internTitle: string, providerId: string, permissions: string[],
    begin: string, end: string, contentVersion?: string, tags?: Array<string>, mediaSets?: Array<Object>): Observable<Medium> {
    const body = {
      Link: (link || '').trim(),
      AnbieterID: providerId,
      Suchbegriffe: (tags ? tags : []),
      Titel: (title || '').trim(),
      InternerTitel: (internTitle || '').trim(),
      Berechtigungen: permissions,
      Typ: 'VIDEO',
      Beginn: begin,
      Ablauf: end,
      MediaSets: mediaSets
    };
    if (contentVersion) {
      body['Stand'] = contentVersion;
    }
    const http = this.http;
    return new Observable(observer => {
      http.put(MediaService.mediaUrl + '/' + id.toString(), body).subscribe((medium: Medium) => {
        observer.next(medium);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  public delete(id: number): Observable<void> {
    return new Observable(subscriber => {
      this.http.delete(MediaService.mediaUrl + '/' + id.toString()).subscribe(() => {
        subscriber.next();
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    });
  }

  private uploadFile(file: File, medium: Medium, subscriber?: Subscriber<Medium>): Observable<void> | undefined {
    const contentType = this.getContentType(file.name);
    if (!contentType) {
        subscriber.error('Ungültiger Dateiname: ' + file.name);
        return;
    }
    const options = {
      contentType: contentType,
      metadata: {
        'customer': this.authService.getCurrentUserCustomerId(),
        'role': this.authService.getCurrentUserRole(),
        'author': this.authService.getCurrentUserName(),
        'media-id': medium.ID.toString(),
        // HTTP-Header sind nur ASCII-kompatibel (https://tools.ietf.org/html/rfc7230#section-3.2.4)
        'file-name': encodeURIComponent(file.name.substr(-100))
      }
    };
    const observable = this.storageService.put(medium.Link, file, options);
    if (subscriber) {
      observable.subscribe(() => {
        subscriber.next(medium);
        subscriber.complete();
      }, error => {
        subscriber.error(error);
      });
    } else {
      return observable;
    }
  }

  public updateFile(id: number, file: File): Observable<Medium> {
    return new Observable(subscriber => {
      this.loadMedia(id).subscribe((result: Medium) => {
        this.uploadFile(file, result, subscriber);
      }, error => {
        subscriber.error(error);
      });
    });
  }

  /**
   * Utils
   */

  private getContentType(fileName: string): string {
    const extension = /\.(png|jpe?g|gif|pdf)$/i.exec(fileName);
    if (!extension) {
      return null;
    }
    switch (extension[0].toLowerCase()) {
      case '.png':
        return 'image/png';
      case '.jpg':
      case '.jpeg':
        return 'image/jpeg';
      case '.gif':
        return 'image/gif';
      case '.pdf':
        return 'application/pdf';
      default:
        return null;
    }
  }
}
