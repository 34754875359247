import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ProductInfoEntryPDFComponent } from '../product-info/product-info.component';

import { PdfViewerService } from '../../services/pdfviewer/pdfviewer.service';
import { StorageService } from '../../services/storage/storage.service';
import { NavigationService } from '../../services/navigation/navigation.service';

import { Medium } from '../../classes/medium';

@Component({
  selector: 'app-product-forms',
  templateUrl: './product-forms.component.html',
  styleUrls: ['./product-forms.component.css', '../product-info/product-info-entry.component.css']
})
export class ProductFormsComponent extends ProductInfoEntryPDFComponent implements OnInit {

  @Input() public media: Medium[];

  constructor(
    pdfViewer: PdfViewerService,
    storageService: StorageService,
    public router: Router,
    public navigationService: NavigationService
  ) {
    super(storageService, pdfViewer);
  }

  ngOnInit() {
    this.media = this.media || [];
  }

  editMedia(event, mediaID) {
    this.router.navigate(['/media-edit', mediaID]);

    event.preventDefault();
    event.stopImmediatePropagation();
  }
}
