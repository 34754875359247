import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { SearchFieldComponent } from '../search-field/search-field.component';
import { ItemList } from '../../classes/item-list';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product/product.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AuthService } from '../../services/auth/auth.service';
import { MessageService } from '../../services/message/message.service';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent extends ItemList<Product> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  public canSeeModels: boolean;

  constructor(
    private productService: ProductService,
    public navigationService: NavigationService,
    public authService: AuthService,
    public messageService: MessageService,
    private router: Router
  ) {
    super('product', navigationService, messageService, 'Produkte verwalten');

    this.canSeeModels = !this.authService.isBroker() && !this.authService.isEnterprise();
  }

  loadItems(limit: number, offset: number) {
    return this.productService.loadProductList(
        limit,
        offset,
        this.filter.selectedProviderId,
        this.filter.selectedCategoryId,
        this.searchField.searchTerm || null,
        this.filter.selectedStatusId,
        this.filter.selectedStageFilters,
        this.filter.selectedSortingId,
        null,
        this.filter.selectedProductModelId).pipe(map(result => ({items: result.productList, maxCount: result.metaData.maxCount})));
  }

  addProduct(): () => void {
    return (() => {
      this.router.navigate(['/product-create']);
    });
  }
}
