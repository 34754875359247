import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Product } from '../../classes/product';
import { NavigationService } from '../../services/navigation/navigation.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { CategoryService } from '../../services/category/category.service';
import { ProviderService } from '../../services/provider/provider.service';
import { ProductService } from '../../services/product/product.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-product-edit-item',
  templateUrl: './product-edit-item.component.html',
  styleUrls: ['./product-edit-item.component.css']
})
export class ProductEditItemComponent implements OnInit, OnDestroy {

  public product: Product;

  private parameterSubscription: Subscription;
  public initialized: boolean;

  constructor(
    private router: Router,
    public authService: AuthService,
    public navigationService: NavigationService,
    public productService: ProductService,
    public categoryService: CategoryService,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.initialized = false;
    this.navigationService.title = 'Produkt bearbeiten';
    this.navigationService.startLoading('product_edit_init');
    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {
      this.productService.loadProduct(params['productId']).subscribe((product: Product) => {
        this.product = product;
        this.navigationService.stopLoading('product_edit_init');
        this.initialized = true;
      });
    });
  }


  ngOnDestroy() {
    this.parameterSubscription.unsubscribe();
  }


  addProduct(): () => void {
    return (() => {
      this.router.navigate(['/product-create']);
    });
  }
}
