import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';

import { AmplifyService } from 'aws-amplify-angular';
import { MimeTypeConverter } from '../utils/mime-type-converter';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private amplifyService: AmplifyService) { }

  private removeVersion(key: string): string {
    // Amplify unterstützt keine Versionsnummern
    if (key.indexOf('?') > -1) {
      key = key.substring(0, key.indexOf('?'));
    }
    return key;
  }

  getSignedUrl(key: string, expiration?: number): Observable<string> {
    // Der key ist bereits eine URL
    if (key.indexOf('https://') === 0) {
      return of(key);
    }

    expiration = expiration ? expiration : 60;
    // Der Amplify-Storage prefixt die Keys mit dem Level, was bei uns aber im S3 Key enthalten ist.
    // Daher hier den Custom-Prefix leer setzen um das Prefixing zu unterbinden.
    const options = {
      expires: expiration,
      customPrefix : {
        public: ''
      }
    };
    key = this.removeVersion(key);

    return from(this.amplifyService.storage().get(key, options).then((url: string) => url));
  }

  put(key: string, file: File, options?: Object): Observable<void> {
    // Der Amplify-Storage prefixt die Keys mit dem Level, was bei uns aber im S3 Key enthalten ist.
    // Daher hier den Custom-Prefix leer setzen um das Prefixing zu unterbinden.
    options = options || {};
    options['customPrefix'] = {
      public: ''
    };
    key = this.removeVersion(key);

    return from(this.amplifyService.storage().put(key, file, options).then((result => undefined), (error => error)));
  }

  remove(key: string): Observable<void> {
    key = this.removeVersion(key);
    return from(this.amplifyService.storage().remove(key).then((result => undefined), (error => error)));
  }

  exists(key: string): Observable<boolean> {
    key = this.removeVersion(key);
    key = key.replace(/^public\//, '');
    const path = key.substring(0, key.lastIndexOf('/') + 1);
    return from(this.amplifyService.storage().list(path).then(((result: any[]) => {
      return result.map(o => o.key).includes(key);
    }), (error => error)));
  }

  isPlaceholderFile(key: string) : Observable<boolean> {
    key = this.removeVersion(key);
    key = key.replace(/^public\//, '');

    return new Observable<boolean>((subscriber) => {
      this.amplifyService.storage().get(key, { download: true }).then((result) => {
        subscriber.next(typeof (<any>result).Metadata.placeholder !== 'undefined');
        subscriber.complete();
      });
    });
  }

  download(key: string) : Observable<boolean> {
    key = this.removeVersion(key);
    key = key.replace(/^public\//, '');

    const filename = key.split('/').pop();

    return new Observable<boolean>((subscriber) => {
      this.amplifyService.storage().get(key, { download: true }).then((result) => {

        const blob = new Blob([(<any>result).Body], {
          type: (<any>result).ContentType
        });
        const url = window.URL.createObjectURL(blob);
        const ext = MimeTypeConverter.getExtensionByMimeType((<any>result).ContentType);

        const aElement = document.createElement('a');
        aElement.href = url;
        aElement.download = filename + ext;
        document.body.appendChild(aElement);
        (<any>aElement).style = 'display: none';
        aElement.click();
        aElement.remove();

        setTimeout(function() {
          return window.URL.revokeObjectURL(url);
        }, 1000);

        subscriber.next(true);
        subscriber.complete();
      }).catch(() => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }
}
