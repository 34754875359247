import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-repeat-drag-drop-section',
  templateUrl: './form-type-repeat-drag-drop.component.html',
  styleUrls: ['./form-type-repeat-drag-drop.component.css'],
})

export class FormTypeRepeatDragDropComponent extends FieldArrayType implements OnInit {
  constructor(builder: FormlyFormBuilder) {
    super(builder);
  }

  ngOnInit() {
  }

  removeItem(itemIndex: number) {
    this.remove(itemIndex);
  }

  addItem() {
    if (this.field.fieldArray.templateOptions.customAdd) {
      this.field.fieldArray.templateOptions.customAdd().subscribe((object: any) => {
        if (object) {
          this.add(null, object);
        }
      });
    } else {
      this.add();
    }
  }

  isDisabled() {
    return (this.field &&
      this.field.fieldArray &&
      this.field.fieldArray.templateOptions &&
      this.field.fieldArray.templateOptions.disabled
    );
  }

  isCustom() {
    return (this.field &&
      this.field.fieldArray &&
      this.field.fieldArray.templateOptions &&
      this.field.fieldArray.templateOptions.isCustom
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    const values = this.formControl.value.slice(0);
    moveItemInArray(values, event.previousIndex, event.currentIndex);
    this.formControl.setValue(values);

    if (this.field.fieldArray.templateOptions.onDrop) {
      this.field.fieldArray.templateOptions.onDrop(event.previousIndex, event.currentIndex);
    }
  }
}
