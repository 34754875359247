import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _moment from 'moment';
import { ImageCropDialog } from 'src/app/classes/image-crop-dialog';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { OutputType } from '@angular/core/src/view';

@Component({
  selector: 'app-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageCropDialogComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(
    public dialogRef: MatDialogRef<ImageCropDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropDialog
  ) {

  }

  ngOnInit() {
  }

  onCloseClick(save): void {
    (<Promise<ImageCroppedEvent>>this.imageCropper.crop()).then((imageCroppedEvent: ImageCroppedEvent) => {
      this.dialogRef.close(save ? {base64: imageCroppedEvent.base64, file: imageCroppedEvent.file} : null);
    });
  }


  imageCropped($event) {

  }

  imageLoaded() {

  }

  cropperReady() {

  }

  loadImageFailed() {

  }
}
