import { Component, OnInit } from '@angular/core';

import { NavigationService } from '../../services/navigation/navigation.service';
import { MessageService } from '../../services/message/message.service';
import { AuthService } from '../../services/auth/auth.service';
import { ChangelogService } from '../../services/changelog/changelog.service';
import { ConsumerService } from 'src/app/services/consumer/consumer.service';
import { CategoryService } from 'src/app/services/category/category.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  constructor(
    public navigationService: NavigationService,
    public messageService: MessageService,
    public authService: AuthService,
    public changelogService: ChangelogService,
    public consumerService: ConsumerService,
    public categoryService: CategoryService
  ) { }

  ngOnInit() { }

}
