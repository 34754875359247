import { Component, OnInit } from '@angular/core';

import { Customer } from '../../classes/customer';
import { Consumer } from '../../classes/consumer';
import { Provider } from '../../classes/provider';

import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-customer-create-item',
  templateUrl: './customer-create-item.component.html',
  styleUrls: ['./customer-create-item.component.css']
})
export class CustomerCreateItemComponent implements OnInit {
  public customer: Customer;
  public consumerList: Array<Consumer>;
  public providerList: Array<Provider>;

  public initialized = false;

  constructor(
    public navigationService: NavigationService,
  ) { }

  ngOnInit() {
    this.initialized = false;
    this.navigationService.title = 'Kunde erstellen';
    this.navigationService.startLoading('customer_create_init');

    this.customer = {
      ID: '',
      Name: '',
      FreiKontingente: 0,
      Aktiv: 1,
      Provider: [],
      Consumer: [{ID: '775d894039e81626ee51ce0b233f016e', Name: 'Snoopr', Suffix: 'SNOOPR', Aktiv: 1}],
      Accounts: [],
      IstPartner: false,
      IstAnbieter: false,
      Verifiziert: false,
      Rechnungsdaten: []
    };
    this.initialized = true;

    this.navigationService.stopLoading('customer_create_init');
  }

}
