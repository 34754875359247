import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { Router } from '@angular/router';

import { MediaService } from '../../services/media/media.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AuthService } from '../../services/auth/auth.service';

import { Medium } from '../../classes/medium';

import * as _moment from 'moment';

@Component({
  selector: 'app-media-edit-item',
  templateUrl: './media-edit-item.component.html',
  styleUrls: ['./media-edit-item.component.css']
})
export class MediaEditItemComponent implements OnInit, OnDestroy {

  public medium: Medium;

  private parameterSubscription: Subscription;

  constructor(
    private mediaService: MediaService,
    private activatedRoute: ActivatedRoute,
    public navigationService: NavigationService,
    public authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.navigationService.title = 'Medium bearbeiten';
    this.navigationService.startLoading('media_edit_init');
    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {
      this.mediaService.loadMedia(params['mediaId']).subscribe((medium: Medium) => {
        this.medium = medium;
        this.navigationService.stopLoading('media_edit_init');
      });
    });
  }

  ngOnDestroy() {
    this.parameterSubscription.unsubscribe();
  }

  addMedia(): () => void {
    return (() => {
      this.router.navigate(['/media-create']);
    });
  }
}
