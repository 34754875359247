import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SafeUrl } from '@angular/platform-browser';

import { YtPlayerDialog } from '../../../classes/ytplayer-dialog';
import { YoutubeService } from '../../../services/youtube/youtube.service';

@Component({
  selector: 'app-ytplayer-dialog',
  templateUrl: './ytplayer-dialog.component.html',
  styleUrls: ['./ytplayer-dialog.component.css']
})
export class YtPlayerDialogComponent implements OnInit {
  public thumbnail: SafeUrl;
  public window: any;
  public parameters: any;

  constructor(
    public dialogRef: MatDialogRef<YtPlayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: YtPlayerDialog,
    private youtubeService: YoutubeService
  ) {
    this.thumbnail = this.youtubeService.getThumbnailUrl(data.VideoId);
    this.window = window;
    this.parameters = {
      autoplay: 1,
      origin: window.location.origin,
      rel: 0
    };
  }

  ngOnInit() {
  }

  onVideoReady() {
  }

  onVideoUnstarted() {
  }

  onVideoEnded() {
  }

  onVideoPlaying() {
  }

  onVideoPaused() {
  }

  onVideoBuffering() {
  }

  onVideoCued() {
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
