import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Provider } from '../../classes/provider';

import { ItemList } from '../../classes/item-list';

import { MessageService } from '../../services/message/message.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AuthService } from '../../services/auth/auth.service';
import { ProviderService } from 'src/app/services/provider/provider.service';
import { map } from 'rxjs/operators';
import { SearchFieldComponent } from '../search-field/search-field.component';


@Component({
  selector: 'app-provider-list',
  templateUrl: './provider-list.component.html',
  styleUrls: ['./provider-list.component.css']
})
export class ProviderListComponent extends ItemList<Provider> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  constructor(
    private router: Router,
    public navigationService: NavigationService,
    public messageService: MessageService,
    public authService: AuthService,
    public providerService: ProviderService
  ) {
      super('provider', navigationService, messageService);
  }

  addProvider(): () => void {
    return (() => {
      this.router.navigate(['/provider-create']);
    });
  }

  loadItems(limit: number, offset: number) {
    return this.providerService.loadProviderList(limit, offset, null, this.searchField.searchTerm || null, this.filter.selectedProviderTypeId).pipe(
      map(result => ({items: result.providerList, maxCount: result.metaData.maxCount}))
    );
  }
}
