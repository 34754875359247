import { Medium } from './medium';
import * as moment from 'moment';

export class PendingMedium extends Medium {
    Datei: FileList;

    constructor(type: string) {
        super();
        this.ID = 0;
        this.Typ = type;
        this.AnbieterID = '';
        this.AnbieterName = '';
        this.Titel = '';
        this.InternerTitel = '';
        this.Link = '';
        this.Bild = '';
        this.Dateiname = '';
        this.Erstelldatum = new Date();
        this.Aenderungsdatum = new Date();
        this.Autor = '';
        this.Version = 1;
        this.Stand = moment().format('MM.YYYY');
        this.Beginn = moment().toDate();
        this.Ablauf = moment('2099-12-31').toDate();
        this.Suchbegriffe = [];
        this.Berechtigungen = ['ROLE_BROKER', 'ROLE_CUSTOMER'];
    }
}

export class MediaSet {
  ID: string;
  SetID: string;
  AnbieterID: string;
  AnbieterName: string;
  AnbieterRolle?: 'Versicherer' | 'Makler';
  Beschreibung: string;
  Kategorien: Array<string>;
  Produkte: Array<string>;
  Downloads?: Array<number | PendingMedium>;
  Formulare?: Array<number | PendingMedium>;
  Videos?: Array<number | PendingMedium>;
  Testberichte?: Array<number | PendingMedium>;
}
