import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _moment from 'moment';

import { GroupedCategorySelectDialog } from '../../../classes/grouped-category-select-dialog';

import { NavigationService } from '../../../services/navigation/navigation.service';
import { MessageService } from '../../../services/message/message.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-grouped-category-select-dialog',
  templateUrl: './grouped-category-select-dialog.component.html',
  styleUrls: ['./grouped-category-select-dialog.component.css']
})
export class GroupedCategorySelectDialogComponent {
  @ViewChild('selectForm') public selectForm: ElementRef;

  public searchterm = '';
  public searchCount = 0;

  constructor(
    public dialogRef: MatDialogRef<GroupedCategorySelectDialogComponent>,
    public navigationService: NavigationService,
    public messageService: MessageService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: GroupedCategorySelectDialog
  ) {
  }

  onChange(): void {
    for (let i = 0; i < this.selectForm.nativeElement.length; i++) {
      const input = this.selectForm.nativeElement[i];
      for (let c = 0; c < this.data.categories.length; c++) {
        if (this.data.categories[c].value === input.value) {
          this.data.categories[c].selected = input.checked;
          break;
        }
      }
    }
  }

  onCloseClick(apply): void {
    if (apply) {
      const selectedCategories = [];
      for (let i = 0; i < this.selectForm.nativeElement.length; i++) {
        const input = this.selectForm.nativeElement[i];
        if (input.name !== 'categories') {
          continue;
        }

        if (input.checked) {
          for (let c = 0; c < this.data.categories.length; c++) {
            if (this.data.categories[c].value === input.value) {
              selectedCategories.push(this.data.categories[c]);
              break;
            }
          } 
        }
      }
      this.dialogRef.close(selectedCategories);
    } else {
      this.dialogRef.close();
    }
  }

  resetCategories(): void {    
    for (let i = 0; i < this.data.categories.length; i++) {
      this.data.categories[i].selected = false;      
    }
  }

  countGroups() {
    let count = 0;
    for (let i = 0; i < this.data.groups.length; i++) {
      if ((<any>this.data.groups[i]).count > 0) {
        count ++;
      }
    }
    return count;
  }

  ceil(val) {
    return Math.ceil(val);
  }

  updateSearchterm(term) {
    this.searchterm = term;

    this.searchCount = 0;
    if (this.searchterm) {
      for (let c = 0; c < this.data.categories.length; c++) {
        const category = this.data.categories[c];
        let io = 0;
        while ((io = category.label.toLowerCase().indexOf(this.searchterm.toLowerCase(), io)) !== -1) {
          io += this.searchterm.length;
          this.searchCount++;
        }
      }
    }
  }

  getMarkedLabel(label) {
    
    if(this.searchterm.length == 0) {
      return label;
    } else {
      const escapedSearchterm = this.searchterm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      return label.replace(new RegExp('(' + escapedSearchterm + ')', 'gi'), '<em>$1</em>')
    }
  }

  isGroupSelected(group) {
    for (let i = 0; i < this.selectForm.nativeElement.length; i++) {
      const input = this.selectForm.nativeElement[i];
      if (input.name !== 'categories') {
        continue;
      }

      let category = null;
      for (let c = 0; c < this.data.categories.length; c++) {
        if (this.data.categories[c].value === input.value) {
          category = this.data.categories[c];
          break;
        }
      }

      if (category === null) {
        continue;
      }

      if (category.group === group.value) {
        if (!category.selected) {
          return false;
        }
      }
    }
    return true;
  }

  onClickGroup(group) {
    const groupSelected = this.isGroupSelected(group);

    for (let i = 0; i < this.selectForm.nativeElement.length; i++) {
      const input = this.selectForm.nativeElement[i];
      if (input.name !== 'categories') {
        continue;
      }

      let category = null;
      for (let c = 0; c < this.data.categories.length; c++) {
        if (this.data.categories[c].value === input.value) {
          category = this.data.categories[c];
          break;
        }
      }

      if (category === null) {
        continue;
      }

      if (category.group === group.value && !input.disabled) {
        category.selected = !groupSelected;
      }
    }
  }

  groupHasEnabledFields(group) {
    for (let c = 0; c < this.data.categories.length; c++) {
      if (this.data.categories[c].group === group.value && !this.data.categories[c].disabled) {
        return true;
      }
    }
    return false;
  }

  isAnySelected(): boolean {
    if(this.isAllowedToRemoveSnooprCategory()) {
      return true;
    }
    for (let i = 0; i < this.data.categories.length; i++) {
      if (this.data.categories[i].selected) {
        return true;
      }
    }
    return false;
  }

  isAllowedToRemoveSnooprCategory(): boolean {
    return this.data.isEdit && this.authService.isSystem();
  }
}
