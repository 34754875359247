import { Component, ViewChild, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-autocomplete-type',
  templateUrl: './form-type-autocomplete.component.html',
  styleUrls: ['./form-type-autocomplete.component.css']
})

export class FormTypeAutocompleteComponent extends FieldType implements OnInit {

  @ViewChild(MatInput) formFieldControl: MatInput;

  filter: string[];
  opts: any;

  ngOnInit() {
    super.ngOnInit();
    this.opts = this.to;
  }

  upadteFilter(event) {
    this.filter = this.to.filter(event.target.value);
  }

  onChange(value) {
    if (this.opts.onChange) {
      this.opts.onChange(value);
    }
  }
}
