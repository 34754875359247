import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatDialog } from '@angular/material';

import { MediaSetSelectDialogComponent } from '../dialog/media-set-select-dialog/media-set-select-dialog.component';
import { MediaSet } from '../../classes/media-set';
import { AuthService } from '../../services/auth/auth.service';
import { MediaSetService } from 'src/app/services/media-set/media-set.service';
import { FormGroup } from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-media-set-edit-type',
  templateUrl: './form-type-media-set-edit.component.html',
  styleUrls: ['./form-type-media-set-edit.component.css']
})

export class FormTypeMediaSetEditComponent extends FieldType implements OnInit {
  public opt: any;

  public form = null;
  public fields = [];
  public mediaSet: MediaSet = null;

  public hasMediaSet = false;
  public providerId = null;
  public providerName = null;
  public providerRole = null;

  constructor(
    public dialog: MatDialog,
    public authService: AuthService
  ) {
    super();
    this.opt = {};
  }

  ngOnInit() {
    super.ngOnInit();
    this.opt = this.to;

    this.form = new FormGroup({});

    this.mediaSet = this.opt.getMediaSet();
    this.hasMediaSet = this.mediaSet !== null;

    this.providerId = this.opt.providerId;
    this.providerName = this.opt.providerName;
    this.providerRole = this.opt.providerRole;
    if (this.hasMediaSet) {
      this.setupMediaSetFields();
    }
  }

  openMediaSetSelect() {
    const dialogRef = this.dialog.open(MediaSetSelectDialogComponent, {
      panelClass: 'media-set-select-dialog-container',
      data: {
        provider: {
          ID: this.providerId,
          Name: this.providerName,
          AnbieterRolle: this.providerRole
        },
        excludeMediaSets: [],
      },
      maxHeight: '100vh'
    });
    dialogRef.afterClosed().subscribe((mediaSet: MediaSet) => {
      if (typeof mediaSet !== 'undefined' && mediaSet !== null) {
        this.mediaSet = mediaSet;
        if (!this.hasMediaSet) {
          this.hasMediaSet = true;
        }
        this.setupMediaSetFields();
        if (typeof this.opt.onMediaSetChange === 'function') {
          this.opt.onMediaSetChange(mediaSet);
        }
      }
    });
  }

  unlinkMediaSet() {
    this.hasMediaSet = false;
    this.mediaSet = null;

    if (typeof this.opt.onMediaSetChange === 'function') {
      this.opt.onMediaSetChange(null);
    }
  }

  setupMediaSetFields() {
    this.fields = [{
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        { // Tipp
          type: 'tip',
          templateOptions: {
            label: 'Warnung',
            open: true,
            description: '<p>Es ist zu beachten, dass nur Enterprise Kunden mehr als 5 Medien anhängen dürfen.\
                          Für den Systemuser wird nicht auf dieses Limit überprüft!</p>'
          },
          // tslint:disable-next-line:max-line-length
          hideExpression: () => !this.authService.isSystem() || (this.mediaSet.Downloads.length <= 5 && this.mediaSet.Testberichte.length <= 5 && this.mediaSet.Formulare.length <= 5)
        }
      ]
    }, {
      type: 'media-linking',
      className: 'width-100',
      templateOptions: {
        providerId: this.mediaSet.AnbieterID,
        Downloads: this.mediaSet.Downloads,
        Testberichte: this.mediaSet.Testberichte,
        Videos: this.mediaSet.Videos,
        Formulare:  this.mediaSet.Formulare,
        ref: this.to.ref,
        limits: {
          Downloads: this.authService.isDefault() ? 5 : 10,
          Testberichte: this.authService.isDefault() ? 5 : 10,
          Formulare: this.authService.isDefault() ? 5 : 10
        }
      }
    }];
  }
}
