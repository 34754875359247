import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import { MatDialog } from '@angular/material';
import { UngroupedProviderSelectDialogComponent } from '../dialog/ungrouped-provider-select-dialog/ungrouped-provider-select-dialog.component';
import { UngroupedProviderSelectDialog } from 'src/app/classes/ungrouped-provider-select-dialog';
import { FormControl} from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-formly-provider-select-section',
  templateUrl: './form-type-provider-select.component.html',
  styleUrls: ['./form-type-provider-select.component.css'],
})

export class FormTypeProviderSelectComponent extends FieldType implements OnInit {
  @ViewChild('providerLabels') private providerLabels: ElementRef;

  public inputFormControl: FormControl;

  public initialized: boolean;

  constructor(
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.initialized = false;
    if (this.to.providers instanceof Observable) {
      this.to.providers.subscribe((result) => {
        this.to.providers = result;
        this.initialized = true;

        const values = [];
        const currentValues = this.formControl.value;
        for (let i = 0; i < this.to.providers.length; i++) {
          if (this.to.providers[i].selected || currentValues.indexOf(this.to.providers[i].value)) {
            values.push(this.to.providers[i].value);
          }
        }
        this.formControl.patchValue(this.to.single ? values[0] : values);

        this.setProviderLabels();
      });
    } else {
      this.initialized = true;
    }
  }
  
  ngAfterViewInit() {
    if (this.initialized) {
      this.setProviderLabels();
    }
  }


  openSelectDialog() {
    const ungroupedProviderSelectDialog = new UngroupedProviderSelectDialog();

    ungroupedProviderSelectDialog.providers = this.to.providers;
    ungroupedProviderSelectDialog.single = this.to.single || false;

    this.dialog.open(UngroupedProviderSelectDialogComponent, {
      data: ungroupedProviderSelectDialog
    }).afterClosed().subscribe((providers: Array<any>) => {
      if (typeof providers !== 'undefined') {
        const values = [];
        for (let i = 0; i < providers.length; i++) {
          values.push(providers[i].value);
        }
        this.formControl.patchValue(this.to.single ? values[0] : values);

        for (let i = 0; i < this.to.providers.length; i++) {
          this.to.providers[i].selected = (this.to.single && this.formControl.value === this.to.providers[i].value) || (!this.to.single && this.formControl.value.indexOf(this.to.providers[i].value) >= 0);
        }

        this.setProviderLabels();
      }
    });
  }

  setProviderLabels() {
    let value = '';
    for (let i = 0; i < this.to.providers.length; i++) {
      if (this.to.providers[i].selected) {
        if (value.length > 0) {
          value += ', '
        }
        value += this.to.providers[i].label;
      }
    }
    this.providerLabels.nativeElement.value = value ? value : 'Keine Auswahl';
  }
}
