import { ViewChild, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material';
import { Observable, of, Subscription } from 'rxjs';

import { FilterComponent } from '../components/filter/filter.component';

import { NavigationService } from '../services/navigation/navigation.service';
import { MessageService } from '../services/message/message.service';


export abstract class ItemList<T extends {ID: any}> implements OnInit {
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(FilterComponent) public filter: FilterComponent;

  public items: Array<T> = [];

  public count = 0;
  public pageSize = 10;
  public pageIndex = 0;

  private loadSubscription: Subscription = null;

  constructor(
    private listName: string,
    public navigationService: NavigationService,
    public messageService: MessageService,
    private listTitle?: string
  ) { }

  ngOnInit() {
    if (this.listTitle) {
        this.navigationService.title = this.listTitle;
    }

    if (this.filter) {
        this.filter.onInit(() => this.initList());
    } else {
        this.initList();
    }
  }

  initList(): void {
    this.count = 0;

    if (this.paginator) {
      this.pageIndex = 0;
      this.paginator.pageIndex = this.pageIndex;
    }
    this.loadItemList();
  }

  abstract loadItems(limit: number, offset: number): Observable<{items: T[], maxCount: number}> | {items: T[], maxCount: number};

  loadItemList(): void {
    this.items = [];

    let loadItemResult = this.loadItems(this.pageSize, this.pageIndex * this.pageSize);

    if (!(loadItemResult instanceof Observable)) {
        loadItemResult = of(loadItemResult);
    } else {
        this.navigationService.startLoading(this.listName + '_loading');
    }

    if (this.loadSubscription !== null) {
      this.loadSubscription.unsubscribe();
      this.loadSubscription = null;
    }

    this.loadSubscription = loadItemResult.subscribe(result => {
      this.loadSubscription = null;
      this.navigationService.stopLoading(this.listName + '_loading');

      for (let i = 0; i < this.pageIndex * this.pageSize; i++) {
        this.items.push(<any>{});
      }
      for (let i = 0; i < result.items.length; i++) {
        this.items.push(result.items[i]);
      }

      this.count = result.maxCount;
      if (this.count / this.pageSize < this.pageIndex) {
        if (this.count === 0) {
          this.pageIndex = 0;
        } else {
          this.pageIndex = Math.floor((this.count - 1) / this.pageSize);
        }
        this.paginator.pageIndex = this.pageIndex;
      }
    }, error => {
      this.loadSubscription = null;
       this.navigationService.stopLoading(this.listName + '_loading');
        this.messageService.showError('Beim Laden der Inhalte ist es zu einem Fehler gekommen: '
         + error.message, error);
    });
  }

  onDelete(item: T) {
      const currentLength = this.items.length;
      this.items = this.items.filter(entry => entry.ID !== item.ID);
      this.count -= currentLength - this.items.length;
      if (this.items.length === 0 && this.pageIndex > 0) {
        this.pageIndex--;
        this.loadItemList();
      }
  }

  pageChanged(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadItemList();
  }

  getSelected() {
    return [];
  }
}
