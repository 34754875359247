import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

import { forkJoin } from 'rxjs';

import { Product } from '../../classes/product';

import { Medium } from '../../classes/medium';
import { PendingMedium } from '../../classes/media-set';

import { MediaService } from '../../services/media/media.service';
import { MediaSetService } from '../../services/media-set/media-set.service';
import { PdfViewerService } from '../../services/pdfviewer/pdfviewer.service';
import { StorageService } from '../../services/storage/storage.service';
import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.css']
})
export class ProductInfoComponent implements OnInit {
  @Input() public product: Product;
  public media: any;

  constructor(
    private mediaSetService: MediaSetService,
    private mediaService: MediaService,
    public navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.navigationService.startLoading('product_info_init');
    forkJoin([
      this.mediaSetService.loadMediaSet(this.product.MediaSet)
    ]).subscribe((result) => {
      const mediaSet = result[0];
      const media = this.media = {
        Downloads: [],
        Testberichte: [],
        Videos: [],
        Formulare: []
      };
      const mediaIds = [].concat(mediaSet.Downloads, mediaSet.Testberichte, mediaSet.Videos, mediaSet.Formulare);
      this.mediaService.loadMediaListByIds(
          mediaIds.filter(value => typeof value === 'number').map((value) => value.toString())
          ).subscribe((medias) => {
        ['Downloads', 'Testberichte', 'Videos', 'Formulare'].forEach((fieldName) => {
            mediaSet[fieldName].forEach((mediaId: number | PendingMedium) => {
                if (mediaId instanceof PendingMedium) {
                    media[fieldName].push(mediaId);
                } else {
                    const mediaItem = medias.mediaList.find((entry: Medium) => entry.ID === mediaId);
                    if (mediaItem) {
                        media[fieldName].push(mediaItem);
                    }
                }
            });
        });
        this.navigationService.stopLoading('product_info_init');
      });
    });
  }
}

const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

export class ProductInfoEntryComponent {

  constructor() {

  }

  isVisible(mediaItem: Medium) {
    const fromDate = new Date(mediaItem.Beginn);
    const toDate = new Date(mediaItem.Ablauf);
    const nowDate = new Date();
    return nowDate >= fromDate && nowDate <= toDate;
  }

  getNotVisibleTooltip(mediaItem: Medium): string {
    const fromDate = new Date(mediaItem.Beginn);
    const toDate = new Date(mediaItem.Ablauf);
    const nowDate = new Date();
    if (nowDate < fromDate) {
      return 'Dieses Medium wird am ' + fromDate.toLocaleDateString('de-DE', dateOptions) + ' veröffentlicht.';
    }
    if (nowDate > toDate) {
      return 'Dieses Medium ist seit dem ' + fromDate.toLocaleDateString('de-DE', dateOptions) + ' nicht mehr öffentlich.';
    }
    return 'Dieses Medium ist noch nicht veröffentlicht.';
  }

}

export class ProductInfoEntryPDFComponent extends ProductInfoEntryComponent {

  constructor(public storageService: StorageService, public pdfViewer: PdfViewerService) {
    super();
  }

  openPDF(pdf: Medium) {
    if (pdf.Link) {
      this.storageService.getSignedUrl(pdf.Link).subscribe(url => {
        this.pdfViewer.openDoc(pdf.Titel, url);
      }, error => {
        console.log('error getting url: ' + error);
      });
    }
  }
}
