import { Component, OnInit, Input } from '@angular/core';

import { ProductService } from '../../services/product/product.service';
import { Product } from '../../classes/product';
import { ProductBenefits } from '../../classes/product-benefits';

@Component({
  selector: 'app-product-benefits',
  templateUrl: './product-benefits.component.html',
  styleUrls: ['./product-benefits.component.css']
})
export class ProductBenefitsComponent implements OnInit {

  @Input() public product: Product;
  public benefits: ProductBenefits;
  public progress = true;

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    this.getBenefits(this.product.ID);
  }

  getBenefits(id: string): void {
    this.productService.getBenefits(id).subscribe((benefits: ProductBenefits) => {
      this.benefits = benefits;
      this.progress = false;
    });
  }
}
