import { MediaSet } from './media-set';

export class Product {
  ID: string;
  Modell: string; // BUSINESS | BUSINESS_PRO
  Status?: string; // ENTWURF | IN_PRUEFUNG | FREIGEGEBEN
  AnbieterID: string;
  AnbieterName: string;
  AnbieterRolle?: 'Versicherer' | 'Makler';
  ProduktID: string;
  Bezeichnung: string; // title
  Beschreibung: string;
  ProduktBezeichnung: string; // product title e.g. Privathaftpflicht
  Individuell: boolean; // no quotation possible
  Erstelldatum: Date;
  Aenderungsdatum: Date;
  Autor?: string;
  Version: number;
  TarifrechnerVersionMin?: string;
  TarifrechnerVersionMax?: string;
  Beginn: Date;
  Ablauf: Date;
  Suchbegriffe?: Array<string>;
  Kategorien?: Array<{ ID: string, Sortierung: number }>;
  Stages?: Array<string>;
  Highlights: Array<{ Text: string }>;
  Filter: Object;
  Leistungen?: Object;
  Defaults: Object;
  Auftrag?: string;
  Vorbehaltstarifierung?: boolean;
  AbrechnungsfreiBis: Date;
  BudgetLimit?: number;
  Mapping: string;
  MediaSet?: string;
  MediaSetInstanz?: MediaSet;
  Berechtigungen: Array<string>;
  Fragen?: Array<Object>;
  Email?: string;
  Risikotraeger?: Array<string>;
  Provisionsregelung?: string;
  ProvisionsregelungsText?: string;

  // Business Plus
  BusinessPlusParameter?: {LinkID:number, Wert: string};

  // FREE
  Text?: string;
  Url?: string;
  AnsprechPartnerAnrede?: string;
  AnsprechPartnerVorname?: string;
  AnsprechPartnerName?: string;
  AnsprechPartnerEmail?: string;
  ScreenshotLink?: string;
  CrawlingDate?: Date;
}

