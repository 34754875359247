import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

import { LeistungsEditorDialog } from '../../../classes/leistungseditor-dialog';

@Component({
  selector: 'app-leistungseditor-dialog',
  templateUrl: './leistungseditor-dialog.component.html',
  styleUrls: ['./leistungseditor-dialog.component.css']
})
export class LeistungsEditorDialogComponent implements OnInit {
  public form = new FormGroup({});
  public model: any = {};
  public options: FormlyFormOptions = {};
  public fields: FormlyFieldConfig[];

  constructor(
    public dialogRef: MatDialogRef<LeistungsEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LeistungsEditorDialog
  ) {
  }

  ngOnInit() {
    this.model = {};
    const dataKeys = Object.keys(this.data.Data);
    dataKeys.forEach(key => {
      const dataItem = this.data.Data[key];
      if (typeof dataItem !== 'object') {
        return;
      }
      this.model[key] = {};

      if (typeof dataItem.Einschluss !== 'undefined') {
        this.model[key]['Einschluss'] = dataItem.Einschluss ? 'true' : 'false';
      } else {
        this.model[key]['Einschluss'] = '';
      }
      this.model[key]['Kommentar'] = dataItem.Kommentar || '';
    });

    this.fields = [];
    this.data.Template.forEach((itemGroup) => {
      this.fields.push({
        template: '<p class="fact-title">' + itemGroup.Titel + '</p>'
      });

      const factKeys = Object.keys(itemGroup.Fakten);
      factKeys.forEach((key) => {
        const fact = itemGroup.Fakten[key];

        let helpcolumn = {};
        if (fact.Hilfe) {
          helpcolumn = {
            type: 'icon',
            className: 'padding-top-12',
            templateOptions: {
              icon: 'help',
              tooltip: fact.Hilfe,
              cursor: 'pointer',
              size: '24px'
            }
          };
        } else {
          helpcolumn = {
            template: '<span class="icon-placeholder"></span>'
          };
        }

        this.fields.push({
          fieldGroupClassName: 'display-flex',
          key: key,
          fieldGroup: [{
            template: '<span class="fact-text">' + fact.Text + '</span>'
          },
          helpcolumn,
          {
            key: 'Einschluss',
            type: 'icon-select',
            className: 'flex-1',
            defaultValue: '',
            wrappers: ['form-field'],
            templateOptions: {
              label: 'Eingeschlossen',
              floatLabel: 'always',
              appearance: 'outline',
              options: [
                {color: '#969696', icon: 'remove_circle', label: 'Keine Angabe', value: '' },
                {color: '#7ab51d', icon: 'check_circle', label: 'Ja', value: 'true' },
                {color: '#e20513', icon: 'cancel', label: 'Nein', value: 'false' }
              ],
              focus: () => { },
              blur: () => { },
              change: () => {
              },
            }
          }, {
            key: 'Kommentar',
            type: 'input',
            className: 'flex-3',
            templateOptions: {
              label: 'Kommentar',
              floatLabel: 'always',
              appearance: 'outline',
              maxLength: 100
            },
            expressionProperties: {
              'templateOptions.disabled': (model) => {
                return model.Einschluss === '';
              }
            }
          }]
        });
      });
    });
  }

  onCloseClick(save: boolean): void {
    if (!save) {
      this.dialogRef.close({save: save});
      return;
    }

    const result = {};
    const modelKeys = Object.keys(this.model);
    modelKeys.forEach(key => {
      const modelItem = this.model[key];
      if (modelItem.Einschluss !== '') {
        result[key] = {
          Einschluss: modelItem.Einschluss === 'true',
          Kommentar: modelItem.Kommentar || ''
        };
      }
    });
    this.dialogRef.close({save: save, data: result});
  }
}
