import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import * as _moment from 'moment';

import { SearchFieldComponent } from '../../search-field/search-field.component';
import { ProviderSelectDialog } from '../../../classes/provider-select-dialog';
import { Provider } from '../../../classes/provider';

import { StorageService } from '../../..//services/storage/storage.service';
import { ProviderCreateDialogComponent } from '../provider-create-dialog/provider-create-dialog.component';
import { ItemList } from 'src/app/classes/item-list';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ProviderService } from 'src/app/services/provider/provider.service';
import { MessageService } from 'src/app/services/message/message.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-provider-select-dialog',
  templateUrl: './provider-select-dialog.component.html',
  styleUrls: ['./provider-select-dialog.component.css']
})
export class ProviderSelectDialogComponent extends ItemList<Provider> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  public selectedProvider: Provider = null;

  constructor(
    public navigationService: NavigationService,
    private providerService: ProviderService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<ProviderSelectDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ProviderSelectDialog
  ) {
    super('provider_select', navigationService, messageService);
  }

  onCloseClick(selectedProvider: Provider = null): void {
    this.dialogRef.close(selectedProvider);
  }

  createNewProvider(): void {
    const dialogRef = this.dialog.open(ProviderCreateDialogComponent, {
      panelClass: 'provider-select-dialog-container',
      maxHeight: '100vh'
    });
    dialogRef.afterClosed().subscribe((provider: Provider) => {
      if (provider !== null) {
        this.dialogRef.close(provider);
      }
    });
  }

  loadItems(limit: number, offset: number) {
    return this.providerService.loadProviderList(
      limit,
      offset,
      this.data.excludeProviderIds,
      this.searchField.searchTerm || null).pipe(
        map(result => ({items: result.providerList, maxCount: result.metaData.maxCount}))
      );
  }
}
