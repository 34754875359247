import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-drag-drop-section',
  templateUrl: './form-type-drag-drop.component.html',
  styleUrls: ['./form-type-drag-drop.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormTypeDragDropComponent extends FieldType {
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.field.fieldGroup, event.previousIndex, event.currentIndex);
    if (this.field.templateOptions.onDrop) {
      this.field.templateOptions.onDrop(event.previousIndex, event.currentIndex);
    }
  }
}
