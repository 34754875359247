import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { NavigationService } from '../../services/navigation/navigation.service';

import { Medium } from '../../classes/medium';

import * as _moment from 'moment';
import { MediaService } from '../../services/media/media.service';
const moment = _moment;

@Component({
  selector: 'app-media-create-item',
  templateUrl: './media-create-item.component.html',
  styleUrls: ['./media-create-item.component.css']
})
export class MediaCreateItemComponent implements OnInit {

  public medium: Medium;
  public originalMedium: Medium;
  private parameterSubscription: Subscription;

  constructor(
    public navigationService: NavigationService,
    public activatedRoute: ActivatedRoute,
    public mediaService: MediaService
  ) {
  }

  ngOnInit() {
    this.navigationService.title = 'Medium erstellen';
    this.navigationService.startLoading('media_create_init');

    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {
      if (params['mediaId']) {
        this.mediaService.loadMedia(params['mediaId'], true).subscribe((result: Medium) => {
          this.originalMedium = JSON.parse(JSON.stringify(result));
          if (moment() >= moment(this.originalMedium.Ablauf)) {
            this.originalMedium = null;
          }

          this.medium = result;
          this.medium.ID = 0;
          this.medium.Dateiname = '';
          this.medium.Beginn = moment().toDate();
          this.medium.Ablauf = moment('2099-12-31').toDate();
          this.navigationService.stopLoading('media_create_init');
        });
      } else {
        this.originalMedium = null;
        this.medium = {
          ID: 0,
          Typ: null,
          AnbieterID: '',
          AnbieterName: '',
          Titel: '',
          InternerTitel: '',
          Link: '',
          Bild: '',
          Dateiname: '',
          Erstelldatum: new Date(),
          Aenderungsdatum: new Date(),
          Autor: '',
          Version: 1,
          Stand: moment().format('MM.YYYY'),
          Beginn: moment().toDate(),
          Ablauf: moment('2099-12-31').toDate(),
          Suchbegriffe: [],
          Berechtigungen: ['ROLE_BROKER', 'ROLE_CUSTOMER']
        };

        this.navigationService.stopLoading('media_create_init');
      }
    });
  }
}
