import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, of, forkJoin } from 'rxjs';
import * as _moment from 'moment';
import { Router } from '@angular/router';

import { Category } from '../../classes/category';
import { Editor } from '../../classes/editor';
import { ItemComponent } from '../../classes/item-component';
import { GenericDialog } from '../../classes/generic-dialog';

import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';

import { PdfViewerService } from '../../services/pdfviewer/pdfviewer.service';
import { CategoryService } from '../../services/category/category.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MessageService } from '../../services/message/message.service';
import { AuthService } from '../../services/auth/auth.service';
import { EditorService } from 'src/app/services/editor/editor.service';
import { ProductGroupService } from 'src/app/services/productgroup/productgroup.service';

export class CategoryEditor extends Editor<CategoryItemComponent, any> {

  constructor(
    public component: CategoryItemComponent,
    private router: Router,
    private messageService: MessageService,
    public dialog: MatDialog,
    private categoryService: CategoryService,
    private isNewCategory: boolean,
    private editorService: EditorService,
    private productGroupService: ProductGroupService
  ) {
      super(component);
  }

  submit(model: any, type: string): Observable<void> {
    return new Observable(subscriber => {
      this.submitModel(model).subscribe((category: Category) => {
        this.editorService.setModel(null);
        subscriber.next();
        subscriber.complete();

        if (this.isNewCategory) {
          this.router.navigate(['/category-edit', category.ID]);
        } else if (this.router.url !== '/category-list') {
          this.router.navigate(['/category-list']);
        } else {
          this.component.editCategory(false);
        }
      }, error => {
        subscriber.error(error);
        this.messageService.showError('Beim Aktualisieren der Kategorie ist es zu einem Fehler gekommen: '
         + error.error.message, error);
      });
    });
  }

  submitModel(model): Observable<Category> {
    if (this.isNewCategory) {
      return this.categoryService.create(model);
    }
    return this.categoryService.update(model);
  }

  preModelModifier(model: any): void {
    if (model.Leistungen && typeof model.Leistungen !== 'string') {
      model.Leistungen = JSON.stringify(model.Leistungen);
    } else if (typeof model.Leistungen === 'undefined' || model.Leistungen === null) {
      model.Leistungen = [];
    }

    if (model.ID.length > 0) {
      const idSplit = model.ID.split('-');
      if (idSplit.length >= 2) {
        model.IDSuffix = '-' + idSplit.pop();
        model.IDValue = idSplit.join('-');
      }
    }

    if (typeof model.Highlights === 'undefined') {
      model.Highlights = '';
    }

    model.Highlights = model.Highlights.replace(/<br \/>/g, '<br>');
  }

  postModelModifier(model: any, type: string): void {
    if (model.Leistungen) {
      try {
        model.Leistungen = JSON.parse(model.Leistungen);
      } catch (e) {
        model.Leistungen = [];
      }
    }
    model.ID = model.IDValue + model.IDSuffix;

    model.Highlights = model.Highlights.replace(/<br \/>/g, '<br>');
    model.Highlights = model.Highlights.replace(/<p>\s+<\/p>/g, '<p><br></p>');
    let trimmedHighlights = model.Highlights;
    do {
      model.Highlights = trimmedHighlights;
      trimmedHighlights = model.Highlights.replace(/<p><br><\/p><p><br><\/p>/g, '<p><br></p>');
    } while (trimmedHighlights !== model.Highlights);
  }
}


@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.css']
})
export class CategoryItemComponent extends ItemComponent implements OnInit {

  @Input() public category: Category;
  @Input() public panelOpenState = false;
  @Input() public info = false;
  @Input() public edit = false;
  @Input() public cancelRedirectUrl = '';

  public editor: CategoryEditor;
  public editorInitialized = false;

  public isNewCategory = false;
  public isRoot = false;

  private controllerSuggestions: Array<string>;
  private groupSuggestions: Array<string>;

  constructor(
    public dialog: MatDialog,
    public pdfViewer: PdfViewerService,
    private categoryService: CategoryService,
    private router: Router,
    private navigationService: NavigationService,
    private messageService: MessageService,
    private authService: AuthService,
    private editorService: EditorService,
    private productGroupService: ProductGroupService
  ) {
    super();
    this.controllerSuggestions = [
      'individuell',
      'spezial',
      'reisekranken',
      'auto',
      'conti-lebenAnlageform',
      'basisrente',
      'berufsunfaehigkeitBAV',
      'renteBAV',
      'beihilfe',
      'betriebshaftpflicht',
      'conti-berufsunfaehigkeit',
      'berufsunfaehigkeit',
      'conti-lebenDefault',
      'conti-bc',
      'einmalanlage',
      'ergaenzung',
      'erwerbsunfaehigkeitbav',
      'glas',
      'gruppenunfall',
      'hausrat',
      'hundehalterhaftpflicht',
      'krankentagegeld',
      'motorrad',
      'pferdehalterhaftpflicht',
      'pflege',
      'haftpflicht',
      'bav',
      'leben',
      'rente',
      'risiko',
      'rechtsschutz',
      'sterbegeld',
      'unfall',
      'unfallrente',
      'gebaeude',
      'vollversicherung',
      'bauherren',
      'veranstaltung',
      'mv-betriebshaftpflicht',
      'mv-risiko',
      'mv-spezial'
    ];

    productGroupService.loadProductGroupList('egal').subscribe(productGroupList => {
      this.groupSuggestions = [];
      productGroupList.productGroupList.forEach(element => {
        this.groupSuggestions.push(element.ID);
      });
    }, error => {
      console.error("error getting productgroups", error);
    });
  }

  createEditorSteps(): void {

    // create step 1
    this.editor.steps.push({
      label: 'Zuordnungsdaten',
      fields: [
        { // Leerzeile / Zwischenüberschrift
          template: '<p><strong>Produktgruppe</strong></p>'
        },
        { // 2. Zeile
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            { // Anbieter
              key: 'ProduktgruppenID',
              type: 'autocomplete',
              className: 'flex-1',
              templateOptions: {
                label: 'Produktgruppen ID',
                appearance: 'outline',
                maxLength: 128,
                required: true,
                filter: (term) => this.groupSuggestions.filter((group: string) => {
                  return term.length === 0 || group.toLowerCase().indexOf(term.toLowerCase()) === 0;
                }),
                onChange: (value) => {
                  const valueSplit = value.split('-');
                  if (valueSplit.length === 2) {
                    // Setzt den Suffix für die ID
                    this.editor.steps[0].fields[3].fieldGroup[1].formControl.setValue('-' + valueSplit[1]);
                  } else {
                    this.editor.steps[0].fields[3].fieldGroup[1].formControl.setValue('');
                  }
                  if (value.toLowerCase() === 'individuell-snoopr') {
                    this.editor.steps[0].fields[5].formControl.setValue('individuell');
                  }
                }
              },
              validators: {
                urlValidation: {
                  expression: (field) => !field.value || /^[a-zA-Z]+-[a-zA-Z]+$/.test(field.value),
                  message: () => 'Die ProduktgruppenID ist ungültig.'
                }
              },
              expressionProperties: {
                'templateOptions.disabled': (model) => {
                  return !this.isNewCategory && !this.authService.isSystem();
                }
              }
            },
            {
              key: 'ProduktgruppenSortierung',
              type: 'input',
              className: 'flex-1',
              templateOptions: {
                type: 'number',
                label: 'Sortierung der Kategorie innerhalb der Produktgruppe',
                appearance: 'outline',
                min: 0
              }
            },
          ]
        },
        { // Leerzeile / Zwischenüberschrift
          template: '<p><strong>Eindeutige Identifikation</strong></p>'
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [{
            type: 'input',
            key: 'IDValue',
            className: 'flex-1',
            templateOptions: {
              label: 'ID',
              appearance: 'outline',
              description: 'Die eindeutige Identifikation ist eine einmalige Bezeichnung zur Wiedererkennung der Kategorie. \
                            Diese wird einmalig bei der Erstellung zugewiesen.',
              disabled: !this.isNewCategory,
              maxLength: 95,
              required: true
            }
          }, {
            type: 'input',
            key: 'IDSuffix',
            className: 'flex-1',
            templateOptions: {
              appearance: 'outline',
              disabled: true,
              maxLength: 32
            }
          }]
        },
        { // Leerzeile / Zwischenüberschrift
      template: '<br><p><strong>Controller ID</strong></p>\
		  <p>Der Controller ist die Steuereinheit zur Berechnung der Produkte dieser Kategorie.</p>'
        },
        {
          type: 'autocomplete',
          key: 'Controller',
          templateOptions: {
            label: 'Controller',
            appearance: 'outline',
            description: '',
            filter: (term) => this.controllerSuggestions.sort().filter((controller: string) => {
              return term.length === 0 || controller.toLowerCase().indexOf(term.toLowerCase()) === 0;
            }),
            required: true
          },
          expressionProperties: {
            'templateOptions.disabled': (model) => {
              if (!this.authService.isSystem() && !this.isNewCategory) {
                return true;
              }
              if (model.ProduktgruppenID && model.ProduktgruppenID.toLowerCase() === 'individuell-snoopr') {
                return true;
              }
              return false;
            }
          }
        }
      ]
    });

    // create step 2
    this.editor.steps.push({
      label: 'Darstellung',
      fields: [
        { // Leerzeile / Zwischenüberschrift
          template: '<p><strong>Bezeichnung / Titel</strong></p>'
        },
        {
          type: 'input',
          key: 'Bezeichnung',
          templateOptions: {
            label: 'Bezeichnung',
            appearance: 'outline',
            description: 'Die Bezeichnung ist der dargestellte Name der Kategorie.',
            maxLength: 256,
            required: true
          }
        },
        { // Leerzeile / Zwischenüberschrift
          template: '<br/>'
        }, {
          type: 'input',
          key: 'Kurzbezeichnung',
          templateOptions: {
            label: 'Kurzbezeichnung',
            appearance: 'outline',
            // tslint:disable-next-line:max-line-length
            description: 'Die vereinfachte Kurzform der Kategorie. Beispiel: Wenn die Bezeichnung "Privathaftpflichtversicherung" ist, dann wäre die Kurzform "Privathaftpflicht"',
            maxLength: 256,
            required: true
        },
        hideExpression: () => !this.isRoot
        },
        { // Leerzeile / Zwischenüberschrift
          template: '<p>&nbsp;<br/><strong>Redaktioneller Text</strong></p>',
          hideExpression: () => {
            return !this.category.ProduktgruppenID || this.category.ProduktgruppenID.toLowerCase() === 'individuell-snoopr';
          }
        },
        {
          type: 'quill',
          key: 'Highlights',
          templateOptions: {
            label: 'Highlights',
            appearance: 'outline',
            rows: 10,
            description: 'Hierbei handelt es sich um den Inhalt der Startseite der Kategorie, ' +
                         'der dort als (formatierter) Text angezeigt wird.',
            prefix: this.category.ID.indexOf('-SNOOPR') >= 0 ? '<div class="bos-salesproduct-highlight">' : '',
            suffix: this.category.ID.indexOf('-SNOOPR') >= 0 ? '</div>' : '',
            tools: [
              'fett', 'kursiv', 'unterstrichen',
              'ueberschrift1', 'ueberschrift3',
              'nummerierte-liste', 'liste',
              'formatierung_entfernen'
            // tslint:disable-next-line:max-line-length
            ].concat(this.category.ID.indexOf('-SNOOPR') >= 0 ? ['bostitel', 'bosinfo', 'bosbeispiele', 'bosbeispiel1', 'bosbeispiel2'] : [])
          },
          hideExpression: () => {
            return !this.category.ProduktgruppenID || this.category.ProduktgruppenID.toLowerCase() === 'individuell-snoopr';
          }
        }
      ]
    });

    // create step 3
    this.editor.steps.push({
      label: 'Medien und Leistungen',
      fields: [
        { // Leerzeile / Zwischenüberschrift
          template: '<p><strong>Verknüpftes Medien-Paket</strong></p>',
          hideExpression: () => {
            return !this.category.MediaSet;
          }
        },
        { // 2. Zeile
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              type: 'input',
              key: 'MediaSet',
              className: 'flex-3',
              templateOptions: {
                label: 'Medien-Paket Bezeichnung',
                appearance: 'outline',
                disabled: true
              },
              hideExpression: () => {
                return !this.category.MediaSet;
              }
            },
            {
              type: 'button',
              className: 'flex-1 media-item-preview-button',
              templateOptions: {
                color: 'default',
                label: 'Medien-Paket bearbeiten',
                onClick: (field) => {
                  const genericDialog = new GenericDialog();
                  genericDialog.titel = 'Medien-Paket Bearbeitung';
                  genericDialog.text = 'Sie sind dabei in die Medien-Paket Bearbeitung zu wechseln. \
                                        <strong>Alle ungespeicherten Änderungen in dieser Kategorie gehen verloren.</strong>';
                  const dialogRef = this.dialog.open(GenericDialogComponent, {
                    maxWidth: '500px',
                    maxHeight: '100vh',
                    data: genericDialog
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result === true) {
                      this.router.navigate(['/media-set-edit', this.category.MediaSet]);
                    }
                  });
                }
              },
              hideExpression: () => {
                return !this.category.MediaSet;
              }
            }
          ]
        },
        { // Leerzeile / Zwischenüberschrift
      template: '<p><strong>Leistungsbeschreibung hinterlegen</strong></p> \
		  <p>Hier können standardisierte Leistungstabellen im JSON-Format definiert werden, \
		  die bei der Produktanlage ausgefüllt werden können.</p>',
          hideExpression: () => {
            return !this.authService.isSystem() && !this.isNewCategory;
          }
        },
        {
          key: 'Leistungen',
          type: 'json',
          templateOptions: {
            label: 'Leistungsbeschreibung bearbeiten',
            title: 'Detaillierte Eigenschaften zum Produkt hinterlegen.'
          },
          hideExpression: () => {
            return !this.authService.isSystem() && !this.isNewCategory;
          }
        }
      ]
    });
  }

  initEditor() {
    if (!this.editorInitialized) {
      this.navigationService.startLoading('category_editor_init');

      this.editor = new CategoryEditor(
        this,
        this.router,
        this.messageService,
        this.dialog,
        this.categoryService,
        this.isNewCategory,
        this.editorService,
        this.productGroupService
      );

      // tslint:disable-next-line:max-line-length
      this.editor.setInfo1(() => '');
      this.editor.setInfo2(() => '');
      this.editor.title = 'EDITOR';

      this.createEditorSteps();
      this.editorInitialized = true;
      setTimeout(() => {
        this.editorService.setModel(this.category);
      }, 0);
      this.navigationService.stopLoading('category_editor_init');
  }
  }

  ngOnInit() {
    this.isNewCategory = this.category.ID === '';
    if (this.edit) {
      this.panelOpenState = true;
      this.initEditor();
    }
    this.isRoot = this.authService.isSystem();
  }

  editCategory(edit: boolean) {
    this.info = false;
    this.edit = edit;

    if (edit) {
      this.initEditor();
    } else if (this.cancelRedirectUrl) {
      this.router.navigate([this.cancelRedirectUrl]);
    } else if (this.editorInitialized) {
      this.editorInitialized = false;
    }
  }

  ngOnDestroy(): void {
    if (this.editorInitialized) {
      this.editorService.setModel(null);
    }
  }
}
