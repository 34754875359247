import { Component, OnInit } from '@angular/core';

import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  constructor(public messageService: MessageService) { }

  ngOnInit() { }

  showMessage(event: Event, index: number): void {
    this.messageService.showMessage(index);
  }

  deleteMessage(event: Event, index: number): void {
    this.messageService.getMessages().splice(index, 1);

    event.preventDefault();
    event.stopImmediatePropagation();
  }

  deleteAllMessages(event: Event): void {
    this.messageService.getMessages().splice(0, this.messageService.getMessages().length);

    event.preventDefault();
    event.stopImmediatePropagation();
  }
}
