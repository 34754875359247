import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Profile } from 'src/app/classes/profile';

export class IBANResult {
  Status: 'OK'|'NOK';
  Meldung: string;
  Bank: {
    Name: string;
    BIC: string;
    PLZ: string;
    Ort: string;
  };
}


@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  /* URL  Definitionen */
  private static readonly utilsUrl = environment.api + '/utils/';

  constructor(
    private http: HttpClient
  ) { }

  public getBankInformation(iban: string): Observable<IBANResult> {
    return <Observable<IBANResult>> this.http.get(UtilsService.utilsUrl + 'bankinfo/' + iban);
  }

  private copyIfPresent(input: any, target: any, field: string) {
    if (input && input[field]) {
      target[field] = input[field];
    }
  }

  public getLegalInformationPdf(profile: Profile): Observable<string> {
    const request = {
      IHKRegistrierungsnummer: profile.CustomerID,
      Name: profile.Rechtsname,
      Anschrift: {},
      Kommunikation: {},
      IHK: {
        Anschrift: {}
      }
    };
    this.copyIfPresent(profile, request, 'Anrede');
    this.copyIfPresent(profile, request, 'Geschaeftsfuehrer');
    this.copyIfPresent(profile, request, 'Taetigkeitsart');
    this.copyIfPresent(profile, request, 'Beteiligungen');
    this.copyIfPresent(profile, request, 'TVO');
    this.copyIfPresent(profile, request, 'Schlichtungsstellen');
    this.copyIfPresent(profile, request.Anschrift, 'Strasse');
    this.copyIfPresent(profile, request.Anschrift, 'Hausnummer');
    this.copyIfPresent(profile, request.Anschrift, 'Postleitzahl');
    this.copyIfPresent(profile, request.Anschrift, 'Ort');
    this.copyIfPresent(profile, request.Kommunikation, 'Telefon');
    this.copyIfPresent(profile, request.Kommunikation, 'TelefonMobil');
    this.copyIfPresent(profile.IHK, request.IHK, 'Name');
    this.copyIfPresent(profile.IHK, request.IHK.Anschrift, 'Strasse');
    this.copyIfPresent(profile.IHK, request.IHK.Anschrift, 'Hausnummer');
    this.copyIfPresent(profile.IHK, request.IHK.Anschrift, 'Postleitzahl');
    this.copyIfPresent(profile.IHK, request.IHK.Anschrift, 'Ort');
    return <Observable<string>> this.http.post(UtilsService.utilsUrl + 'legalinformation/pdf', request).
      pipe(map(value => value['Base64']));
  }
}
