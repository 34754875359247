import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';

import { FormlyDialog } from '../../../classes/formly-dialog';

@Component({
  selector: 'app-formly-dialog',
  templateUrl: './formly-dialog.component.html',
  styleUrls: ['./formly-dialog.component.css']
})
export class FormlyDialogComponent {

  public form = new FormGroup({});

  constructor(public dialogRef: MatDialogRef<FormlyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: FormlyDialog) { }

  onCloseClick(save: boolean): void {
    this.dialogRef.close(save ? this.data.model : null);
  }
}
