import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { MediaListComponent } from './components/media-list/media-list.component';
import { MediaCreateItemComponent } from './components/media-create-item/media-create-item.component';
import { MediaEditItemComponent } from './components/media-edit-item/media-edit-item.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductCreateItemComponent } from './components/product-create-item/product-create-item.component';
import { ProductEditItemComponent } from './components/product-edit-item/product-edit-item.component';
import { MediaSetListComponent } from './components/media-set-list/media-set-list.component';
import { MediaSetCreateItemComponent } from './components/media-set-create-item/media-set-create-item.component';
import { MediaSetEditItemComponent } from './components/media-set-edit-item/media-set-edit-item.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { CategoryCreateItemComponent } from './components/category-create-item/category-create-item.component';
import { CategoryEditItemComponent } from './components/category-edit-item/category-edit-item.component';
import { ProvidersManagementComponent } from './components/providers-management/providers-management.component';
import { CustomerCreateItemComponent } from './components/customer-create-item/customer-create-item.component';
import { CustomerEditItemComponent } from './components/customer-edit-item/customer-edit-item.component';
import { ConsumerCreateItemComponent } from './components/consumer-create-item/consumer-create-item.component';
import { ConsumerEditItemComponent } from './components/consumer-edit-item/consumer-edit-item.component';
import { ProviderCreateItemComponent } from './components/provider-create-item/provider-create-item.component';
import { ProviderEditItemComponent } from './components/provider-edit-item/provider-edit-item.component';
import { NavigationGuard } from './guards/navigation/navigation.guard';
import { ChangesGuard } from './guards/changes/changes.guard';
// import { AnalyseDashboardComponent } from './components/analyse-dashboard/analyse-dashboard.component';

const routes: Routes = [
  { path: '', canActivateChild: [NavigationGuard], children: [
    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeComponent, canDeactivate: [ChangesGuard] },
    { path: 'profile', component: ProfileComponent, canDeactivate: [ChangesGuard] },
    { path: 'profile/:customerId', component: ProfileComponent, canDeactivate: [ChangesGuard] },
    { path: 'programs', component: ProgramsComponent, canDeactivate: [ChangesGuard] },
    { path: 'programs/:customerId', component: ProgramsComponent, canDeactivate: [ChangesGuard] },
    { path: 'media-list', component: MediaListComponent, canDeactivate: [ChangesGuard] },
    { path: 'media-create', component: MediaCreateItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'media-create/:mediaId', component: MediaCreateItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'media-edit/:mediaId', component: MediaEditItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'product-list', component: ProductListComponent, canDeactivate: [ChangesGuard]},
    { path: 'product-create', component: ProductCreateItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'product-create/:productId', component: ProductCreateItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'product-edit/:productId', component: ProductEditItemComponent, canDeactivate: [ChangesGuard]  },
    { path: 'media-set-list', component: MediaSetListComponent, canDeactivate: [ChangesGuard] },
    { path: 'media-set-create', component: MediaSetCreateItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'media-set-edit/:mediaSetId', component: MediaSetEditItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'category-list', component: CategoryListComponent, canDeactivate: [ChangesGuard] },
    { path: 'category-create', component: CategoryCreateItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'category-edit/:categoryId', component: CategoryEditItemComponent, canDeactivate: [ChangesGuard]  },
    { path: 'providers-management/:tabName', component: ProvidersManagementComponent , canDeactivate: [ChangesGuard]},
    { path: 'customer-create', component: CustomerCreateItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'customer-edit/:customerId', component: CustomerEditItemComponent, canDeactivate: [ChangesGuard]  },
    { path: 'provider-create', component: ProviderCreateItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'provider-edit/:providerId', component: ProviderEditItemComponent, canDeactivate: [ChangesGuard]  },
    { path: 'consumer-create', component: ConsumerCreateItemComponent, canDeactivate: [ChangesGuard] },
    { path: 'consumer-edit/:consumerId', component: ConsumerEditItemComponent, canDeactivate: [ChangesGuard]  },
   // { path: 'analyse-dashboard', component: AnalyseDashboardComponent, canDeactivate: [ChangesGuard]  },
    { path: '', redirectTo: '/login', pathMatch: 'full' }
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
