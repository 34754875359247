import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { map } from 'rxjs/operators';

import { ItemList } from '../../../classes/item-list';
import { SearchFieldComponent } from '../../search-field/search-field.component';
import { MediaSetSelectDialog } from '../../../classes/media-set-select-dialog';
import { MediaSet } from '../../../classes/media-set';
import { MediaSetCreateDialogComponent } from '../media-set-create-dialog/media-set-create-dialog.component';

import { NavigationService } from '../../../services/navigation/navigation.service';
import { MessageService } from '../../../services/message/message.service';
import { MediaSetService } from '../../../services/media-set/media-set.service';
import { MediaSetEditDialogComponent } from '../media-set-edit-dialog/media-set-edit-dialog.component';

@Component({
  selector: 'app-media-set-select-dialog',
  templateUrl: './media-set-select-dialog.component.html',
  styleUrls: ['./media-set-select-dialog.component.css']
})
export class MediaSetSelectDialogComponent extends ItemList<MediaSet> {
  @ViewChild(SearchFieldComponent) public searchField: SearchFieldComponent;

  public selectedMediaSet: MediaSet = null;

  constructor(
    public navigationService: NavigationService,
    private mediaSetService: MediaSetService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<MediaSetSelectDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MediaSetSelectDialog
  ) {
    super('media_set_select', navigationService, messageService);
  }

  onCloseClick(selectedMediaSet: MediaSet = null): void {
    if (selectedMediaSet !== null) {
      this.mediaSetService.loadMediaSet(selectedMediaSet.ID).subscribe((result: MediaSet) => {
        this.dialogRef.close(result);
      });
    } else {
      this.dialogRef.close(selectedMediaSet);
    }
  }

  createNewMediaSet(): void {
    const dialogRef = this.dialog.open(MediaSetCreateDialogComponent, {
      panelClass: 'media-set-create-dialog-container',
      maxHeight: '100vh',
      data: {
        specificProviderList: [this.data.provider]
      }
    });

    dialogRef.afterClosed().subscribe((mediaSet: MediaSet) => {
      if (typeof mediaSet !== 'undefined' && mediaSet !== null) {
        this.dialogRef.close(mediaSet);
      }
    });
  }

  loadItems(limit: number, offset: number) {
    const providerId = this.data.provider ? this.data.provider.ID : this.filter.selectedProviderId;
    return this.mediaSetService.loadMediaSetList(
        limit,
        offset,
        providerId,
        null,
        this.searchField.searchTerm || null,
        this.filter.selectedSortingId,
        this.data.excludeMediaSetIds).pipe(map(result => ({items: result.mediaSetList, maxCount: result.metaData.maxCount})));
  }

  editMediaSet(mediaSetId: string) {
    const dialogRef = this.dialog.open(MediaSetEditDialogComponent, {
      panelClass: 'media-set-edit-dialog-container',
      maxHeight: '100vh',
      data: {
        mediaSetId: mediaSetId
      }
    });

    const _this = this;
    dialogRef.afterClosed().subscribe((mediaSet: MediaSet | string) => {
      if (typeof mediaSet === 'string' && mediaSet === 'deleted') {
        for (let i = 0; i < _this.items.length; i++) {
          const item = _this.items[i];
          if (item.ID === mediaSetId) {
            _this.items.splice(i, 1);
            _this.count--;
            break;
          }
        }
        if (_this.selectedMediaSet !== null && _this.selectedMediaSet.ID === mediaSetId) {
          _this.selectedMediaSet = null;
        }
      } else if (typeof mediaSet !== 'undefined' && mediaSet !== null) {
        for (let i = 0; i < _this.items.length; i++) {
          const item = _this.items[i];
          if (item.ID === mediaSetId) {
            _this.items[i] = <MediaSet>mediaSet;
            break;
          }
        }
      }
    });
  }
}
