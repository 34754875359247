import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import * as _moment from 'moment';

import { ProductGroup } from '../../classes/productgroup';

import { ConsumerService } from '../consumer/consumer.service';
import { MessageService } from '../message/message.service';

import { environment } from '../../../environments/environment';

const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class ProductGroupService {
  /* URL  Definitionen */
  private static readonly productgroupsUrl = environment.api + '/productgroups';

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private consumerService: ConsumerService,
  ) {
  }



  // tslint:disable-next-line: max-line-length
  public loadProductGroupList(filterProvider?: string): Observable<any> {
    let urlParameter = '?consumerId=775d894039e81626ee51ce0b233f016e&customerId=snoopr';
    //https://8yhhgky631.execute-api.eu-central-1.amazonaws.com/test/productgroups?consumerId=775d894039e81626ee51ce0b233f016e&customerId=snoopr
    //https://8yhhgky631.execute-api.eu-central-1.amazonaws.com/test/productgroups?consumerId=775d894039e81626ee51ce0b233f016e&customerId=snoopr

    return this.http.get(ProductGroupService.productgroupsUrl + urlParameter).pipe(
      map(productGroupList => {
        return {
          productGroupList: productGroupList,
          metaData: (<Array<any>>productGroupList).pop()
        };
      }), tap(e=>console.log("e", e)),
      catchError(this.messageService.handleError('loadproductGroupList', []))
    );
  }

}
