import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { NavigationService } from '../../services/navigation/navigation.service';
import { MediaSetService } from '../../services/media-set/media-set.service';
import { AuthService } from '../../services/auth/auth.service';

import { MediaSet } from '../../classes/media-set';

@Component({
  selector: 'app-media-set-edit-item',
  templateUrl: './media-set-edit-item.component.html',
  styleUrls: ['./media-set-edit-item.component.css']
})
export class MediaSetEditItemComponent implements OnInit, OnDestroy {

  public mediaSet: MediaSet;

  private parameterSubscription: Subscription;

  constructor(
    private router: Router,
    public authService: AuthService,
    private mediaSetService: MediaSetService,
    public navigationService: NavigationService,
    public activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.navigationService.title = 'Medien-Paket bearbeiten';
    this.navigationService.startLoading('media_set_edit_init');

    this.parameterSubscription = this.activatedRoute.params.subscribe(params => {
      this.mediaSetService.loadMediaSet(params['mediaSetId']).subscribe((mediaSet: MediaSet) => {
        this.mediaSet = mediaSet;
        this.navigationService.stopLoading('media_set_edit_init');
      });
    });
  }

  ngOnDestroy() {
    this.parameterSubscription.unsubscribe();
  }

  addMediaSet(): () => void {
    return (() => {
      this.router.navigate(['/media-set-create']);
    });
  }
}
