import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

import { Customer } from '../../classes/customer';
import { MessageService } from '../../services/message/message.service';

import { environment } from '../../../environments/environment';
import { ConsumerService } from '../consumer/consumer.service';
import { AuthService } from '../auth/auth.service';
import { NotifyService, NotifyType } from '../notify/notify.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  /* URL  Definitionen */
  private static readonly customersUrl = environment.api + '/customer';

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private consumerService: ConsumerService,
    private authService: AuthService,
    private notifyService: NotifyService
  ) {
    this.authService.authStateChange$.subscribe(state => {
      if (state === 'signedIn') {
        if (this.authService.isBroker()) {
          this.loadCustomer(this.authService.getCurrentUserCustomerId(), true).subscribe((customer: Customer) => {
            this.authService.setBrokerData(customer.IstAnbieter, customer.Verifiziert, customer.BerechtigtZumVeroeffentlichen);
          }, () => {
            this.authService.logOut();
            this.notifyService.notify(NotifyType.Error, 'Es ist ein Fehler aufgetreten.');
          });
        } else {
          this.authService.setBrokerData(false, false, true);
        }
      }
    });
    if (this.authService.isLoggedIn()) {
      if (this.authService.isBroker()) {
        this.loadCustomer(this.authService.getCurrentUserCustomerId(), true).subscribe((customer: Customer) => {
          this.authService.setBrokerData(customer.IstAnbieter, customer.Verifiziert, customer.BerechtigtZumVeroeffentlichen);
        }, () => {
          this.authService.logOut();
          this.notifyService.notify(NotifyType.Error, 'Es ist ein Fehler aufgetreten.');
        });
      } else {
        this.authService.setBrokerData(false, false, true);
      }
    }
  }

  /**
   * Customer CRUD
   */

  public create(customerModel: Customer): Observable<Customer> {
    const customer: any = {
      ID: customerModel.ID.trim(),
      Name: (customerModel.Name || '').trim(),
      Aktiv: customerModel.Aktiv,
      FreiKontingente: customerModel.FreiKontingente,
      Provider: customerModel.Provider,
      Consumer: customerModel.Consumer,
      Accounts: customerModel.Accounts,
      Rolle: customerModel.Rolle,
      IstPartner: customerModel.IstPartner,
      IstAnbieter: customerModel.IstAnbieter,
      HomepageProgrammAktiv: customerModel.HomepageProgrammAktiv,
      Verifiziert: customerModel.Verifiziert.valueOf
    };

    if (customerModel.HomepageProgrammDaten) {
      customer.HomepageProgrammDaten = customerModel.HomepageProgrammDaten;
    }

	if (customerModel.Rechnungsdaten){
		customer.Rechnungsdaten = customerModel.Rechnungsdaten;
	}

    const http = this.http;
    return new Observable(observer => {
      http.post(CustomerService.customersUrl, customer).subscribe((result: Customer) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // tslint:disable-next-line:max-line-length
  public loadCustomerList(limit?: number, offset?: number, filterSearch?: string, filterType?: string, filterVerifyStatus?: string): Observable<any> {
    let urlParameter = '?consumerId=' + this.consumerService.selectedConsumer.ID + '&bare=true';
    if (typeof limit !== 'undefined' && limit !== null) {
      urlParameter += '&limit=' + limit;
    }
    if (typeof offset !== 'undefined' && offset !== null) {
      urlParameter += '&offset=' + offset;
    }
    if (typeof filterSearch !== 'undefined' && filterSearch !== null) {
      urlParameter += '&filterSearch=' + filterSearch;
    }
    if (typeof filterType !== 'undefined' && filterType !== null) {
      urlParameter += '&filterType=' + filterType;
    }
    if (typeof filterVerifyStatus !== 'undefined' && filterVerifyStatus !== null) {
      urlParameter += '&filterVerifyStatus=' + filterVerifyStatus;
    }
    return this.http.get<Array<Customer>>(CustomerService.customersUrl + urlParameter).pipe(
      map(customerList => {
        return {
          customerList: customerList,
          metaData: (<Array<any>>customerList).pop()
        };
      }),
      catchError(this.messageService.handleError('loadCustomerList', []))
    );
  }

  public loadCustomer(id: string, bare?: boolean): Observable<Customer> {
    let urlParams = '';
    if (bare) {
      urlParams += '?bare=true';
    }
    return this.http.get<Customer>(CustomerService.customersUrl + '/' + id + urlParams).pipe(
      catchError(this.messageService.handleError('loadCustomer', null))
    );
  }

  public update(customerModel: Customer): Observable<Customer> {
    const customer: any = {
      ID: customerModel.ID.trim(),
      Name: (customerModel.Name || '').trim(),
      Aktiv: customerModel.Aktiv,
      FreiKontingente: customerModel.FreiKontingente,
      Provider: customerModel.Provider,
      Consumer: customerModel.Consumer,
      Accounts: customerModel.Accounts,
      IstPartner: customerModel.IstPartner,
      IstAnbieter: customerModel.IstAnbieter,
      HomepageProgrammAktiv: customerModel.HomepageProgrammAktiv,
      Verifiziert: customerModel.Verifiziert
    };

    if (customerModel.HomepageProgrammDaten) {
      customer.HomepageProgrammDaten = customerModel.HomepageProgrammDaten;
    }
	
	if (customerModel.Rechnungsdaten){
		customer.Rechnungsdaten = customerModel.Rechnungsdaten;
	}
	
	

    const http = this.http;
    return new Observable(observer => {
      http.put(CustomerService.customersUrl + '/' + customer.ID, customer).subscribe((result: Customer) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
