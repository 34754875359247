import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  private youtubeRegex: RegExp = /^https?\:\/\/(?:www\.)?(?:(?:youtube\.com\/(?:watch\?(?:.*&)*v=|embed\/))|(?:youtu\.be\/))([\w-]+)(?:&|\?|$)/;

  constructor(
        private sanitizer: DomSanitizer
  ) {
  }

  public isValidYoutubeLink(youtubeLink: string): boolean {
    return this.youtubeRegex.exec(youtubeLink) != null;
  }

  public getVideoId(youtubeLink: string): string {
    const regexpResult = this.youtubeRegex.exec(youtubeLink);
    if (!regexpResult)
      return null;
    return regexpResult[1];
  }

  public getThumbnailUrl(videoId: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://i.ytimg.com/vi/' + videoId + '/mqdefault.jpg');
  }

}
