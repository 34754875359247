import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { CodeEditorDialog } from '../../../classes/code-editor-dialog';
import { NotifyService, NotifyType } from '../../../services/notify/notify.service';

@Component({
  selector: 'app-code-editor-dialog',
  templateUrl: './code-editor-dialog.component.html',
  styleUrls: ['./code-editor-dialog.component.css']
})
export class CodeEditorDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<CodeEditorDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: CodeEditorDialog,
      private notifyService: NotifyService
  ) {

  }
  public options: any;

  ngOnInit(): void {
    this.options = {
        minimap: {
          enabled: false
        },
        smoothScrolling: true,
        theme: 'vs-light',
        language: this.data.Language,
        formatOnPaste: true,
        contextmenu: true
    };
    if (this.data.Language.toLowerCase() === 'json') {
      try {
        this.data.Code = JSON.stringify(JSON.parse(this.data.Code), undefined, 4);
      } catch (e) {
        this.data.Code = '';
      }
    }
  }

  onCloseClick(save: boolean): void {
    if (save && this.data.Language.toLowerCase() === 'json') {
      try {
        const o = JSON.parse(this.data.Code);
        if (o && typeof o === 'object') {
          this.dialogRef.close(save);
          return;
        }
      } catch (e) { }
      this.notifyService.notify(NotifyType.Error, 'Es liegt ein Fehler im Schema vor.');
    } else {
      this.dialogRef.close(save);
    }
  }

  onInit(editor: any): void {
  }
}
