import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable, forkJoin, of } from 'rxjs';
import * as _moment from 'moment';
import { Router } from '@angular/router';

import { Provider } from '../../classes/provider';
import { ItemComponent } from '../../classes/item-component';
import { Editor } from '../../classes/editor';

import { ProviderService } from '../../services/provider/provider.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MessageService } from '../../services/message/message.service';
import { StorageService } from '../../services/storage/storage.service';

import { ConsumerSelectDialogComponent } from '../dialog/consumer-select-dialog/consumer-select-dialog.component';
import { BusinessPlusParameter } from 'src/app/classes/business-plus-parameter';
import { EditorService } from 'src/app/services/editor/editor.service';
import { GenericDialog } from 'src/app/classes/generic-dialog';
import { GenericDialogComponent } from '../dialog/generic-dialog/generic-dialog.component';

export class ProviderEditor extends Editor<ProviderItemComponent, any> {

  constructor(
    public component: ProviderItemComponent,
    private router: Router,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private providerService: ProviderService,
    private isNewProvider: boolean,
    private editorService: EditorService
  ) {
      super(component);
  }

  submit(model: any, type: string): Observable<void> {
    return new Observable(subscriber => {
      this.submitModel(model).subscribe((provider: Provider) => {
        this.editorService.setModel(null);
        subscriber.next();
        subscriber.complete();

        if (this.dialogRef && this.isNewProvider) {
          this.dialogRef.close(provider);
        } else if (this.isNewProvider) {
          this.router.navigate(['/provider-edit', provider.ID]);
        } else if (this.router.url !== '/providers-management/provider') {
          this.router.navigate(['/providers-management', 'provider']);
        } else {
          this.component.editProvider(false);
        }
      }, error => {
        subscriber.error(error);

        this.messageService.showError('Beim Aktualisieren des Produktgebers ist es zu einem Fehler gekommen: '
         + error.error.message, error);
      });
    });
  }

  submitModel(model): Observable<Provider> {
    // TODO: Warndialog?
    const iconFile: File = model.IconDatei ? model.IconDatei : null;
    const avatarFile: File = model.AvatarDatei ? model.AvatarDatei : null;

    if (this.isNewProvider) {
      return new Observable(subscriber => {
        this.providerService.create(model).subscribe((provider: Provider) => {
          if ((iconFile && (<any>iconFile) !== true) || (avatarFile && (<any>avatarFile) !== true)) {
            forkJoin([
              (iconFile && (<any>iconFile) !== true) ? this.providerService.uploadProviderIcon(iconFile, model) : of(undefined),
              (avatarFile && (<any>avatarFile) !== true) ? this.providerService.uploadProviderAvatar(avatarFile, model) : of(undefined),
            ]).subscribe(() => {
              subscriber.next(provider);
              subscriber.complete();
            });
          } else {
            subscriber.next(provider);
            subscriber.complete();
          }
        });
      });
    }

    return new Observable(subscriber => {
      this.providerService.update(model).subscribe((provider: Provider) => {

        if (iconFile || avatarFile) {
          forkJoin([
            iconFile ? this.providerService.uploadProviderIcon(iconFile, model) : of(undefined),
            avatarFile ? this.providerService.uploadProviderAvatar(avatarFile, model) : of(undefined),
          ]).subscribe(() => {
            subscriber.next(provider);
            subscriber.complete();
          });
        }
      });
    });
  }
}


@Component({
  selector: 'app-provider-item',
  templateUrl: './provider-item.component.html',
  styleUrls: ['./provider-item.component.css']
})
export class ProviderItemComponent extends ItemComponent implements OnInit {

  @Input() public provider: Provider;
  @Input() public panelOpenState = false;
  @Input() public info = false;
  @Input() public edit = false;
  @Input() public cancelRedirectUrl = '';
  @Input() public dialogRef: MatDialogRef<any>;

  public editor: ProviderEditor;
  public editorInitialized = false;

  public isNewProvider = false;

  private nextId = 0;

  constructor(
    public dialog: MatDialog,
    private providerService: ProviderService,
    private router: Router,
    private navigationService: NavigationService,
    private messageService: MessageService,
    private storageService: StorageService,
    private editorService: EditorService
  ) {
    super();
  }

  createEditorSteps(): void {
    // create step 1
    this.editor.steps.push({
      label: 'Grundeinstellungen',
      fields: [{
        type: 'input',
        key: 'ID',
        templateOptions: {
          label: 'ID',
          appearance: 'outline',
          description: 'Die eindeutige ID ist der Schlüssel des Produktgebers.',
          maxLength: 256,
          required: true,
          disabled: !this.isNewProvider
        }
      }, {
        template: '<br>'
      }, {
        type: 'input',
        key: 'Name',
        templateOptions: {
          label: 'Name',
          appearance: 'outline',
          description: 'Der Name ist die dargestellte Bezeichnung des Produktgebers.',
          maxLength: 256,
          required: true
        }
      }, {
        template: '<br><p><strong>Vertriebsvereinbarungstext</strong></p>',
        hide: this.provider.AnbieterRolle === 'Makler'
      }, {
        type: 'quill',
        key: 'Vertriebsvereinbarung',
        templateOptions: {
          required: false,
          label: 'Vertriebsvereinbarungstext',
          floatLabel: 'always',
          appearance: 'outline',
          description: '',
          rows: 8,
          maxLength: 1000,
          maxTextLength: 500,
          tools: [
            'fett', 'kursiv', 'unterstrichen',
            'ueberschrift1', 'ueberschrift2', 'ueberschrift3',
            'nummerierte-liste', 'liste', 'link',
            'formatierung_entfernen'
          ]
        },
        hide: this.provider.AnbieterRolle === 'Makler'
      }, {
        template: '<br>'
      }, {
        type: 'checkbox',
        key: 'Vermittlernummer',
        templateOptions: {
          label: 'Vermittlernummer hinterlegbar',
          description: 'Markler können Ihre Vermittlernummer für diesen Produktgeber hinterlegen.',
          indeterminate: false
        }
      }, {
        template: '<br><br><strong>Avatar des Produktgebers</strong>',
        hide: this.provider.AnbieterRolle === 'Makler'
      }, { // Dateiupload
        key: 'AvatarDatei',
        type: 'image-crop',
        className: 'flex-1',
        templateOptions: {
          label: 'Dateiauswahl',
          floatLabel: 'always',
          appearance: 'outline',
          accept: '.jpg,.jpeg,.png,.gif',
          previewImage: 'public/assets/broker/_DEFAULT/avatar',
          image: this.isNewProvider ? null : 'public/assets/provider/' + this.provider.ID + '/avatar',
          disabled: false,
          maintainAspectRatio: true,
          isRound: true,
          aspectRatio: 1 / 1,
          resizeToWidth: 200,
          resizeToHeight: 200,
          cropperMinWidth: 100,
          cropperMinHeight: 100,
          previewHeight: 80,
          previewWidth: 80,
          onImageChange: (model, image) => {
            if (this.provider.AnbieterRolle !== 'Makler') {
              this.editor.editorComponent.validateControlByKey('AvatarImageValidator');
            }
          }
        },
        hideExpression: () => this.provider.AnbieterRolle === 'Makler'
      }, {
        key: 'AvatarImageValidator',
        type: 'hidden',
        validators: {
          required: {
            expression: (field) => {
              return  typeof (<any>this.editor.model).AvatarDatei !== 'undefined' && (<any>this.editor.model).AvatarDatei !== null;
            },
            message: () => 'Es muss ein Avatar hochgeladen werden.'
          }
        },
        hideExpression: () => this.provider.AnbieterRolle === 'Makler'
      }, {
        template: '<br><br><strong>Logo des Produktgebers</strong>',
        hide: this.provider.AnbieterRolle === 'Makler'
      }, { // Dateiupload
        key: 'IconDatei',
        type: 'image-crop',
        className: 'flex-1',
        templateOptions: {
          label: 'Dateiauswahl',
          floatLabel: 'always',
          appearance: 'outline',
          accept: '.jpg,.jpeg,.png,.gif',
          previewImage: 'public/assets/broker/_DEFAULT/logo',
          image: this.isNewProvider ? null : 'public/assets/provider/' + this.provider.ID + '/logo',
          disabled: false,
          skipCropping: true,
          maintainAspectRatio: true,
          isRound: false,
          aspectRatio: 16 / 3,
          cropperMinWidth: 160,
          cropperMinHeight: 30,
          previewHeight: 50,
          onImageChange: (model: any, image: any, originalImage: any, isAppliedExternal: boolean) => {
            this.editor.editorComponent.validateControlByKey('LogoImageValidator');
          },
          onImageURLChange: (model: any, imageURL: string, isAppliedExternal: boolean) => {
            if (this.provider.AnbieterRolle !== 'Makler') {
              this.provider.Bild = imageURL;
            }
          }
        },
        hideExpression: () => this.provider.AnbieterRolle === 'Makler'
      }, {
        key: 'LogoImageValidator',
        type: 'hidden',
        validators: {
          required: {
            expression: (field) => {
              return  typeof (<any>this.editor.model).IconDatei !== 'undefined' && (<any>this.editor.model).IconDatei !== null;
            },
            message: () => 'Es muss ein Logo hochgeladen werden.'
          }
        },
        hideExpression: () => this.provider.AnbieterRolle === 'Makler'
      }]
    });

    // create step 2
    this.editor.steps.push({
      label: 'Consumer',
      fields: [{
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{
          key: 'Consumer',
          type: 'repeat',
          className: 'flex-1',
          fieldArray: {
            fieldGroupClassName: 'display-flex',
            templateOptions: {
              label: 'Consumer hinzufügen',
              disabled: false,
              isCustom: true,
              customAdd: () => {
                const excludeIds = [];
                for (let i = 0; i < this.provider.Consumer.length; i++) {
                  excludeIds.push(this.provider.Consumer[i].ID);
                }

                const dialogRef = this.dialog.open(ConsumerSelectDialogComponent, {
                  panelClass: 'consumer-select-dialog-container',
                  maxHeight: '100vh',
                  data: {
                    excludeConsumerIds: excludeIds
                  }
                });
                return dialogRef.afterClosed();
              }
            },
            fieldGroup: [{
              className: 'flex-1',
              type: 'consumer'
            }]
          }
        }]
      }]
    });

    // create step 3: Parameter
    this.editor.steps.push({
      label: 'Business Plus Parameter Verwaltung',
      fields: [{
        fieldGroup: [{
          key: 'BusinessPlusParameterListe',
          type: 'repeat',
          fieldArray: {
            templateOptions: {
              label: 'Parameter hinzufügen',
              maxLength: 50,
              disabled: false,
              divider: true,
              space: '15px',
              customAdd: () => {
                const parameter: BusinessPlusParameter = new BusinessPlusParameter();
                parameter.Beschreibung = '';
                parameter.Wert = '';
                parameter.LinkID = this.nextId++;
                return of(parameter);
              },
              customRemove: (model) => {
                const genericDialog = new GenericDialog();

                const beschreibung = model && model.Beschreibung !== '' ? ' <strong>' + model.Beschreibung + '</strong> ' : ' ';
                genericDialog.titel = 'Link/Parameter löschen ?';
                genericDialog.text = 'Soll der Link/Parameter' + beschreibung + 'endgültig gelöscht werden?';
                const dialogRef = this.dialog.open(GenericDialogComponent, {
                  maxWidth: '500px',
                  maxHeight: '100vh',
                  data: genericDialog
                });
                
                return new Observable((subscriber) => {
                  dialogRef.afterClosed().subscribe(result => {
                    if (result === true) {
                      if (model.Schluessel) {
                        model.Inaktiv = true;
                        subscriber.next(false);
                      } else {
                        subscriber.next(true);
                      }
                      subscriber.complete();
                    }
                  });
                });
              }
            },
            fieldGroup: [{
              template: '<p><strong>Angaben für Business Plus Verlinkungen</strong></p>'
            }, {
              type: 'input',
              key: 'Beschreibung',
              templateOptions: {
                label: 'Name oder Beschreibung für diesen Parameter',
                placeholder: 'z.B. PHV-Rechner, VGV-Rechner',
                appearance: 'outline',
                required: true
              },
            }, {
              type: 'input',
              key: 'Wert',
              templateOptions: {
                label: 'Wert oder Url des Parameters',
                placeholder: 'z.B. http://www.link-zum-phv-rechner.de',
                appearance: 'outline',
                required: true
              },
            }]
          }
        }]
      }]
    });


  }

  initEditor() {
    if (!this.editorInitialized) {
      this.navigationService.startLoading('provider_editor_init');

      this.editor = new ProviderEditor(
        this,
        this.router,
        this.messageService,
        this.dialog,
        this.dialogRef,
        this.providerService,
        this.isNewProvider,
        this.editorService
      );

      // tslint:disable-next-line:max-line-length
      this.editor.setInfo1(() => '');
      this.editor.setInfo2(() => '');
      this.editor.title = 'EDITOR';

      this.createEditorSteps();
      this.editorInitialized = true;

      this.editorInitialized = true;
      setTimeout(() => {
        this.editorService.setModel(this.provider);
      }, 0);

      this.navigationService.stopLoading('provider_editor_init');

      this.nextId = 0;
      // nextId
      if (this.provider.BusinessPlusParameterListe && this.provider.BusinessPlusParameterListe.length > 0) {
        this.provider.BusinessPlusParameterListe.forEach(parameter => {
          if (parameter.LinkID >= this.nextId) {
            this.nextId = parameter.LinkID + 1;
          }
        });
      }
    }
  }

  ngOnInit() {
    this.isNewProvider = this.provider.ID === '';
    if (this.edit) {
      this.panelOpenState = true;
      this.initEditor();
    }

    if (!this.isNewProvider) {
      const bild = this.provider.Bild;
      this.provider.Bild = '';
      this.storageService.getSignedUrl(bild).subscribe(url => {
        this.provider.Bild = url;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.editorInitialized) {
      this.editorService.setModel(null);
    }
  }

  editProvider(edit: boolean) {
    this.info = false;
    this.edit = edit;

    if (edit) {
      this.initEditor();
    } else if (this.dialogRef) {
      this.dialogRef.close(null);
    } else if (this.cancelRedirectUrl) {
      this.router.navigate([this.cancelRedirectUrl]);
    } else if (this.editorInitialized) {
      this.editorInitialized = false;
    }
  }
}
